import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MinLoader, useModal } from 'components';
import { httpRequestsOnSuccessActions, PaymentActions } from 'store';
import { FindLoad, FindSuccess, FIRST_PACKAGE, MODAL_NAMES, SECOND_PACKAGE } from 'utils';

const ACTION_TYPE = 'ACTIVATE_PACKAGE';

export const UpgradeDowngrade = () => {
    const history = useHistory();
    const { close, params, open } = useModal();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const dispatch = useDispatch();
    const renderTitle = params?.modalType === 'upgrade' ? 'Upgrade Subscription' : 'Downgrade Subscription';
    const renderText = params?.modalType === 'upgrade' ?
        'Upgrade your subscription now to access advanced features. Upgrade and improve your capabilities.'
        :
        'If you downgrade your subscription plan, we automatically adjust your account settings to reflect the downgraded plan.';

    useEffect(() => {
        if (success) {
            // dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            // history.push('/plansAndPricing');

            close();
            open(MODAL_NAMES.SUCCESS_CANCEL, { modalInfo: 'downgrade', modalType: 'success' });
        }
    }, [success]);

    const handleChancelSubscription = () => {
        const packageType =  params?.packageType ;
        dispatch(PaymentActions.activatePackage(packageType));
    };

    return (
        <div className='cancel-confirm'>
            <p className='chancel-title'>{renderTitle}</p>
            <div className='cancel-options'>
                <p>{renderText}</p>
            </div>
            <div className='actions'>
                <button className='no-button' onClick={() => close()}>
                    No
                </button>
                <button className='yes-button' onClick={() => handleChancelSubscription()}>
                    {loader?.length ?
                        <MinLoader color={'white'} /> : params?.modalType === 'upgrade' ? 'Upgrade' : 'Downgrade'}
                </button>
            </div>
        </div>
    );
};
