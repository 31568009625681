import { GET_MY_PROFILE_SUCCESS, SIGN_IN_SUCCESS } from './auth.type';

const initialState = {
    accessToken: null,
    myProfile: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.data.token,
            };

        /** My Profile */

        case GET_MY_PROFILE_SUCCESS:
            return {
                ...state,
                myProfile: action.payload,
            };

        /** End */
        default:
            return state;
    }
};
