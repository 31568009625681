import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useModal, SubmitButton, CustomInput, ForgotPassword, AlreadyHave } from 'components';
import { authActions } from 'store/auth';
import { FindError, MODAL_NAMES, EmailValidator, FindSuccess } from 'utils';
import { Svg } from 'assets/images';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

const REQUEST_TYPE = 'SIGN_IN';

export const SignInModal = () => {
    const { open, close } = useModal();
    const dispatch = useDispatch();
    const backError = FindError(REQUEST_TYPE);
    const success = FindSuccess(REQUEST_TYPE);

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    useEffect(() => {
        return () => dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
    }, []);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
        }
    }, [success]);

    const onSubmit = (data) => {
        dispatch(authActions.signIn(data));
    };

    const handleSignUp = () => {
        close(MODAL_NAMES.SIGN_IN);
        open(MODAL_NAMES.SIGN_UP);
    };

    const handleForgotPassword = () => {
        close(MODAL_NAMES.SIGN_IN);
        open(MODAL_NAMES.FORGOT_PASSWORD);
    };


    return (
        <div className='modal-width'>
            <form onSubmit={handleSubmit(onSubmit)} className='form-messages'>
                <CustomInput
                    imgSrc={errors?.email ? Svg.SmsRed : Svg.EmailInput}
                    name='email'
                    placeholder='Email'
                    control={control}
                    rules={{
                        required: true,
                        pattern: {
                            value: EmailValidator,
                            message: 'Must be valid email',
                        },
                    }}
                    type='email'
                    errMessage={
                        backError?.error === 'User with this email was not found.' ? 'User with this email was not found.' :
                            backError?.error === 'This user`s account is not active.' ? 'This user`s account is not active.' : ''
                    }
                />
                <CustomInput
                    imgSrc={errors?.password ? Svg.LockRed : Svg.Lock}
                    control={control}
                    name='password'
                    placeholder='Password'
                    type='password'
                    // rules={{
                    //     required: true,
                    //     pattern: {
                    //         value: PasswordValidator,
                    //         message: 'Not valid Password',
                    //     },
                    // }}
                    errMessage={backError?.error === 'User password does not match.' && 'User password does not match.'}
                />

                <SubmitButton title='Sign In' type='submit' actionType={REQUEST_TYPE} />
                <ForgotPassword handleClick={() => handleForgotPassword()} />
                <AlreadyHave handleClick={handleSignUp} buttonText='Sign-up' questionText='Don’t have an account?' />
            </form>
        </div>
    );
};
