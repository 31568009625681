import React, { useState } from 'react';
import { UploadServices } from 'store';
import * as Styled from './styled';
import { Svg } from 'assets/images';
import { docLogo } from 'utils';
import { LinearProgress } from '@mui/material';
import NoUploadedFiles from './noUploadedFiles';
export const UploadDocument = ({ onChange, value, docs = [] }) => {
    const [loading, setLoading] = useState(false);
    const handleChange = async (e) => {
        try {
            setLoading(true);
            if (e.target.files[0]) {
                let formData = new FormData();
                formData.append('files', e.target.files[0]);
                const res = await UploadServices.UploadImage(formData);
                if (res) {
                    onChange(res.data);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Styled.UploadContainer>
            <Styled.UploadLabel onChange={handleChange} value={value}>
                {loading ? (
                    <div className="loading">
                        <LinearProgress style={{ background: '#E1F2F3' }} />
                    </div>
                ) : (
                    <>
                        <input type="file" />
                        <img src={Svg.Upload} alt="Upload" />
                        <div>
                            Drag & Drop or <span>Browse</span> file
                        </div>
                        <span>Supported formats: pdf, jpeg, png</span>
                    </>
                )}
            </Styled.UploadLabel>
            <div>
                {docs.map((item, _, arr) => (
                    <>
                        <div key={item.id}>
                            {arr.length ? (
                                <>
                                    <img alt="doc" width="42px" height="42px" src={docLogo(item?.file?.url)} />
                                    <span>{item?.file?.name}</span>
                                    <img
                                        alt="remove"
                                        src={Svg.CloseRed}
                                        onClick={() => {
                                            onChange(item, 'remove');
                                        }}
                                    />
                                </>
                            ) : (
                                <NoUploadedFiles open={true} />
                            )}
                        </div>
                    </>
                ))}
            </div>
        </Styled.UploadContainer>
    );
};
