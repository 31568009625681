/* Drrivers Reducer */

import {
    GET_DRIVERS_SUCCESS,
    GET_DRIVER_DETAILS_SUCCESS,
    GET_DRIVERS_RATE_SUCCESS,
    GET_DRIVER_DOCUMENT_SUCCESS,
} from './drivers.type';

const initialState = {
    driversList: [],
    driversDetailsList: [],
    driversRateList: [],
    driverDocuments: null
};

export const drivingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DRIVERS_SUCCESS:
            return {
                ...state,
                driversList: action.payload,
            };
        case GET_DRIVER_DETAILS_SUCCESS:
            return {
                ...state,
                driversDetailsList: action.payload,
            };
        case GET_DRIVERS_RATE_SUCCESS:
            return {
                ...state,
                driversRateList: action.payload,
            };

        /** Driver Documents */
        case GET_DRIVER_DOCUMENT_SUCCESS:
            return {
                ...state,
                driverDocuments: action.payload,
            };
        /** End */

        default:
            return state;
    }
};
