import {
    getTrailer,
    createTrailer,
    editTrailer,
    deleteTrailer,
    changeTrailerStatus,
    getTrailerId,
    createTrailerDocument,
    editTrailerDocumentArchive,
    deleteTrailerDocument,
    getTrailerDocument,
    getTrailerCoast,
    createTrailerCosts,
} from './trailers.action';
export { traileredReducer } from './trailers.reducer';
export { watchTrailer } from './trailers.saga';

export const trailerActions = {
    getTrailer,
    createTrailer,
    editTrailer,
    deleteTrailer,
    changeTrailerStatus,
    getTrailerId,
    /** Trailer Documents */
    createTrailerDocument,
    getTrailerDocument,
    editTrailerDocumentArchive,
    deleteTrailerDocument,
    /** End */
    /** Trailer Coasts */
    createTrailerCosts,
    getTrailerCoast,
    /** End */
};
