import { PriceConvertor } from '../../../../components/table';


export const CoastCard = (props) => {
    return (
        <>
            {props?.type === 'amount' ?
                <div className='coast-card coast-card-green'>
                    <div className='flex'>
                        <p className='amount-card-title'>{props?.name}</p>
                    </div>
                    <p className='card-price'>
                        {props?.convert ?
                            <PriceConvertor price={props?.price ? props?.price : 0} />
                            :
                            props?.price
                        }
                    </p>
                </div>
                :
                <div style={props?.noRight ? { marginRight: 0 } : {}} className='coast-card coast-card-gray'>
                    <div className='flex'>
                        <img src={props?.icon} alt={'icon'} />
                        <p className='card-title'>{props?.name}</p>
                    </div>
                    <p className='card-price'>
                        {props?.convert ?
                            <PriceConvertor price={props?.price ? props?.price : 0} />
                            :
                            <p>
                                {props?.price}{props?.priceText &&
                                <span>/&nbsp;<span className='price-text'>{props?.priceText}</span></span>}
                            </p>
                        }
                    </p>
                </div>
            }
        </>
    );
};