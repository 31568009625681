import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { DateRow, Loader, NoDataComponent, PaginationItem, PopperComponent, StatusBtn, TextRow } from 'components';
import { httpRequestsOnSuccessActions, userActions } from 'store';
import { FindLoad, MODAL_NAMES, SendPageSave } from 'utils';
import { ACTION_TYPE } from './constants';

export const UsersMobile = ({ list }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const loader = FindLoad(ACTION_TYPE);

    const handleClick = (item, name) => {
        if (name === 'DELETE_USER') {
            dispatch(userActions.deleteUser(item?.auth?.id));
        } else if (name === 'RESEND') {
            dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', item?.auth?.id));
            dispatch(userActions.resendInvite(item?.auth?.id));
        } else if (name === 'Activate' || name === 'Inactivate') {
            const status = name === 'Activate';
            dispatch(userActions.changeUserStatus(status, item?.auth?.id));
        }
    };

    const UserMenu = (item) => {
        return [
            {
                title: 'Edit',
                params: { info: item, modalTitle: 'Edit User Information' },
                name: MODAL_NAMES.USER_INVITE_MODAL,
            },
            item?.auth?.status === 'PENDING'
                ? {
                      title: item?.auth?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                      name: MODAL_NAMES.INACTIVATE_MODAL,
                      params: {
                          title: item?.fullName,
                          modalType: item?.auth?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                          subTitle: 'Inactivating a user will not active in the system. You can activate user when do you want.',
                          actionType: 'CHANGE_USER_STATUS',
                          handleChange: () => handleClick(item?.auth?.status === 'ACTIVE' ? 'Inactivate' : 'Activate', item),
                      },
                  }
                : null,
            {
                title: 'Delete',
                name: MODAL_NAMES.DELETE_MODAL,
                params: {
                    title: item?.fullName,
                    subTitle: 'Deleting a user will permanently remove it from the system delete',
                    actionType: 'DELETE_USER',
                    handleDelete: () => handleClick('DELETE_USER', item),
                },
                color: '#D03325',
            },
        ];
    };

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <>
            {list?.users?.length ? (
                <>
                    {list?.users?.map((item, j) => (
                        <div key={j} className="card-box">
                            <div className="user-card" onClick={handleClick}>
                                <div key={j}>
                                    <Box className="card-header">
                                        <div className="header-btn">
                                            <PopperComponent list={UserMenu(item)} />
                                        </div>
                                    </Box>

                                    <Box className="card-content">
                                        <div className="flex" style={{ width: '100%' }}>
                                            <span className="text-green">
                                                <TextRow name={item?.fullName} />
                                            </span>
                                        </div>
                                        <div className="user-mobile-text">
                                            <span className="text-bold">Email:</span>
                                            <span className="text-space" />
                                            <span className="text-email">
                                                <TextRow name={item?.email} />
                                            </span>
                                        </div>
                                        <div className="space-between">
                                            <div className="text-unic">
                                                <DateRow date={item?.createdAt} />
                                            </div>
                                            <div className="flex">
                                                <StatusBtn handleClick={(type) => item(type)} info={item} />
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    ))}

                    {list?.count !== 0 && (
                        <div className="mobile-pagination" style={{ padding: '16px 0' }}>
                            <PaginationItem
                                listLength={list?.users?.length}
                                page={info?.page}
                                handleReturn={(number) => SendPageSave(number, info, history)}
                                count={list?.count}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div style={{ height: '100%' }}>
                    <NoDataComponent />
                </div>
            )}
        </>
    );
};
