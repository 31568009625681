import { useModalBase } from 'utils';
import { Svg } from '../../assets/images';

export const ModalHeader = ({ title }) => {
    const { closeModal } = useModalBase();

    return (
        <div className='modal-header'>
            <p className='custom-modal-title'>{title}</p>
            <button type='button' className='close-button' onClick={() => closeModal()}>
                <img src={Svg.CloseModal} alt='Close-Modal' />
            </button>
        </div>
    );
};


export const ModalHeaderNoPadding = ( ) => {
    const { closeModal } = useModalBase();

    return (
        <div className='modal-header-no-padding'>
            <button type='button' className='close-button' onClick={() => closeModal()}>
                <img src={Svg.CloseModal} alt='Close-Modal' />
            </button>
        </div>
    );
};