import React from 'react';
import { FullTable, TableWrapper } from 'components';
import { useSelector } from 'react-redux';
import { loadsBody, loadsHead } from '../../loadBoard/constants';

export const LoadsFragment = ({ title, loadList, ACTION_TYPE }) => {
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));

    return (
        <div className="loads-container">
            <div className="loads-table">
                <TableWrapper
                    firstButton={'Active'}
                    secondButton={'Inactive'}
                    addButton={'Add Load'}
                    buttonsTab={false}
                    tab={false}
                    buttonsTabAddButton={false}
                    wrapperTitile={title}>
                    <FullTable
                        head={loadsHead(activePackage)}
                        body={loadsBody(activePackage)}
                        loadingType={ACTION_TYPE}
                        list={loadList?.loads}
                        listCount={loadList?.count}
                        noText={'Truck Load'}
                    />
                </TableWrapper>
            </div>
        </div>
    );
};
