import { call, put, takeLatest } from 'redux-saga/effects';
import { trailerService } from './trailers.service';
import {
    GET_TRAILER,
    GET_TRAILER_SUCCESS,
    CREATE_TRAILER,
    EDIT_TRAILER,
    DELETE_TRAILER,
    CHANGE_TRAILER_STATUS,
    GET_TRAILER_ID,
    GET_TRAILER_ID_SUCCESS,
    CREATE_TRAILER_DOCUMENT,
    EDIT_TRAILER_DOCUMENT_ARCHIVE,
    DELETE_TRAILER_DOCUMENT,
    GET_TRAILER_DOCUMENT,
    GET_TRAILER_DOCUMENT_SUCCESS, GET_TRAILER_COASTS, GET_TRAILER_COASTS_SUCCESS, CREATE_TRAILER_COASTS,
} from './trailers.type';
import { httpRequestsOnErrorsActions } from 'store/http_requests_on_errors';
import { httpRequestsOnLoadActions } from 'store/http_requests_on_load';
import { httpRequestsOnSuccessActions } from 'store/http_requests_on_success';

/* Get Trailer */
function* getTrailerList({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(trailerService.getTrailer, payload?.body);
        yield put({
            type: GET_TRAILER_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Create Trailer */
function* createTrailer({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(trailerService.createTrailer, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Edit Trailer */
function* editTrailer({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(trailerService.editTrailer, payload.body, payload.id);
        yield put({
            type: GET_TRAILER_ID,
            payload: { id: payload.id, load:'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/* Delete Trailer  */
function* deleteTrailer({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(trailerService.deleteTrailer, payload);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Change Status Trailer */
function* changeTrailerStatus({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(trailerService.changeStatusTrailer, payload.id, payload.active);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Get Trailers Id */

function* getTrailerId({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if(payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(trailerService.getTrailersId, payload?.id);
        yield put({
            type: GET_TRAILER_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** Trailer Documents */

function* createTrailerDocument({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(trailerService.createTrailerDocument, payload?.id, payload?.body);
        yield put({
            type: GET_TRAILER_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getTrailerDocument({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(trailerService.getTrailerDocument, payload?.id);
        yield put({
            type: GET_TRAILER_DOCUMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editTrailerDocumentArchive({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(trailerService.editTrailerDocumentArchive, payload.id, payload.docId, payload.archive);
        yield put({
            type: GET_TRAILER_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type))
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteTrailerDocument({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(trailerService.deleteTrailerDocument, payload.id, payload.docId);
        yield put({
            type: GET_TRAILER_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End */

/** Trailer Coast */

function* trailerCoasts({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    if(payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(trailerService.trailerCoastService, payload.id);
        yield put({
            type: GET_TRAILER_COASTS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* createTrailerCoasts({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(trailerService.createTrailerCoastService, payload?.id, payload?.body);
        yield put({
            type: GET_TRAILER_COASTS,
            payload: { id:payload.id, load:'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

export const watchTrailer = function* watchTrailerAuth() {
    yield takeLatest(GET_TRAILER, getTrailerList);
    yield takeLatest(CREATE_TRAILER, createTrailer);
    yield takeLatest(EDIT_TRAILER, editTrailer);
    yield takeLatest(DELETE_TRAILER, deleteTrailer);
    yield takeLatest(CHANGE_TRAILER_STATUS, changeTrailerStatus);
    yield takeLatest(GET_TRAILER_ID, getTrailerId);

    /** Trailer Documents */
    yield takeLatest(CREATE_TRAILER_DOCUMENT, createTrailerDocument);
    yield takeLatest(GET_TRAILER_DOCUMENT, getTrailerDocument);
    yield takeLatest(EDIT_TRAILER_DOCUMENT_ARCHIVE, editTrailerDocumentArchive);
    yield takeLatest(DELETE_TRAILER_DOCUMENT, deleteTrailerDocument);
    /** End */

    /** Trailer Coast */
    yield takeLatest(GET_TRAILER_COASTS, trailerCoasts);
    yield takeLatest(CREATE_TRAILER_COASTS, createTrailerCoasts);
    /** End */
};
