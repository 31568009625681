import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MinLoader, useModal } from 'components';
import { FindLoad, FindSuccess, SavePage } from 'utils';
import { httpRequestsOnSuccessActions } from 'store';

export const InactivateUser = () => {
    const { close, params } = useModal();
    const loader = FindLoad(params?.actionType);
    const success = FindSuccess(params?.actionType);
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(params?.actionType));
            close();
            SavePage(history, pushInfo, { load: 'noLoad' });
        }
    }, [success]);

    return (
        <div className="inactivate-form">
            <div className="inactivate-title">
                <p>{`Do you want ${params?.modalType}`}</p>
                <span className="green-title">{params?.title}</span>
                <span> ? </span>
            </div>

            <p className="inactivate-description">{params?.subTitle}</p>

            <div className="space-between">
                <button style={{ marginRight: '16px' }} className="save-green-btn" onClick={() => params?.handleChange()}>
                    {loader ? <MinLoader color={'white'} /> : params?.modalType}
                </button>

                <button className="cancel-gray-btn" onClick={() => close()}>
                    Cancel
                </button>
            </div>
        </div>
    );
};
