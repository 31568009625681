import React, { useState } from 'react';
import axios from 'axios';
import { MinLoader } from 'components';
import { Svg } from 'assets/images';
const DownloadFile = ({ item, icon }) => {
    const [downloadLoading, setDownloadLoading] = useState(false);

    const saveFile = async ( ) => {
        setDownloadLoading(item?.id);
        try {
            const response = await axios({
                url: `/files/download`,
                method: 'POST',
                responseType: 'blob',
                data: item?.file,
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const fileIndex = response?.data?.type?.search('/');
            const fileType = response?.data?.type?.slice(fileIndex + 1);
            let filename = item?.file?.originalName ? item?.file?.originalName : `file.${fileType === 'svg+xml' ? 'svg' : fileType}`;

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } finally {
            setDownloadLoading('');
        }
    };

    // const saveFile = async (id, i) => {
    //     setDownloadLoading(true);
    //     try {
    //         if (i?.file) {
    //             const texIndex = i?.file.url.lastIndexOf('.');
    //             const fileType = i?.file.url.substring(texIndex);
    //             const token = localStorage.getItem('access-token');
    //             await axios({
    //                 url: `/files/download/${id}`,
    //                 method: 'GET',
    //                 responseType: 'blob',
    //                 headers: { 'access-token': token },
    //             }).then((response) => {
    //                 const url = window.URL.createObjectURL(new Blob([response.data]));
    //                 const link = document.createElement('a');
    //                 link.href = url;
    //                 link.setAttribute('download', `file${fileType}`);
    //                 document.body.appendChild(link);
    //                 link.click();
    //             });
    //         }
    //     } finally {
    //         setDownloadLoading(false);
    //     }
    // };

    return (
        <>
            {downloadLoading ? (
                <MinLoader color={'#0E9594'} margin={0} position="initial" />
            ) : (
                <img src={icon || Svg.Download} alt="download" onClick={ saveFile } />
            )}
        </>
    );
};

export default DownloadFile;
