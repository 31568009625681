import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { CreateCancel, CustomTextArea, ModalHeader } from 'components';
import { FindSuccess, useModalBase } from 'utils';
import { httpRequestsOnSuccessActions, noteActions } from 'store';

export const AddNotes = ({ modelId, note, type }) => {
    const ACTION_TYPE = note ? 'EDIT_NOTE' : 'CREATE_NOTE';
    const dispatch = useDispatch();
    const success = FindSuccess(ACTION_TYPE);
    const { closeModal } = useModalBase();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            text: note?.text,
        },
    });

    const onSubmit = (data) => {
        const info = {
            ...data,
            'resource': modelId,
            'onModel': type,
        };
        if (note) {
            info.noteId = note?.id;
            dispatch(noteActions.editNote(info));
        } else {
            delete info.noteId;
            dispatch(noteActions.addNote(info));
        }
    };

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            closeModal();
        }
    }, [success]);

    return (
        <div className='add-note-wrapper'>
            <ModalHeader title={note ? 'Edit Note' : 'Add Note'} />
            <div className='modal-body'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomTextArea
                        name={'text'}
                        id={'text'}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={''}
                        label={'Note'}
                        placeholder={'Write note...'}
                        maxLength={1000}
                        height='big'
                        showMaxCount={true}
                    />
                    <CreateCancel
                        title={note ? 'Edit' : 'Add'}
                        actionType={ACTION_TYPE}
                    />
                </form>
            </div>
        </div>
    );
};