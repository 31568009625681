import { useHistory } from 'react-router-dom';
import { StyledPaymentMethod } from './style';
import { Svg } from 'assets/images';

export const PaymentMethod = ({ info }) => {
    const route = useHistory();

    return (
        <StyledPaymentMethod>
            <p className="title">Card Information</p>
            <div className="cards-wrapper-section">
                <div className="card-wrapper">
                    <div className="visa-info">
                        <p>
                            {info?.card === 'visa' ? 'Visa' : info?.card === 'mastercard' ? 'Master' : ''}
                            Card
                        </p>
                        <img src={info?.card === 'visa' ? Svg.Visa : Svg.Master} alt="Visa" />
                    </div>

                    <div className="card-date">
                        <p>{`**** **** **** ${info?.last4}`}</p>
                        <p>{`${info?.exp_month}/${info?.exp_year}`}</p>
                    </div>

                    <div className="action-wrapper">
                        <button onClick={() => route.push(`/checkout?editCard=true`)} type="button" className="edit-btn">
                            Edit Card
                        </button>
                        {/*<button type='button' className='delete-btn'>*/}
                        {/*    Delete*/}
                        {/*</button>*/}
                    </div>
                </div>
            </div>
            <div className="we-accept-wrapper">
                <p className="we-accept">We Accept</p>
                <img src={Svg.Visa} alt="Visa" />
                <img src={Svg.Master} alt="Master" />
            </div>
        </StyledPaymentMethod>
    );
};
