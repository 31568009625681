import moment from 'moment';
import { StyledInvoiceTable } from './style';
import { invoiceTHeadCaptions } from '../../../payments/constants';
import { Svg } from 'assets/images';
import { FIRST_PACKAGE, FREE_PACKAGE, PREMIUM_PACKAGE, SECOND_PACKAGE } from 'utils';

export const InvoiceTable = ({ invoices }) => {
    const renderPackage = (type) => {
        if (type === 0) {
            return FREE_PACKAGE;
        } else if (type === 499) {
            return FIRST_PACKAGE;
        } else if (type === 999) {
            return SECOND_PACKAGE;
        } else if (type === 1999) {
            return PREMIUM_PACKAGE;
        }
        {
            return '...';
        }
    };

    const renderPrice = (amountPaid) => {
        if (amountPaid === 0) {
            return 0;
        } else if (amountPaid === 499) {
            return 4.99;
        } else if (amountPaid === 999) {
            return 9.99;
        } else if (amountPaid === 1999) {
            return 19.99;
        } else {
            return 0;
        }

    };

    return (
        <StyledInvoiceTable>
            <div className='cards-table'>
                <div className='cards-table-head'>
                    {invoiceTHeadCaptions.map((item, index) => (
                        <p key={index} style={{ maxWidth: item.width, textTransform: 'capitalize' }}>
                            {item.caption}
                        </p>
                    ))}
                </div>

                <div className='cards-table-body'>
                    {invoices?.length ? (
                        invoices?.map((item, index) => {
                            return (
                                <div key={index} className='cards-table-body-item'>
                                    <p style={{ maxWidth: '155px' }}>{moment(item?.startDate).format('L')}</p>
                                    <p
                                        style={{
                                            maxWidth: '308px',
                                            textTransform: 'capitalize',
                                        }}>
                                        {renderPackage(item?.amountPaid)}
                                    </p>
                                    <p style={{ maxWidth: '162px' }}>
                                        $ {renderPrice(item?.amountPaid)}
                                    </p>
                                    <p style={{ maxWidth: '187px' }} className='actions-wrapper'>
                                        <a href={item?.hostedInvoiceUrl} target={'_blank'}>
                                            <img style={{ marginRight: '10px' }} src={Svg.Show} alt='' />
                                        </a>
                                        <a className='invoice-link' href={item?.invoicePDF}>
                                            <img src={Svg.Download} alt='Download' />
                                        </a>
                                    </p>
                                </div>
                            );
                        })
                    ) : (
                        <div className={'no-invoice-text'}>
                            <p>No invoices yet</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='mobile-cards-table'>
                {invoices?.length ? (
                    invoices?.map((item, index) => {
                        return (
                            <div key={index + 1} className='mobile-cards-item'>
                                <div className='title-info-wrapper'>
                                    <p className='title'>Date</p>
                                    <p className='info'>{moment(item?.startDate).format('L')}</p>
                                </div>
                                <div className='title-info-wrapper'>
                                    <p className='title'>Items</p>
                                    <p className='info' style={{ textTransform: 'capitalize' }}>
                                        {renderPackage(item?.amountPaid)}
                                    </p>
                                </div>
                                <div className='title-info-wrapper'>
                                    <p className='title'>Cost</p>
                                    <p className='info'>
                                        $ {renderPrice(item?.amountPaid)}
                                    </p>
                                </div>
                                <div className='buttons-wrapper'>
                                    <a className='invoice-link' href={item?.invoicePDF}>
                                        <img style={{ marginRight: '10px' }} src={Svg.Show} alt='' />
                                    </a>
                                    <a href={item?.hostedInvoiceUrl} target={'_blank'}>
                                        <img src={Svg.Download} alt='Download' />
                                    </a>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className={'no-invoice-text-mobile'}>
                        <p>No invoices yet</p>
                    </div>
                )}
            </div>
        </StyledInvoiceTable>
    );
};
