import { Svg } from '../../assets/images';
import { RolePermissions } from '../../utils/hooks';
import { useSelector } from 'react-redux';

export const CreateMobileButton = ({ handleClick }) => {
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));

    return (
        <>
            {RolePermissions(activePackage) &&
                <div className='mobile-wrapper-btn'>
                    <button onClick={handleClick} className='mobile-add-button-style'>
                        <img src={Svg.AddMobileIcon} alt='Add Mobile Icon' />
                    </button>
                </div>
            }
        </>
    );
};