import { addNote, deleteNote, editNote, getNotes } from './notes.action';

export { notesReducer } from './notes.reducer';
export { watchNotes } from './notes.saga';

export const noteActions = {
    /** Note Actions */
    getNotes,
    addNote,
    editNote,
    deleteNote,
    /** End */
};