




export const renderErrorMessage = ( assignTruck, assignDriver, assignCustomer, assignStop, assignPickUp, assignDropOff ) => {
    if(!assignTruck && !assignDriver && !assignCustomer && !assignStop){
        return 'Please fill out all required fields.You must assign truck, driver, customer and add one pick up, one drop off!'
    }
    else if(assignTruck && !assignDriver && !assignCustomer && !assignStop ){
        return 'Please fill out all required fields.You must assign driver, customer and add one pick up, one drop off!'
    }
    else if(!assignTruck && assignDriver && !assignCustomer && !assignStop ){
        return 'Please fill out all required fields.You must assign truck, customer and add one pick up, one drop off!'
    }
    else if(!assignTruck && !assignDriver && assignCustomer && !assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign truck, driver and add one pick up, one drop off!'
    }
    else if(!assignTruck && !assignDriver && !assignCustomer && assignStop ){
        return 'Please fill out all required fields.You must assign truck, driver and customer'
    }
    else if(!assignTruck && assignDriver && assignCustomer && assignStop ){
        return 'Please fill out all required fields.You must assign truck!'
    }
    else if(!assignTruck && !assignDriver && assignCustomer && assignStop ){
        return 'Please fill out all required fields.You must assign truck and driver!'
    }
    else if(!assignTruck && !assignDriver && !assignCustomer && assignStop ){
        return 'Please fill out all required fields.You must assign truck, driver and customer!'
    }
    else if(!assignTruck && !assignDriver && !assignCustomer && !assignPickUp && assignDropOff){
        return 'Please fill out all required fields.You must assign truck, driver and customer and one pick up!'
    }
    else if(!assignTruck && !assignDriver && !assignCustomer && assignPickUp && !assignDropOff){
        return 'Please fill out all required fields.You must assign truck, driver and customer and one drop off!'
    }
    else if(assignTruck && assignDriver && assignCustomer && assignPickUp && !assignDropOff){
        return 'Please fill out all required fields.You must assign one drop off!'
    }
    else if(assignTruck && assignDriver && assignCustomer && !assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign one pick up and one drop off!'
    }
    else if(assignTruck && assignDriver && assignCustomer && !assignPickUp && assignDropOff){
        return 'Please fill out all required fields.You must assign one pick up!'
    }
    else if(assignTruck && !assignDriver && assignCustomer && assignStop ){
        return 'Please fill out all required fields.You must assign driver!'
    }
    else if(assignTruck && assignDriver && !assignCustomer && assignStop ){
        return 'Please fill out all required fields.You must assign customer!'
    }
    else if(assignTruck && assignDriver && assignCustomer && !assignStop && !assignPickUp ){
        return 'Please fill out all required fields.You must assign customer!'
    }
    else if(assignTruck && assignDriver && assignCustomer && !assignStop && !assignPickUp ){
        return 'Please fill out all required fields.You must assign one pick up!'
    }
    else if(assignTruck && assignDriver && assignCustomer && !assignStop && !assignDropOff ){
        return 'Please fill out all required fields.You must assign one pick up and one drop off!'
    }
    else if(assignTruck && !assignDriver && assignCustomer && assignPickUp && assignDropOff ){
        return 'Please fill out all required fields.You must assign driver!'
    }
    else if(assignTruck && !assignDriver && !assignCustomer && assignPickUp && assignDropOff ){
        return 'Please fill out all required fields.You must assign driver and customer!'
    }
    else if(assignTruck && !assignDriver && !assignCustomer && !assignPickUp && assignDropOff ){
        return 'Please fill out all required fields.You must assign driver, customer and one pick up!'
    }
    else if(assignTruck && !assignDriver && !assignCustomer && assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign driver, customer and one drop off!'
    }
    else if(assignTruck && !assignDriver && assignCustomer && !assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign driver and one pick up, one drop off!'
    }
    else if(assignTruck && !assignDriver && assignCustomer && assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign one drop off!'
    }
    else if(assignTruck && !assignDriver && assignCustomer && !assignPickUp && assignDropOff ){
        return 'Please fill out all required fields.You must assign one pick up!'
    }
    else if(assignTruck && assignDriver && !assignCustomer && !assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign customer and one pick up, one drop off!'
    }
    else if(assignTruck && assignDriver && !assignCustomer && assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign customer and one drop off!'
    }
    else if(assignTruck && assignDriver && !assignCustomer && !assignPickUp && assignDropOff ){
        return 'Please fill out all required fields.You must assign customer and one pick up!'
    }
    else if(!assignTruck && !assignDriver && assignCustomer && !assignPickUp && assignDropOff ){
        return 'Please fill out all required fields.You must assign truck, driver and one pick up!'
    }
    else if(!assignTruck && !assignDriver && assignCustomer && assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign truck, driver and one drop off!'
    }
    else if(!assignTruck && !assignDriver && assignCustomer && assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign truck, customer and one drop off!'
    }
    else if(!assignTruck && assignDriver && assignCustomer && assignPickUp && !assignDropOff ){
        return 'Please fill out all required fields.You must assign truck and one pick up!'
    }
    else if(!assignTruck && assignDriver && assignCustomer && !assignPickUp && assignDropOff ){
        return 'Please fill out all required fields.You must assign truck and one drop off!'
    }



    else{
        return 'Please fill out all required fields.You must assign truck, driver, customer and add one pick up, one drop off!'
    }

}