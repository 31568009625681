import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { FormControl, LinearProgress, MenuItem, Radio, Select } from '@mui/material';
import { httpRequestsOnLoadActions, UploadServices } from 'store';
import { FindLoad } from 'utils';
import { Svg } from '../../assets/images';
import { MinLoader } from '../loaders';
import { AntSwitch } from '../inputs/style';
import { RolePermissions } from '../../utils/hooks';
import { TextRow } from '../table';

export const Documents = ({
                            docks,
                            actionType,
                            deleteType,
                            handleUpload,
                            typeList,
                            handleDelete,
                            activeStatus,
                            handleSetStatus,
                            changeStatus,
                            uploadType,
                          }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [downloadLoad, setDownloadLoad] = useState('');
  const [documents, setDocuments] = useState([]);
  const addLoader = FindLoad(actionType);
  const deleteLoader = FindLoad(deleteType);
  const changeStatusLoader = FindLoad(changeStatus);
  const dispatch = useDispatch();
  const token = localStorage.getItem('access-token');

  const { activePackage } = useSelector((state) => ({
    activePackage: state.payment.activePackage,
  }));

  useEffect(() => {
    if (docks) {
      setDocuments(docks);
    }
  }, [docks]);

  const deleteDocument = (item) => {
    handleDelete(item);
    setSelectedId(item?.file?._id);
  };

  const setDockStatus = (item) => {
    handleSetStatus(item);
    setSelectedId(item?.file?._id);
  };

  const handleChange = async (e, k) => {
    if (e.target.files[0]) {
      let formData = new FormData();
      formData.append('files', e.target.files[0]);
      try {
        dispatch(httpRequestsOnLoadActions.appendLoading(actionType));
        const res = await UploadServices.UploadImage(formData);
        const info = {
          file: {
            ...res?.data,
            name: e.target.files[0].name,
          },
        };
        if (typeList) {
          info.type = fileType;
          info.name = e.target.files[0].name;
        } else {
          info.name = e.target.files[0].name;
        }

        handleUpload(info);
      } catch (e) {
        dispatch(httpRequestsOnLoadActions.removeLoading(actionType));
      }
    }
  };

  const handleFilter = (e) => {
    setFilterType(e.target.value);
    if (e.target.value === 'All') {
      setDocuments(docks);
    } else {
      const filtered = docks.filter((i) => i?.type === e.target.value);
      setDocuments(filtered);
    }
  };


  const saveFile = async (item) => {
    setDownloadLoad(item?.id);
    try {
      const response = await axios({
        url: `/files/download`,
        method: 'POST',
        responseType: 'blob',
        data: item?.file,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileIndex = response?.data?.type?.search('/');
      const fileType = response?.data?.type?.slice(fileIndex + 1);
      let filename = item?.file?.originalName ? item?.file?.originalName : `file.${fileType === 'svg+xml' ? 'svg' : fileType}`;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}`);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } finally {
      setDownloadLoad('');
    }
  };


  const renderType = (type) => {
    switch (type) {
      case 'DRIVER_LICENSE':
        return 'Diver License';

      case 'OTHER':
        return 'Other';

      case 'RATE_CON':
        return 'Rate Con';

      case 'POD':
        return 'Pod';

      case 'INVOICE':
        return 'Invoice';

      default:
        return '...';
    }
  };

  return (
    <div className='documents-wrapper'>
      <p className='title'>Documents</p>
      <div className='sections-wrapper'>
        <div className='uploaded-docks'>
          <div className='filter-selector'>
            {!uploadType && (
              <div className='select-option'>
                <select onChange={handleFilter} value={filterType ? filterType : ''}>
                  <option value='All'>All Documents</option>
                  {typeList?.map((i, index) => (
                    <option key={index} value={i?.type}>
                      {i?.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div className='document-items-wrapper'>
            {addLoader?.length ? (
              <div className='item-loading'>
                <LinearProgress style={{ background: '#E1F2F3' }} />
              </div>
            ) : (
              ''
            )}

            {documents?.length ? (
              documents?.map((i, k) => (
                <div key={k} className='item'>
                  <p className='item-title text-style'>

                    {i?.type ? `${renderType(i?.type)}` : ''}
                    {<TextRow name={i?.file?.originalName ? i?.file?.originalName : 'Not Set'} />}
                    {/*<TextRow name={i?.file?.name ? i?.file?.name : 'Not Set'} />*/}
                  </p>
                  <div className='buttons-wrapper'>
                    {i?.file?.url && (
                      <a href={i?.file?.url} target={'_blank'} style={{ width: '36px', marginTop: '3px' }}>
                        <img src={Svg.OpenLink} alt='icon' />
                      </a>
                    )}
                    <button style={{ width: '36px', height: '30px' }} onClick={() => saveFile(i)}>
                      {downloadLoad === i?.id ? (
                        <MinLoader color={'#FF9A00'} />
                      ) : (
                        <img src={Svg.DownloadItem} alt='DownloadItem' />
                      )}
                    </button>
                    <button type='button' style={{ width: '31px' }} onClick={() => deleteDocument(i)}>
                      {deleteLoader?.length && i?.file?._id === selectedId ? (
                        <MinLoader color={'red'} />
                      ) : (
                        <img src={Svg.DeleteItem} alt='DeleteItem' />
                      )}
                    </button>

                    {RolePermissions(activePackage) && activeStatus && (
                      <div className='select-status'>
                        {changeStatusLoader?.length && i?.file?._id === selectedId ? (
                          <MinLoader color={'#0e9594'} />
                        ) : (
                          <AntSwitch onClick={() => setDockStatus(i)} checked={i?.status === activeStatus} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className='no-documents'>
                <p>No Uploaded Documents</p>
              </div>
            )}
          </div>
        </div>

        <div className='upload-docks'>
          <img src={Svg.Upload} alt='Upload' />
          <p className='upload-text'>To upload a document please, select the document type.</p>

          <div className='upload-select'>
            {!uploadType && (
              <div className='mobile-upload'>
                <FormControl sx={{ m: 1, maxWidth: 400, width: '100%' }}>
                  <Select
                    value={fileType ? fileType : ''}
                    onChange={(e) => setFileType(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value='' />
                    {typeList?.map((i, k) => (
                      <MenuItem onClick={() => setFileType(i?.type)} key={k} value={i?.type}>
                        {/*<Radio*/}
                        {/*    checked={fileType === i?.type}*/}
                        {/*    value={i?.type}*/}
                        {/*    name='radio-buttons'*/}
                        {/*/>*/}
                        <p>{i?.name}</p>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>

          <label className='custom-file-upload'>
            <input
              onClick={(event) => (event.target.value = null)}
              disabled={typeList ? !fileType : false}
              onChange={handleChange}
              type='file'
              id='file'
            />
            <i className='fa fa-cloud-upload' />
            {RolePermissions(activePackage) && (
              <span className={typeList && !fileType ? 'upload-err' : 'upload'}>
                                {typeList && !fileType ? 'Select file type please' : 'Upload File'}
                            </span>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};
