import axios from 'axios';

export const userService = {
    /**  Organization User  */
    getUsersService: (params) => axios.get(`/user`, { auth: true, params: { ...params } }),

    inviteUserService: (body) => axios.post(`/user/invite`, body, { auth: true }),

    editUserService: (body, id) => axios.patch(`/user/${id}`, { ...body }, { auth: true }),

    deleteUserService: (id) => axios.delete(`/user/${id}`, { auth: true }),

    changeUserStatusService: (id, status) => axios.patch(`/user/${id}/status/${status}`, {}, { auth: true }),

    resendInviteService: (id) => axios.post(`/user/reInvite/${id}`, {}, { auth: true }),
    /** End  */

};
