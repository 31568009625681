import { MinLoader } from 'components/loaders';
import { FindLoad } from 'utils';

export const SubmitButton = ({ title, actionType, type }) => {
    const load = FindLoad(actionType);

    return (
        <button className="add-button" type={type ? type : 'button'}>
            {load?.length ? <MinLoader color={'white'} /> : <span className="button-text">{title}</span>}
        </button>
    );
};
