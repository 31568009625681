import React from 'react';
import Typography from '@mui/material/Typography';
import { MinLoader, useModal } from 'components';
import { StyledRestaurantPayment } from './style';
import { ActivePlan, PaymentMethod, InvoiceTable } from './core';
import { FindLoad, FREE_PACKAGE, MODAL_NAMES } from 'utils';
import { Svg } from 'assets/images';

export const ActivePaymentFragment = ({ userPackage, cardInfo, invoices }) => {
    const { open } = useModal();
    const loader = FindLoad('cancel');
    // const end = userPackage?.createdAt && new Date(new Date(userPackage?.createdAt).setMonth(new Date().getMonth() + 1));

    const handleOpenChancelSubscription = () => {
        open(MODAL_NAMES.CANCEL_SUBSCRIPTION, { modalType: 'cancel' });
    };

    return (
        <div className={'styled-restaurant-payment'}>
            <div className="billing-wrapper">
                <Typography weight="bold" className="g-title" color="text">
                    Billing And Payment
                </Typography>

                <>
                    <p className="active-plane">Active plan</p>
                    <div className="cards-wrapper">
                        <ActivePlan userInfo={userPackage} />
                    </div>
                </>

                <>
                    {userPackage?.isPackageActive !== false && userPackage?.package !== FREE_PACKAGE && (
                        <>
                            <p className="active-plane">Payment Method</p>
                            <div className="cards-wrapper">
                                <PaymentMethod info={cardInfo} />
                            </div>
                        </>
                    )}

                    {userPackage?.package !== FREE_PACKAGE && invoices?.length ? (
                        <>
                            <p className="active-plane ">Invoices</p>
                            <InvoiceTable invoices={invoices} />
                        </>
                    ) : (
                        ''
                    )}

                    {userPackage?.isPackageActive === true && !userPackage?.subEndDate && userPackage?.package !== FREE_PACKAGE && (
                        <div className="cancel-button-wrapper">
                            <button onClick={handleOpenChancelSubscription} className="cancel-button">
                                {loader?.length ? <MinLoader color={'white'} /> : 'Cancel Subscription'}
                            </button>
                            <img src={Svg.Info} alt="Info" />
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};
