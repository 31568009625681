import { PopperComponent, TextRow, StatusTruck } from 'components';
import { CheckCoastPermission, MODAL_NAMES } from 'utils';
import { Svg } from 'assets/images';
import moment from 'moment';
import { HtmlTooltip } from '../../components/messages/htmlTool';

export const ACTION_TYPE = 'GET_TRUCK';

export const ACTION_TYPE_LOAD = 'GET_LOAD';

export const tracksBody = [
    { rowText: (item) => <TextRow name={item?.displayId} /> },
    { rowText: (item) => <TextRow name={item?.name} /> },
    { rowText: (item) => <TextRow name={item?.color ? item?.color : 'Not Set'} /> },
    { rowText: (item) => <StatusTruck info={item?.status} /> },
];

export const truckStatus = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'AVAILABLE',
        label: 'Available',
    },
    {
        value: 'INACTIVE',
        label: 'Inactive',
    },
    {
        value: 'IN_TRANSIT',
        label: 'In Transit',
    },
    {
        value: 'MAINTENANCE',
        label: 'Maintenance',
    },
];

export const tracksHead = [
    { name: 'id', title: 'ID' },
    { name: 'name', title: 'Name' },
    { name: 'color', title: 'Color' },
    {
        name: 'status',
        title: 'Status',
        type: 'status',
        list: [...truckStatus],
    },
];

export const trackLoadStatus = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        value: 'PENDING',
        label: 'Pending',
    },
    {
        value: 'DELIVERED',
        label: 'Delivered',
    },
    {
        value: 'COMPLETE',
        label: 'Complete',
    },
    {
        value: 'CANCELED',
        label: 'Canceled',
    },
];

export const tracksLoadHead = (activePackage) => {
    return [
        { name: 'id', title: 'ID' },
        { name: 'pick', title: 'Pick Up Date' },
        { name: 'firstStop', title: 'First Stop' },
        { name: 'lastStop', title: 'Last Stop' },
        { name: 'customer', title: 'Customer Rate' },
        CheckCoastPermission(activePackage) && { name: 'cost', title: 'Cost' },
        CheckCoastPermission(activePackage) && { name: 'profit', title: 'Profit' },
        {
            name: 'status',
            title: 'Status',
            type: 'status',
            list: [...trackLoadStatus],
        },
    ];
};

const handleRenderStops = (type, item) => {
    const lastStopDate = item?.stops.find((i) => i.type === 'PICKUP');
    const firstStop = item?.stops.find((i) => i.type === 'PICKUP');
    const lastStop = item?.stops.find((i) => i.type === 'DROP_OFF');
    if (item?.stops) {
        if (type === 'stopDate') {
            if (firstStop) {
                return moment(lastStopDate?.date).format('MM/DD/YYYY');
            } else {
                return 'Not Set';
            }
        }
        if (type === 'first') {
            if (firstStop) {
                return firstStop?.address?.formattedAddress;
            } else {
                return 'Not Set';
            }
        }
        if (type === 'last') {
            if (lastStop) {
                return lastStop?.address?.formattedAddress;
            } else {
                return 'Not Set';
            }
        }
    } else {
        return 'Not Set';
    }
};

const LoadTextRow = ({ text }) => {
    if (text) {
        const count = Math.ceil(200 / 10);
        const slicedText = '' + text?.slice(0, count);
        return (
            <div style={{ color: '#506C84' }} className="text-style">
                {text?.length > count ? (
                    <HtmlTooltip title={<p>{text}</p>} placement="top-start">
                        <p style={{ textOverflow: 'ellipsis' }}>{`${slicedText}...`}</p>
                    </HtmlTooltip>
                ) : (
                    <p>{text}</p>
                )}
            </div>
        );
    } else {
        return '...';
    }
};

export const loadsBody = (activePackage) => {
    return [
        {
            rowText: (item) => (
                <div>
                    <div className="text-gunmetal">{item?.displayId}</div>
                </div>
            ),
        },
        {
            rowText: (item) => (
                <div style={{ width: '100%' }}>
                    <div className="text-gunmetal">{handleRenderStops('stopDate', item)}</div>
                </div>
            ),
        },
        {
            rowText: (item) => (
                <div className="flex space-between">
                    <div style={{ width: '100%' }}>
                        <LoadTextRow text={handleRenderStops('first', item)} />
                    </div>
                    <div>
                        <img src={Svg.CircleRight} alt="Circle-Right" />
                    </div>
                </div>
            ),
        },
        {
            rowText: (item) => (
                <div>
                    <div className="text-gunmetal">
                        <LoadTextRow text={handleRenderStops('last', item)} />
                    </div>
                </div>
            ),
        },

        {
            rowText: (item) => (
                <div>
                    <div className="text-gunmetal" style={{ fontWeight: '600' }}>
                        {item?.totalProfit?.customerRate ? <>${item?.totalProfit?.customerRate} </> : 'Not Set'}
                    </div>
                </div>
            ),
        },
        CheckCoastPermission(activePackage) && {
            rowText: (item) => (
                <div>
                    <div
                        style={{ fontWeight: '600' }}
                        className={`text-gunmetal 
                       `}>
                        {Number(item?.totalProfit?.customerRate) - Number(item?.totalProfit?.profit) === 0 ? ' +' : ' '}
                        {item?.totalProfit?.totalTripCost ? <>${item?.totalProfit?.totalTripCost} </> : 'Not Set'}
                    </div>
                </div>
            ),
        },
        CheckCoastPermission(activePackage) && {
            rowText: (item) => (
                <div>
                    <div style={{ fontWeight: '600' }} className={`text-gunmetal${item?.totalProfit?.profit >= 0 ? 'text-success' : ''}`}>
                        {item?.totalProfit?.profit ? (
                            <span className={` ${item?.totalProfit?.profit < 0 && 'text-error'} `}>
                                {item?.totalProfit?.profit ? (
                                    <span>
                                        {item?.totalProfit?.profit >= 0 ? (
                                            <span style={{ color: '#0BB53A' }}>+${item?.totalProfit?.profit}</span>
                                        ) : (
                                            <span>-${-item?.totalProfit?.profit}</span>
                                        )}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </span>
                        ) : (
                            'Not Set'
                        )}
                    </div>
                </div>
            ),
        },

        { rowText: (item) => <StatusTruck info={item?.status} /> },
    ];
};

export const truckStatusDropdown = [
    {
        svg: Svg.RedRoundedIcon,
        value: 'MAINTENANCE',
        label: 'Maintenance',
    },
    {
        svg: Svg.IndigoRoundedIcon,
        value: 'IN_TRANSIT',
        label: 'In Transit',
    },
    {
        svg: Svg.GreenRoundedIcon,
        value: 'AVAILABLE',
        label: 'Available',
    },
    {
        svg: Svg.BlueRoundedIcon,
        value: 'INACTIVE',
        label: 'Inactive',
    },
];

export const truckMenuPopper = {
    button: (item, info) => (
        <div>
            <PopperComponent
                list={[
                    {
                        title: 'Edit',
                        params: { info: info, modalTitle: 'Edit Truck Load ' },
                        name: MODAL_NAMES.TRUCK_LOAD_MODAL,
                    },
                    {
                        title: info?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                        name: MODAL_NAMES.INACTIVATE_MODAL,
                        params: {
                            title: info?.type,
                            modalType: info?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                            subTitle: 'Inactivating a user will not active in the system. You can activate user when do you want.',
                            actionType: 'CHANGE_TRUCK_STATUS',
                            handleChange: () => item(info?.status === 'ACTIVE' ? 'Inactivate' : 'Activate'),
                        },
                    },
                    {
                        title: 'Delete',
                        name: MODAL_NAMES.DELETE_MODAL,
                        params: {
                            title: info?.type,
                            subTitle: 'Deleting a user will permanently remove it from the system',
                            actionType: 'DELETE_TRUCK_LOAD',
                            handleDelete: () => item('DELETE_TRUCK_LOAD'),
                        },
                        color: '#D03325',
                    },
                ]}
            />
        </div>
    ),
};

export const TruckMenu = [
    {
        name: 'Edit',
        modalName: MODAL_NAMES.TRACK_MODAL,
    },
    {
        name: 'Inactivate',
        modalName: MODAL_NAMES.INACTIVATE_MODAL,
    },
    {
        name: 'Delete',
        modalName: MODAL_NAMES.DELETE_MODAL,
        color: '#d03325',
    },
];

export const TruckLoadMenu = [
    {
        name: 'Edit',
        modalName: MODAL_NAMES.EDIT_LOAD,
    },
    {
        name: 'Inactivate',
        modalName: MODAL_NAMES.INACTIVATE_MODAL,
    },
    {
        name: 'Delete',
        modalName: MODAL_NAMES.DELETE_TRUCK,
        color: '#d03325',
    },
];

export const truckDocumentsList = [
    { name: 'Rate Con', type: 'RATE_CON' },
    { name: 'Pod', type: 'POD' },
    { name: 'Invoice', type: 'INVOICE' },
    { name: 'Other', type: 'OTHER' },
];
