import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useModal, SubmitButton, CustomInput, ForgotPassword, AlreadyHave } from 'components';
import { authActions, httpRequestsOnErrorsActions } from 'store';
import { FindError, MODAL_NAMES, EmailValidator, PasswordValidator } from 'utils';
import { Svg } from 'assets/images';

const REQUEST_TYPE = 'SIGN_UP';

export const SignUpModal = () => {
    const { close, open } = useModal();
    const dispatch = useDispatch();
    const backError = FindError(REQUEST_TYPE);
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            fullName: '',
            email: '',
            password: '',
            confirmationPassword: '',
        },
    });

    const onSubmit = (data) => {
        const sendData = {
            fullName: data?.fullName,
            email: data?.email,
            password: data?.password,
        };
        if (data.password === data?.confirmationPassword) {
            dispatch(authActions.signUp(sendData));
        } else {
            setError('confirmationPassword');
        }
    };

    const handleSignIn = () => {
        // close(MODAL_NAMES.SIGN_UP);
        open(MODAL_NAMES.SIGN_IN);
    };

    const handleForgotPassword = () => {
        // close(MODAL_NAMES.SIGN_UP);
        open(MODAL_NAMES.FORGOT_PASSWORD);
        // open(MODAL_NAMES.FORGOT_PASSWORD);
    };

    useEffect(() => {
        if (backError?.error === 'User already exists.') {
            setError('email');
        }
    }, [backError]);

    useEffect(() => {
        return () => dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
    }, []);

    return (
        <div className="signup-form">
            <form onSubmit={handleSubmit(onSubmit)} className="form-messages">
                <CustomInput
                    imgSrc={errors?.fullName ? Svg.ProfileRed : Svg.UserInput}
                    name="fullName"
                    placeholder="Full Name"
                    control={control}
                    rules={{ required: true }}
                    type="text"
                />
                <CustomInput
                    imgSrc={errors?.email ? Svg.SmsRed : Svg.EmailInput}
                    name="email"
                    placeholder="Email"
                    control={control}
                    rules={{
                        required: true,
                        pattern: {
                            value: EmailValidator,
                            message: 'Must be valid email',
                        },
                    }}
                    type="email"
                    errMessage={backError?.error === 'User already exists.' && 'User already exists.'}
                />
                <CustomInput
                    imgSrc={errors?.password ? Svg.LockRed : Svg.Lock}
                    name="password"
                    placeholder="Password"
                    control={control}
                    rules={{
                        required: true,
                        pattern: {
                            value: PasswordValidator,
                            message: 'Not valid Password',
                        },
                    }}
                    type="password"
                />
                <CustomInput
                    imgSrc={errors?.confirmationPassword ? Svg.LockRed : Svg.Lock}
                    name="confirmationPassword"
                    placeholder="Confirmation Password"
                    control={control}
                    rules={{
                        required: true,
                        pattern: {
                            value: PasswordValidator,
                            message: 'Not valid Password',
                        },
                    }}
                    type="password"
                    errMessage={
                        errors?.confirmationPassword && errors?.confirmationPassword?.type !== 'required'
                            ? 'Password does not match with the confirmation'
                            : ''
                    }
                />
                <div className="footer-box">
                    <p>
                        <span className="description">By clicking the
                          Sign Up button you agree to</span>
                        <br/>
                        <a href="/terms" className="sign-up">
                            Terms of Use
                        </a>
                        <span className="description">and</span>
                        <a href="/privacy" className="sign-up">
                            Privacy Policy
                        </a>
                    </p>
                    <span className="description">
                        to FleetForest
                    </span>
                </div>

                <SubmitButton type="submit" title="Sign Up" actionType={REQUEST_TYPE} />
                <AlreadyHave handleClick={handleSignIn} buttonText="Sign In" questionText="Already have an account?" />
                {/*<ForgotPassword handleClick={() => handleForgotPassword()} />*/}


            </form>
        </div>
    );
};
