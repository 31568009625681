import React, { Fragment } from 'react';
import { Images } from 'assets/images';
import { GetStart } from 'components';
import { Divider } from '@material-ui/core';
import { FIRST_PACKAGE, PREMIUM_PACKAGE, SECOND_PACKAGE } from 'utils';
import { yourPlanFeatures } from '../../payments/constants';

export const PayFragment = ({ handleClick }) => {
    const firstPackage = yourPlanFeatures(FIRST_PACKAGE);
    const secondPackage = yourPlanFeatures(SECOND_PACKAGE);
    const premiumPackage = yourPlanFeatures(PREMIUM_PACKAGE);

    const plansAndPricing = [
        {
            title: 'Basic',
            description: 'For companies that need to manage their data',
            price: '$4.99',
            mo: '/truck/mo',
            active: firstPackage?.items,
            notActive: firstPackage?.notSupport,
        },
        {
            title: 'Plus',
            description: 'For companies that would like to gain insights into their data',
            price: '$9.99',
            mo: '/truck/mo',
            active: secondPackage?.items,
            notActive: secondPackage?.notSupport,
        },
        {
            title: 'Premium',
            description: 'For companies that would like to gain insights into their data',
            price: '$19.99',
            mo: '/truck/mo',
            active: premiumPackage?.items,
            notActive: premiumPackage?.notSupport,
        },
    ];

    return (
        <Fragment>
            <div className='pay-box'>
                <div className='pay-container'>
                    {plansAndPricing?.map((item, k) => (
                        <div className='pay-card' key={k}>
                            <div className='pay-title'>
                                <h4 className='font-bold title text-h4'>{item?.title}</h4>
                            </div>
                            <div className='pay-subtitle'>
                                <h6 className='font-normal text-md'>{item?.description}</h6>
                            </div>
                            <div className='pay-info'>
                                <div>
                                    <span className='pay-number'>{item?.price}</span>
                                    <span className='pay-mouth'>{item?.mo}</span>
                                </div>
                            </div>
                            <div>
                                <GetStart title={'Get Started'} handelClick={handleClick} />
                            </div>
                            <div className='pay-list'>
                                <div>
                                    {item?.active?.map((item, index) => (
                                        <div key={index} className='icon-title-wrapper'>
                                            <img src={Images.CheckGreen} alt='Check-Green' />
                                            <p>{item?.text} </p>
                                        </div>
                                    ))}
                                </div>
                                <Divider />
                                <div style={{ marginTop: '16px' }}>
                                    {item?.notActive?.map((item, index) => (
                                        <div key={index} className='icon-title-wrapper'>
                                            <img src={Images.CheckRed} alt='Check-Green' />
                                            <p>{item?.text} </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};
