// Utils

export { initAxiosInterceptors, FindLoad, FindError, FindSuccess, CheckCoastPermission, docLogo } from './hooks';
export { Colors } from './colors/colors';
export { EmailValidator, PasswordValidator } from './validators';
export { MODAL_NAMES } from './constants';
export { SavePage, SendPageSave, SaveParams } from './paginate';
export { RolePermissions } from './hooks';
export { useModalBase, CustomModals, CustomModalProvider, useWindowDimensions } from './hooks';

export const FREE_PACKAGE = 'free';
export const FIRST_PACKAGE = 'basic';
export const SECOND_PACKAGE = 'plus';
export const PREMIUM_PACKAGE = 'premium';

export const ReturnPrice = (usedPackage) => {
    return usedPackage === 'free'
        ? '0'
        : usedPackage === 'basic'
        ? '4.99'
        : usedPackage === 'plus'
        ? '9.99'
        : usedPackage === 'premium'
        ? '19.99'
        : '';
};

export const USER = 'USER';
export const ORG = 'ORG';
