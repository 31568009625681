import { combineReducers } from 'redux';
import { httpRequestsOnLoadReducer } from '../http_requests_on_load';
import { httpRequestsOnSuccessReducer } from '../http_requests_on_success';
import { httpRequestsOnErrorsReducer } from '../http_requests_on_errors';
import { authReducer } from 'store/auth';
import { userReducer } from 'store/user';
import { truckingReducer } from 'store/truck';
import { drivingReducer } from 'store/drivers';
import { loadReducer } from 'store/load';
import { traileredReducer } from 'store/trailers';
import { customerReducer } from 'store/customers';
import { userPaymentReducer } from 'store/payment';
import { notesReducer } from 'store/notes';

export const appReducer = combineReducers({
    auth: authReducer,
    payment: userPaymentReducer,
    user: userReducer,
    drivers: drivingReducer,
    truck: truckingReducer,
    load: loadReducer,
    trailer: traileredReducer,
    customer: customerReducer,
    notes:notesReducer,


    httpOnLoad: httpRequestsOnLoadReducer,
    httpOnSuccess: httpRequestsOnSuccessReducer,
    httpOnError: httpRequestsOnErrorsReducer,
});
