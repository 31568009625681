import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, CustomInput, MaskInput, CreateCancel, AddressInput } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { customersAction } from 'store/customers';

const ACTION_TYPE = 'CREATE_CUSTOMER';

export const AddCustomer = () => {
    const { close } = useModal();
    const { control, handleSubmit, setError } = useForm({});
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);
    const [enteredAddress, setEnteredAddress] = useState('');

    function handleAddressChange(selectedAddress) {
        setEnteredAddress(selectedAddress);
    }

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        if (backError?.error === 'phone must be a valid phone number.') {
            setError('phone');
        }
    }, [backError]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };

        if (enteredAddress?.address) {
            sendInfo.address = enteredAddress;
        }

        if (data?.phoneNumber) {
            sendInfo.phoneNumber = data?.phoneNumber && parseInt(data?.phoneNumber.replace(/[^0-9]/g, '')) + '';
        } else {
            delete sendInfo.phoneNumber;
        }

        sendInfo.mcNumber = data?.mcNumber && parseInt(data?.mcNumber.replace(/[^0-9]/g, '')) + '';
        !sendInfo.email && delete sendInfo.email;
        !sendInfo.billingEmail && delete sendInfo.billingEmail;
        dispatch(customersAction.createCustomer(sendInfo));
    };

    const mobileError =
        backError?.error === 'This Organization has a driver with this data.'
            ? 'This Organization has a driver with this data.'
            : backError?.error && backError?.error?.[0] === 'phone must be a valid phone number'
                ? 'Phone Number must be a valid phone number'
                : backError?.error && backError?.error?.[0] === 'phoneNumber must be a valid phone number'
                    ? 'Phone Number must be a valid phone number'
                    : '';

    const nameError =
        backError?.error === 'Customer with this Name or MC Number already exists' ? 'Customer with this Name or MC Number already exists' :
            backError?.error && backError?.error?.[0] === 'Customer with this Name or MC Number already exists' ? 'Customer with this Name or MC Number already exists'
                : '';

    return (
        <div className='invite-form customer-form'>
            <div className='driver-container'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomInput
                        name='name'
                        control={control}
                        rules={{ required: true }}
                        type='text'
                        label='Name*'
                        placeholder={'Name'}
                        errMessage={nameError}
                        maxLength={40}
                    />
                    <MaskInput
                        name='phoneNumber'
                        label='Phone Number'
                        control={control}
                        rules={{ required: false }}
                        type='phone'
                        placeholder={'Phone Number'}
                        errMessage={!!mobileError}
                        errMessageToShow={mobileError}
                    />
                    <CustomInput
                        name='email'
                        control={control}
                        rules={{ required: false }}
                        type='email'
                        label='Email Address'
                        placeholder={'Email Address'}
                    />
                    <CustomInput
                        name='billingEmail'
                        control={control}
                        rules={{ required: false }}
                        type='email'
                        label='Billing Email'
                        placeholder={'Billing Email '}
                    />
                    <AddressInput
                        label={'Address'}
                        errorBoolean={backError?.error === 'Unable to verify the address' ? 'Unable to verify the address' : ''}
                        onTrigger={handleAddressChange}
                        enteredValue={enteredAddress}
                        notRequired={true}
                    />
                    <MaskInput
                        mask={'999999'}
                        name='mcNumber'
                        label='MC number*'
                        control={control}
                        rules={{ required: true }}
                        type='phone'
                        placeholder={'MC Number'}
                        errMessage={!!mobileError}
                        errMessageToShow={nameError}
                    />
                    <CreateCancel
                        title={'Save'}
                        actionType={ACTION_TYPE}
                        closeType={ACTION_TYPE}
                    />
                </form>
            </div>
        </div>
    );
};
