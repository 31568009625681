/**  Service */
import axios from 'axios';

export const customersService = {
    /** Customer Type */
    createCustomer: (body) => axios.post(`/customers`, body, { auth: true }),

    getCustomer: (params) => axios.get(`/customers`, { auth: true, params: { ...params } }),

    editCustomer: (body, id) => axios.patch(`/customers/${id}`, { ...body }, { auth: true }),

    getCustomerId: (params, id) => axios.get(`/customers/${id}`, { auth: true, params: { ...params } }),

    deleteCustomer: (id) => axios.delete(`/customers/${id}`, { auth: true }),

    changeStatusCustomer: (id, status) => axios.patch(`/customers/${id}/status/${status}`, {}, { auth: true }),
    /** End */

    /** Customer Contact */
    createCustomerContact: (body, id) => axios.post(`/customers/${id}/contact`, body, { auth: true }),

    editCustomerContact: (body, id, contactId) => axios.patch(`/customers/${id}/contact/${contactId}`, { ...body }, { auth: true }),

    getCustomerIdContact: (id) => axios.get(`/customers/${id}/contacts`, { auth: true }),

    deleteCustomerContact: (customerId, id) => axios.delete(`/customers/${customerId}/contact/${id}`, { auth: true }),
    /** End */
};
