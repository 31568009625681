import {
    signIn,
    signOut,
    changePassword,
    forgotPassword,
    resetPassword,
    signUp,
    getMyProfile,
    editMyProfile,
    completeAccount,
    contactUs,
} from './auth.action';
export { authReducer } from './auth.reducer';
export { watchAuth } from './auth.saga';
export { SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_FAIL, SIGN_OUT, SIGN_UP, CHANGE_PASSWORD, FORGOT_PASSWORD, RESET_PASSWORD } from './auth.type';

export const authActions = {
    signUp,
    signIn,
    signOut,
    changePassword,
    forgotPassword,
    resetPassword,
    completeAccount,
    /** My Profile */
    getMyProfile,
    editMyProfile,
    /** End */
    contactUs,
};
