export const Colors = {
  theme: {
    white: "#FFFFFF",
    main: "#0E9594",
    lightMain: "#E1F2F3",
    orange: "#FF9A00",
    lightOrange: "#FFF3E0",
    gunmetal: "#506C84",
    lightGunmetal: "#8CA2B8",
    darkGunmetal: "#20313F",
    greyWhite: "#F5F7F9",
    neutralGrey: "#BFBFBF",
    darkGreen: "#0F7A79",
    alphaGunmetal: "#3C4151",
    gunmetal500: "#5D7A95",
  },
};
