import { Svg } from 'assets/images';
import { USER } from 'utils';

export const RoutersInfo = (userType) => {
    if (userType === USER) {
        return [
            {
                key: 1,
                name: 'Trucks',
                path: '/trucks',
                activeIcon: Svg.TrucksActive,
                icon: Svg.Trucks,
                alt: 'Truck',
            },
            {
                key: 2,
                name: 'Trailers',
                path: '/trailers',
                activeIcon: Svg.TrailersActive,
                icon: Svg.Trailers,
                alt: 'Trailers',
            },
            {
                key: 3,
                name: 'Drivers',
                path: '/drivers',
                activeIcon: Svg.DriversActive,
                icon: Svg.Drivers,
                alt: 'Drivers',
            },
            {
                key: 4,
                name: 'Customers',
                path: '/customers',
                activeIcon: Svg.CustomerActive,
                icon: Svg.Customer,
                alt: 'Customer',
            },
            {
                key: 5,
                name: 'Loads',
                path: '/loads',
                activeIcon: Svg.BoardActive,
                icon: Svg.Board,
                alt: 'Load',
            },
        ];
    } else {
        return [
            {
                key: 0,
                name: 'Users',
                path: '/users',
                activeIcon: Svg.UserActive,
                icon: Svg.User,
                alt: 'User',
            },
            {
                key: 1,
                name: 'Trucks',
                path: '/trucks',
                activeIcon: Svg.TrucksActive,
                icon: Svg.Trucks,
                alt: 'Truck',
            },
            {
                key: 2,
                name: 'Trailers',
                path: '/trailers',
                activeIcon: Svg.TrailersActive,
                icon: Svg.Trailers,
                alt: 'Trailers',
            },
            {
                key: 3,
                name: 'Drivers',
                path: '/drivers',
                activeIcon: Svg.DriversActive,
                icon: Svg.Drivers,
                alt: 'Drivers',
            },
            {
                key: 4,
                name: 'Customers',
                path: '/customers',
                activeIcon: Svg.CustomerActive,
                icon: Svg.Customer,
                alt: 'Customer',
            },
            {
                key: 5,
                name: 'Loads',
                path: '/loads',
                activeIcon: Svg.BoardActive,
                icon: Svg.Board,
                alt: 'Load',
            },
            {
                key: 6,
                name: 'Billing',
                path: '/billing',
                activeIcon: Svg.BillingActiveIcon,
                icon: Svg.BillingIcon,
                alt: 'Billing',
            },
        ];
    }
};
