import React from 'react';
import { Svg } from 'assets/images';
import { Button, List, ListItem, ListItemButton, ListItemText, Box, Drawer } from '@mui/material';
import { useModal } from 'components';
import { MODAL_NAMES } from 'utils';

export const SideMenu = ({ anchor, saidOpen, toggleDrawer }) => {
    const { open } = useModal();
    const handleLogIn = () => {
        open(MODAL_NAMES.SIGN_IN);
    };

    const handleToTop = () => {
        window.scrollTo(0, 0);
    };

    const links = [
        { name: 'Home', link: '#home' },
        { name: 'Features', link: '#features' },
        { name: 'Pricing', link: '#pricing' },
        { name: 'Contact us', link: '#contactUs' },
    ];
    return (
        <Drawer anchor={anchor} open={saidOpen} onClose={toggleDrawer(anchor, false)}>
            <Box className='side-container'>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <div>
                        <img src={Svg.Logo} alt='Logo' className={'logo-img'} />
                    </div>
                    <Button onClick={toggleDrawer('top', true)}>
                        <img src={Svg.CloseIcon} className={'logo-img'} alt='Close-Icon' />
                    </Button>
                </Box>
                <Box role='presentation' onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
                    <List className='list-menu'>
                        {links.map((text, index) => {
                            return <a className='drawer-link-styles' onClick={() =>
                                text?.name === 'Home' ?
                                    handleToTop() : toggleDrawer(anchor, false)}
                                      key={index}
                                      href={text?.link}
                            >
                                <ListItem disablePadding>
                                    <ListItemButton className='side-link'>
                                        <ListItemText primary={text?.name} className='dark-link' />
                                    </ListItemButton>
                                </ListItem>
                            </a>;
                        })}
                        <ListItem disablePadding>
                            <ListItemButton className='side-link' onClick={() => handleLogIn()}>
                                <ListItemText color='success' primary={'Log In'} className='green-link' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Drawer>
    );
};
