import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { CustomInput, AddressInput, MaskInput, CardBtn } from 'components';
import { FindError, FindLoad, FindSuccess } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { customersAction } from 'store/customers';

const REQUEST_TYPE = 'EDIT_CUSTOMER';

export const BasicInfo = ({ tab, indexTab, detailsList, infoList }) => {
    const dispatch = useDispatch();
    const [editInput, setEditInput] = useState(true);
    const loadSave = FindLoad(REQUEST_TYPE);
    const backError = FindError(REQUEST_TYPE);
    const success = FindSuccess(REQUEST_TYPE);
    const [enteredAddress, setEnteredAddress] = useState('');

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: infoList?.name,
            mcNumber: infoList?.mcNumber,
            address: infoList?.address,
            email: infoList?.email,
            billingEmail: infoList?.billingEmail,
            phoneNumber: infoList?.phoneNumber,
        },
    });

    useEffect(() => {
        if (infoList?.address) {
            const currentAddress = {
                address: infoList?.address?.formattedAddress,
            };
            if (infoList?.address?.unit) {
                currentAddress.unit = infoList?.address?.unit;
            }
            setEnteredAddress(currentAddress);
        }
    }, [detailsList]);

    const resetDefault = () => {
        reset({
            name: infoList?.name,
            mcNumber: infoList?.mcNumber,
            address: infoList?.address,
            email: infoList?.email,
            billingEmail: infoList?.billingEmail,
            phoneNumber: infoList?.phoneNumber,
        });
    };

    const handleChancel = () => {
        handleEdit();
        reset({
            name: infoList?.name,
            mcNumber: infoList?.mcNumber,
            address: infoList?.address,
            email: infoList?.email,
            billingEmail: infoList?.billingEmail,
            phoneNumber: infoList?.phoneNumber,
        });
    };

    useEffect(() => {
        if (detailsList) {
            resetDefault();
        }
    }, [detailsList]);

    useEffect(() => {
        if (success) {
            setEditInput(true);
            dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_CUSTOMER'));
        }
    }, [success]);

    function handleAddressChange(selectedAddress) {
        setEnteredAddress(selectedAddress);
    }

    const handleEdit = () => {
        dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
        resetDefault();
        setEditInput(!editInput);
    };

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };

        if (enteredAddress?.address) {
            sendInfo.address = enteredAddress;
        }else{
            delete sendInfo.address
        }

        if (data?.phoneNumber) {
            sendInfo.phoneNumber = `${parseInt(data?.phoneNumber.replace(/[^0-9]/g, ''))}`;
        } else {
            delete sendInfo.phoneNumber;
        }
        dispatch(customersAction.editCustomer(sendInfo, infoList?.id));
    };

    const mobileError =
        backError?.error === 'This Organization has a driver with this data.'
            ? 'This Organization has a driver with this data.'
            : backError?.error && backError?.error?.[0] === 'phone must be a valid phone number'
                ? 'phone must be a valid phone number'
                : backError?.error && backError?.error?.[0] === 'phoneNumber must be a valid phone number'
                    ? 'phoneNumber must be a valid phone number'
                    : '';

    return (
        <Box value={tab} index={indexTab}>
            <div className='details-container'>
                <div className='details-form'>
                    <form onSubmit={handleSubmit(onSubmit)} className='form-messages'>
                        <Box className='laod-space' />
                        <Box className='load-form'>
                            {/* Basic Info */}
                            <Box className='basic-banner '>
                                <div className='load-title basic-title'>
                                    <h4 className='load-basic'>Basic Info</h4>
                                </div>
                                <CardBtn
                                    className={'basic-btn'}
                                    editInput={editInput}
                                    handleEdit={handleEdit}
                                    handleChancel={handleChancel}
                                    load={loadSave}
                                />
                            </Box>
                            <Grid container columnSpacing={3} rowSpacing={0}>
                                <Grid item xs={12} md={6}>
                                    <CustomInput
                                        name='name'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type='text'
                                        label='Name*'
                                        placeholder={'Write name'}
                                        maxLength={40}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MaskInput
                                        name='phoneNumber'
                                        label='Phone Number'
                                        control={control}
                                        rules={{ required: false }}
                                        type='phone'
                                        placeholder={'Phone Number'}
                                        errMessage={!!mobileError}
                                        backError={backError}
                                        errMessageToShow={
                                            mobileError === 'phone must be a valid phone number'
                                                ? 'Phone must be a valid phone number'
                                                : mobileError === 'This Organization has a driver with this data.'
                                                    ? 'This Organization has a driver with this data.'
                                                    : backError?.error && backError?.error?.[0] === 'phoneNumber must be a valid phone number'
                                                        ? 'phoneNumber must be a valid phone number'
                                                        : ''
                                        }
                                        disabled={editInput}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomInput
                                        name='email'
                                        control={control}
                                        rules={{ required: false }}
                                        type='email'
                                        label='Email Address'
                                        placeholder={'Write Email Address'}
                                        disabled={editInput}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomInput
                                        name='billingEmail'
                                        control={control}
                                        disabled={editInput}
                                        rules={{ required: false }}
                                        type='email'
                                        label='Billing Email'
                                        placeholder={'Write Email'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AddressInput
                                        label={'Address'}
                                        disabled={editInput}
                                        errorBoolean={backError?.error === 'Unable to verify the address' ? 'Unable to verify the address' : errors?.address}
                                        onTrigger={handleAddressChange}
                                        enteredValue={enteredAddress}
                                        flexAble={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MaskInput
                                        disabled={editInput}
                                        mask={'999999'}
                                        name='mcNumber'
                                        label='MC number*'
                                        control={control}
                                        rules={{ required: true }}
                                        type='phone'
                                        placeholder={'Write MC Number'}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </div>
            </div>
        </Box>
    );
};
