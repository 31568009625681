import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, CustomInput, CreateCancel } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, truckActions } from 'store';

export const CreateTruck = () => {
    const { close, params } = useModal();
    const ACTION_TYPE = params?.info ? 'EDIT_TRUCK' : 'CREATE_TRUCK';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);
    const { control, handleSubmit, setError } = useForm({});

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        if (backError?.error === 'This Organization has a truck with this ID.') {
            setError('truckId');
        }
    }, [backError]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const onSubmit = (data) => {
        dispatch(truckActions.createTruck(data));
    };

    return (
        <div className='invite-form'>
            <div className='truck-container'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomInput
                        name='name'
                        control={control}
                        rules={{ required: true }}
                        type='text'
                        label='Name*'
                        placeholder={'Name'}
                        maxLength={40}
                    />
                    <CustomInput
                        name='truckNumber'
                        control={control}
                        rules={{ required: false }}
                        type='text'
                        label='Truck Number'
                        placeholder={'Truck Number'}
                        maxLength={40}
                    />
                    <CustomInput
                        name='make'
                        control={control}
                        rules={{ required: false }}
                        type='text'
                        label='Make'
                        placeholder={'Make'}
                        maxLength={40}
                    />
                    <CustomInput
                        name='model'
                        control={control}
                        rules={{ required: false }}
                        type='text'
                        label='Model'
                        placeholder={'Model'}
                        maxLength={40}
                    />
                    <CustomInput
                        name='color'
                        control={control}
                        rules={{ required: false }}
                        type='text'
                        label='Color'
                        placeholder={'Color'}
                        errMessage={
                            backError?.error === 'This Organization has a truck with this ID.'
                                ? 'This Organization has a truck with this ID.'
                                : ''
                        }
                        maxLength={40}
                    />
                    <CreateCancel
                        title={'Save'}
                        actionType={ACTION_TYPE}
                        closeType={'TRACK_MODAL'}
                    />
                </form>
            </div>
        </div>
    );
};
