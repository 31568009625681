import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useModal, Loader, GoBackButton } from 'components';
import { MODAL_NAMES, FindLoad } from 'utils';
import { Box, Tab, Tabs } from '@mui/material';
import { driversActions, trailerActions, loadActions, truckActions } from 'store';
import { customersAction } from 'store/customers';
import { loadStatusDropdown } from './constants';
import { LoadDetails } from './fragments/loadDetails';
import { LoadTransaction } from './fragments/loadTransaction';
import { NotesSection } from '../../fragments';
import { LoadCost } from './fragments/loadCost';

export const LoadBoardInfo = () => {
    const {
        truckDetailsList,
        truckList,
        trailerList,
        driversList,
        loadIdList,
        loadDocument,
        customerList,
    } = useSelector((state) => ({
        truckDetailsList: state?.truck?.truckDetailsList,
        truckList: state?.truck?.truckList,
        trailerList: state?.trailer?.trailerList,
        driversList: state?.drivers?.driversList,
        loadIdList: state?.load?.loadIdList,
        loadDocument: state?.load?.loadDocument,
        customerList: state?.customer?.customerList,
    }));

    const { open } = useModal();
    const history = useHistory();
    const dispatch = useDispatch();
    const loader = FindLoad('GET_LOAD_ID');
    const paramsRouter = useParams();
    const info = history?.location?.state;
    const [valueTab, setValueTab] = useState('details');
    const [status, setStatus] = useState('');

    useEffect(() => {
        dispatch(loadActions.getDocumentLoad(paramsRouter?.id));
    }, []);

    const handleChange = (event, newValueTab) => {
        setValueTab(newValueTab);
    };

    const handelDelete = (type) => {
        if (type === 'cancel') {
            dispatch(loadActions.cancelStatus(loadIdList?.id, 'CANCELED'));
        } else {
            open(MODAL_NAMES.DELETE_MODAL, {
                title: `Load  ${loadIdList?.displayId} `,
                subTitle: 'Delete Load from the system',
                actionType: 'DELETE_LOAD',
                historyPush: '/loads',
                handleDelete: () => handelDeleteTruck('DELETE_LOAD'),
            });
        }
    };

    const handelDeleteTruck = () => {
        dispatch(loadActions.deleteLoad(paramsRouter.id));
    };

    useEffect(() => {
        if (loadIdList?.status) {
            setStatus(loadIdList.status);
        }
    }, [loadIdList]);

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 10;
        delete filteredInfo.page;
        dispatch(loadActions.getLoad({ ...filteredInfo }));
    }, [info]);

    useEffect(() => {
        dispatch(trailerActions.getTrailer({ status: 'ACTIVE' }));
        dispatch(driversActions.getDriver({ status: 'ACTIVE' }));
        dispatch(truckActions.getTruck({ status: 'AVAILABLE' }));
        dispatch(customersAction.getCustomer({ status: 'ACTIVE' }));
        dispatch(loadActions.getLoadId(paramsRouter.id));
    }, []);

    if (loader?.length) return <Loader />;

    return (
        <div className='load-info info-container'>
            <div className='load-header info-header'>
                <div className='info-router'>
                    <GoBackButton title={'Load'} link={'/loads'} />
                </div>
                <div className='load-banner info-banner'>
                    <div className='truck-title'>
                        <h4 className='title'>
                            Load <span>{loadIdList?.displayId}</span>
                        </h4>
                    </div>
                    <div className='header-btn'>
                        <div className='popper-desktop'>
                            <div className='popper-btn'>
                                <div className='header-select'>
                                    <div className='select-status'>
                                        {loadStatusDropdown?.map((item, index) =>
                                                item?.value === status && (
                                                    <div key={index} className='status-style'>
                                                        <span className='dropdown-icon'>
                                                            <img src={item.svg} alt='icon' />
                                                        </span>
                                                        <span className='dropdown-text'>{item.label}</span>
                                                    </div>
                                                ),
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='load-tab info-tab'>
                    <Box className='tab-btns info-btns'>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={valueTab} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab label='Details' value='details' />
                                <Tab label='Transaction' value='transaction' />
                                {loadIdList?.status !== 'PENDING' && <Tab label='Costs' value='costs' />}
                                <Tab label='Notes' value='notes' />
                            </Tabs>
                        </Box>
                    </Box>
                </div>
            </div>
            <div className='info-main'>
                <Box className='info-btns'>
                    {valueTab === 'details' && (
                        <>
                            <LoadDetails
                                tab={valueTab}
                                indexTab={1}
                                detailsList={truckDetailsList}
                                trailerList={trailerList}
                                driversList={driversList}
                                truckList={truckList}
                                customerList={customerList}
                                loadIdList={loadIdList}
                                loadDocument={loadDocument}
                                handelDelete={handelDelete}
                            />
                        </>
                    )}
                    {valueTab === 'transaction' && (
                        <LoadTransaction loadIdList={loadIdList} />
                    )}
                    {valueTab === 'costs' && loadIdList?.status !== 'PENDING' && (
                        <LoadCost />
                    )}
                    {valueTab === 'notes' && (
                        <NotesSection type={'load'} />
                    )}
                </Box>
            </div>
        </div>
    );
};
