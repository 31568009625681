import { useEffect, useMemo, useState } from 'react';
import { AddMaintenance } from './addMaintenance';
import { FindSuccess, useModalBase } from 'utils';
import * as Styled from './styled';
import { truckService } from 'store/truck/truck.service';
import { Svg } from 'assets/images';
import DownloadFile from './downloadFile';
import NoUploadedFiles from './noUploadedFiles';
import moment from 'moment';
import { Loader } from 'components';

const update_type = 'UPDATE_TRUCK_MAINTENANCE_SERVICE';

export const RightModal = ({ open, setOpen, id, onEdit }) => {
    const { openModal } = useModalBase();
    const [loading, setLoading] = useState(false);
    const [openDetails, setOpenDetails] = useState(true);
    const [openDocs, setOpenDocs] = useState(true);
    const update_success = FindSuccess(update_type);

    const [data, setData] = useState({
        serviceName: '',
        serviceDate: '',
        totalCost: '',
        note: '',
        documents: [],
    });
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (id) {
                    if (update_success === 0) await truckService.getMaintenanceById(id).then(({ data }) => setData(data));
                } else setOpen(false);
            } finally {
                setLoading(false);
            }
        })();
    }, [id, setOpen, update_success]);
    const iter = useMemo(() => {
        const images = [];
        const files = [];
        if (!data?.documents?.length) return { images, files };
        for (let item of data?.documents) {
            const file_type = item?.file?.url?.split('.')?.at(-1);
            if (file_type === 'svg' || file_type === 'png' || file_type === 'jpeg' || file_type === 'jpg') images.push(item);
            else files.push(item);
        }
        return { images, files };
    }, [data?.documents]);
    return (
        <Styled.Container open={open} className="right-modal">
            <div className="flex space-between">
                <h4 className="title">{data?.serviceName}</h4>
                <img src={Svg.CloseBtn} alt="close-modal" onClick={() => setOpen(false)} />
            </div>
            <div
                className="flex flex-end mt-16"
                onClick={() => openModal(true, <AddMaintenance initialValues={{ ...data, serviceDate: new Date(data.serviceDate) }} />)}>
                <img src={Svg.EditLoad} onClick={onEdit} alt="edit" />
                Edit
            </div>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Styled.Details openModal={open} open={openDetails && open}>
                        <div className="flex space-between">
                            <Styled.Head>Details</Styled.Head>
                            <img src={Svg.ArrowTop} onClick={() => setOpenDetails((p) => !p)} alt="^" />
                        </div>
                        <div className="details">
                            <span>Service name:</span>
                            <span>{data?.serviceName || 'Not Set'}</span>
                        </div>
                        <div className="details">
                            <span>Service Date:</span>
                            <span>{data?.serviceDate ? moment(data?.serviceDate).format('MM/DD/YYYY') : 'Not Set'}</span>
                        </div>

                        <div className="details">
                            <span>Total Cost:</span>
                            <span> {data?.totalCost ? `$ ${data?.totalCost}` : 'Not Set'}</span>
                        </div>
                        <div className="details">
                            <span>Note:</span>
                            <span>{data?.note || 'Not Set'}</span>
                        </div>
                    </Styled.Details>
                    <Styled.Details openModal={open} open={openDocs && open}>
                        <div className="flex space-between">
                            <Styled.Head>Files</Styled.Head>
                            <img src={Svg.ArrowTop} onClick={() => setOpenDocs((p) => !p)} alt="^" />
                        </div>
                        {!iter.images.length || !iter.files.length ? (
                            <NoUploadedFiles open={openDocs && open} />
                        ) : (
                            <>
                                {iter.files.map((item) => (
                                    <div className="docs show-hide" key={item?.file?.id}>
                                        <img src={Svg.File} width="42px" height="42px" alt="doc" />
                                        <span>{item?.file?.name}</span>
                                        <DownloadFile item={item} />
                                    </div>
                                ))}
                                <Styled.ImagesContainer className="show-hide">
                                    {iter.images.map((item) => (
                                        <div key={item?.file?.id}>
                                            <img src={item?.file?.url} alt="doc" />
                                            <div>
                                                <DownloadFile icon={Svg.UploadWhite} item={item} />
                                                <a href={item?.file?.url} target="_blank" rel="noreferrer">
                                                    <img src={Svg.EyeOpenWhite} alt="eye" />
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </Styled.ImagesContainer>
                            </>
                        )}
                    </Styled.Details>
                </>
            )}
        </Styled.Container>
    );
};
