/** Types */

/** My Profile */
export const ACTIVATE_PACKAGE = 'ACTIVATE_PACKAGE';

export const GET_MY_PROFILE_PACKAGE = 'GET_MY_PROFILE_PACKAGE';
export const GET_MY_PROFILE_PACKAGE_SUCCESS = 'GET_MY_PROFILE_PACKAGE_SUCCESS';

export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';

export const GET_CARD_INFO = 'GET_CARD_INFO';
export const GET_CARD_INFO_SUCCESS = 'GET_CARD_INFO_SUCCESS';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
/** Edit */