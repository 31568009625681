import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trailerActions } from 'store';
import {
    FullTable,
    TableWrapper,
    useModal,
    MobileCard,
    Loader,
    PaginationItem,
    MobileFiltering,
    NoDataComponent,
    CreateMobileButton,
} from 'components';
import { ACTION_TYPE, trailersHead, trailersBody, TrailersMenu, trailersType, trailerMobileStatus } from './constants';
import { FindLoad, MODAL_NAMES, SaveParams, SendPageSave } from 'utils';

const title = 'Trailers';

export const TrailersPage = () => {
    const { trailerList } = useSelector((state) => ({
        trailerList: state?.trailer?.trailerList,
    }));

    const { open } = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);

    const handleAddTrailer = () => {
        open(MODAL_NAMES.CREATE_TRAILER, { modalTitle: 'Add Trailer' });
    };

    const handleClick = (item, name) => {
        if (name === 'DELETE_TRAILER') {
            dispatch(trailerActions.deleteTrailer(item?.id));
        } else if (name === 'Activate' || name === 'Inactivate' || name === 'In_Transit' || name === 'Maintenance') {
            const status = name === 'Activate';
            dispatch(trailerActions.chnageStausTrailer(status, item?.id));
        }
    };

    const handleFilterStatus = (event) => {
        SaveParams(history, {
            ...info,
            status: event?.target?.defaultValue,
        });
    };

    const handleFilterType = (event) => {
        SaveParams(history, {
            ...info,
            type: event?.target?.defaultValue,
        });
    };

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 10;
        if (info?.type && info?.type !== 'ALL') {
            filteredInfo.type = info.type;
        } else {
            delete filteredInfo.type;
        }
        if (info?.status && info?.status !== 'ALL') {
            filteredInfo.status = info.status;
        } else {
            delete filteredInfo.status;
        }
        delete filteredInfo.page;
        dispatch(trailerActions.getTrailer({ ...filteredInfo }));
    }, [info]);

    return (
        <div className='trailer-container page-container'>
            <div className='desktop-view'>
                <TableWrapper
                    firstButton={'Active'}
                    secondButton={'Inactive'}
                    addButton={'Add Trailer'}
                    buttonsTab={true}
                    tab={false}
                    buttonsTabAddButton={true}
                    handleIniteUser={handleAddTrailer}
                    wrapperTitile={title}>
                    <FullTable
                        head={trailersHead}
                        body={trailersBody}
                        loadingType={ACTION_TYPE}
                        list={trailerList?.trailers}
                        listCount={trailerList?.count}
                        noText={title}
                        handleClickButton={handleClick}
                        handleClick={(id) => history.push({ pathname: `/trailer/${id}` })}
                    />
                </TableWrapper>
            </div>
            <div className='mobile-view'>
                <div className='wrapper-header'>
                    <div className='header-title'>
                        <h4 className='title'>{title}</h4>
                    </div>
                    <div className='header-btn'>
                        <div className='filter-menu'>
                            <MobileFiltering
                                title={'Filter'}
                                titleStatus={'Filter the Status'}
                                titleType={'Filter the Type'}
                                status={info?.status}
                                type={info?.type}
                                typeList={trailersType}
                                statusList={trailerMobileStatus}
                                handleFilterType={handleFilterType}
                                handleFilterStatus={handleFilterStatus}
                            />
                        </div>

                        <CreateMobileButton handleClick={handleAddTrailer} />
                    </div>
                </div>

                {loader?.length ?
                    <Loader />
                    :
                    trailerList?.trailers?.length ? (
                        <div className='mobile-card'>
                            {trailerList?.trailers?.map((item, key) => (
                                <div key={key} className='card-box'>
                                    <MobileCard
                                        poperList={TrailersMenu}
                                        actionType={ACTION_TYPE}
                                        item={item}
                                        handleClickButton={handleClick}
                                        handleClick={() => history.push({ pathname: `/trailer/${item.id}` })}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={{ height: '100%' }}>
                            <NoDataComponent />
                        </div>
                    )}
            </div>

            {trailerList?.count !== 0 && (
                <div className='mobile-pagination'>
                    <PaginationItem
                        listLength={trailerList?.count}
                        page={info?.page}
                        handleReturn={(number) => SendPageSave(number, info, history)}
                        count={trailerList?.count}
                    />
                </div>
            )}
        </div>
    );
};
