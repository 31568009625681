/* Truck Load Reducer */

import {
    GET_LOAD_SUCCESS,
    GET_LOAD_ID_SUCCESS,
    GET_LOAD_TRUCK_ID_SUCCESS,
    GET_LOAD_ID_DOCUMENT_SUCCESS,
    GET_TRANSACTION_SUCCESS, GET_LOAD_COASTS_SUCCESS,
} from './load.type';

const initialState = {
    loadList: [],
    loadIdList: [],
    loadIdTruckList: [],
    loadDocument: null,
    loadTxn: [],
    loadCoasts: null
};

export const loadReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOAD_SUCCESS:
            return {
                ...state,
                loadList: action.payload,
            };
        case GET_LOAD_ID_SUCCESS:
            return {
                ...state,
                loadIdList: action.payload,
            };
        case GET_LOAD_TRUCK_ID_SUCCESS:
            return {
                ...state,
                loadIdTruckList: action.payload,
            };

        /** Loads Document */

        case GET_LOAD_ID_DOCUMENT_SUCCESS:
            return {
                ...state,
                loadDocument: action.payload,
            };

        /** End */

        case GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                loadTxn: action.payload,
            };

            case GET_LOAD_COASTS_SUCCESS:
            return {
                ...state,
                loadCoasts: action.payload,
            };
        default:
            return state;
    }
};
