import React, { useState } from 'react';
import { Svg } from 'assets/images';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import {
    ContactUs,
    EverythingFragment,
    FeaturesFragment,
    BannerFragment,
    PayFragment,
    SaveFragment,
    WeCanFragment,
    HowItFragment,
    StartFreeFragment,
} from './fragments';
import { GetStart, useModal } from 'components';
import { MODAL_NAMES } from 'utils';

export const LandingPage = ({}) => {
    const { open } = useModal();
    const handleGetStart = () => {
        open(MODAL_NAMES.SIGN_UP);
    };
    return (
        <div className="landing-page">
            {/* Header */}
            <div className="landing-header">
                <Container maxWidth="lg">
                    <div className="header-container">
                        <div className="aside-header">
                            <div className="header-title">
                                <h1 className="text-h1">
                                    <span className="title-we">Made by Carriers for </span>
                                    <br />
                                    <span className="title-loads">Carriers and Truckers</span>
                                </h1>
                            </div>
                            <div className="subtitle-box">
                                <p className="header-subtitle">Designed to help you manage your trucks, drivers,</p>
                                <p className="header-subtitle">loads and see your profits in real time</p>
                            </div>
                            <div className="header-get">
                                <GetStart handelClick={() => handleGetStart()} title={'Sign Up Now'} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* Main */}
            <main>
                <Container className="global-container">
                    {/* Mobile Header */}
                    <section id={'home'} className="section-header">
                        <div className="aside-header">
                            <div className="header-title">
                                <h2 className="title-we">Made by Carriers for</h2>

                                <h2 style={{ marginLeft: 0 }} className="title-loads">
                                    Carriers and Truckers
                                </h2>
                            </div>
                            <div className="subtitle-box">
                                <p className="header-subtitle">
                                    Designed to help you manage your trucks, drivers, loads and see your profits in real time
                                </p>
                            </div>
                            <div className="header-get">
                                <GetStart handelClick={() => handleGetStart()} title={'Sign Up Now'} />
                            </div>
                        </div>
                    </section>
                    {/* Section Banner */}
                    <section className="section-banner">
                        <BannerFragment />
                    </section>
                    {/* Section Everything */}
                    <section className="section-everything">
                        <EverythingFragment />
                    </section>
                </Container>
                {/* Section Start Free */}
                <div className="section-free">
                    <StartFreeFragment handleClick={handleGetStart} />
                </div>
                <Container maxWidth="lg">
                    {/* Section Features */}
                    <section id={'features'} className="section-features">
                        <FeaturesFragment />
                    </section>
                    {/* Section How It Works */}
                    <section className="section-how">
                        <HowItFragment />
                    </section>
                    {/* Section We can help make */}
                    <section className="section-we">
                        <WeCanFragment />
                    </section>
                    {/*  Section Save your time and start now */}
                    <section className="section-save">
                        <SaveFragment handleClick={() => handleGetStart()} />
                    </section>
                    {/* Section Pay Card */}
                    <section id={'pricing'} className="section-pay">
                        <PayFragment handleClick={() => handleGetStart()} />
                    </section>
                </Container>
                {/* Section Contact Us */}
                <section id={'contactUs'} className="section-contact">
                    <ContactUs />
                </section>
            </main>
            {/* Footer */}
            <footer className="footer">
                <Container maxWidth="lg">
                    <div className="footer-section">
                        <div className="footer-img">
                            <Link to={'/'}>
                                <img src={Svg.LogoWhite} alt="logo-White" />
                            </Link>
                        </div>
                        <div className="footer-box">
                            <div className="footer-title">
                                <Link to="/terms" className="terms-link">
                                    <span className="privacy-text">Terms of Use</span>
                                </Link>
                                <span className="space" />
                                <Link to="/privacy" className="privacy-link">
                                    <span className="privacy-text">Privacy</span>
                                </Link>
                            </div>
                            <div className="footer-info">2023 ©FleetForest. All rights reserved.</div>
                        </div>
                    </div>
                </Container>
            </footer>
        </div>
    );
};
