import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StyledActivePlan } from './style';
import { yourPlanFeatures } from '../../../payments/constants';
import { Svg, Images } from 'assets/images';
import { FREE_PACKAGE, ReturnPrice } from 'utils';

export const ActivePlan = ({ userInfo }) => {
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));
    const plan = yourPlanFeatures(userInfo?.package);
    const router = useHistory();
    const startDate = userInfo?.package === FREE_PACKAGE ? moment(userInfo?.createAt).format('L') :
        userInfo?.packageStartDate ? moment(userInfo?.packageStartDate).format('L') : '';
    const checkStatus = userInfo?.isPackageActive || userInfo?.package === FREE_PACKAGE;

    const handleClick = () => {
        router.push('/plansAndPricing');
    };

    return (
        <StyledActivePlan>
            <div className='active-plan-wrapper'>
                <div className='first-section'>
                    <p style={{ textTransform: 'capitalize' }} className='title'>
                        {activePackage?.package}
                    </p>
                    <div className='pay-items-wrapper'>
                        <div className='icon-wrapper'>
                            <img src={Svg.PayDate} alt='PayDate' />
                        </div>
                        <p className='standart-text'>
                            <span className='dark-text'> Started Date</span> :&nbsp;{startDate}
                        </p>
                    </div>
                    {checkStatus && (
                        <div className='pay-items-wrapper'>
                            <div className='icon-wrapper'>
                                <img src={Svg.PayStatus} alt='PayStatus' />
                            </div>

                            <p className='standart-text'>
                                <span className='dark-text'> Status : </span>
                                <span className={'status'}>
                                    &nbsp;{userInfo?.isPackageActive ? (userInfo?.package === FREE_PACKAGE ? 'Free' : 'Paid') : ''}
                                </span>
                            </p>
                        </div>
                    )}
                    <div className='pay-items-wrapper'>
                        <div className='icon-wrapper'>
                            <img src={Svg.ReceiptText} alt='ReceiptText' />
                        </div>
                        <p className='standart-text'>
                            <span className='dark-text'> Price: </span>
                            {`$${ReturnPrice(userInfo?.package)}/per truck`}
                        </p>
                    </div>
                    <div className='pay-items-wrapper'>
                        <div className='icon-wrapper'>
                            <img src={Svg.TruckTick} alt='TruckTick' />
                        </div>
                        <p className='standart-text'>
                            <span className='dark-text'>Number of Trucks:</span>
                            <span className='text-quantity'>{activePackage?.itemQuantity}</span>
                        </p>
                    </div>
                </div>

                <div className='second-section'>
                    <p className='title'>Your plan features</p>
                    <div className='flex-able-wrapper'>
                        <div className='first-wrap'>
                            {plan?.items?.map((item, index) => (
                                <div key={index} className='plan-items-wrapper'>
                                    <div className='plan-basic '>
                                        <img src={Images.CheckGreen} alt='Check-Green' />
                                    </div>
                                    <p className='features-text'>{item.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <hr className='plan-line' />
            <div className='button-text-wrapper'>
                <div style={{ width: '100%' }}>
                    {userInfo?.isPackageActive === false ? (
                        <div>
                            <p className='plan-info'>
                                You have paid until <span
                                style={{ color: 'black' }}>{moment(userInfo?.packageEndDate).format('L')}</span>
                            </p>
                            <p className='plan-info'> After the expiration date, your ....</p>
                        </div>
                    ) : userInfo?.package === FREE_PACKAGE ? (
                        <p className='plan-info'></p>
                    ) : (
                        <>
                            <p className='plan-info'>
                                Your next payment is <span>{`$${
                                activePackage?.package === FREE_PACKAGE ? ReturnPrice(userInfo?.package)  :
                                    +ReturnPrice(userInfo?.package) * activePackage?.itemQuantity
                                } USD`}</span>, to be
                                charged on
                                <span>&nbsp;{moment(userInfo?.nextInvoiceDate).format('L')}</span>
                            </p>
                            <p className='plan-info'>Your payment will be automatically renewed each month.</p>
                        </>
                    )}
                </div>

                {userInfo?.isPackageActive !== false && (
                    <div className='upgrade-button-wrapper'>
                        <button onClick={handleClick} className='upgrade'>
                            Change Plan
                        </button>
                    </div>
                )}
            </div>
        </StyledActivePlan>
    );
};
