import {
    GET_TRAILER_SUCCESS,
    GET_TRAILER_ID_SUCCESS,
    GET_TRAILER_DOCUMENT_SUCCESS,
    GET_TRAILER_COASTS_SUCCESS,
} from './trailers.type';

const initialState = {
    trailerList: [],
    trailerId: [],
    trailerDocument: null,
    trailerCoasts: [],
};

export const traileredReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRAILER_SUCCESS:
            return {
                ...state,
                trailerList: action.payload,
            };

        case GET_TRAILER_ID_SUCCESS:
            return {
                ...state,
                trailerId: action.payload,
            };

        /** Trailer Documents */

        case GET_TRAILER_DOCUMENT_SUCCESS:
            return {
                ...state,
                trailerDocument: action.payload,
            };

        /** End */

        /** Trailer Documents */

        case GET_TRAILER_COASTS_SUCCESS:
            return {
                ...state,
                trailerCoasts: action.payload,
            };

        /** End */

        default:
            return state;
    }
};
