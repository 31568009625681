import axios from 'axios';
import { activatePackage, cancelSubscription, getCardInfo, getInvoices, getMyPackage } from './payment.action';
export { userPaymentReducer } from './payment.reducer';
export { watchUserPayment } from './payment.saga';

export const PaymentActions = {
    getMyPackage,
    getInvoices,
    getCardInfo,
    cancelSubscription,
    activatePackage,
};

export const services = {
    getSubscription: () => axios.get('/subscriptions', { auth: true }),
};

