/* Action */

import {
    GET_TRAILER,
    CREATE_TRAILER,
    DELETE_TRAILER,
    EDIT_TRAILER,
    CHANGE_TRAILER_STATUS,
    GET_TRAILER_ID,
    CREATE_TRAILER_DOCUMENT,
    EDIT_TRAILER_DOCUMENT_ARCHIVE,
    DELETE_TRAILER_DOCUMENT,
    GET_TRAILER_DOCUMENT,
    GET_TRAILER_COASTS,
    CREATE_TRAILER_COASTS,
} from './trailers.type';

/* Get Trailer */
export const getTrailer = (body, load) => {
    return {
        type: GET_TRAILER,
        payload: { body, load },
    };
};

/* Create Trailer */
export const createTrailer = (body) => {
    return {
        type: CREATE_TRAILER,
        payload: { body },
    };
};

/* Edit Trailer */
export const editTrailer = (body, id) => {
    return {
        type: EDIT_TRAILER,
        payload: { body, id },
    };
};

/* Delete Trailer */
export const deleteTrailer = (id) => {
    return {
        type: DELETE_TRAILER,
        payload: id,
    };
};

/* Change Trailer Status */
export const changeTrailerStatus = (id, active) => {
    return {
        type: CHANGE_TRAILER_STATUS,
        payload: { id, active },
    };
};

/* Get Trailers Id */
export const getTrailerId = (id) => {
    return {
        type: GET_TRAILER_ID,
        payload: { id },
    };
};

/** Trailer Documents */

export const createTrailerDocument = (id, body) => {
    return {
        type: CREATE_TRAILER_DOCUMENT,
        payload: { id, body },
    };
};

export const getTrailerDocument = (id) => {
    return {
        type: GET_TRAILER_DOCUMENT,
        payload: { id },
    };
};

export const editTrailerDocumentArchive = (id, docId, archive) => {
    return {
        type: EDIT_TRAILER_DOCUMENT_ARCHIVE,
        payload: { id, docId, archive },
    };
};

export const deleteTrailerDocument = (id, docId) => {
    return {
        type: DELETE_TRAILER_DOCUMENT,
        payload: { id, docId },
    };
};

/** End */

/** Trailer Coasts */

export const createTrailerCosts = (id, body) => {
    return {
        type: CREATE_TRAILER_COASTS,
        payload: { id, body },
    };
};

export const getTrailerCoast = (id) => {
    return {
        type: GET_TRAILER_COASTS,
        payload: { id },
    };
};

/** End */
