import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MinLoader, useModal } from 'components';
import { FindLoad, FindSuccess, SavePage } from 'utils';
import { httpRequestsOnSuccessActions } from 'store';
import { useHistory } from 'react-router-dom';

export const DeleteModal = () => {
    /**
     * Delete Modal.
     */

    const { close, params } = useModal();
    const loader = FindLoad(params?.actionType);
    const success = FindSuccess(params?.actionType);
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(params?.actionType));
            close();
            SavePage(history, pushInfo, { load: 'noLoad' });
            if (params?.historyPush) {
                history.push(params?.historyPush);
            }
        }
    }, [success]);


    return (
        <div className="delete-form">
            <div className="delete-modal-title">
                {params?.noTitle ? ' ' : <p className="want-delete">Do you want Delete </p>}
                <span className="delete-title">{params?.title}</span>
                <span className="want-delete"> ? </span>
            </div>
            <div className="delete-subtitle">{params?.subTitle}</div>
            <div className="delete-modal-buttons-wrapper">
                <button className="cancel-button" onClick={() => close()}>
                    Cancel
                </button>
                <button className="delete-button" onClick={() => params?.handleDelete()}>
                    {loader ? <MinLoader color={'white'} /> : params?.deleteButton ? params.deleteButton : 'Delete'}
                </button>
            </div>
        </div>
    );
};
