import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { CustomInput, AddressInput, MaskInput, CardBtn } from 'components';
import { FindError, FindLoad, FindSuccess } from 'utils';
import { driversActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

const REQUEST_TYPE = 'EDIT_DRIVER_DETAILS';

export const BasicInfo = ({ detailsList, params }) => {
    const dispatch = useDispatch();
    const [editInput, setEditInput] = useState(true);
    const loadSave = FindLoad(REQUEST_TYPE);
    const backError = FindError(REQUEST_TYPE);
    const success = FindSuccess(REQUEST_TYPE);
    const [enteredAddress, setEnteredAddress] = useState('');

    const {
        reset,
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            phone: '',
            driverLicense: '',
            address: '',
            status: '',
        },
    });

    const resetDefault = () => {
        reset({
            firstName: detailsList?.firstName,
            lastName: detailsList?.lastName,
            phone: detailsList?.phone,
            driverLicense: detailsList?.driverLicense,
            address: detailsList?.address,
            status: detailsList?.status,
        });
    };

    const handleChancel = () => {
        handleEdit();
        reset({
            firstName: detailsList?.firstName,
            lastName: detailsList?.lastName,
            phone: detailsList?.phone,
            driverLicense: detailsList?.driverLicense,
            address: detailsList?.address,
            status: detailsList?.status,
        });
    };

    useEffect(() => {
        if (detailsList) {
            resetDefault();
        }
    }, [detailsList]);

    useEffect(() => {
        if (success) {
            setEditInput(true);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
        }
    }, [success]);

    useEffect(() => {
        if (detailsList?.address) {
            setEnteredAddress(detailsList?.address);
        }
    }, [detailsList]);

    function handleAddressChange(selectedAddress) {
        setEnteredAddress(selectedAddress);
        if (errors?.address) {
            clearErrors('address');
        }
    }

    const handleEdit = () => {
        dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
        resetDefault();
        setEditInput(!editInput);
    };

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };

        if (enteredAddress && !enteredAddress?.formattedAddress) {
            sendInfo.address = enteredAddress;
        } else if (enteredAddress?.formattedAddress) {
            sendInfo.address = {
                address: enteredAddress?.formattedAddress,
                unit: enteredAddress?.unit,
            };
        }
        if (enteredAddress) {
            dispatch(driversActions.editDriverDetails(sendInfo, params?.id));
            setEditInput(false);
        } else {
            setError('address');
        }
    };

    return (
        <Box  >
            <div className='details-container'>
                <div className='details-form'>
                    <form onSubmit={handleSubmit(onSubmit)} className='form-messages'>
                        <Box className='laod-space' />
                        <Box className='load-form'>
                            <Box className='basic-banner space-between'>
                                <div className='load-title basic-title'>
                                    <h4 className='load-basic'>Basic Info</h4>
                                </div>
                                <CardBtn
                                    className={'basic-btn'}
                                    editInput={editInput}
                                    handleEdit={handleEdit}
                                    handleChancel={handleChancel}
                                    load={loadSave}
                                />
                            </Box>
                            <Grid container columnSpacing={3} rowSpacing={0}>
                                <Grid item xs={12} md={6}>
                                    <CustomInput
                                        name='firstName'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type='text'
                                        label='First Name*'
                                        placeholder={'First Name'}
                                        maxLength={40}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomInput
                                        name='lastName'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type='text'
                                        label='Last Name*'
                                        placeholder={'Last Name'}
                                        maxLength={40}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MaskInput
                                        name='phone'
                                        label='Phone Number*'
                                        placeholder={'Phone Number'}
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type='phone'
                                        errMessage={backError?.error?.[0] === 'phone must be a valid phone number'}
                                        errMessageToShow={
                                            backError?.error?.[0] === 'phone must be a valid phone number'
                                                ? 'Phone must be a valid phone number'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomInput
                                        name='driverLicense'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type='text'
                                        label='Driver’s License*'
                                        placeholder={'Driver’s License'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <AddressInput
                                        label={'Address*'}
                                        disabled={editInput}
                                        errorBoolean={backError?.error === 'Unable to verify the address' ? 'Unable to verify the address' : errors?.address}
                                        onTrigger={handleAddressChange}
                                        enteredValue={enteredAddress}
                                        backError={backError}
                                        flexAble={true}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </div>
            </div>
        </Box>
    );
};
