import { StatusTruck, DateRow, TextRow, ActionTransaction, PriceRow } from 'components';
import { CheckCoastPermission, MODAL_NAMES } from 'utils';
import { Svg } from 'assets/images';
import moment from 'moment';

export const ACTION_TYPE = 'GET_LOAD';
export const ACTION_TYPE_TRANSACTION = 'GET_TRANSACTION';

export const loadsHead = (activePackage) => {
    return [
        { name: 'id', title: 'ID' },
        { name: 'pick', title: 'Pick Up Date' },
        { name: 'truck', title: 'Truck' },
        { name: 'firstStop', title: 'First Stop' },
        { name: '', title: '' },
        { name: 'lastStop', title: 'Last Stop' },
        { name: 'customer', title: 'Customer Rate' },
        CheckCoastPermission(activePackage) && { name: 'cost', title: 'Cost' },
        CheckCoastPermission(activePackage) && { name: 'profit', title: 'Profit' },
        {
            name: 'status',
            title: 'Status',
            type: 'status',
            list: [
                {
                    value: 'ALL',
                    label: 'All',
                },
                {
                    value: 'ACTIVE',
                    label: 'Active',
                },
                {
                    value: 'PENDING',
                    label: 'Pending',
                },
                {
                    value: 'COMPLETE',
                    label: 'Complete',
                },
                {
                    value: 'DELIVERED',
                    label: 'Delivered',
                },
                {
                    value: 'CANCELED',
                    label: 'Canceled ',
                },
            ],
        },
    ];
};

const handleRenderStops = (type, item) => {
    const lastStopDate = item?.stops.find((i) => i.type === 'PICKUP');
    const firstStop = item?.stops.find((i) => i.type === 'PICKUP');
    const lastStop = item?.stops.find((i) => i.type === 'DROP_OFF');
    if (item?.stops) {
        if (type === 'stopDate') {
            if (firstStop) {
                return moment(lastStopDate?.date).format('MM/DD/YYYY');
            } else {
                return 'Not Set';
            }
        }
        if (type === 'first') {
            if (firstStop) {
                return firstStop?.address?.formattedAddress;
            } else {
                return 'Not Set';
            }
        }
        if (type === 'last') {
            if (lastStop) {
                return lastStop?.address?.formattedAddress;
            } else {
                return 'Not Set';
            }
        }
    } else {
        return 'Not Set';
    }
};

export const loadsBody = (activePackage) => {
    return [
        {
            rowText: (item) => (
                <div>
                    <div className='text-gunmetal'>{item?.displayId}</div>
                </div>
            ),
        },
        {
            rowText: (item) => (
                <div>
                    <div className='text-gunmetal '>{handleRenderStops('stopDate', item)}</div>
                </div>
            ),
        },

        {
            rowText: (item) => (
                <div>
                    <div className='text-gunmetal'>{item?.truck?.displayId ? item?.truck?.displayId : 'Not Set'}</div>
                </div>
            ),
        },
        {
            rowText: (item) => (
                <div className='text-gunmetal text-style'>
                    <TextRow name={handleRenderStops('first', item)} textWidth={10} />
                </div>
            ),
        },
        {
            rowText: () => (<img src={Svg.CircleRight} alt='Circle-Right' />),
        },
        {
            rowText: (item) => (
                <div className='text-gunmetal text-style'>
                    <TextRow name={handleRenderStops('last', item)} textWidth={10} />
                </div>
            ),
        },
        {
            rowText: (item) => (
                <div>
                    <div className='text-gunmetal-light text-style'>
                        {item?.totalProfit?.customerRate ?
                            <PriceRow
                                info={item?.totalProfit?.customerRate ? item?.totalProfit?.customerRate : 0}
                                textWidth={8}
                            />
                            :
                            <p>Not Set</p>
                        }
                    </div>
                </div>
            ),
        },
        CheckCoastPermission(activePackage) && {
            rowText: (item) => (
                <div>
                    <div className={`text-gunmetal-light text-style`}>
                        {Number(item?.totalProfit?.customerRate) - Number(item?.totalProfit?.profit) === 0 ? ' +' : ' '}

                        {item?.totalProfit?.totalTripCost ?
                            <PriceRow
                                info={item?.totalProfit?.totalTripCost ? item?.totalProfit?.totalTripCost : 0}
                                textWidth={8}
                            />
                            :
                            <p>Not Set</p>
                        }
                    </div>
                </div>
            ),
        },
        CheckCoastPermission(activePackage) && {
            rowText: (item) => (
                <div className='text-style'>
                    <div
                        className={`text-gunmetal-light${item?.totalProfit?.profit >= 0 ? 'text-success' : ''}`}>
                        {item?.totalProfit?.profit ? (
                            <span className={`${item?.totalProfit?.profit < 0 && 'text-error'} text-style`}>
                                {item?.totalProfit?.profit ? (
                                    <>
                                        {item?.totalProfit?.profit >= 0 ? (
                                            <span className={'flex text-style'} style={{ color: '#0BB53A' }}>+
                                                  <PriceRow
                                                      info={item?.totalProfit?.profit ? item?.totalProfit?.profit : 0}
                                                      textWidth={9}
                                                      color={'#0BB53A'}
                                                  />
                                            </span>
                                        ) : (
                                            <span className={'flex text-style'}>
                                                   <PriceRow
                                                       info={item?.totalProfit?.profit ? item?.totalProfit?.profit : 0}
                                                       textWidth={9}
                                                       color={'#D03325'}
                                                   />
                                            </span>
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}
                            </span>
                        ) : (
                            'Not Set'
                        )}
                    </div>
                </div>
            ),
        },
        { rowText: (item) => <StatusTruck info={item?.status} /> },
    ];
};

export const loadStatus = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        value: 'PENDING',
        label: 'Pending',
    },
    {
        value: 'COMPLETE',
        label: 'Complete',
    },
    {
        value: 'DELIVERED',
        label: 'Delivered',
    },
    {
        value: 'CANCELED',
        label: 'Canceled ',
    },
];

export const loadStatusDropdown = [
    {
        svg: Svg.GreenRoundedIcon,
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        svg: Svg.OrangeRoundedIcon,
        value: 'PENDING',
        label: 'Pending',
    },
    {
        svg: Svg.IndigoRoundedIcon,
        value: 'DELIVERED',
        label: 'Delivered',
    },

    {
        svg: Svg.BlueRoundedIcon,
        value: 'COMPLETE',
        label: 'Complete',
    },
    {
        svg: Svg.RedRoundedIcon,
        value: 'CANCELED',
        label: 'Canceled',
    },
];

export const transactionHead = [
    { name: 'id', title: 'ID' },
    { name: 'amount', title: 'Amount' },
    { name: 'created Date', title: ' Created Date' },
    {
        name: 'type',
        title: 'Type',
        type: 'type',
        list: [
            {
                value: 'ALL',
                label: 'All',
            },
            {
                value: 'INITIAL_RATE',
                label: 'Initial Rate',
            },
            {
                value: 'RATE_ADJUSTMENT',
                label: 'Rate Adjustment',
            },
            {
                value: 'LATE_FEE',
                label: 'Late Fee',
            },
            {
                value: 'DETENTION',
                label: 'Detention',
            },
            {
                value: 'LAYOVER',
                label: 'Layover',
            },
        ],
    },
    { name: 'description', title: ' Description' },
    { name: 'action', title: ' Action' },
];

export const userStatus = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        value: 'PENDING',
        label: 'Pending',
    },
    {
        value: 'INACTIVE',
        label: 'Inactive',
    },
];

export const transactionBody = [
    { rowText: (item) => <div style={{ color: 'rgb(80, 108, 132)' }}>{item?.displayId}</div> },
    {
        rowText: (item) => <div
            style={{ display: 'flex', color: item?.amount < 0 ? '#D03325' : '#506C84' }}>{item?.amount < 0 ? '-' : '+'}
            <PriceRow info={item?.amount ? item.amount : 0} color={item?.amount < 0 ? '#D03325' : '#506C84'} /></div>,
    },
    { rowText: (item) => <DateRow date={item?.createdAt} /> },
    { rowText: (item) => <div style={{ color: 'rgb(80, 108, 132)' }}>{txnType(item?.type)}</div> },
    { rowText: (item) => <TextRow name={item?.description} /> },
    { rowText: (item) => <ActionTransaction name={item} /> },
];

export const transactionStatus = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'INITIAL_RATE',
        label: 'Initial Rate',
    },
    {
        value: 'RATE_ADJUSTMENT',
        label: 'Rate Adjustment',
    },
    {
        value: 'LATE_FEE',
        label: 'Late Fee',
    },
    {
        value: 'DETENTION',
        label: 'Detention',
    },
    {
        value: 'LAYOVER',
        label: 'Layover',
    },
];

export const LoadMenu = [
    {
        name: 'Edit',
        modalName: MODAL_NAMES.EDIT_LOAD_BOARD,
    },
    {
        name: 'Inactivate',
        modalName: MODAL_NAMES.INACTIVATE_MODAL,
    },
    {
        name: 'Delete',
        modalName: MODAL_NAMES.DELETE_BOARD,
        color: '#d03325',
    },
];

export const cretaeLoadDTO = [
    {
        value: 'DRY',
        label: 'Dry',
    },
    {
        value: 'FLATBED',
        label: 'Flatbed',
    },
    {
        value: 'REEFER',
        label: 'Reefer',
    },
    {
        value: 'POWER_ONLY',
        label: 'Power Only',
    },
];

export const cretaeStopDTO = [
    {
        value: 'PICKUP',
        label: 'Pick Up',
    },
    {
        value: 'DROP_OFF',
        label: 'Drop Off',
    },
];

export const cretaeLoadTxnDTO = [
    // {
    //     value: 'INITIAL_RATE',
    //     label: 'Initial Rate',
    // },
    {
        value: 'RATE_ADJUSTMENT',
        label: 'Rate Adjustment',
    },
    {
        value: 'LUMPER',
        label: 'Lumper',
    },
    {
        value: 'LATE_FEE',
        label: 'Late Fee',
    },
    {
        value: 'DETENTION',
        label: 'Detention',
    },
    {
        value: 'LAYOVER',
        label: 'Layover',
    },
];

export const loadDocumentsList = [
    { name: 'Rate Con', type: 'RATE_CON' },
    { name: 'Pod', type: 'POD' },
    { name: 'Invoice', type: 'INVOICE' },
    { name: 'Other', type: 'OTHER' },
];

export const loadStatusTitle = (name) => {
    switch (name) {
        case 'PENDING':
            return 'ACTIVE';
        case 'ACTIVA':
            return 'DELIVERED';
        case 'DELIVERED':
            return 'COMPLETE';
        default:
            return name;
    }
};

export const txnType = (name) => {
    switch (name) {
        case 'INITIAL_RATE':
            return 'Initial Rate';
        // case 'INITIAL_RATE':
        //     return '...';
        case 'RATE_ADJUSTMENT':
            return 'Rate Adjustment';
        case 'LATE_FEE':
            return 'Late Fee';
        case 'DETENTION':
            return 'Detention';
        case 'LAYOVER':
            return 'Layover';
        default:
            return name;
    }
};
