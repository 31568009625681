import React, { Fragment } from 'react';

export const GetStart = ({ title, handelClick }) => {
    return (
        <Fragment>
            <button className="green-btn" onClick={handelClick}>
                {title}
            </button>
        </Fragment>
    );
};
