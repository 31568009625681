/**  Types */

/**  Create Truck */
export const CREATE_TRUCK = 'CREATE_TRUCK';

/**  Get Truck */
export const GET_TRUCK = 'GET_TRUCK';
export const GET_TRUCK_SUCCESS = 'GET_TRUCK_SUCCESS';

/**  Change Truck Status */
export const CHANGE_TRUCK_STATUS = 'CHANGE_TRUCK_STATUS';

/** Edit Truck Details */
export const EDIT_TRUCK_DETAILS = 'EDIT_TRUCK_DETAILS';

/** Get Truck Details */
export const GET_TRUCK_DETAILS = 'GET_TRUCK_DETAILS';
export const GET_TRUCK_DETAILS_SUCCESS = 'GET_TRUCK_DETAILS_SUCCESS';

export const REMOVE_TRUCK_DETAILS = 'REMOVE_TRUCK_DETAILS';

/**  Delete Truck */
export const DELETE_TRUCK = 'DELETE_TRUCK';

export const GET_TRUCK_LOADS = 'GET_TRUCK_LOADS';

export const GET_TRUCK_LOADS_SUCCESS = 'GET_TRUCK_LOADS_SUCCESS';

/** Truck Documents */
export const CREATE_TRUCK_DOCUMENT = 'CREATE_TRUCK_DOCUMENT';

export const GET_TRUCK_DOCUMENT = 'GET_TRUCK_DOCUMENT';
export const GET_TRUCK_DOCUMENT_SUCCESS = 'GET_TRUCK_DOCUMENT_SUCCESS';

export const EDIT_TRUCK_DOCUMENT_ARCHIVE = 'EDIT_TRUCK_DOCUMENT_ARCHIVE';

export const DELETE_TRUCK_DOCUMENT = 'DELETE_TRUCK_DOCUMENT';

/** Truck Costs */
export const CREATE_TRUCK_COSTS = 'CREATE_TRUCK_COSTS';

export const GET_TRUCK_COSTS = 'GET_TRUCK_COSTS';
export const GET_TRUCK_COSTS_SUCCCESS = 'GET_TRUCK_COSTS_SUCCCESS';

// truck mainenance
export const GET_TRUCK_MAINTENANCE = 'GET_TRUCK_MAINTENANCE';
export const GET_TRUCK_MAINTENANCE_SUCCESS = 'GET_TRUCK_MAINTENANCE_SUCCESS';
//create maintenance service
export const CREATE_TRUCK_MAINTENANCE_SERVICE = 'CREATE_TRUCK_MAINTENANCE_SERVICE';
export const UPDATE_TRUCK_MAINTENANCE_SERVICE = 'UPDATE_TRUCK_MAINTENANCE_SERVICE';
export const CREATE_TRUCK_MAINTENANCE_SERVICE_SUCCESS = 'CREATE_TRUCK_MAINTENANCE_SERVICE_SUCCESS';
//upload maintenance document
export const UPLOAD_TRUCK_MAINTENANCE_DOCUMENT = 'UPLOAD_TRUCK_MAINTENANCE_DOCUMENT';
export const UPLOAD_TRUCK_MAINTENANCE_DOCUMENT_SUCCESS = 'UPLOAD_TRUCK_MAINTENANCE_DOCUMENT_SUCCESS';
