/** Actions */

import {
    SIGN_IN,
    SIGN_UP,
    SIGN_OUT,
    CHANGE_PASSWORD,
    RESET_PASSWORD,
    FORGOT_PASSWORD,
    GET_MY_PROFILE,
    EDIT_MY_PROFILE,
    COMPLETE_ACCOUNT,
    CONTACT_US,
} from './auth.type';

export const signUp = (body) => {
    return {
        type: SIGN_UP,
        payload: { body },
    };
};

export const signIn = (body) => {
    return {
        type: SIGN_IN,
        payload: { body },
    };
};

export const signOut = () => {
    return {
        type: SIGN_OUT,
    };
};

export const changePassword = (body) => {
    return {
        type: CHANGE_PASSWORD,
        payload: { body },
    };
};

export const forgotPassword = (data) => {
    return {
        type: FORGOT_PASSWORD,
        payload: data,
    };
};

export const resetPassword = (passwords) => {
    return {
        type: RESET_PASSWORD,
        payload: { passwords },
    };
};

export const completeAccount = (data) => {
    return {
        type: COMPLETE_ACCOUNT,
        payload: data,
    };
};

/** My Profile */

export const getMyProfile = () => {
    return {
        type: GET_MY_PROFILE,
    };
};

export const editMyProfile = (info) => {
    return {
        type: EDIT_MY_PROFILE,
        payload: info,
    };
};

/** Edit */

/**  Contact Us  */

export const contactUs = (body) => {
    return {
        type: CONTACT_US,
        payload: { body },
    };
};
