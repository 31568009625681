
export const AlreadyHave = ({ handleClick, questionText, buttonText }) => {
  return(
    <div className="already-box">
      <p>
        <span className="already-have">{questionText} </span>
        <button className="sign-in-button" onClick={handleClick}>
          {buttonText}
        </button>
      </p>
    </div>
  )
}