import { FREE_PACKAGE, PREMIUM_PACKAGE, SECOND_PACKAGE } from '../index';

export const RolePermissions = (activePackage, premium) => {
    if (premium) {
        return (
            (activePackage?.package === SECOND_PACKAGE && activePackage?.isPackageActive === true) ||
            (activePackage?.package === PREMIUM_PACKAGE && activePackage?.isPackageActive === true)
        );
    } else {
        // if ( activePackage?.package === SECOND_PACKAGE && activePackage?.isPackageActive === true){
        return activePackage?.isPackageActive === true;
    }
};

export const CheckCoastPermission = (activePackage) => {
    return !!(
        (activePackage?.package === FREE_PACKAGE && activePackage?.isPackageActive) ||
        (activePackage?.package === SECOND_PACKAGE && activePackage?.isPackageActive) ||
        (activePackage?.package === PREMIUM_PACKAGE && activePackage?.isPackageActive)
    );
};
