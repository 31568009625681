import { PopperComponent, StatusBtn, DateRow, TextRow } from 'components';
import { MODAL_NAMES } from 'utils';
import { StatusBtnNoPermission } from '../../components/table/tableRows';

export const ACTION_TYPE = 'GET_USER';

export const usersHead = [
    { name: 'username', title: 'Username' },
    { name: 'email', title: 'Email' },
    { name: 'created Date', title: ' Created Date' },
    {
        name: 'status',
        title: 'Status',
        type: 'status',
        list: [
            {
                value: 'ALL',
                label: 'All',
            },
            {
                value: 'ACTIVE',
                label: 'Active',
            },
            {
                value: 'PENDING',
                label: 'Pending',
            },
            {
                value: 'INACTIVE',
                label: 'Inactive',
            },
        ],
    },
];

export const userStatus = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        value: 'PENDING',
        label: 'Pending',
    },
    {
        value: 'INACTIVE',
        label: 'Inactive',
    },
];

export const usersBody = [
    {
        button: (item, info) => (
            <div>
                <PopperComponent
                    list={[
                        {
                            title: 'Edit',
                            params: {
                                info: info,
                                modalTitle: 'Edit User Information',
                            },
                            name: MODAL_NAMES.USER_INVITE_MODAL,
                        },
                        info?.auth?.status !== 'PENDING' && {
                            title: info?.auth?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                            name: MODAL_NAMES.INACTIVATE_MODAL,
                            params: {
                                title: info?.fullName,
                                modalType: info?.auth?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                                subTitle: 'Inactivating a user will not active in the system. You can activate user when do you want.',
                                actionType: 'CHANGE_USER_STATUS',
                                handleChange: () => item(info?.auth?.status === 'ACTIVE' ? 'Inactivate' : 'Activate'),
                            },
                        },
                        {
                            title: 'Delete',
                            name: MODAL_NAMES.DELETE_MODAL,
                            params: {
                                title: info?.fullName,
                                subTitle: 'Deleting a user will permanently remove it from the system',
                                actionType: 'DELETE_USER',
                                handleDelete: () => item('DELETE_USER', item?.auth?.id),
                            },
                            color: '#D03325',
                        },
                    ]}
                />
            </div>
        ),
    },
    { rowText: (item, active) => <TextRow   name={item?.fullName}  /> },
    { rowText: (item, active) => <TextRow   name={item?.email} /> },
    { rowText: (item) => <DateRow date={item?.createdAt} /> },
    { button: (item, info, load) => (
            <div>
                <StatusBtn handleClick={(type) => item(type)} info={info} load={load} />
            </div>
        ),
    },
];

export const usersBodyNoPackage = [
    { button: () => <div /> },
    { rowText: (item, active) => <TextRow name={item?.fullName}   /> },
    { rowText: (item, active) => <TextRow name={item?.email}   /> },
    { rowText: (item) => <DateRow date={item?.createdAt} /> },
    {
        button: (item, info, load) => (
            <div>
                <StatusBtnNoPermission info={info} load={load} />
            </div>
        ),
    },
];
