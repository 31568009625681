import React from 'react';
import { Pagination } from '@mui/material';

export const PaginationItem = ({ count, handleReturn, page, listLength }) => {
    const limitCountNumber = 10;
    const firsCountNumber = page ? page <= 1 ? 1 : (page - 1) * limitCountNumber + 1 : 1;
    // const shownCountNumber = page <= 1 ? listLength : firsCountNumber - 1 + listLength;

    const handleChangePage = (val) => {
        handleReturn(val);
    };

    return (
        <div className='pagination-wrapper'>
            <div className='pagination-description'>
                <p>
                    {`Showing ${firsCountNumber ? firsCountNumber : 0} to ${listLength ? listLength : 0} of ${count ? count : 0} entries`}

                </p>
            </div>
            <Pagination
                onChange={(event, val) => handleChangePage(val, 'vvv')}
                page={page}
                count={count && Math.ceil(count / limitCountNumber)}
            />
        </div>
    );
};
