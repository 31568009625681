import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { FindSuccess, SavePage } from 'utils';
import { useModal, CustomInput, CustomSelect, CreateCancel } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, trailerActions } from 'store';
import { trailersCreateType } from '../../constants';

export const CreateTrailer = () => {
    const { close, params } = useModal();
    const ACTION_TYPE = params?.info ? 'EDIT_TRAILER' : 'CREATE_TRAILER';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            make: '',
            model: '',
            currentMiles: '',
            type: '',
        },
    });

    const onSubmit = (data) => {
        const formData = {
            ...data,
        };
        formData.startingMiles = Number(0);
        formData.currentMiles = Number(data.currentMiles);
        dispatch(trailerActions.createTrailer(formData));
    };

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    return (
        <div className="trailer-modal">
            <div className="invite-container">
                <form onSubmit={handleSubmit(onSubmit)} className="form-messages">
                    <Grid container spacing={0} marginTop={2}>
                        <Grid item xs={12}>
                            <CustomInput
                                name="make"
                                control={control}
                                rules={{ required: true }}
                                type="text"
                                label="Make*"
                                placeholder={'Trailer Make'}
                                maxLength={40}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInput
                                name="model"
                                control={control}
                                rules={{ required: true }}
                                type="text"
                                label="Model*"
                                placeholder={'Trailer Model'}
                                maxLength={40}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomSelect
                                name={'type'}
                                control={control}
                                rules={{ required: true }}
                                listSelect={trailersCreateType}
                                label="Type*"
                                displayName={'label'}
                                displayValue={'value'}
                                placeholder="Choose Type"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInput
                                name="currentMiles"
                                control={control}
                                rules={{ required: true }}
                                type="number"
                                label="Current Miles*"
                                placeholder="Current miles"
                                min={1}
                                maxLength={40}
                                lastIcon={'mi'}
                                step={'numeric'}
                            />
                        </Grid>
                    </Grid>
                    <CreateCancel title={'Save'} actionType={ACTION_TYPE} closeType={'CREATE_TRAILER'} />
                </form>
            </div>
        </div>
    );
};
