import React, { useContext } from 'react';
import { ModalContext } from 'components/modal/context';
import { AllSetModal, MyProfile, DeleteModal, InactivateUser } from './core';
import {
    SignInModal,
    SignUpModal,
    RessetSuccessModal,
    ResetModal,
    ForgotModal,
    InviteUser,
    CreateTruck,
    CreateDriver,
    CreateTruckLoad,
    CreateLoad,
    CreateTrailer,
    AddTransaction,
    AddStops,
    AddDriverRate,
    PastRates,
    AddFuelCard,
    AddCustomer,
    AddContact,
} from 'pages';
import { Backdrop, Modal } from '@mui/material';
import { Svg } from 'assets/images';
import { useModal } from 'components';
import { CancelConfirmModal } from '../../pages/activePayment/fragments/core/cancelConfirmModal';
import { UpgradeDowngrade } from '../../pages/activePayment/fragments/core/upgradeDowngrade';
import { SuccessError } from '../../pages/activePayment/fragments/core/successError';

export const AllModals = () => {
    const { activeModal, setActiveModal } = useContext(ModalContext);
    const { close, params } = useModal();
    const complete = window.location.search.search('token=') !== -1;

    const renderTitle = () => {
        switch (activeModal) {
            case 'SIGN_IN':
                return 'Login to FleetForest';
            case 'SIGN_UP':
                return 'Welcome to FleetForest';
            case 'FORGOT_PASSWORD':
                return 'Forgot your Password?';
            case 'RESET_PASSWORD':
                return complete ? 'Welcome to FleetForest' : 'Reset Password';
            case 'CANGRATS_ALL':
                return 'Congress All ';
            case 'EDIT_LOAD':
                return 'Edit Load';
            case 'ADD_LOAD':
                return 'Add Load';
            case 'MENU_USER':
                return '';
            case 'ADD_TRANSACTION':
                return 'Add Transaction';
            default:
                return false;
        }
    };


    function useModalSwitch() {
        switch (activeModal) {
            case 'SIGN_IN':
                return <SignInModal />;
            case 'MENU_USER':
                return <MyProfile />;
            case 'SIGN_UP':
                return <SignUpModal />;
            case 'RESET_PASSWORD':
                return <ResetModal />;
            case 'FORGOT_PASSWORD':
                return <ForgotModal />;
            case 'CANGRATS_ALL':
                return <AllSetModal />;
            case 'CANGRATS_RESET':
                return <RessetSuccessModal />;
            case 'USER_INVITE_MODAL':
                return <InviteUser />;
            case 'TRACK_MODAL':
                return <CreateTruck />;
            case 'TRUCK_LOAD_MODAL':
                return <CreateTruckLoad />;
            case 'CREATE_LOAD':
                return <CreateLoad />;
            case 'DRIVER_MODAL':
                return <CreateDriver />;
            case 'INACTIVATE_MODAL':
                return <InactivateUser />;
            case 'DELETE_MODAL':
                return <DeleteModal />;
            case 'CREATE_TRAILER':
                return <CreateTrailer />;
            case 'EDIT_DRIVER':
                return <CreateDriver />;
            case 'ADD_TRANSACTION':
                return <AddTransaction />;
            case 'ADD_STOPS':
                return <AddStops />;
            case 'POST_RATES':
                return <PastRates />;
            case 'ADD_DRIVER_RATE':
                return <AddDriverRate />;
            case 'ADD_FUEL_CARD':
                return <AddFuelCard />;
            case 'ADD_CUSTOMER':
                return <AddCustomer />;
            case 'ADD_CONTACTS':
                return <AddContact />;
            case 'CANCEL_SUBSCRIPTION':
                return <CancelConfirmModal />;
            case 'UPGRADE_DOWNGRADE':
                return <UpgradeDowngrade />;
            case 'SUCCESS_CANCEL':
                return <SuccessError />;
            default:
                return null;
        }
    }

    const body = <div>{useModalSwitch()}</div>;
    return (
        <Modal
            open={!!activeModal}
            onClose={() => setActiveModal('')}
            aria-labelledby='spring-modal-title'
            aria-describedby='spring-modal-description'
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '99999',
                boxShadow: 24,
                p: 4,
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            {activeModal === 'MENU_USER' ? (
                <div>{body}</div>
            ) : (
                <div className='modal-wrapper'>
                    <div className='flex-end'>
                        <button type='button' className='close-button' onClick={() => close()}>
                            <img src={Svg.CloseModal} alt='Close-Modal' />
                        </button>
                    </div>
                    {/*{params?.modalTitle && (*/}
                    <div style={renderTitle() ? { justifyContent: 'center' } : {}} className='align-start'>
                        <p className='modal-title'>{params?.modalTitle ? params.modalTitle : renderTitle()}</p>
                    </div>
                    {/*)}*/}
                    <div>{body}</div>
                </div>
            )}
        </Modal>
    );
};
