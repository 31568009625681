import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FindError, FindSuccess, SavePage } from 'utils';
import {
    useModal,
    CustomInput,
    CreateCancel,
    CustomTextArea,
    AddressInput,
    CustomSelect,
    DatePickerInput,
} from 'components';
import { cretaeStopDTO } from '../../constants';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, loadActions } from 'store';
import { Grid } from '@mui/material';
import moment from 'moment';

export const AddStops = () => {
    /**
     * Add Stops.
     */

    const { close, params } = useModal();
    const ACTION_TYPE = params?.param ? 'EDIT_LOAD_ID_STOP_ID' : 'CREATE_LOAD_ID_STOP';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(params?.param ? 'EDIT_LOAD_ID_STOP_ID' : 'CREATE_LOAD_ID_STOP');
    const [enteredAddress, setEnteredAddress] = useState(params?.param?.address);
    const [customErr, setCustomErr] = useState('');
    const { loadIdList } = useSelector((state) => ({
        loadIdList: state?.load?.loadIdList,
    }));
    // const [selectedDate, setSelectedDate] = useState(new Date(params?.param?.date));
    // const [selectedTime, setSelectedTime] = useState(new Date(params?.param?.date));

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: params?.param?.name,
            address: enteredAddress ? enteredAddress : '',
            type: params?.param?.type,
            date: params?.param?.date ? moment(params?.param?.date).format('YYYY-MM-DD') : '',
            time: params?.param?.date ? moment(params?.param?.date).format('HH:mm') : '',

            instruction: params?.param?.instructions,
        },
        mode: 'onChange',
    });

    function handleAddressChange(selectedAddress) {
        setEnteredAddress(selectedAddress);
        setCustomErr('');
    }

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };

        if (params?.param) {
            if (enteredAddress && !enteredAddress?.formattedAddress) {
                sendInfo.address = enteredAddress;
            } else if (enteredAddress?.formattedAddress) {
                sendInfo.address = {
                    address: enteredAddress?.formattedAddress,
                    unit: enteredAddress?.unit,
                };
            }
        }

        if (!params?.param && enteredAddress) {
            sendInfo.address = enteredAddress;
        }

        const myDate = new Date(data?.date);
        myDate.setHours(data?.time.slice(0, 2));
        myDate.setMinutes(data?.time.slice(3, 5));
        sendInfo.date = myDate;
        delete sendInfo.time;


        if (enteredAddress?.address) {
            if (params?.param) {
                dispatch(loadActions.editLoadIdStopId(sendInfo, loadIdList?.id, params?.param?.id));
            } else {
                dispatch(loadActions.createLoadIdStop(sendInfo, loadIdList?.id));
            }
        } else {
            setCustomErr('address');
        }
    };

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    return (
        <div className='invite-form add-stops'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomInput
                            name='name'
                            control={control}
                            rules={{ required: false }}
                            defaultValue={params?.param?.name ? params?.param?.name : ''}
                            type='text'
                            label='Stop Name'
                            placeholder={'Stop Name'}
                            maxLength={40}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressInput
                            label={'Address*'}
                            errorBoolean={
                                customErr === 'address'
                                    ? true
                                    : backError?.error === 'Unable to verify the address'
                                        ? 'Unable to verify the address'
                                        : ''
                            }
                            rules={{ required: true }}
                            onTrigger={handleAddressChange}
                            enteredValue={enteredAddress}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomSelect
                            name={'type'}
                            control={control}
                            rules={{ required: true }}
                            listSelect={cretaeStopDTO}
                            displayValue={'value'}
                            displayName={'label'}
                            label='Type*'
                            placeholder={'Choose Type'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className='date-selector'>
                        <CustomInput
                            name='date'
                            control={control}
                            rules={{ required: false }}
                            // defaultValue={params?.param?.name ? params?.param?.name : ''}
                            type='date'
                            label='Date'
                            placeholder={'Add Date'}
                        />

                        {/*<DatePickerInput*/}
                        {/*    name="date"*/}
                        {/*    control={control}*/}
                        {/*    rules={{ required: true }}*/}
                        {/*    type="date"*/}
                        {/*    label="Add Date*"*/}
                        {/*    placeholder={'Add Date'}*/}
                        {/*    defaultValue={selectedDate}*/}
                        {/*/>*/}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            name='time'
                            control={control}
                            rules={{ required: false }}
                            // defaultValue={params?.param?.name ? params?.param?.name : ''}
                            type='time'
                            label='Time'
                            placeholder={'Add Time'}
                        />
                        {/*<DatePickerInput*/}
                        {/*    name="time"*/}
                        {/*    control={control}*/}
                        {/*    rules={{ required: true }}*/}
                        {/*    type="time"*/}
                        {/*    label="Add Time*"*/}
                        {/*    placeholder={'Add Time'}*/}
                        {/*    defaultValue={selectedTime}*/}
                        {/*/>*/}
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextArea
                            name={'instruction'}
                            control={control}
                            rules={{ required: false }}
                            defaultValue={''}
                            label={'Instruction'}
                            placeholder={'Write description..'}
                            maxLength={200}
                        />
                    </Grid>
                </Grid>
                <CreateCancel
                    title={'Save'}
                    actionType={ACTION_TYPE}
                    closeType={'CREATE_TRAILER'}
                />
            </form>
        </div>
    );
};
