import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { updateDriverRate } from '../../constants';
import { FindSuccess, SavePage } from 'utils';
import { useModal, CreateCancel, CustomInput, CustomSelect } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, driversActions } from 'store';

export const AddDriverRate = () => {
    /**
     * Add Driver Rate.
     */

    const { driversDetailsList } = useSelector((state) => ({
        driversDetailsList: state?.drivers?.driversDetailsList,
    }));

    const { close } = useModal();
    const ACTION_TYPE = 'EDIT_DRIVERS_RATE';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const { control, handleSubmit, setError } = useForm({
        defaultValues: {
            rate: '',
            mode: '',
        },
    });

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };
        sendInfo.rate = Number(data.rate);

        dispatch(driversActions.editDriverRate(sendInfo, driversDetailsList?.id));
    };

    return (
        <div className="invite-form">
            <div className="driver-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomInput
                        name="rate"
                        control={control}
                        rules={{ required: true }}
                        type="number"
                        label="Rate*"
                        placeholder={'Driver Rate'}
                        dollar={true}
                        step={'numeric'}
                    />
                    <CustomSelect
                        name={'mode'}
                        control={control}
                        rules={{ required: true }}
                        listSelect={updateDriverRate}
                        displayValue={'value'}
                        displayName={'label'}
                        label="Type*"
                        placeholder={'Choose Type'}
                    />
                    <CreateCancel title={'Save'} actionType={ACTION_TYPE} closeType={ACTION_TYPE} />
                </form>
            </div>
        </div>
    );
};
