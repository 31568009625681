import React, { Fragment } from 'react';
import { Svg } from 'assets/images';
import { MODAL_NAMES } from 'utils';
import { useModal, MinLoader, NoDataComponent, TextRow } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { customersAction } from 'store/customers';
import { Box, Accordion, AccordionSummary, AccordionDetails, Button, Grid } from '@mui/material';
import { SlicedRow } from '../../../components/table/tableRows';
import { RolePermissions } from '../../../utils/hooks';

export const Contacts = ({ customerContactsList, customerIdList, paramsRouter }) => {
    /**
     * Contacts.
     */

    const { open } = useModal();
    const load = 0;
    const dispatch = useDispatch();
    const customerId = customerIdList?.id;
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));

    const handleCreateContact = () => {
        open(MODAL_NAMES.ADD_CONTACTS, { modalTitle: 'Add Contact' });
    };

    const editContact = (item) => {
        open(MODAL_NAMES.ADD_CONTACTS, {
            modalTitle: 'Edit Contact',
            info: {
                ...item,
            },
        });
    };

    const handleDelete = (item) => {
        open(MODAL_NAMES.DELETE_MODAL, {
            title: `Customer  ${item?.fullName}`,
            subTitle: 'Deleting a customer will permanently remove it from the system',
            actionType: 'DELETE_CUSTOMER_CONTACT',
            handleDelete: () => handleDeleteDriver('DELETE_CUSTOMER_CONTACT', item?.id),
        });
    };

    const handleDeleteDriver = (_, contactId) => {
        dispatch(customersAction.deleteCustomerContact(customerId, contactId));
    };

    return (
        <div className="details-container">
            <div className="rate-container">
                <div className="space-between">
                    <div className="driver-title">
                        <h4 className="title-basic">Contacts</h4>
                    </div>
                    <div className="basic-title">
                        {RolePermissions(activePackage) && (
                            <button className="add-rate" onClick={handleCreateContact}>
                                <span>
                                    <img src={Svg.AddStepIcon} alt="Add Step Icon" />
                                </span>
                                <span className="rate-title">
                                    Add
                                    <span className="desktop-text"> Contact</span>
                                </span>
                            </button>
                        )}
                    </div>
                </div>
                <div className="contact-header">
                    <div className="fuel-body">
                        <div className="card-head">
                            <Grid container className="fuel-box">
                                <Grid item md={2}>
                                    <div className="flex fuel-title">Full Name</div>
                                </Grid>
                                <Grid item md={3}>
                                    <div className="flex fuel-title">Email Address</div>
                                </Grid>
                                <Grid item md={2}>
                                    <div className="flex fuel-title">Phone Number</div>
                                </Grid>
                                <Grid item md={4}>
                                    <div className="flex fuel-title">Notes</div>
                                </Grid>
                                <Grid item md={1}>
                                    <div className="flex fuel-title">Action</div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                {customerContactsList?.length ? (
                    <div className="contact-body">
                        {customerContactsList.map((item, index) => (
                            <div className="fuel-body" key={index}>
                                <div className="card-body">
                                    <Grid container className="fuel-box">
                                        <Grid item md={2}>
                                            <div className="flex">
                                                <TextRow name={item?.fullName} color={'#41586D'}/>
                                            </div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className="flex">
                                                <TextRow name={item?.email ? item?.email : 'Not Set'} color={'#41586D'} />
                                            </div>
                                        </Grid>
                                        <Grid item md={2}>
                                            <div className="flex">
                                                <span style={{ color: '#41586D', fontWeight: 400 }} className="fuel-text">
                                                    {item?.phone ? item?.phone : 'Not Set'}
                                                </span>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className="flex">
                                                <TextRow name={item?.notes ? item?.notes : 'Not Set'} color={'#41586D'} />
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className=" fuel-action">
                                                <button className="edit-btn" onClick={() => editContact(item)}>
                                                    {load.length ? (
                                                        <MinLoader color={'yellow'} />
                                                    ) : (
                                                        <img src={Svg.EditYellowIcon} alt="Edit Yellow Icon" />
                                                    )}
                                                </button>
                                                <button className="delete-btn" onClick={() => handleDelete(item)}>
                                                    {load.length ? <MinLoader color={'red'} /> : <img src={Svg.DeleteItem} alt="icon" />}
                                                </button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Fragment>
                        <NoDataComponent />
                    </Fragment>
                )}


                <div className="stops-container stops-mobile">
                    {customerContactsList.map((item, index) => (
                        <Accordion key={index} className="accordion-box">
                            <AccordionSummary
                                expandIcon={<img src={Svg.AccordionIcon} alt="Accordion-Icon" />}
                                aria-controls="panel-content"
                                id="panel-header"
                                className="pannel-summary">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}>
                                    <Box className="flex title-box">
                                        <div className="stop-title">{item?.fullName}</div>
                                    </Box>
                                    <Box className="flex action-box to-do">
                                        <div className="edit-icon">
                                            <img src={Svg.OrangeEdit} alt="Orange Edit" onClick={() => editContact(item)} />
                                        </div>
                                        <div className="delete-icon">
                                            <Button variant="text" color="error" onClick={() => handleDelete(item)}>
                                                <img src={Svg.RedDelete} alt="Red Delete" />
                                            </Button>
                                        </div>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails className="details-mobile">
                                <Box className="flex pick-box details-titile">
                                    <div className="img-box">
                                        <img src={Svg.PickUpIcon} alt="Pick-Up-Icon" />
                                    </div>
                                    <div className="pick-header">
                                        <div className="pick">
                                            <span className="pick-title">{item?.type === 'PICKUP' ? 'Pick Up :' : 'Drop Off :'}</span>
                                            <span className="pick-img">
                                            {item?.type === 'DROP_OFF' ? (
                                                <img src={Svg.DropOffIcon} alt="Drop-Off-Icon" />
                                            ) : (
                                                <img src={Svg.OrangeTopIcon} alt="Orange Top Icon" />
                                            )}
                                        </span>
                                        </div>
                                        <div className="pick-text">{item.address?.formattedAddress}</div>
                                    </div>
                                </Box>
                            </AccordionDetails>
                            <AccordionDetails className="details-box">
                                <Box className="stops-details">
                                    <div className="details-titile data-desktop">
                                        <div className="details-space">
                                            <span className="text-bold">Email:</span>
                                            <span className="text-info"> {item?.email ? item?.email : 'Not Set'}</span>
                                        </div>
                                        <div className="details-space">
                                            <span className="text-bold">Phone Number:</span>
                                            <span className="text-info"> {item?.phone ? item?.phone : 'Not Set'}</span>
                                        </div>
                                        <div className="details-space">
                                            <span className="text-bold">Note:</span>
                                            <span className="text-info">{item?.notes ? item?.notes : 'Not Set'}</span>
                                        </div>
                                    </div>
                                </Box>
                            </AccordionDetails>
                            <AccordionDetails className="mobile-box">
                                <Box className="action-box">
                                    <div className="edit-icon">
                                        <img src={Svg.OrangeEdit} alt="Orange Edit" onClick={() => editContact(item)} />
                                    </div>
                                    <div className="delete-icon">
                                        <img src={Svg.RedDelete} alt="Red Delete" onClick={() => handleDelete(item)} />
                                    </div>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>



        </div>
    );
};
