/* Truck Load */

import {
    getLoad,
    createLoad,
    editLoad,
    deleteLoad,
    changeStatus,
    getLoadId,
    getLoadTruckId,
    createLoadIdStop,
    editLoadIdStopId,
    deleteLoadsStopId,
    createDocumentLoad,
    getDocumentLoad,
    editDocumentIdArchive,
    deleteDocumentDocId,
    createTxn,
    editTxnAction,
    getLoadTxn, cancelStatus, getLoadCosts,
} from './load.action';
export { loadReducer } from './load.reducer';
export { watchLoad } from './load.saga';

export const loadActions = {
    getLoad,
    createLoad,
    editLoad,
    deleteLoad,
    changeStatus,
    cancelStatus,
    getLoadId,
    getLoadTruckId,
    createLoadIdStop,
    editLoadIdStopId,
    deleteLoadsStopId,
    getLoadCosts,
    /** Loads Document */
    createDocumentLoad,
    getDocumentLoad,
    editDocumentIdArchive,
    deleteDocumentDocId,
    /** End */
    createTxn,
    editTxnAction,
    getLoadTxn,
};
