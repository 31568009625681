import React, { useState, Fragment, useContext, createContext } from 'react';
import { CustomModal } from '../../components';

export const ModalContext = createContext();

export const CustomModalProvider = ({ children }) => {
    const [activeModal, setActiveModal] = useState('');
    const [params, setParams] = useState({});

    return (
        <Fragment>
            <ModalContext.Provider
                value={{
                    activeModal,
                    setActiveModal,
                    params,
                    setParams,
                }}
            >
                {children}
            </ModalContext.Provider>
        </Fragment>
    );
};

export const useModalBase = () => {
    const {  setActiveModal, params, setParams } = useContext(ModalContext);

    return {
        openModal: (modalName, params = {}) => {
            setActiveModal(true);
            setParams(params);
        },
        closeModal: () => {
            setActiveModal('');
            setParams({});
        },
        params,
    };
};


export const CustomModals = () => {
    const { activeModal } = useContext(ModalContext);
    const { params } = useModalBase();

    return (
        <CustomModal
            openDefault={activeModal ? activeModal : false}
            content={
                <div>
                    {params}
                </div>
            }
        />
    );
};