import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ratesType } from '../../constants';
import { driversActions } from 'store';
import { DateRow, PriceRow } from 'components';
import { Svg } from 'assets/images';

export const PastRates = () => {
    const { driversRateList, driversDetailsList } = useSelector((state) => ({
        driversRateList: state?.drivers?.driversRateList,
        driversDetailsList: state?.drivers?.driversDetailsList,
    }));
    const dispatch = useDispatch();
    const pastList = driversRateList.filter((item) => item?.startDate && item?.endDate);

    useEffect(() => {
        dispatch(driversActions.getDriverRate(driversDetailsList?.id));
    }, []);

    return (
        <div className="past-modal">
            <div className="desktop-rates">
                <div className="rates-header">
                    <div className="title">
                        <span>Start Date</span>
                    </div>
                    <div className="title">
                        <span>End Date</span>
                    </div>
                    <div className="title">
                        <span>Rate</span>
                    </div>
                    <div className="title">
                        <span>Type</span>
                    </div>
                </div>
                <div className="list-rates">
                    {pastList?.map((item, index) => (
                        <div className="rates-body" key={index}>
                            <div className="subtitle">
                                <DateRow date={item?.startDate} />
                            </div>
                            <div className="subtitle">
                                <DateRow date={item?.endDate} />
                            </div>
                            <div className="subtitle">
                                <PriceRow info={item?.rate ? item?.rate : 0} />
                            </div>
                            <div className="subtitle">
                                {ratesType(item?.mode)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="tablet-rate">
                {pastList?.map((item, index) => (
                    <div className="rates-body" key={index}>
                        <div className="flex rate-box">
                            <div className="past-rate-icon">
                                <img src={Svg.StartTime} alt="Start Time" />
                            </div>
                            <div className="rate-text flex-full-width">
                                <span className="rate-mobile-title">Start Date:</span>
                                <span className="past-rate-subtitle ">
                                    <DateRow date={item?.startDate} />
                                </span>
                            </div>
                        </div>
                        <div className="flex rate-box">
                            <div className="past-rate-icon">
                                <img src={Svg.EndTime} alt="End Time" />
                            </div>
                            <div className="rate-text flex-full-width">
                                <span className="rate-mobile-title">End Date:</span>
                                <span className="past-rate-subtitle">
                                    <DateRow date={item?.endDate} />
                                </span>
                            </div>
                        </div>
                        <div className="flex rate-box">
                            <div className="past-rate-icon">
                                <img src={Svg.ReteIcon} alt="Rate Icon" />
                            </div>
                            <div className="rate-text flex-full-width">
                                <span className="rate-mobile-title">Rate:</span>
                                <span className="past-rate-subtitle">
                                      <PriceRow info={item?.rate ? item?.rate : 0} />
                                </span>
                            </div>
                        </div>
                        <div className="flex rate-box">
                            <div className="past-rate-icon">
                                <img src={Svg.SecurityTime} alt="Security Time" />
                            </div>
                            <div className="rate-text flex-full-width">
                                <span className="rate-mobile-title">Type:</span>
                                <span className="past-rate-subtitle">{item?.mode ? ratesType(item?.mode) : 'Not Set'}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
