import styled from 'styled-components';

export const StyledActivePlan = styled.div`
    .second-section {
        max-height: 300px;
        height: auto;

        @media (min-width: 320px) {
            max-width: 100%;
        }
        @media (min-width: 1279px) {
            max-width: 550px;
        }
        .first-wrap {
            display: flex;
            flex-wrap: wrap;
            max-height: 280px;
            overflow: auto;
        }
        .plan-items-wrapper {
            width: 250px;
        }
    }

    .active-plan-wrapper {
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 100%;
        @media (min-width: 320px) {
            flex-direction: column;
        }
        @media (min-width: 1279px) {
            flex-direction: row;
        }
    }

    .section-box {
        display: flex;
        aline-item: center;
        @media (max-width: 768px) {
            flex-direction: column;
        }

        .seconnd-space {
            margin: 0 45px;
            @media (max-width: 1440px) {
                margin: 0 65px;
            }
        }
    }

    .yellow-icon-wrapper {
        width: 24px;
        height: 24px;
        background: #fff3e0;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .plan-items-wrapper {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #20313f;
            margin-left: 12px;
        }

        // .plan-basic {
        //     position: relative;
        //     bottom: 10px;
        // }
    }

    .dark-text {
        color: #324557;
        font-weight: 700;
    }

    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #324557;
        margin-bottom: 24px;
    }

    .pay-items-wrapper {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        .standart-text {
            margin-left: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #7e7e7e;

            span {
                color: #324557;

                &.status {
                    color: green;
                }
            }

            .text-quantity {
                margin-left: 5px;
            }
        }

        .features-text {
            margin-left: 16px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #324557;
        }
    }

    .button-text-wrapper {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .upgrade-button-wrapper {
        display: flex;
        justify-content: flex-end;
        @media (max-width: 767px) {
            margin-top: 24px;
        }

        .upgrade {
            text-transform: capitalize;
            width: 229px;
            height: 48px;
            background: #0e9594;
            border-radius: 4px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
            border: none;
            @media (min-width: 320px) {
                width: 180px;
            }
            @media (min-width: 768px) {
                width: 200px;
            }
            @media (min-width: 1280px) {
                width: 229px;
            }
        }
    }

    .plan-line {
        margin: 42px 0 24px;
        border: 1px solid #e9e9eb;
        background: transparent;
    }

    .plan-info {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #506c84;

        span {
            font-weight: 700;
            color: #506c84;
        }

        ,
        @media(min-width: 320 px) {
            font-size: 12px;
        }
        @media (min-width: 1440px) {
            font-size: 14px;
        }
    }

    .icon-wrapper {
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .flex-able-wrapper {
        display: flex;
        @media (min-width: 320px) {
            flex-direction: column;
        }
        @media (min-width: 1440px) {
            flex-direction: row;
        }

        .second-wrap {
            @media (min-width: 320px) {
                margin-left: 0;
            }
            @media (min-width: 1440px) {
                margin-left: 33px;
            }
        }
    }
`;

export const StyledPaymentMethod = styled.div`
    .title {
        font-family: Poppins, sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: black;
        margin-bottom: 24px;
    }

    .cards-wrapper-section {
        display: flex;
        @media (min-width: 320px) {
            flex-direction: column;
        }
        @media (min-width: 769px) {
            flex-direction: row;
        }
    }

    .card-wrapper {
        max-width: 295px;
        width: 100%;
        height: 164px;
        background: #f5f7f9;
        border: 1px solid #f5f7f9;
        border-radius: 4px;
        padding: 26px 24px;
        margin-right: 10px;
    }

    .visa-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        p {
            font-family: Open Sans, sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #324557;
        }
    }

    .card-date {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-family: Open Sans, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #324557;
        margin: 16px 0 32px 0;
    }

    .action-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            border: none;
            background: transparent;
            font-weight: 400;
            font-size: 14px;

            &.edit-btn {
                color: #0e9594;
            }

            &.delete-btn {
                margin-left: 16px;
                color: #d03325;
            }
        }
    }

    .we-accept-wrapper {
        display: flex;
        align-items: center;
        margin-top: 18px;

        svg {
            margin-right: 9px;
        }
    }

    .we-accept {
        font-family: Open Sans, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #313131;
        margin-right: 12px;
    }

    .add-card {
        max-width: 295px;
        width: 100%;
        height: 164px;
        border: 1px solid #e9e9eb;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 320px) {
            margin-top: 16px;
        }
        @media (min-width: 769px) {
            margin-top: 0;
        }

        .add-card-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: Open Sans, sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: orange;

            svg {
                margin-bottom: 19px;

                path {
                    fill: orange;
                }
            }
        }
    }
`;

export const StyledInvoiceTable = styled.div`
    width: 100%;

    .invoice-link {
        margin-right: 12px;
    }

    .no-invoice-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #506c84;
        padding: 16px 32px;
    }

    .no-invoice-text-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #506c84;
        margin-bottom: 20px;
    }

    .actions-wrapper {
        max-width: 165px;
        display: flex;
        align-items: center;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .mobile-cards-table {
        display: none;
        @media (max-width: 768px) {
            display: block;
        }

        .mobile-cards-item {
            width: auto;
            min-height: 152px;
            height: auto;
            margin-bottom: 8px;
            background: white;
            border-radius: 8px;
            padding: 16px 24px;
        }

        .title-info-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .title {
                width: 80px;
                font-family: Open Sans, sans-serif;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: black;
                margin-right: 22px;
                text-transform: capitalize;
            }

            .info {
                font-family: Open Sans, sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #51566d;
            }
        }

        .buttons-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .cards-table {
        width: 100%;
        height: auto;
        background: white;
        border: 1px solid #e9e9eb;
        border-radius: 8px;
        @media (max-width: 768px) {
            display: none;
        }

        .cards-table-head {
            display: flex;
            justify-content: space-between;
            background: #e1f2f3;
            border-radius: 8px 8px 0 0;
            width: 100%;
            padding: 16px 32px;
            @media (max-width: 768px) {
                padding: 13px 16px;
            }

            p {
                width: 100%;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #506c84;
            }
        }

        .cards-table-body {
            background: white;
            border-radius: 8px;
            height: 200px;
            overflow: auto;
        }

        .cards-table-body-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 15px 32px;
            border-bottom: 1px solid #c8d8e6;
            @media (max-width: 768px) {
                padding: 13px 16px;
            }

            p {
                width: 100%;
                font-family: Open Sans, sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #506c84;
            }
        }
    }
`;

// export const StyledPayInfo = styled.div`
//   width: auto;
//   height: auto;
//   background: white;
//   border-radius: 8px;
//   @media (min-width: 320px) {
//     margin: 8px 16px 0;
//     padding: 16px;
//     width: 340px;
//   }
//   @media (min-width: 769px) {
//     width: 499px;
//   }
//   @media (min-width: 1440px) {
//     width: 330px;
//     margin: 0;
//   }
//
//   .items-wrapper {
//     display: flex;
//     align-items: center;
//     margin-bottom: 16px;
//
//     p {
//       margin-left: 12px;
//       font-family: Open Sans, sans-serif;
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 24px;
//       color: black;
//     }
//   }
//
//   .price-title {
//     font-family: Open Sans, sans-serif;
//     font-weight: 600;
//     font-size: 18px;
//     line-height: 28px;
//     color: black;
//   }
//
//   .plane-type {
//     margin: 24px 0;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     width: 100%;
//     font-family: Poppins, sans-serif;
//     font-weight: 700;
//     font-size: 18px;
//     line-height: 28px;
//     color: black;
//   }
//
//   .check-wrapper {
//     display: flex;
//     align-items: start;
//     font-family: Open Sans, sans-serif;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 20px;
//
//     a {
//       color: black;
//     }
//   }
//
//   .check-text-style {
//     font-family: Open Sans, sans-serif;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 20px;
//     margin-left: 8px;
//     color: black;
//   }
// `;
//
// export const InvoiceInfoContainer = styled.div`
//   .invoice-head {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//   }
// `;
//
// export const CancelConfirm = styled.div`
//   text-align: center;
//
//   .title {
//     margin-bottom: 16px !important;
//     text-transform: capitalize;
//     margin-top: 32px;
//   }
//
//   .options {
//     display: flex;
//     flex-direction: column;
//     gap: 16px;
//     margin-bottom: 32px;
//   }
//
//   .actions {
//     justify-content: space-between;
//     display: flex;
//
//     button {
//       &:last-child {
//         background-color: #2b273c1a;
//         color: #2b273c;
//         margin-left: 16px;
//       }
//
//       border-radius: 10px !important;
//
//       .b-fade {
//         border-radius: 10px !important;
//       }
//     }
//   }
//
//
// `;
