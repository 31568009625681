import {
    getDriver,
    createDrivers,
    changeDriverStatus,
    editDriverDetails,
    getDriverDetails,
    getDriverRate,
    editDriverRate,
    deleteDriver,
    createFuel,
    editFuel,
    deleteFuel,
    createDriverDocument,
    editDriverDocumentArchive,
    deleteDriverDocument,
    getDriverDocuments,

    // ReInvite
    reInvite,
} from './drivers.action';
export { drivingReducer } from './drivers.reducer';
export { watchDrivers } from './drivers.saga';

export const driversActions = {
    /** Driver Actions */
    getDriver,
    createDrivers,
    changeDriverStatus,
    editDriverDetails,
    getDriverDetails,
    deleteDriver,
    /** Driver Rate */
    editDriverRate,
    getDriverRate,
    /** Fuel Card */
    createFuel,
    editFuel,
    deleteFuel,
    /** Driver Documents */
    createDriverDocument,
    getDriverDocuments,
    editDriverDocumentArchive,
    deleteDriverDocument,
    /** End */

    //  ReInvite
    reInvite,
};
