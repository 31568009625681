import React, { Fragment } from 'react';
import { Images } from 'assets/images';
import { Grid } from '@mui/material';

export const EverythingFragment = () => {
    /**
     *  Hooks.
     */
    return (
        <Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} className='order-img'>
                    <img src={Images.EverythingLarg} alt='Web-Evrething' className='full-img' />
                </Grid>
                <Grid item xs={12} md={6} className='order-content'>
                    <div className='column'>
                        <div className='flex-start'>
                            <h2 className='title font-semiBold text-h2'>Everything you need to</h2>
                        </div>
                        <div className='flex-start'>
                            <h2 className='subtitle font-semiBold text-h2'>power your business</h2>
                        </div>
                        <div className='box-text'>
                            <p className='type-text'>
                                Fleetforest was born out of the need for a simple, effective and robust tool to ease the
                                challenges associated with running a logistics company. Our search for a similar
                                solution resulted in desperation and we decided to build one ourselves to make our day
                                to day work easier to minimize losses, plan ahead and gain visibility into our
                                operations. By realizing how much headache was spared as a result of using FleetForest,
                                we decided to release it to the world to use.
                            </p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    );
};
