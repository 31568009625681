import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { withStyles } from '@material-ui/core/styles';

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 16,
    padding: 0,
    display: 'flex',
    marginTop: 6,
    marginLeft: '8px',
  },
  switchBase: {
    paddingTop: '2.2px',
    padding: 3,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#0e9594',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    marginTop: 0,
    boxShadow: 'none',
    background: 'white',
  },
  track: {
    border: 'none',
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
  },
  checked: {},
}))(Switch);


export const inputsStyle = makeStyles(() => ({
  inputTextField: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      height: "48px",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-3px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -2px) scale(0.75)",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },
  },
  SignInInput: {
    width: "100%",
    "& .MuiFormLabel-root": {
      fontSize: "16px",
      color: "black",
    },

    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "red",
    },
  },
}));
