/**  Saga */

import { call, put, takeLatest } from 'redux-saga/effects';
import { loadService } from './load.service';
import {
    GET_LOAD,
    GET_LOAD_ID,
    GET_LOAD_ID_SUCCESS,
    EDIT_LOAD,
    CREATE_LOAD,
    DELETE_LOAD,
    CHANGE_LOAD_STATUS,
    GET_LOAD_SUCCESS,
    GET_LOAD_TRUCK_ID,
    GET_LOAD_TRUCK_ID_SUCCESS,
    CREATE_LOAD_ID_STOP,
    EDIT_LOAD_ID_STOP_ID,
    DELETE_LOAD_ID_STOP_ID,
    CREATE_LOAD_ID_DOCUMENT,
    GET_LOAD_ID_DOCUMENT,
    GET_LOAD_ID_DOCUMENT_SUCCESS,
    EDIT_LOAD_DOCUMENT_ARCHIVE,
    DELETE_LOAD_DOCUMENT,
    CREATE_TRANSACTION,
    EDIT_TRANSACTION_ACTION,
    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    CANCEL_LOAD, GET_LOAD_COASTS, GET_LOAD_COASTS_SUCCESS,
} from './load.type';
import { httpRequestsOnErrorsActions } from 'store/http_requests_on_errors';
import { httpRequestsOnLoadActions } from 'store/http_requests_on_load';
import { httpRequestsOnSuccessActions } from 'store/http_requests_on_success';

/* Get Load */
function* getLoad({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(loadService.getLoad, payload?.body);
        yield put({
            type: GET_LOAD_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Create Load */
function* createLoad({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.createLoad, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Edit Load */
function* editLoad({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(loadService.editLoad, payload.body, payload.id);
        yield put({
            type: GET_LOAD_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/* Delete Load  */
function* deleteLoad({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.deleteLoad, payload);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Change Status Load */
function* changeStatus({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.changeStatus, payload.id, payload.status);
        yield put({
            type: GET_LOAD_ID,
            payload: { id: payload.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* cancelLoad({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.changeStatus, payload.id, payload.status);
        yield put({
            type: GET_LOAD_ID,
            payload: { id: payload.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Get Load ID */
function* getLoadId({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(loadService.getLoadId, payload?.id);
        yield put({
            type: GET_LOAD_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Get Load Truck ID */
function* getLoadTruckId({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(loadService.getLoadTruckId, payload?.id);
        yield put({
            type: GET_LOAD_TRUCK_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/* Create Load Stop */
function* createLoadStop({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.createLoadStop, payload.body, payload.id);
        yield put({
            type: GET_LOAD_ID,
            payload: { id: payload.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/* Edit Load Stop Id */
function* editLoadStopId({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.editLoadStopId, payload.body, payload.id, payload.stopId);
        yield put({
            type: GET_LOAD_ID,
            payload: { id: payload.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/* Delete Load Id Stop StopId */
function* deleteLoadsStopId({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.deleteLoadsStopId, payload.id, payload.stopId);
        yield put({
            type: GET_LOAD_ID,
            payload: { id: payload.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** Loads Document */
function* createDocumentLoad({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.createDocumentLoad, payload.id, payload.body);
        yield put({
            type: GET_LOAD_ID_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getDocumentLoad({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(loadService.getDocumentLoad, payload?.id);
        yield put({
            type: GET_LOAD_ID_DOCUMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editDocumentIdArchive({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.editDocumentIdArchive, payload.body, payload.id, payload.docId, payload.archive);
        yield put({
            type: GET_LOAD_ID_DOCUMENT,
            payload: { id: payload?.body },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteDocumentDocId({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.deleteDocumentDocIdService, payload.id, payload.docId);
        yield put({
            type: GET_LOAD_ID_DOCUMENT,
            payload: { id: payload?.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/**End */

/* Transaction Saga */
function* createTxn({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.createLoadTxn, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editTxnAction({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(loadService.editTxnAction, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getTxn({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(loadService.getLoadTxn, payload?.body, payload?.id);
        yield put({
            type: GET_TRANSACTION_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getCoasts({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));

    try {
        const res = yield call(loadService.getLoadCoastsService, payload?.id, );
        yield put({
            type: GET_LOAD_COASTS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchLoad = function* watchLoadAuth() {
    yield takeLatest(GET_LOAD, getLoad);
    yield takeLatest(CREATE_LOAD, createLoad);
    yield takeLatest(EDIT_LOAD, editLoad);
    yield takeLatest(DELETE_LOAD, deleteLoad);
    yield takeLatest(CHANGE_LOAD_STATUS, changeStatus);
    yield takeLatest(CANCEL_LOAD, cancelLoad);
    yield takeLatest(GET_LOAD_ID, getLoadId);
    yield takeLatest(GET_LOAD_TRUCK_ID, getLoadTruckId);
    /** Loads Stops */
    yield takeLatest(CREATE_LOAD_ID_STOP, createLoadStop);
    yield takeLatest(EDIT_LOAD_ID_STOP_ID, editLoadStopId);
    yield takeLatest(DELETE_LOAD_ID_STOP_ID, deleteLoadsStopId);
    /** Loads Document */
    yield takeLatest(CREATE_LOAD_ID_DOCUMENT, createDocumentLoad);
    yield takeLatest(GET_LOAD_ID_DOCUMENT, getDocumentLoad);
    yield takeLatest(EDIT_LOAD_DOCUMENT_ARCHIVE, editDocumentIdArchive);
    yield takeLatest(DELETE_LOAD_DOCUMENT, deleteDocumentDocId);
    /** Load Transaction */
    yield takeLatest(CREATE_TRANSACTION, createTxn);
    yield takeLatest(EDIT_TRANSACTION_ACTION, editTxnAction);
    yield takeLatest(GET_TRANSACTION, getTxn);

    yield takeLatest(GET_LOAD_COASTS, getCoasts);
};
