export const MODAL_NAMES = {
    /* Auth Modal */
    SIGN_IN: 'SIGN_IN',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SIGN_UP: 'SIGN_UP',
    CANGRATS_ALL: 'CANGRATS_ALL',
    CANGRATS_RESET: 'CANGRATS_RESET',
    INACTIVATE_MODAL: 'INACTIVATE_MODAL',
    /* Users Modal */
    USER_INVITE_MODAL: 'USER_INVITE_MODAL',
    DELETE_USER: 'DELETE_USER',
    /* Trucks Modal */
    TRACK_MODAL: 'TRACK_MODAL',
    DELETE_TRUCK: 'DELETE_TRUCK',
    DELETE_TRUCK_LOAD: 'DELETE_TRYCK_LOAD',
    TRUCK_LOAD_MODAL: 'TRUCK_LOAD_MODAL',
    INACTIVE_TRUCK: 'INACTIVE_TRUCK',
    INAACTIVE_TRUCK_LOAD: 'INAACTIVE_TRUCK_LOAD',
    /* Drivers Modal */
    DELETE_DRIVERS: 'DELETE_TRUCK',
    EDIT_DRIVER: 'EDIT_DRIVER',
    INACTIVE_DRIVER: 'INACTIVE_DRIVER',
    DRIVER_MODAL: 'DRIVER_MODAL',
    POST_RATES: 'POST_RATES',
    ADD_DRIVER_RATE: 'ADD_DRIVER_RATE',
    ADD_FUEL_CARD: 'ADD_FUEL_CARD',
    /* Load Board Modal */
    CREATE_LOAD: 'CREATE_LOAD',
    ADD_LOAD: 'ADD_LOAD',
    EDIT_LOAD: 'EDIT_LOAD',
    INACTIVATE_LOAD: 'INACTIVATE_LOAD',
    DELETE_LOAD: 'DELETE_LOAD',
    INACTIVATE_BOARD: 'INACTIVATE_BOARD',
    DELETE_BOARD: 'DELETE_BOARD',
    ADD_STOPS: 'ADD_STOPS',
    ADD_TRANSACTION: 'ADD_TRANSACTION',
    /* Biling Modal */
    CANCEL_SUBSCRIBTION: 'CANCEL_SBSCRIBTION',
    PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
    PAYMENT_ERROR: 'PAYMENT_ERROR',
    MENU_USER: 'MENU_USER',
    DELETE_MODAL: 'DELETE_MODAL',
    /* Trailer Modal */
    CREATE_TRAILER: 'CREATE_TRAILER',
    /* Customer Modal */
    ADD_CUSTOMER: 'ADD_CUSTOMER',
    ADD_CONTACTS: 'ADD_CONTACTS',


    /* Payment Modals */
    CHECK_PAYMENT_HELPER: 'CHECK_PAYMENT_HELPER',
    CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
    UPGRADE_DOWNGRADE: 'UPGRADE_DOWNGRADE',
    SUCCESS_CANCEL: 'SUCCESS_CANCEL',

};
