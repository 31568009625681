import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, CreateCancel, MaskInput } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, driversActions } from 'store';

export const AddFuelCard = () => {
    /**
     * Add Fuel Card.
     */

    const { driversDetailsList } = useSelector((state) => ({
        driversDetailsList: state?.drivers?.driversDetailsList,
    }));

    const { close, params } = useModal();
    const ACTION_TYPE = params?.info ? 'DRIVER_EDIT_FUEL' : 'DRIVER_CREATE_FUEL';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);
    const driverId = driversDetailsList?.id;
    const fuelId = params?.info?.id;

    const { control, handleSubmit, setError } = useForm({
        defaultValues: {
            cardNumber: params?.info?.cardNumber,
        },
    });

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };

        if(data?.cardNumber){
            sendInfo.cardNumber = parseInt(data?.cardNumber.replace(/[^0-9]/g, ''))
        }
        if (params?.info) {
            dispatch(driversActions.editFuel(sendInfo, driverId, fuelId));
        } else {
            dispatch(driversActions.createFuel(sendInfo, driverId));
        }
    };

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        if (backError?.error === 'phone must be a valid phone number.') {
            setError('cardNumber');
        }
    }, [backError]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    return (
        <div className="invite-form">
            <div className="driver-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MaskInput
                        mask={'9999-9999-9999-9999'}
                        name="cardNumber"
                        label="Fuel Card Number*"
                        control={control}
                        rules={{ required: true }}
                        type="phone"
                        errMessage={backError?.error?.[0] === 'phone must be a valid phone number'}
                        errMessageToShow={
                            backError?.error?.[0] === 'phone must be a valid phone number' ? 'Phone must be a valid phone number' : ''
                        }
                    />
                    <CreateCancel title={'Save'} actionType={ACTION_TYPE} closeType={ACTION_TYPE} />
                </form>
            </div>
        </div>
    );
};
