import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, CustomInput, CustomSelect, CreateCancel } from 'components';
import {
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    loadActions,
    truckActions,
    driversActions,
    trailerActions,
} from 'store';
import { customersAction } from 'store/customers';
import { cretaeLoadDTO } from 'pages/loadBoard/constants';

const ACTION_TYPE = 'CREATE_LOAD';

export const CreateLoad = () => {
    const { truckList, trailerList, driversList, customerList, truckDetailsList } = useSelector((state) => ({
        truckList: state?.truck?.truckList,
        trailerList: state?.trailer?.trailerList,
        driversList: state?.drivers?.driversList,
        customerList: state?.customer?.customerList,
        truckDetailsList: state?.truck?.truckDetailsList,
    }));

    const { close } = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);
    const [inputs, setInputs] = useState('');

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            type: null,
            weight: null,
            customerRate: null,
            loadMiles: null,
            deadheadMiles: null,
            truckId: null,
            driver: null,
            coDriver: null,
            fuelCost: null,
            assignTrailer: null,
            customer: null,
        },
    });

    useEffect(() => {
        dispatch(truckActions.removeTruckDetails());
    }, []);

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        if (backError?.error === 'This Organization has a truck with this ID.') {
            setError('truckId');
        }
    }, [backError]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            dispatch(truckActions.removeTruckDetails());
        };
    }, []);

    useEffect(() => {
        dispatch(truckActions.getTruck({ status: 'AVAILABLE' }));
        dispatch(driversActions.getDriver({ status: 'ACTIVE' }));
        dispatch(trailerActions.getTrailer({ status: 'ACTIVE' }));
        dispatch(customersAction.getCustomer({ status: 'ACTIVE' }));
    }, []);

    useEffect(() => {
        if (truckDetailsList) {
            if (inputs?.type !== 'POWER_ONLY') {
                setValue('assignTrailer', truckDetailsList?.assignedTrailerId);
            }
            setValue('driver', truckDetailsList?.assignedDriverId);
            setValue('coDriver', truckDetailsList?.assignedCoDriverId);
            setInputs({
                type: inputs?.type,
                driver: truckDetailsList?.assignedDriverId,
                coDriver: truckDetailsList?.assignedCoDriverId,
            });
        }
    }, [truckDetailsList]);

    useEffect(() => {
        if (inputs?.truckId) {
            dispatch(truckActions.getTruckDetails(inputs?.truckId));
        }
    }, [inputs]);

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };
        if (sendInfo.assignTrailer === 'notSet') {
            sendInfo.assignTrailer = null;
        }
        if (sendInfo.driver === 'notSet') {
            sendInfo.driver = null;
        }
        if (sendInfo.coDriver === 'notSet') {
            sendInfo.coDriver = null;
        }
        dispatch(loadActions.createLoad(sendInfo));
    };

    const handleReset = () => {
        setValue('assignTrailer', 'notSet');
        setValue('driver', 'notSet');
        setValue('coDriver', 'notSet');
    };

    const handleChange = (e) => {
        if (e.target.name === 'type') {
            if (e.target.value === 'POWER_ONLY') {
                setValue('assignTrailer', 'notSet');
            } else {
                setValue('assignTrailer', truckDetailsList?.assignedTrailerId);
            }
        }
        if (e.target.name === 'truckId' && e.target.value) {
            dispatch(truckActions.getTruckDetails(e.target.value));
            handleReset();
        }
        if (e.target.name === 'truckId' && !e.target.value) {
            dispatch(truckActions.removeTruckDetails());
            handleReset();
        }
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    return (
        <div className="load-modal">
            <div className="invite-container">
                <form onSubmit={handleSubmit(onSubmit)} className="form-messages">
                    <Grid container columnSpacing={2} marginTop={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <CustomSelect
                                handleBlur={handleChange}
                                name={'type'}
                                control={control}
                                rules={{ required: true }}
                                defaultValue={cretaeLoadDTO?.value ? cretaeLoadDTO?.label : ''}
                                listSelect={cretaeLoadDTO}
                                displayValue={'value'}
                                displayName={'label'}
                                label="Type*"
                                placeholder={'Type'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CustomInput
                                name="customerRate"
                                control={control}
                                rules={{ required: true }}
                                type="number"
                                label="Customer Rate*"
                                placeholder={'Rate'}
                                dollar={true}
                                step={'numeric'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CustomInput
                                name="weight"
                                control={control}
                                rules={{ required: true }}
                                type="number"
                                label="Weight*"
                                placeholder={'Weight'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CustomInput
                                name="fuelCost"
                                control={control}
                                rules={{ required: true }}
                                type="number"
                                label="Fuel Cost*"
                                placeholder={'Fuel Cost'}
                                dollar={true}
                                step={'numeric'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CustomInput
                                name="loadMiles"
                                control={control}
                                rules={{ required: true }}
                                label="Load Miles*"
                                type="number"
                                step={'numeric'}
                                min={1}
                                placeholder={'Brake Change'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <CustomInput
                                name="deadheadMiles"
                                control={control}
                                rules={{ required: true }}
                                type="number"
                                label="Dead Head Miles*"
                                placeholder={'Tire Change'}
                                step={'numeric'}
                                min={1}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                handleBlur={handleChange}
                                name={'truckId'}
                                control={control}
                                rules={{ required: false }}
                                listSelect={truckList?.trucks}
                                displayValue={'id'}
                                displayName={'name'}
                                label="Assign Truck"
                                placeholder={'Choose Truck'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomSelect
                                name={'assignTrailer'}
                                control={control}
                                rules={{ required: false }}
                                listSelect={
                                    // truckDetailsList ? trailerList?.trailers?.filter((i) => i?.id === truckDetailsList?.assignedTrailerId)
                                    //     :
                                    trailerList?.trailers
                                }
                                displayValue={'id'}
                                displayName={'make'}
                                label="Assign Trailer"
                                placeholder={'Choose Trailer'}
                                disabled={inputs?.type === 'POWER_ONLY'}
                                customOption={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomSelect
                                handleBlur={handleChange}
                                name={'driver'}
                                control={control}
                                disabled={false}
                                rules={{ required: false }}
                                listSelect={
                                    // truckDetailsList ? driversList?.drivers?.filter((i) => i?.id === truckDetailsList?.assignedDriverId)
                                    //     :
                                    inputs?.coDriver
                                        ? driversList?.drivers?.filter((i) => i?.id !== inputs?.coDriver)
                                        : driversList?.drivers
                                }
                                label="Assign Driver"
                                displayName={'firstName'}
                                displayValue={'id'}
                                placeholder="Choose Driver"
                                customOption={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomSelect
                                handleBlur={handleChange}
                                displayName={'firstName'}
                                displayValue={'id'}
                                name={'coDriver'}
                                control={control}
                                rules={{ required: false }}
                                listSelect={
                                    // truckDetailsList ? driversList?.drivers?.filter((i) => i?.id === truckDetailsList?.assignedCoDriverId)
                                    //     :
                                    inputs?.driver ? driversList?.drivers?.filter((i) => i?.id !== inputs?.driver) : driversList?.drivers
                                }
                                label="Assign CoDriver"
                                placeholder={'Choose CoDriver'}
                                customOption={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CustomSelect
                                name={'customer'}
                                control={control}
                                rules={{ required: false }}
                                listSelect={customerList?.customers}
                                displayValue={'id'}
                                displayName={'name'}
                                label="Assign Customer"
                                placeholder={'Choose Customer'}
                            />
                        </Grid>
                    </Grid>
                    <div className="footer-box">
                        <CreateCancel title={'Save'} actionType={ACTION_TYPE} closeType={ACTION_TYPE} />
                    </div>
                </form>
            </div>
        </div>
    );
};
