import React from 'react';
import { Svg } from 'assets/images';

export const ErrorCard = ({ subtitle }) => {
    return (
        <div className="error-card">
            <div className="card-img">
                <div className="img-box">
                    <img src={Svg.NotificationFailed} alt="Notifiaction-Failed" />
                </div>
            </div>
            <div className="card-box">
                <p className="error-title">
                    Error Message:
                    <span className="error-subtitle">{subtitle}</span>
                </p>
            </div>
        </div>
    );
};
