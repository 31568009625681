import {
    GET_CUSTOMER,
    GET_CUSTOMER_CONTACTS,
    GET_CUSTOMER_ID,
    EDIT_CUSTOMER,
    EDIT_CUSTOMER_CONTACT,
    CREATE_CUSTOMER,
    CHANGE_CUSTOMER_STATUS,
    CREATE_CUSTOMER_CONTACT,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_CONTACT,
} from './customers.type';

/**  Customer Type */
export const createCustomer = (body) => {
    return {
        type: CREATE_CUSTOMER,
        payload: { body },
    };
};

export const getCustomer = (body, load) => {
    return {
        type: GET_CUSTOMER,
        payload: { body, load },
    };
};

export const editCustomer = (body, id) => {
    return {
        type: EDIT_CUSTOMER,
        payload: { body, id },
    };
};

export const getCustomerId = (id, body) => {
    return {
        type: GET_CUSTOMER_ID,
        payload: { id, body },
    };
};

export const deleteCustomer = (id) => {
    return {
        type: DELETE_CUSTOMER,
        payload: { id },
    };
};

export const changeCustomerStatus = (id, status) => {
    return {
        type: CHANGE_CUSTOMER_STATUS,
        payload: { id, status },
    };
};
/**  End */

/**  Customer Contact */
export const createCustomerContact = (body, id) => {
    return {
        type: CREATE_CUSTOMER_CONTACT,
        payload: { body, id },
    };
};

export const editCustomerContact = (body, id, contactId) => {
    return {
        type: EDIT_CUSTOMER_CONTACT,
        payload: { body, id, contactId },
    };
};

export const getCustomerContacts = (id, body) => {
    return {
        type: GET_CUSTOMER_CONTACTS,
        payload: { id, body },
    };
};

export const deleteCustomerContact = (customerId, id) => {
    return {
        type: DELETE_CUSTOMER_CONTACT,
        payload: { customerId, id },
    };
};
/** End */