import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useModal, DropdownSelect, PopperComponent, Documents, Loader, GoBackButton } from 'components';
import { Box, Tab, Tabs, Button } from '@mui/material';
import { trailersStatus } from './constants';
import { Info } from './fragments';
import { CheckCoastPermission, FindLoad, MODAL_NAMES } from 'utils';
import { trailerActions } from 'store';
import { NotesSection } from '../../fragments';
import { TrailerCoast } from './fragments/trailerCoast';

export const TrailerInfo = () => {
    const { trailerId, trailerDocument, activePackage, trailerCoasts } = useSelector((state) => ({
        trailerId: state?.trailer?.trailerId,
        trailerDocument: state?.trailer?.trailerDocument,
        activePackage: state.payment.activePackage,
        trailerCoasts: state?.trailer?.trailerCoasts,

    }));
    const { open } = useModal();
    const dispatch = useDispatch();
    const paramsRouter = useParams();
    const loader = FindLoad('GET_TRAILER_ID');
    const [valueTab, setValueTab] = useState('info');
    const [status, setStatus] = useState('');

    useEffect(() => {
        if (trailerId) {
            setStatus(trailerId?.status);
        }
    }, [trailerId]);

    useEffect(() => {
        dispatch(trailerActions.getTrailerId(paramsRouter.id));
        dispatch(trailerActions.getTrailerDocument(paramsRouter.id));
    }, []);

    useEffect(() => {
        if(activePackage && CheckCoastPermission(activePackage)){
            dispatch(trailerActions.getTrailerCoast(paramsRouter?.id));
        }
    }, [activePackage])

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
        const active = event.target.value;
        const driverId = trailerId.id;
        if (active === 'ACTIVE') {
            dispatch(trailerActions.changeTrailerStatus(driverId, true));
        } else {
            dispatch(trailerActions.changeTrailerStatus(driverId, false));
        }
    };

    const handleChange = (event, newValueTab) => {
        setValueTab(newValueTab);
    };

    const handelDeleteTrailer = () => {
        dispatch(trailerActions.deleteTrailer(paramsRouter.id));
    };

    const handelDeleteDriver = () => {
        open(MODAL_NAMES.DELETE_MODAL, {
            title: `Trailer ${trailerId?.displayId} `,
            subTitle: 'Deleting a trailer will permanently remove it from the system',
            actionType: 'DELETE_TRAILER',
            handleDelete: () => handelDeleteTrailer('DELETE_TRAILER'),
            historyPush: '/trailers',
        });
    };

    const handleStatus = (item) => {
        dispatch(trailerActions.changeTrailerStatus(trailerId.id, item !== 'ACTIVE'));
    };

    const TrailersMenuList = () => {
        return [
            {
                title: trailerId?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                name: MODAL_NAMES.INACTIVATE_MODAL,
                params: {
                    title: `Trailer ${trailerId.displayId} `,
                    modalType: trailerId?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                    subTitle: 'Inactivating a user will not active in the system. You can activate user when do you want.',
                    actionType: 'CHANGE_TRAILER_STATUS',
                    handleChange: () => handleStatus(trailerId?.status),
                },
            },
        ];
    };

    const handleUploadFile = (dock) => {
        const file = {
            file: {
                ...dock?.file
            },
            status: 'CURRENT',
            type: dock?.type,
        };
        dispatch(trailerActions.createTrailerDocument(paramsRouter.id, file));
    };

    const handleDeleteUpload = (dock) => {
        dispatch(trailerActions.deleteTrailerDocument(paramsRouter.id, dock?.id));
    };

    const handleSetStatus = (dock) => {
        const status = dock?.status === 'CURRENT';
        dispatch(trailerActions.editTrailerDocumentArchive(paramsRouter.id, dock?.id, status));
    };

    if (loader?.length) {
        return <Loader />;
    }


    return (
        <div className='trailer-info info-container'>
            <div className='trailer-header info-header'>
                <div className='info-router'>
                    <GoBackButton title={'Trailer'} link={'/trailers'} />
                </div>
                <div className='trailer-banner'>
                    <div className='header-title'>
                        <h4 className='title'>
                            Trailer <span>{trailerId?.displayId}</span>
                        </h4>
                    </div>
                    <div className='header-btn'>
                        <div className='wrapper-btn'>
                            <div className='popper-desktop'>
                                <div className='popper-btn'>
                                    <div className='header-select'>
                                        <DropdownSelect
                                            value={status}
                                            setStatus={setStatus}
                                            handleChange={handleChangeStatus}
                                            options={trailersStatus}
                                            idInput={'status'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='popper-mobile'>
                                <div className='popper-btn'>
                                    <PopperComponent list={TrailersMenuList()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='trailer-tab'>
                    <Box className='tab-btns'>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={valueTab} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab label='Basic  Info' value='info' />
                                {CheckCoastPermission(activePackage) && <Tab label='Costs' value='costs' />}
                                <Tab label='Notes' value='notes' />
                            </Tabs>
                        </Box>
                    </Box>
                </div>
            </div>
            <div className='trailer-main info-main'>
                {valueTab === 'info' && (
                    <Box className='tab-btns'>
                        <Info
                            params={paramsRouter}
                            paramsList={trailerId}
                        />
                        <div className='document-container'>
                            <Documents
                                docks={trailerDocument?.length ? trailerDocument : []}
                                actionType={'CREATE_TRAILER_DOCUMENT'}
                                deleteType={'DELETE_TRAILER_DOCUMENT'}
                                changeStatus={'EDIT_TRAILER_DOCUMENT_ARCHIVE'}
                                activeStatus={'CURRENT'}
                                // typeList={trailerDocumentsList}
                                handleDelete={handleDeleteUpload}
                                handleUpload={handleUploadFile}
                                handleSetStatus={handleSetStatus}
                                uploadType={'noType'}
                            />
                        </div>

                        <div className='box-footer'>
                            <Button variant='text' color='error' onClick={handelDeleteDriver}>
                                <span className='text-error '>Delete Trailer</span>
                            </Button>
                        </div>
                    </Box>
                )}

                {CheckCoastPermission(activePackage) && valueTab === 'costs' &&
                    <TrailerCoast trailerCoasts={trailerCoasts} />
                }
                {valueTab === 'notes' &&
                    <NotesSection type={'trailer'} />
                }
            </div>
        </div>
    );
};
