import axios from 'axios';

export const paymentService = {

    activatePackageService: ( type ) => axios.post('/subscriptions', { package: type }, { auth: true }),

    getSubscriptionService: () => axios.get('/subscriptions', { auth: true }),

    getCardService: () => axios.get('/subscriptions/pmtMethod', { auth: true }),

    getInvoicesService: () => axios.get('/subscriptions/invoices', { auth: true }),

    cancelSubscriptionService: () => axios.delete('/subscriptions', { auth: true }),

};