import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Popover } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AddButton, CustomDelete, Loader, NoDataComponent, TextRow } from 'components';
import { Svg } from '../../assets/images';
import { noteActions } from 'store';
import { FindLoad, useModalBase } from 'utils';
import { AddNotes } from './modals/addNotes';

const ACTION_TYPE = 'GET_NOTES';

export const NotesSection = ({ type }) => {
    const { noteList } = useSelector((state) => ({
        noteList: state.notes.noteList,
    }));
    const { openModal } = useModalBase();


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const loader = FindLoad(ACTION_TYPE);
    const dispatch = useDispatch();
    const params = useParams();
    const [currentFile, setCurrentFile] = useState();

    useEffect(() => {
        dispatch(noteActions.getNotes(params?.id, type));
    }, []);

    const handleClick = (event, item) => {
        setCurrentFile(item);
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleOpenDel = () => {
        setAnchorEl(null);
        const getParams = {
            'resource': params?.id,
            'onModel': type,
        };

        openModal(true,
            <CustomDelete
                doYou={'Do you want Delete Note ?'}
                // text={'are you sure you want Delete this Note ?'}
                handleDelete={() => {
                    dispatch(noteActions.deleteNote(getParams, currentFile?.id));
                }}
                actionType={'DELETE_NOTE'}
            />,
        );
    };

    const handleEdit = () => {
        setAnchorEl(null);
        openModal(true,
            <AddNotes
                modelId={params?.id}
                type={type}
                note={currentFile}
            />,
        );
    };

    if (loader?.length) return <Loader />;

    return (
        <div className='notes-section-wrapper'>
            <div className='add-note-button'>
                <AddButton addText={'Add Note'}
                           handleAdd={() => openModal(true, <AddNotes modelId={params?.id} type={type} />)} />
            </div>
            <div className='notes-wrapper'>
                {noteList?.length ? noteList?.map((i, j) => (
                        <div className='note-card' key={j}>
                            <div className='icon-and-info'>
                                <img src={Svg.NoteIcon} alt='icon' />
                                <div className='note-title-and-info'>
                                    <p className='note-title'>{i?.text}
                                        {/*<TextRow name={i?.text} />*/}
                                        {/*{i?.text}*/}
                                    </p>
                                    <div className='date-and-creator'>
                                        <p>Date <span>{moment(i?.createdAt).format('MM/DD/YYYY')}</span></p>
                                        <p className='creator-name'>Creator
                                            Name <span>{i?.creator?.fullName ? i?.creator?.fullName : '...'}</span></p>
                                    </div>
                                </div>
                            </div>
                            <button
                                className='popper-button'
                                aria-describedby={id}
                                onClick={(e) => handleClick(e, i)}
                            >
                                <MoreVertIcon style={{ color: '#758EA6' }} />
                            </button>
                        </div>
                    ))
                    :
                    <div style={{ height: '100%', width: '100%' }}>
                        <NoDataComponent />
                    </div>
                }
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={(e) => handleClose(e)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className='popper-items-wrapper'>
                    <button onClick={(e) => handleEdit()}>
                        {'Edit'}
                    </button>
                    <button onClick={(e) => handleOpenDel(e, currentFile?.id)} style={{color:'#D03325'}}>
                        {'Delete'}
                    </button>
                </div>
            </Popover>
        </div>
    );
};