import { Svg } from 'assets/images';
import { useModal } from 'components';
import { useHistory } from 'react-router-dom';

export const SuccessError = ({}) => {
    const history = useHistory();
    const { open, close, params } = useModal();

    const handleClosePush = () => {
        history.push('/payment');
        close();
    };

    return (
        <div className="success-error-modal">
            <img src={params?.modalType === 'success' ? Svg.Done : Svg.Cancel} alt={'Done'} />
            <p className="title-style">{params?.modalType === 'success' ? 'Success' : 'Error'}</p>
            {params?.modalType === 'success' ? (
                <p className="sub-title-style">
                    Your payment successfully have <br /> done.You can see invoice on page <br />
                    <a style={{ color: '#FF9A00' }} href="/payment">
                        Billing and payment
                    </a>
                </p>
            ) : (
                <p className="sub-title-style">Sorry your payment haven’t done.Please try again</p>
            )}
            <button onClick={handleClosePush} className="done-again-button">
                Done
            </button>
        </div>
    );
};
