import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Box, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import { Svg } from 'assets/images';
import { MODAL_NAMES } from 'utils';
import { useModal, NoDataComponent, TextRow } from 'components';
import { loadActions } from 'store';

export const StopsFragment = ({ stopsList, paramsId, loadStatus }) => {
    const { open } = useModal();
    const dispatch = useDispatch();

    const handelDeleteStop = (e, item) => {
        e.stopPropagation();
        open(MODAL_NAMES.DELETE_MODAL, {
            title: `Stops ${item?.name}`,
            subTitle: 'Deleting a Step will permanently remove it from the system',
            actionType: 'DELETE_LOAD_ID_STOP_ID',
            handleDelete: () => handleDeleteDriver('DELETE_LOAD_ID_STOP_ID', item.id),
        });
    };

    const handleEditStop = (e, item) => {
        e.stopPropagation();
        open(MODAL_NAMES.ADD_STOPS, {
            modalTitle: 'Edit Stop',
            param: {
                ...item,
            },
        });
    };

    const handleDeleteDriver = (_, stopId) => {
        dispatch(loadActions.deleteLoadsStopId(paramsId?.id, stopId));
    };

    const checkLoadStatus = loadStatus === 'DELIVERED' || loadStatus === 'CANCELED' || loadStatus === 'COMPLETE';

    return (
        <Fragment>
            <div className="stops-container">
                {stopsList?.length ? (
                    stopsList.map((item, index) => (
                        <Accordion key={index} className="accordion-box">
                            <AccordionSummary
                                expandIcon={<img src={Svg.AccordionIcon} alt="Accordion-Icon" />}
                                aria-controls="panel-content"
                                id="panel-header"
                                className="pannel-summary ">
                                <Box className="flex title-box space-between">
                                    <div className="stop-title" style={{ width: '100%' }}>
                                        <TextRow name={item?.name} textWidth={11} />
                                    </div>

                                    <div className="stop-title stop-desktop-view" style={{ whiteSpace: 'nowrap' }}>
                                        {item?.date ? (
                                            <>
                                                {moment(item?.date).format('MM/DD/YYYY')}&nbsp;
                                                {moment(item?.date).format('LT')}
                                            </>
                                        ) : (
                                            'Not Set'
                                        )}
                                    </div>
                                </Box>
                                <Box className="flex pick-box">
                                    <div className="img-box">
                                        <img src={Svg.PickUpIcon} alt="Pick-Up-Icon" />
                                    </div>
                                    <div className="pick-header">
                                        <div className="pick">
                                            <span className="pick-title">{item?.type === 'PICKUP' ? 'Pick Up :' : 'Drop Off :'}</span>
                                            <span className="pick-img">
                                                {item?.type === 'DROP_OFF' ? (
                                                    <img src={Svg.DropOffIcon} alt="Drop-Off-Icon" />
                                                ) : (
                                                    <img src={Svg.OrangeTopIcon} alt="Orange-Top-Icon" />
                                                )}
                                            </span>
                                        </div>
                                        <div className="pick-text" style={{ width: '100%' }}>
                                            <TextRow
                                                name={`${item?.address?.formattedAddress} ${item?.address?.unit ? item.address?.unit : ''}`}
                                                textWidth={10}
                                            />
                                        </div>
                                    </div>
                                </Box>
                                <Box className="flex action-box">
                                    <div className="edit-icon">
                                        <Button variant="text" onClick={(e) => handleEditStop(e, item)} disabled={checkLoadStatus}>
                                            {checkLoadStatus ? (
                                                <img src={Svg.DisabledOrange} alt="Disabled_Orange" className="disabled-icon" />
                                            ) : (
                                                <img src={Svg.OrangeEdit} alt="Orange Edit" />
                                            )}
                                        </Button>
                                    </div>
                                    <div className="delete-icon">
                                        <Button
                                            variant="text"
                                            color="error"
                                            onClick={(e) => handelDeleteStop(e, item)}
                                            disabled={checkLoadStatus}>
                                            {checkLoadStatus ? (
                                                <img src={Svg.DisabledDelete} alt="Disabled-Delete" className="disabled-icon" />
                                            ) : (
                                                <img src={Svg.RedDelete} alt="Red Delete" />
                                            )}
                                        </Button>
                                    </div>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails className="details-mobile details-box pick-mobile">
                                <Box className="flex pick-box details-titile" style={{ alignItems: 'flex-start' }}>
                                    <div className="img-box">
                                        <img src={Svg.PickUpIcon} alt="Pick-Up-Icon" />
                                    </div>
                                    <div className="pick-header">
                                        <div className="pick">
                                            <span className="pick-title">{item?.type === 'PICKUP' ? 'Pick Up :' : 'Drop Off :'}</span>
                                            <span className="pick-img">
                                                {item?.type === 'DROP_OFF' ? (
                                                    <img src={Svg.DropOffIcon} alt="Drop-Off-Icon" />
                                                ) : (
                                                    <img src={Svg.OrangeTopIcon} alt="Orange Top Icon" />
                                                )}
                                            </span>
                                        </div>
                                        <div className="pick-text">{`${item?.address?.formattedAddress} ${
                                            item?.address?.unit ? item.address?.unit : ''
                                        }`}</div>
                                    </div>
                                </Box>
                                <Box className="flex pick-box details-titile" style={{ margin: '8px 0' }}>
                                    <div className={'flex'}>
                                        <div className={'flex'}>
                                            <span className="text-green">Date:</span>
                                            <span className="text-info">
                                                {item?.date ? moment(item?.date).format('MM/DD/YYYY') : 'Not Set'}
                                            </span>
                                        </div>
                                        <div className={'flex'} style={{ marginLeft: '12px' }}>
                                            <span className="text-green">Time:</span>
                                            <span className="text-info">{item?.date ? moment(item?.date).format('LT') : 'Not Set'}</span>
                                        </div>
                                    </div>
                                </Box>

                                <Box className="flex pick-box details-titile">
                                    <div>
                                        <span className="text-green">Instruction:</span>
                                        <span className="text-info">{item?.instructions ? item.instructions : 'Not Set'}</span>
                                    </div>
                                </Box>
                            </AccordionDetails>

                            <div className={'tablet-and-desktop'}>
                                <AccordionDetails className="details-box ">
                                    <Box className="stops-details">
                                        <div className="details-description">
                                            <div>
                                                <span className="text-green">Instruction:</span>
                                                <span className="text-info">{item?.instructions ? item.instructions : 'Not Set'}</span>
                                            </div>
                                        </div>
                                    </Box>
                                </AccordionDetails>
                            </div>

                            <AccordionDetails className="details-mobile details-box ">
                                <Box className="action-box">
                                    <div className="edit-icon">
                                        <img src={Svg.OrangeEdit} alt="Orange Edit" onClick={(e) => handleEditStop(e, item)} />
                                    </div>
                                    <div className="delete-icon">
                                        <img src={Svg.RedDelete} alt="Red Delete" onClick={(e) => handelDeleteStop(e, item)} />
                                    </div>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <Fragment>
                        <NoDataComponent />
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};
