import React from 'react';
import { Box, Button } from '@mui/material';
import { Svg } from 'assets/images';
import { MinLoader } from 'components/loaders';
import { RolePermissions } from '../../utils/hooks';
import { useSelector } from 'react-redux';

export const CardBtn = ({ className, editInput, handleEdit, load, handleChancel }) => {
    /**
     *  Card Btn.
     */

    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));

    return (
        <>
            {RolePermissions(activePackage) && (
                <div className={className}>
                    {editInput ? (
                        <div className="flex">
                            <Button type="button" onClick={handleEdit}>
                                <img src={Svg.EditLoad} alt="Edit-Load" />
                                <span className="edit-load">Edit</span>
                            </Button>
                        </div>
                    ) : (
                        <div className="flex">
                            <Button type="button" onClick={handleChancel}>
                                <span className="cance-load">Cancel</span>
                            </Button>
                            <Box className="btn-space" />
                            <Button type="submit" sentenceCase>
                                {load?.length ? <MinLoader color={'#0E9594'} /> : <span className="save-load">Save</span>}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
