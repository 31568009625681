import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { httpRequestsOnSuccessActions, userActions } from 'store';
import { FullTable, TableWrapper, useModal, FilterMenu, CreateMobileButton } from 'components';
import { ACTION_TYPE, usersBody, usersBodyNoPackage, usersHead, userStatus } from './constants';
import { MODAL_NAMES, SaveParams, RolePermissions } from 'utils';
import { UsersMobile } from './usersMobile';

const title = 'Users';

export const UsersPage = () => {
    const { userList, activePackage } = useSelector((state) => ({
        userList: state?.user?.userList,
        activePackage: state.payment.activePackage,
    }));

    const { open } = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const dispatch = useDispatch();

    const handleInviteUser = () => {
        open(MODAL_NAMES.USER_INVITE_MODAL, { modalTitle: 'Want to Invite User?' });
    };

    const handleClick = (item, name) => {
        if (name === 'DELETE_USER') {
            dispatch(userActions.deleteUser(item?.auth?.id));
        } else if (name === 'RESEND') {
            dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', item?.auth?.id));
            dispatch(userActions.resendInvite(item?.auth?.id));
        } else if (name === 'Activate' || name === 'Inactivate') {
            const status = name === 'Activate';
            dispatch(userActions.changeUserStatus(status, item?.auth?.id));
        }
    };

    const handleFilterStatus = (event) => {
        SaveParams(history, { ...info, status: event?.target?.defaultValue });
    };

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 10;
        if (info?.status && info?.status !== 'ALL') {
            filteredInfo.status = info.status;
        } else {
            delete filteredInfo.status;
        }
        delete filteredInfo.page;
        dispatch(userActions.getUsers({ ...filteredInfo }));
    }, [info, dispatch]);

    return (
        <div className="user-container page-container">
            <div className="desktop-view">
                <TableWrapper
                    firstButton={'Active'}
                    secondButton={'Inactive'}
                    addButton={'Invite User'}
                    buttonsTab={true}
                    tab={false}
                    buttonsTabAddButton={true}
                    handleIniteUser={handleInviteUser}
                    wrapperTitile={title}>
                    <FullTable
                        head={usersHead}
                        body={RolePermissions(activePackage) ? usersBody : usersBodyNoPackage}
                        loadingType={ACTION_TYPE}
                        list={userList?.users}
                        listCount={userList?.count}
                        noText={title}
                        handleClickButton={handleClick}
                    />
                </TableWrapper>
            </div>
            <div className="mobile-view">
                <div className="wrapper-header">
                    <div className="header-title">
                        <h4 className="title">{title}</h4>
                    </div>
                    <div className="header-btn">
                        <div className="filter-menu">
                            <FilterMenu
                                title={'Filter the Status'}
                                list={userStatus}
                                status={info?.status}
                                handleFilterStatus={handleFilterStatus}
                            />
                        </div>
                        <CreateMobileButton handleClick={handleInviteUser} />
                    </div>
                </div>

                <UsersMobile list={userList} />
            </div>
        </div>
    );
};
