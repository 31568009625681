/**  Types */

/** Driver Actions */
export const CREATE_DRIVERS = 'CREATE_DRIVERS';

export const GET_DRIVERS = 'GET_DRIVERS';
export const GET_DRIVERS_SUCCESS = 'GET_DRIVERS_SUCCESS';

export const EDIT_DRIVER_DETAILS = 'EDIT_DRIVER_DETAILS';

export const GET_DRIVER_DETAILS = 'GET_DRIVER_DETAILS';
export const GET_DRIVER_DETAILS_SUCCESS = 'GET_DRIVER_DETAILS_SUCCESS';

export const CHANGE_DRIVER_STATUS = 'CHANGE_DRIVER_STATUS';

export const DELETE_DRIVERS = 'DELETE_DRIVERS';
/** End */

/** Driver Rate */
export const EDIT_DRIVERS_RATE = 'EDIT_DRIVERS_RATE';

export const GET_DRIVERS_RATE = 'GET_DRIVERS_RATE';
export const GET_DRIVERS_RATE_SUCCESS = 'GET_DRIVERS_RATE_SUCCESS';
/** End */

/** Drivers Fuel Types. */
export const DRIVER_CREATE_FUEL = 'DRIVER_CREATE_FUEL';

export const DRIVER_EDIT_FUEL = 'DRIVER_EDIT_FUEL';

export const DRIVER_DELETE_FUEL = 'DRIVER_DELETE_FUEL';
/** End */

/** Driver Documents */
export const CREATE_DRIVER_DOCUMENT = 'CREATE_DRIVER_DOCUMENT';

export const GET_DRIVER_DOCUMENT = 'GET_DRIVER_DOCUMENT';
export const GET_DRIVER_DOCUMENT_SUCCESS = 'GET_DRIVER_DOCUMENT_SUCCESS';

export const EDIT_DRIVER_DOCUMENT_ARCHIVE = 'EDIT_DRIVER_DOCUMENT_ARCHIVE';

export const DELETE_DRIVER_DOCUMENT = 'DELETE_DRIVER_DOCUMENT';

// ReInvite Driver.
export const REIVITE_DRIVERS = 'REIVITE_DRIVERS';
