import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ButtonsTab } from '../buttons/tab';
import { RolePermissions } from 'utils';

export const TableWrapper = ({
    buttonsTab,
    buttonsTabAddButton,
    children,
    firstButton,
    secondButton,
    addButton,
    wrapperTitile,
    tab,
    handleIniteUser,
}) => {
    const history = useHistory();
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));

    return (
        <div>
            <div className="wrapper-header">
                <div className="header-title">
                    <h4 className="title">{wrapperTitile}</h4>
                </div>
                <div className="header-btn">
                    <div className="wrapper-btn">
                        <button onClick={() => handleIniteUser()} className="add-button-style">
                            <p> + </p>
                        </button>
                    </div>
                </div>
            </div>
            {buttonsTab && (
                <div className="wrapper-box">
                    {tab && <ButtonsTab first={firstButton} second={secondButton} />}
                    {buttonsTabAddButton && RolePermissions(activePackage) &&
                        (
                        <div className="wrapper-btn">
                            <button onClick={() => handleIniteUser()} className="add-button-style">
                                <p style={{ marginRight: 10, fontSize: 22, marginBottom: 5 }}> + </p>
                                {addButton}
                            </button>
                        </div>
                    )}
                </div>
            )}
            {children}
        </div>
    );
};
