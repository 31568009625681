import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, CustomInput, SubmitButton } from 'components';
import { useForm } from 'react-hook-form';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, loadActions } from 'store';

export const CreateTruckLoad = () => {
    /**
     * Hooks.
     */

    const { close, params } = useModal();
    const ACTION_TYPE = params?.info ? 'EDIT_LOAD' : 'CREATE_LOAD';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            type: params?.info?.type,
            weight: params?.info?.weight,
            customerRate: params?.info?.customer?.rate,
            pickUp: params?.info?.pickUp,
            dropOff: params?.info?.dropOff,
            latestAddress: params?.info?.latestAddress,
            fuelCost: params?.info?.fuelCost,
            truckId: params?.id?.id,
        },
    });

    const onSubmit = (data) => {
        if (params?.info) {
            dispatch(loadActions.editLoad(data, params?.info?.id));
        } else {
            dispatch(loadActions.createLoad(data));
        }
    };

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        if (backError?.error === 'This Organization has a truck with this ID.') {
            setError('truckId');
        }
    }, [backError]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    return (
        <div className="load-modal">
            <div className="invite-container">
                <form onSubmit={handleSubmit(onSubmit)} className="form-messages">
                    <Grid container spacing={0} marginTop={2}>
                        <Grid item xs={12} md={4} lg={4}>
                            <CustomInput
                                name="type"
                                control={control}
                                rules={{ required: true }}
                                type="text"
                                label="Load Type"
                                placeholder={'Type'}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <CustomInput
                                name="weight"
                                control={control}
                                rules={{ required: true }}
                                type="number"
                                label="Load weight"
                                placeholder={'Weight'}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <CustomInput
                                name="customerRate"
                                control={control}
                                rules={{ required: true }}
                                type="number"
                                label="Customer Rate"
                                placeholder={'Rate'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <CustomInput
                                name="pickUp"
                                control={control}
                                rules={{ required: true }}
                                type="text"
                                label="Load Pick off"
                                placeholder={'Address'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <CustomInput
                                name="dropOff"
                                control={control}
                                rules={{ required: true }}
                                type="text"
                                label="Load Drop off"
                                placeholder={'Rate'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <CustomInput
                                name="latestAddress"
                                control={control}
                                rules={{ required: true }}
                                type="text"
                                label="Latest Address of Truck"
                                placeholder={'Address'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <CustomInput
                                name="fuelCost"
                                control={control}
                                rules={{ required: true }}
                                type="number"
                                label="Truck Fuel Cost"
                                placeholder={'Cost'}
                            />
                        </Grid>
                    </Grid>
                    <div className="footer-box">
                        <div className="submit-box">
                            <Button className="add-modal-button default-btn" color="info" onClick={() => close('ADD_LOAD')}>
                                <span className="button-text">Cancel</span>
                            </Button>
                        </div>
                        <div className="submit-box">
                            <Button className="add-modal-button green-btn" color="success" type="submit ">
                                <span className="button-text">Save</span>
                            </Button>
                        </div>
                    </div>
                    <div className="footer-box">
                        <div className="submit-box">
                            <Button className="add-modal-button default-btn" color="info" onClick={() => close('ADD_LOAD')}>
                                <span className="button-text">Cancel</span>
                            </Button>
                        </div>
                        <div className="submit-box">
                            <SubmitButton title={params ? 'Save' : 'Invite'} type="submit" actionType={ACTION_TYPE} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
