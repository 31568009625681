import React from 'react';
import { Box, Card, Divider } from '@mui/material';
import { FindLoad } from 'utils';
import { Loader, ActionTransaction, TextRow } from 'components';
import { DateRow } from '../table';

export const MobileCard = ({ action, handleClick, item, handleClickButton, actionType, userCount, poperList }) => {
    const loader = FindLoad(actionType);


    // const renderSwitch = (name) => {
    //     switch (name) {
    //         case 'ACTIVE':
    //             return 'Active';
    //         case 'INACTIVE':
    //             return 'Inactive';
    //         case 'IN_TRANSIT':
    //             return 'In Transit';
    //         case 'MAINTENANCE':
    //             return 'Maintenance';
    //         default:
    //             return 'Active';
    //     }
    // };

    const txnType = (name) => {
        switch (name) {
            case 'INITIAL_RATE':
                return 'Initial Rate';
            case 'RATE_ADJUSTMENT':
                return 'Rate Adjustment';
            case 'LATE_FEE':
                return 'Late Fee';
            case 'DETENTION':
                return 'Detention';
            case 'LAYOVER':
                return 'Layover';
            default:
                return name;
        }
    };

    const trailerType = (name) => {
        switch (name) {
            case 'OTHER':
                return 'Other';
            case 'FLATBED':
                return 'Flatbed';
            case 'DRY_VAN':
                return 'Dryvan';
            case 'REEFER':
                return 'Reefer';
            default:
        }
    };

    const StatusMobile = (info) => {
        return (
            <div style={{ height: '24px' }} className='flex status-box'>
                {info === 'ACTIVE' && <div className='title-active'> Active</div>}
                {info === 'PENDING' && <div className='title-pending'> Pending</div>}
                {info === 'INACTIVE' && <div className='title-inactive'> Inactive</div>}
                {info === 'DELIVERED' && <div className='title-deliverd'>Delivered</div>}
                {info === 'COMPLETE' && <div className='title-complete'>Complete</div>}
                {info === 'CANCELED' && <div className='title-canceld'>Canceled</div>}
                {info === 'HOLD' && <div className='title-hold'>Hold</div>}
                {info === 'TERMINATED' && <div className='title-terminated'>Terminated</div>}
                {info === 'RECRUITMENT' && <div className='title-recruitment'>Recruitment</div>}
            </div>
        );
    };

    if (loader?.length) return <Loader />;

    return (
        <Card variant='outlined' className='user-card' onClick={handleClick}>

            {actionType === 'GET_TRUCK' && (
                <Box className='card-content'>
                    <div className='flex'>
                        <span className='text-green text-style'>
                            <TextRow name={item?.name} textWidth={8} />
                        </span>
                    </div>
                    <div style={{ margin: '4px 0' }} className='flex'>
                        <span className='text-bold'>ID:</span>
                        <span className='text-space' />
                        <span className='text-unic'>{item?.displayId}</span>
                    </div>
                    <div className='flex'>
                        <span className='text-bold'>Color:</span>
                        <span className='text-space' />
                        <span className='text-unic text-style'>
                             <TextRow name={item?.color ? item?.color : 'Not Set'} textWidth={8} />
                        </span>
                    </div>
                    <div style={{ marginTop: '4px' }} className='space-between'>
                        <span className='text-unic'> Status</span>
                        {item?.status === 'ACTIVE' && <span className='text-active'> Active</span>}
                        {item?.status === 'PENDING' && <span className='text-pending'> Pending</span>}
                        {item?.status === 'INACTIVE' && <span className='text-inactive'> Inactive</span>}
                        {item?.status === 'IN_TRANSIT' && <span className='text-transit'> In Transit</span>}
                        {item?.status === 'MAINTENANCE' && <span className='text-maintence'> Maintenance</span>}
                        {item?.status === 'AVAILABLE' && <span className='text-active'> Available</span>}
                    </div>
                </Box>
            )}
            {actionType === 'GET_DRIVERS' && (
                <Box className='card-content'>
                    <div className='flex'>
                        <span className='text-green'>
                              <TextRow
                                  name={`${item?.firstName ? item?.firstName : ''} ${item?.lastName ? item?.lastName : ''}`}
                                  textWidth={12} color={'#0E9594'} />
                        </span>
                    </div>
                    <div className='flex space-top' style={{ margin: '4px 0 0' }}>
                        <span className='text-bold'>Phone Number:</span>
                        <span className='text-space' />
                        <span className='text-unic'> {item?.phone}</span>
                    </div>
                    <div className='flex space-top'>
                        <span className='text-bold'>ID:</span>
                        <span className='text-space' />
                        <span className='text-unic'>{item?.displayId}</span>
                    </div>
                    <div className='space-between' style={{ margin: '4px 0 0' }}>
                        <span className='text-resault '> Status</span>
                        <span className='text-space' />
                        <span className='text-resault'>{StatusMobile(item.status)}</span>
                    </div>

                    <div className='space-between'>
                        <span className='text-resault '> App Invite</span>
                        <span className='text-space' />
                        <span className='text-resault'>{StatusMobile(item.appState)}</span>
                    </div>
                </Box>
            )}
            {actionType === 'GET_TRAILER' && (
                <Box className='card-content'>
                    <div className='flex'>
                        <span className='text-green'>{item?.displayId}</span>
                    </div>
                    <div className='flex space-top'>
                        <span className='text-bold'>Model:</span>
                        <span className='text-space' />
                        <span className='text-unic text-style' style={{ color: '#506C84' }}>
                             <TextRow name={item?.model} textWidth={8} />
                        </span>
                    </div>
                    <div className='flex space-top'>
                        <span className='text-bold'>Type:</span>
                        <span className='text-space' />
                        <span className='text-unic'>{item?.type ? <>{trailerType(item.type)}</> : 'Not Yet'}</span>
                    </div>
                    <div className='flex space-top space-between'>
                        <span className='text-bold'>Status:</span>
                        <span className='text-space' />
                        {item?.status === 'ACTIVE' ? (
                            <span className='title-active'>Active</span>
                        ) : (
                            <span className='title-inactive'>Inactive</span>
                        )}
                    </div>
                </Box>
            )}
            {action === 'GET_TRANSACTION' && (
                <Box className='card-content transaction-card'>
                    <div className='card-load'>
                        <div className='flex'>
                            <span className='text-bold'>Load ID: </span>
                            <span className='text-space' />
                            <span className='text-info'>{item.displayId}</span>
                        </div>
                        <div className='flex mobile-text '>
                            <span className={`text-bold ${item?.amount < 0 ? 'text-error' : ''} `}>
                                {item.amount ? (
                                    <>
                                        {item?.amount < 0 ? '-' : '+ '}
                                        {'$'}
                                        {item.amount}{' '}
                                    </>
                                ) : (
                                    'Not Set'
                                )}
                            </span>
                        </div>
                    </div>
                    <Divider className='divider' />
                    <div className='card-type'>
                        <div className='flex desktop-box'>
                            <span className='text-bold'>Amount: </span>
                            <span className='text-space' />
                            <span className='text-info'>{item.amount ? <>+${item.amount}</> : 'Not Yet'}</span>
                        </div>
                        <div className='flex'>
                            <span className='text-bold'>Type: </span>
                            <span className='text-space' />
                            <span className='text-info'>{item?.type ? <>{txnType(item.type)}</> : 'Not Yet'}</span>
                        </div>
                        <div className='flex'>
                            <span className='text-bold'>Date: </span>
                            <span className='text-space' />
                            <span className='text-info'>{item.createdAt ?
                                <DateRow date={item.createdAt} /> : 'Not Yet'}</span>
                        </div>
                    </div>
                    <div className='card-descrption'>
                        <span className='text-bold'>Description:</span>
                        <span className='text-space' />
                        <span className='text-info'>{item?.description ? item.description : 'Not Yet'}</span>
                    </div>
                    <div className='card-footer'>
                        <div className='flex action-box'>
                            <ActionTransaction name={item} />
                        </div>
                    </div>
                </Box>
            )}
            {actionType === 'GET_CUSTOMER' && (
                <Box className='card-content'>
                    <div style={{ width: 'auto' }}>
                        <span className='text-green'>ID:</span>
                        <span className='text-space' />
                        <span className='text-green'>{item?.displayId}</span>
                    </div>
                    <div className='flex' style={{ margin: '4px 0' }}>
                        <span className='text-bold'>Name:</span>
                        <span className='text-space' />
                        <span className='text-unic' style={{ width: '100%' }}>
                               <TextRow name={item?.name} textWidth={12} />
                        </span>
                    </div>
                    <div className='flex' style={{ marginBottom: '4px' }}>
                        <span className='text-bold'>MC Number:</span>
                        <span className='text-space' />
                        <span className='text-unic'> {item?.mcNumber}</span>
                    </div>
                    <div className='space-between'>
                        <span className='text-bold'> Status</span>
                        {item?.status === 'ACTIVE' && <span className='text-active'> Active</span>}
                        {item?.status === 'PENDING' && <span className='text-pending'> Pending</span>}
                        {item?.status === 'INACTIVE' && <span className='text-inactive'> Inactive</span>}
                        {item?.status === 'IN_TRANSIT' && <span className='text-transit'> In Transit</span>}
                        {item?.status === 'MAINTENANCE' && <span className='text-maintence'> Maintenance</span>}
                        {item?.status === 'AVAILABLE' && <span className='text-active'> Available</span>}
                        {item?.status === 'DO_NOT_USE' && <span className='text-not'> Do Not Use</span>}
                    </div>
                </Box>
            )}
        </Card>
    );
};
