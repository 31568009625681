/* Action */

import {
    GET_LOAD,
    GET_LOAD_ID,
    CREATE_LOAD,
    EDIT_LOAD,
    DELETE_LOAD,
    CHANGE_LOAD_STATUS,
    GET_LOAD_TRUCK_ID,
    CREATE_LOAD_ID_STOP,
    EDIT_LOAD_ID_STOP_ID,
    DELETE_LOAD_ID_STOP_ID,
    CREATE_LOAD_ID_DOCUMENT,
    GET_LOAD_ID_DOCUMENT,
    EDIT_LOAD_DOCUMENT_ARCHIVE,
    DELETE_LOAD_DOCUMENT,
    CREATE_TRANSACTION,
    EDIT_TRANSACTION_ACTION,
    GET_TRANSACTION,
    CANCEL_LOAD, GET_LOAD_COASTS,
} from './load.type';

/* Get Load */
export const getLoad = (body, load) => {
    return {
        type: GET_LOAD,
        payload: { body, load },
    };
};

/* Create Load */
export const createLoad = (body) => {
    return {
        type: CREATE_LOAD,
        payload: { body },
    };
};

/* Edit Load */
export const editLoad = (body, id) => {
    return {
        type: EDIT_LOAD,
        payload: { body, id },
    };
};

/* Delete Load */
export const deleteLoad = (id) => {
    return {
        type: DELETE_LOAD,
        payload: id,
    };
};

/* Change Status Load */
export const changeStatus = (id, status) => {
    return {
        type: CHANGE_LOAD_STATUS,
        payload: { id, status },
    };
};

export const cancelStatus = (id, status) => {
    return {
        type: CANCEL_LOAD,
        payload: { id, status },
    };
};

/* Get Load Id */
export const getLoadId = (id) => {
    return {
        type: GET_LOAD_ID,
        payload: { id },
    };
};

/* Get Load Id */
export const getLoadTruckId = (id) => {
    return {
        type: GET_LOAD_TRUCK_ID,
        payload: { id },
    };
};

/* Create Load Id Stop */
export const createLoadIdStop = (body, id) => {
    return {
        type: CREATE_LOAD_ID_STOP,
        payload: { body, id },
    };
};

/* Edit Load Id Stop Stop Id */
export const editLoadIdStopId = (body, id, stopId) => {
    return {
        type: EDIT_LOAD_ID_STOP_ID,
        payload: { body, id, stopId },
    };
};

/* Delete Load Id Stop StopId */
export const deleteLoadsStopId = (id, stopId) => {
    return {
        type: DELETE_LOAD_ID_STOP_ID,
        payload: { id, stopId },
    };
};

/** Loads Document */

export const createDocumentLoad = (id, body) => {
    return {
        type: CREATE_LOAD_ID_DOCUMENT,
        payload: { id, body },
    };
};

export const getDocumentLoad = (id) => {
    return {
        type: GET_LOAD_ID_DOCUMENT,
        payload: { id },
    };
};

export const editDocumentIdArchive = (body, id, docId, archive) => {
    return {
        type: EDIT_LOAD_DOCUMENT_ARCHIVE,
        payload: { body, id, docId, archive },
    };
};

export const deleteDocumentDocId = (id, docId) => {
    return {
        type: DELETE_LOAD_DOCUMENT,
        payload: { id, docId },
    };
};

/** End */

/* Load Transaction Action */
export const createTxn = (body) => {
    return {
        type: CREATE_TRANSACTION,
        payload: { body },
    };
};

export const editTxnAction = (id) => {
    return {
        type: EDIT_TRANSACTION_ACTION,
        payload: { id },
    };
};

export const getLoadTxn = (id, body) => {
    return {
        type: GET_TRANSACTION,
        payload: { id, body },
    };
};

export const getLoadCosts = (id) => {
    return{
        type:GET_LOAD_COASTS,
        payload: { id }
    }
}