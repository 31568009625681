/* User Reducer */

import { GET_USER_SUCCESS } from './user.type';

const initialState = {
    userList: [],
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_SUCCESS:
            return {
                ...state,
                userList: action.payload,
            };

        default:
            return state;
    }
};
