import React from 'react';
import { Container } from '@mui/material';

export const TermsPage = () => {
    /**
     *  Terms Page.
     */

    return (
        <div className="privacy-page">
            <Container maxWidth="lg">
                <div className="page-info">
                    <div className="title-box">
                        <h3 className="privacy-title">
                            Terms
                            <span className="space">&</span>
                            Conditions
                        </h3>
                    </div>
                    <div className="privacy-body">
                        <p className="privacy-subtitle">
                            1.Introduction <br />
                            These Terms and Conditions govern your use of our truck management software. By using our software, you agree to
                            be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, you must not use our
                            software.
                        </p>
                        <p className="privacy-subtitle">
                            2. Use of Software <br />
                            Our software is designed to help manage truck fleets, track routes and deliveries, and optimize operations. You
                            may use our software only for lawful purposes and in accordance with these Terms and Conditions. You must not
                            use our software in any way that violates any applicable federal, state, local, or international law or
                            regulation
                        </p>
                        <p className="privacy-subtitle">
                            3. Use of Software <br />
                            Our software and its entire contents, features, and functionality are owned by us or our licensors and are
                            protected by copyright, trademark, and other intellectual property or proprietary rights laws.
                        </p>

                        <p className="privacy-subtitle">
                            4. Disclaimer of Warranties <br />
                            We do not guarantee that our software will be uninterrupted, error-free, or free from viruses or other harmful
                            components. We make no warranties or representations about the accuracy or completeness of the content provided
                            through our software.
                        </p>
                        <p className="privacy-subtitle">
                            5. Limitation of Liability <br />
                            In no event shall we be liable for any indirect, incidental, special, or consequential damages arising out of or
                            in connection with your use of our software.
                        </p>
                        <p className="privacy-subtitle">
                            6. Governing Law <br />
                            These Terms and Conditions shall be governed by and construed in accordance with the laws of [state/country],
                            without giving effect to any choice of law or conflict of law provisions.
                        </p>
                        <p className="privacy-subtitle">
                            7. Termination <br />
                            We may terminate or suspend your access to our software immediately, without prior notice or liability, for any
                            reason whatsoever, including without limitation if you breach these Terms and Conditions.
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
};
