import { inviteUser, deleteUser, editUser, getUsers, changeUserStatus, resendInvite } from "./user.action";
export { userReducer } from './user.reducer';
export { watchUser } from './user.saga';

export const userActions = {
    getUsers,
    inviteUser,
    editUser,
    deleteUser,
    changeUserStatus,
    resendInvite
};