import { INVITE_USER, EDIT_USER, DELETE_USER, GET_USER, CHANGE_USER_STATUS, RESEND_INVITE } from "./user.type";

export const getUsers = (body, load) => {
    return {
        type: GET_USER,
        payload: { body, load },
    };
};

export const inviteUser = (body) => {
    return {
        type: INVITE_USER,
        payload: { body },
    };
};

export const editUser = (body, id) => {
    return {
        type: EDIT_USER,
        payload: { body, id },
    };
};

export const deleteUser = (id) => {
    return {
        type: DELETE_USER,
        payload: id,
    };
};

export const changeUserStatus = (status, id) => {
    return {
        type: CHANGE_USER_STATUS,
        payload: { status, id },
    };
};

export const resendInvite = (id) => {
    return {
        type: RESEND_INVITE,
        payload: { id },
    };
};
