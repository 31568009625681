/** Types */
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const SIGN_UP = 'SIGN_UP';

export const SIGN_OUT = 'SIGN_OUT';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const RESET_PASSWORD = 'RESET_PASSWORD';

/** My Profile */
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';

export const EDIT_MY_PROFILE = 'EDIT_MY_PROFILE';
/** Edit */

/** My Profile */
export const COMPLETE_ACCOUNT = 'COMPLETE_ACCOUNT';

/** Edit */

/** Contact Us  */

export const CONTACT_US = 'CONTACT_US';
