import { DateRow, PriceRow, TextRow } from 'components';

export const maintenanceHead = [
    { name: 'date', title: 'Service Date', custom: false },
    { name: 'name', title: 'Service Name', custom: false },
    { name: 'vendor', title: 'Vendor', custom: false },
    { name: 'cost', title: 'Total Cost' },
];

export const maintenanceBody = [
    { rowText: (item) => <DateRow date={item?.serviceDate} /> },
    { rowText: (item) => <TextRow name={item?.serviceName || 'Not Set'} /> },
    { rowText: (item) => <TextRow name={item?.vendor ? item?.vendor : 'Not Set'} /> },
    { rowText: (item) => <PriceRow className="maintenance-price-row" info={item?.totalCost ? item?.totalCost : 0} /> },
];

export const ACTION_TYPE = 'GET_TRUCK_MAINTENANCE';
