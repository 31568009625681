import { OutlinedBtn } from 'components';
import React, { Fragment } from 'react';

export const StartFreeFragment = ({ handleClick }) => {
    /**
     * Start Free Fragment.
     */

    return (
        <Fragment>
            <div className="free-body">
                <div className="free-info">
                    <div className="title-box">
                        <h3 className="start-title">Start your 30 day free trial now</h3>
                    </div>
                    <div className="subtitle-box">
                        <p className="start-subtitle">Try First and Decide later, no credit card required</p>
                    </div>
                </div>
                <div className="free-box">
                    <OutlinedBtn title={'Start Trial'} handelClick={handleClick} />
                </div>
            </div>
        </Fragment>
    );
};
