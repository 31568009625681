/** Actions */
import axios from 'axios';

import { store } from './store';
import { httpRequestsOnLoadActions } from './http_requests_on_load';
export { authActions } from './auth';
export { userActions } from './user';
export { truckActions } from './truck';
export { driversActions } from './drivers';
export { loadActions } from './load';
export { trailerActions } from './trailers';
export { services } from './payment';
export { PaymentActions } from './payment';
export { noteActions } from './notes';

export { httpRequestsOnSuccessActions } from './http_requests_on_success';
export { httpRequestsOnErrorsActions } from './http_requests_on_errors';
export { store, httpRequestsOnLoadActions };
export const UploadServices = {
    UploadImage: async (formData) => {
        try {
            store.dispatch(httpRequestsOnLoadActions.appendLoading('UPLOAD_IMAGE'));
            return await axios.post('/files/upload?includeThumbnail=false', formData, { auth: true, params: '' });
        } finally {
            store.dispatch(httpRequestsOnLoadActions.removeLoading('UPLOAD_IMAGE'));
        }
    },
};
