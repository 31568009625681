import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CustomInput, CustomSelect, CardBtn } from 'components';
import { FindError, FindLoad, FindSuccess } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, trailerActions } from 'store';
import { trailersType } from '../constants';

export const Info = ({ params, paramsList }) => {
    const dispatch = useDispatch();
    const [editInput, setEditInput] = useState(true);
    const loadSave = FindLoad('EDIT_TRAILER');
    const backError = FindError('EDIT_TRAILER');
    const success = FindSuccess('EDIT_TRAILER');

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            make: paramsList?.make,
            model: paramsList?.model,
            currentMiles: paramsList?.currentMiles,
            type: paramsList?.type,
        },
    });

    useEffect(() => {
        if (paramsList) {
            handleDefault();
        }
    }, [paramsList]);

    useEffect(() => {
        if (success) {
            setEditInput(true);
            dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_TRAILER'));
        }
    }, [success]);

    const handleDefault = () => {
        reset({
            make: paramsList?.make,
            model: paramsList?.model,
            currentMiles: paramsList?.currentMiles,
            type: paramsList?.type,
        });
    };

    const handleEdit = () => {
        handleDefault();
        backError && dispatch(httpRequestsOnErrorsActions.removeError('EDIT_TRAILER'));
        setEditInput(!editInput);
    };

    const onSubmit = (data) => {
        const formData = {
            ...data,
        };
        formData.startingMiles = Number(data.startingMiles);
        formData.currentMiles = Number(data.currentMiles);
        dispatch(trailerActions.editTrailer(formData, params?.id));
        setEditInput(false);
    };

    const handleChancel = () => {
        handleEdit();
        reset({
            make: paramsList?.make,
            model: paramsList?.model,
            currentMiles: paramsList?.currentMiles,
            type: paramsList?.type,
        });
    };

    return (
        <div className='details-container'>
            <div className='details-form'>
                <form onSubmit={handleSubmit(onSubmit)} className='form-messages'>
                    <Box className='laod-space' />
                    <Box className='load-form'>
                        <Box className='basic-banner space-between'>
                            <div className='load-title basic-title'>
                                <h4 className='load-basic'>Basic Info</h4>
                            </div>
                            <CardBtn
                                handleChancel={handleChancel}
                                className={'basic-btn'}
                                editInput={editInput}
                                handleEdit={handleEdit}
                                load={loadSave}
                            />
                        </Box>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    name='model'
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={editInput}
                                    type='text'
                                    label='Model*'
                                    maxLength={40}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    name='make'
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={editInput}
                                    type='text'
                                    label='Make*'
                                    maxLength={40}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomSelect
                                    name={'type'}
                                    displayName={'label'}
                                    displayValue={'value'}
                                    control={control}
                                    disabled={editInput}
                                    rules={{ required: true }}
                                    listSelect={trailersType}
                                    label='Type*'
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomInput
                                    name='currentMiles'
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={editInput}
                                    type='number'
                                    label='Current Miles*'
                                    lastIcon={'mi'}
                                    step={'numeric'}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </div>
        </div>
    );
};
