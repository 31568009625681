import { call, put, takeLatest } from 'redux-saga/effects';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import {
    ACTIVATE_PACKAGE,
    CANCEL_SUBSCRIPTION,
    GET_CARD_INFO, GET_CARD_INFO_SUCCESS,
    GET_INVOICES,
    GET_INVOICES_SUCCESS,
    GET_MY_PROFILE_PACKAGE,
    GET_MY_PROFILE_PACKAGE_SUCCESS,
} from './payment.type';
import { paymentService } from './payment.service';

/** Payment */

function* activatePackage({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(paymentService.activatePackageService, payload);
        yield put({
            type: GET_MY_PROFILE_PACKAGE,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getMyProfilePackage({ type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(paymentService.getSubscriptionService);
        yield put({
            type: GET_MY_PROFILE_PACKAGE_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getInvoices({ type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(paymentService.getInvoicesService);
        yield put({
            type: GET_INVOICES_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getCardInfo({ type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(paymentService.getCardService);
        yield put({
            type: GET_CARD_INFO_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* cancelSubscription({ type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(paymentService.cancelSubscriptionService);
        yield put({
            type: GET_MY_PROFILE_PACKAGE,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

export const watchUserPayment = function* watchPaymentAuth() {
    /** Payment */
    yield takeLatest(ACTIVATE_PACKAGE, activatePackage);
    yield takeLatest(GET_MY_PROFILE_PACKAGE, getMyProfilePackage);
    yield takeLatest(GET_INVOICES, getInvoices);
    yield takeLatest(GET_CARD_INFO, getCardInfo);
    yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubscription);
    /** Edit */
};
