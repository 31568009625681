import React from 'react';
import { FullTable, TableWrapper } from 'components';
import { ACTION_TYPE, loadsHead, loadsBody } from '../constants';
import { Box } from '@mui/material';
import { FindLoad, FREE_PACKAGE, SECOND_PACKAGE } from 'utils';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const LoadsTable = ({ tab, title, indexTab, loadList, info }) => {
    /**
     * Hooks.
     */
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));
    const loader = FindLoad(ACTION_TYPE ? ACTION_TYPE : '');
    const history = useHistory();

    return (
        <Box value={tab} index={indexTab}>
            <div className="loads-container">
                <div>
                    <div className="loads-table">
                        <TableWrapper
                            firstButton={'Active'}
                            secondButton={'Inactive'}
                            addButton={'Add Load'}
                            buttonsTab={true}
                            tab={false}
                            buttonsTabAddButton={false}
                            wrapperTitile={title}
                        >
                            <FullTable
                                head={loadsHead(activePackage)}
                                body={loadsBody(activePackage)}
                                loadingType={info?.truck ? 'GET_LOAD_TRUCK_ID' : ACTION_TYPE}
                                list={loadList?.loads}
                                listCount={loadList?.count}
                                noText={title}
                                handleClick={(id) => history.push({ pathname: `/load/${id}` })}
                            />
                        </TableWrapper>
                    </div>
                </div>
            </div>
        </Box>
    );
};
