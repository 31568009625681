import { Svg } from '../../../assets/images';

export const perList = [
    {
        costId: 0,
        title: 'Tire Costs',
        svg: Svg.TireIcon,
        total: false,
        type: 'total',
        name: 'tireCosts',
    },
    {
        costId: 1,
        title: 'Brake Costs',
        svg: Svg.BrakeIcon,
        total: false,
        name: 'brakeCosts',
        type: 'total',
    },

    {
        costId: 2,
        title: 'Deprecation',
        svg: Svg.DeprecationIcon,
        total: false,
        type: 'total',
        name: 'deprecation',
    },
    {
        costId: 4,
        title: 'Other per miles cost',
        svg: Svg.PerIcon,
        total: false,
        name: 'otherMileCost',
    },
    {
        costId: 5,
        title: 'Total Amount',
        total: true,
        totalNumber: 'Per',
    },
];

export const fixList = [
    {
        title: 'Parking',
        svg: Svg.ParkingIcon,
        total: false,
        name: 'parkingCost',
        text: 'per month',
    },
    {
        constId: 1,
        title: 'Insurance',
        svg: Svg.InsuranceIcon,
        total: false,
        name: 'insuranceCost',
        text: 'per month',
    },

    {
        constId: 2,
        title: 'Other monthly Costs',
        svg: Svg.PerIcon,
        total: false,
        name: 'otherFixCost',
        text: 'per month',
    },

    {
        constId: 3,
        title: 'Total Amount',
        total: true,
        text: 'per month',
        totalNumber: 'Fix',
    },
];