import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Svg } from 'assets/images';
import { MODAL_NAMES, RolePermissions } from 'utils';
import { DateRow, useModal, NoDataComponent, Loader, PriceRow } from 'components';
import { Box, Grid } from '@mui/material';
import { ratesType } from '../constants';

export const DriverRate = ({ driversRateList, rateList }) => {
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));

    const { open } = useModal();

    const handleCreateDriver = () => {
        open(MODAL_NAMES.ADD_DRIVER_RATE, { modalTitle: 'Add Driver Rate' });
    };

    const handleSeePast = () => {
        open(MODAL_NAMES.POST_RATES, {
            modalTitle: 'Past Rates',
        });
    };

    return (
        <Box className='details-container'>
            <div className='rate-container'>
                <div className='space-between'>
                    <div className='driver-title'>
                        <h4 className='title-basic'>Driver Rate</h4>
                    </div>
                    <div className='basic-title'>
                        {RolePermissions(activePackage) && (
                            <button className='add-rate' onClick={handleCreateDriver}>
                                <span>
                                    <img src={Svg.AddStepIcon} alt='Add Step Icon' />
                                </span>
                                <span className='rate-title'>
                                    Add <span className='desktop-text'>Rate</span>
                                </span>
                            </button>
                        )}
                    </div>
                </div>
                {rateList?.rate ? (
                    <Grid container rowGap={1} className='rate-body'>
                        <Grid item xs={12} md={4} className='rate-box'>
                            <div className='flex'>
                                <div className='rate-icon'>
                                    <img src={Svg.StartTime} alt='Start Time' />
                                </div>
                                <div className='rate-text flex-full-width'>
                                    <span className='rate-mobile-title'>Start Date:</span>
                                    <span className='past-rate-subtitle '>
                                       <DateRow date={rateList?.startDate} />
                                     </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} className='rate-box text-style'>
                            <div className='flex-full-width text-style'>
                                <div className='rate-icon'>
                                    <img src={Svg.ReteIcon} alt='Rate Icon' />
                                </div>
                                <div className='rate-text flex-full-width text-style'>
                                    <span className='rate-mobile-title'>Rate:</span>
                                    <span className='past-rate-subtitle text-style' style={{ width: '100%' }}>
                                      <PriceRow info={rateList?.rate ? rateList?.rate : 0} />
                                </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} className='rate-box'>
                            <div className='flex'>
                                <div className='rate-icon'>
                                    <img src={Svg.SecurityTime} alt='Security Time' />
                                </div>
                                <div className='rate-text flex-full-width'>
                                    <span className='rate-mobile-title'>Type:</span>
                                    <span className='past-rate-subtitle'>{rateList?.mode ? ratesType(rateList?.mode) : 'Not Set'}</span>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <Fragment>
                        {rateList ? (
                            <div style={{ height: '100%' }}>
                                <NoDataComponent />
                            </div>
                        ) : (
                            <Loader />
                        )}
                    </Fragment>
                )}
                {driversRateList.length > 1 && driversRateList?.[0]?.startDate && driversRateList?.[0]?.startDate ? (
                    <div className='post-container'>
                        <div className=''>
                            <button className='post-btn' onClick={handleSeePast}>
                                <span className='post-title'>See Past Rates</span>
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </Box>
    );
};
