import React from 'react';
import { CircularProgress } from '@mui/material';

export const Loader = ({ style, height }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                height: height ? height : '60vh',
                width: '100%',
                justifyContent: 'center',
            }}>
            <CircularProgress
                style={{
                    width: '100px',
                    height: '100px',
                    position: style ? style : 'relative',
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: '#0e9594',
                }}
            />
        </div>
    );
};
