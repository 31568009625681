import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useModal, SubmitButton, CustomInput } from 'components';
import { FindSuccess, MODAL_NAMES, PasswordValidator } from 'utils';
import { Svg } from 'assets/images';
import { authActions, httpRequestsOnSuccessActions } from 'store';

export const ResetModal = () => {
    /**
     * Reset Password Modal.
     */

    const complete = window.location.search.search('token=') !== -1;
    const link = window.location.search;
    const ACTION_TYPE = complete ? 'COMPLETE_ACCOUNT' : 'RESET_PASSWORD';
    const { open, close } = useModal();
    const dispatch = useDispatch();
    const success = FindSuccess(ACTION_TYPE);
    const [token, setToken] = useState('');

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        newPassword: '',
        confirmation: '',
    });

    useEffect(() => {
        if (success) {
            close('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            open(MODAL_NAMES.CANGRATS_RESET);
        }
    }, [success]);

    useEffect(() => {
        if (link.search('resetToken=') !== -1) {
            const pos = link.slice(link.search('resetToken='));
            const resetToken = pos.substring(11);
            setToken(resetToken);
        }
        if (link.search('token=') !== -1) {
            const pos = link.slice(link.search('token='));
            const registerToken = pos.substring(6);
            setToken(registerToken);
        }
    }, []);

    const onSubmit = (data) => {
        if (data?.newPassword === data?.rewritePassword) {
            const passwords = {
                newPassword: data.newPassword,
                confirmation: data?.rewritePassword,
                token: token,
            };
            if (complete) {
                dispatch(authActions.completeAccount(passwords));
            } else {
                dispatch(authActions.resetPassword(passwords));
            }
        } else {
            setError('rewritePassword', { type: 'notEqual' });
        }
    };

    return (
        <div className="modal-width">
            <div className="enter-emile-title">
                <p>
                    {complete
                        ? 'You have been invited to join FleetForest as an admin.Please create password for your account'
                        : 'Use at least 8 characters, 1 upper case and 1 digit.'}
                </p>
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomInput
                        imgSrc={errors?.password ? Svg.LockRed : Svg.Lock}
                        name="newPassword"
                        placeholder="New Password"
                        control={control}
                        rules={{
                            required: true,
                            pattern: {
                                value: PasswordValidator,
                                message: 'Not valid Password',
                            },
                        }}
                        type="password"
                    />
                    <CustomInput
                        imgSrc={errors?.password ? Svg.LockRed : Svg.Lock}
                        name="rewritePassword"
                        placeholder="Rewrite Password"
                        control={control}
                        rules={{
                            required: true,
                            pattern: {
                                value: PasswordValidator,
                                message: 'Not valid Password',
                            },
                        }}
                        type="password"
                        errMessage={errors?.rewritePassword?.type === 'notEqual' && 'Password and confirm password does not match'}
                    />
                    <SubmitButton type="submit" title="Done" actionType={ACTION_TYPE} />
                </form>
            </div>
        </div>
    );
};
