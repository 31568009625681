import { call, put, takeLatest } from 'redux-saga/effects';
import { customersService } from './customers.service';
import {
    GET_CUSTOMER,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_CONTACTS,
    GET_CUSTOMER_CONTACTS_SUCCESS,
    GET_CUSTOMER_ID,
    GET_CUSTOMER_ID_SUCCESS,
    EDIT_CUSTOMER,
    CREATE_CUSTOMER_CONTACT,
    EDIT_CUSTOMER_CONTACT,
    CREATE_CUSTOMER,
    CHANGE_CUSTOMER_STATUS,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_CONTACT,
} from './customers.type';
import { httpRequestsOnErrorsActions } from 'store/http_requests_on_errors';
import { httpRequestsOnLoadActions } from 'store/http_requests_on_load';
import { httpRequestsOnSuccessActions } from 'store/http_requests_on_success';

/** Customer Type */
function* createCustomer({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(customersService.createCustomer, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getCustomer({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(customersService.getCustomer, payload?.body);
        yield put({
            type: GET_CUSTOMER_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editCustomer({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(customersService.editCustomer, payload.body, payload.id);
        yield put({
            type: GET_CUSTOMER_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getCustomerId({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(customersService.getCustomerId, payload?.body, payload.id);
        yield put({
            type: GET_CUSTOMER_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteCustomer({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(customersService.deleteCustomer, payload.id);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* changeCustomerStatus({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(customersService.changeStatusCustomer, payload.id, payload.status);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End */

/** Customer Contact */
function* createCustomerContact({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(customersService.createCustomerContact, payload?.body, payload?.id);
        yield put({
            type: GET_CUSTOMER_CONTACTS,
            payload: { id: payload.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editCustomerContact({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(customersService.editCustomerContact, payload.body, payload.id, payload?.contactId);
        yield put({
            type: GET_CUSTOMER_CONTACTS,
            payload: { id: payload.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getCustomerContacts({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(customersService.getCustomerIdContact, payload.id);
        yield put({
            type: GET_CUSTOMER_CONTACTS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteCustomerContact({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(customersService.deleteCustomerContact, payload?.customerId, payload.id);
        yield put({
            type: GET_CUSTOMER_CONTACTS,
            payload: { id: payload.customerId },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End */

export const watchCustomers = function* watchCustomersAuth() {
    /** Customer Type */
    yield takeLatest(CREATE_CUSTOMER, createCustomer);
    yield takeLatest(GET_CUSTOMER, getCustomer);
    yield takeLatest(EDIT_CUSTOMER, editCustomer);
    yield takeLatest(GET_CUSTOMER_ID, getCustomerId);
    yield takeLatest(DELETE_CUSTOMER, deleteCustomer);
    yield takeLatest(CHANGE_CUSTOMER_STATUS, changeCustomerStatus);
    /** End */
    /** Customer Contact */
    yield takeLatest(CREATE_CUSTOMER_CONTACT, createCustomerContact);
    yield takeLatest(EDIT_CUSTOMER_CONTACT, editCustomerContact);
    yield takeLatest(GET_CUSTOMER_CONTACTS, getCustomerContacts);
    yield takeLatest(DELETE_CUSTOMER_CONTACT, deleteCustomerContact);
    /** End */
};
