/* Action */

import {
    CREATE_TRUCK,
    GET_TRUCK,
    CHANGE_TRUCK_STATUS,
    EDIT_TRUCK_DETAILS,
    GET_TRUCK_DETAILS,
    DELETE_TRUCK,
    CREATE_TRUCK_DOCUMENT,
    GET_TRUCK_DOCUMENT,
    EDIT_TRUCK_DOCUMENT_ARCHIVE,
    DELETE_TRUCK_DOCUMENT,
    GET_TRUCK_LOADS,
    CREATE_TRUCK_COSTS,
    GET_TRUCK_COSTS,
    REMOVE_TRUCK_DETAILS,
    GET_TRUCK_MAINTENANCE,
    CREATE_TRUCK_MAINTENANCE_SERVICE,
    UPDATE_TRUCK_MAINTENANCE_SERVICE,
} from './truck.type';

/* Create Truck */
export const createTruck = (body) => {
    return {
        type: CREATE_TRUCK,
        payload: { body },
    };
};

/* Get Truck */
export const getTruck = (body, load) => {
    return {
        type: GET_TRUCK,
        payload: { body, load },
    };
};

/* Change Truck Status */
export const changeTruckStatus = (id, status) => {
    return {
        type: CHANGE_TRUCK_STATUS,
        payload: { id, status },
    };
};

/* Edit Truck Details */
export const editTruckDetails = (body, id) => {
    return {
        type: EDIT_TRUCK_DETAILS,
        payload: { body, id },
    };
};

/* Get Truck Details */
export const getTruckDetails = (id) => {
    return {
        type: GET_TRUCK_DETAILS,
        payload: { id },
    };
};

export const removeTruckDetails = () => {
    return {
        type: REMOVE_TRUCK_DETAILS,
    };
};

/* Delete Truck */
export const deleteTruck = (id) => {
    return {
        type: DELETE_TRUCK,
        payload: id,
    };
};

export const truckLoads = (id, info) => {
    return {
        type: GET_TRUCK_LOADS,
        payload: { id, info },
    };
};

/** Truck Documents */

export const createTruckDocument = (id, body) => {
    return {
        type: CREATE_TRUCK_DOCUMENT,
        payload: { id, body },
    };
};

export const getTruckDocument = (id) => {
    return {
        type: GET_TRUCK_DOCUMENT,
        payload: { id },
    };
};

export const editTruckDocumentArchive = (id, docId, archive) => {
    return {
        type: EDIT_TRUCK_DOCUMENT_ARCHIVE,
        payload: { id, docId, archive },
    };
};

export const deleteTruckDocument = (id, docId) => {
    return {
        type: DELETE_TRUCK_DOCUMENT,
        payload: { id, docId },
    };
};

/** End */

/* Create Truck Costs */
export const createTruckCosts = (id, body) => {
    return {
        type: CREATE_TRUCK_COSTS,
        payload: { id, body },
    };
};

/* Get Truck Costs */
export const getTruckCosts = (id, body, load) => {
    return {
        type: GET_TRUCK_COSTS,
        payload: { id, body, load },
    };
};

export const getTruckMaintenance = ({ id, skip, limit }) => {
    return {
        type: GET_TRUCK_MAINTENANCE,
        payload: { skip, limit, id },
    };
};

export const createTruckMaintenanceService = (values) => {
    return {
        type: CREATE_TRUCK_MAINTENANCE_SERVICE,
        payload: values,
    };
};
export const updateTruckMaintenanceService = (id, values) => {
    return {
        type: UPDATE_TRUCK_MAINTENANCE_SERVICE,
        payload: { id, values },
    };
};
export const uploadTruckMaintenanceDocument = (id, file) => {
    return {
        type: CREATE_TRUCK_MAINTENANCE_SERVICE,
        payload: { id, file },
    };
};
