import { Svg } from 'assets/images';
export const docLogo = (type) => {
    if (!type) return Svg.File;
    const file_type = type?.split('.')?.at(-1);
    switch (file_type) {
        case 'pdf':
            return Svg.Pdf;
        case 'jpeg':
            return type;
        case 'jpg':
            return type;
        case 'png':
            return type;
        case 'svg':
            return type;
        default:
            return Svg.File;
    }
};
