import React, { useEffect, useState } from 'react';
import { MinLoader, ModalHeader, CustomTextArea, CustomInput, DatePickerInput } from 'components';
import { FindLoad, FindSuccess, useModalBase } from 'utils';
import { useForm } from 'react-hook-form';
import { Box, Tab, Tabs } from '@mui/material';
import { useDispatch } from 'react-redux';
import { truckActions } from 'store';
import { useLocation } from 'react-router-dom';
import { httpRequestsOnSuccessActions } from 'store';
import { UploadDocument } from './uploadDocument';

const create_type = 'CREATE_TRUCK_MAINTENANCE_SERVICE';
const update_type = 'UPDATE_TRUCK_MAINTENANCE_SERVICE';

export const AddMaintenance = ({ initialValues }) => {
    const dispatch = useDispatch();
    const { closeModal } = useModalBase();
    const [valueTab, setValueTab] = useState('details');
    const location = useLocation();
    const truck_id = location.pathname.split('/').at(-1);

    const [documents, setDocuments] = useState(initialValues?.documents || []);
    const create_success = FindSuccess(create_type);
    const update_success = FindSuccess(update_type);
    const create_loading = FindLoad(create_type);
    const update_loading = FindLoad(update_type);
    const upload_image_loading = FindLoad('UPLOAD_IMAGE');
    const {
        control,
        handleSubmit,
        setValue,
        formState: {},
    } = useForm({
        defaultValues: {
            truckId: truck_id,
            ...initialValues,
        },
    });

    const onSubmit = (values) => {
        if (!upload_image_loading) {
            const data = {
                ...values,
                serviceDate: new Date(values.serviceDate).toISOString(),
            };

            if (initialValues?.id) {
                dispatch(truckActions.updateTruckMaintenanceService(initialValues.id, data));
            } else {
                dispatch(truckActions.createTruckMaintenanceService(data));
            }
        }
    };

    const handleUploadFile = (dock, type) => {
        if (type === 'remove') {
            const new_array = documents.filter((elem) => elem.id !== dock.id);
            setDocuments(new_array);
            setValue('documents', new_array);
        } else {
            const file = {
                file: {
                    id: dock?.id,
                    url: dock?.url,
                    thumbUrl: dock?.url,
                    name: dock?.name,
                },
                id: dock?.id,
            };
            const docs = [...documents, file];
            setDocuments(docs);
            setValue('documents', docs);
        }
    };

    useEffect(() => {
        if (create_success?.data && create_success.type === create_type) {
            closeModal();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(create_type));
            dispatch(truckActions.getTruckMaintenance({ id: truck_id, skip: 0, limit: 20 }));
        }
    }, [create_success]);
    useEffect(() => {
        if (update_success?.data && update_success.type === update_type) {
            closeModal();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(update_type));
            dispatch(truckActions.getTruckMaintenance({ id: truck_id, skip: 0, limit: 20 }));
        }
    }, [update_success]);

    return (
        <div className="add-note-wrapper">
            <ModalHeader title={`${initialValues ? 'Edit' : 'Add'} Service`} />
            <div className="modal-body truck-info">
                <div className="truck-tab info-tab">
                    <Tabs
                        className="maintanence-tabs info-btns"
                        value={valueTab}
                        onChange={(_, value) => setValueTab(value)}
                        aria-label="basic tabs example">
                        <Tab className="tabs-item" label="Details" value="details" />
                        <Tab className="tabs-item" label="Files" value="files" />
                    </Tabs>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {valueTab === 'details' ? (
                        <>
                            <CustomInput
                                name="serviceName"
                                control={control}
                                rules={{ required: true }}
                                disabled={false}
                                type="text"
                                label="Service Name*"
                                placeholder={'Write Service Name'}
                                maxLength={40}
                            />{' '}
                            <CustomInput
                                name="vendor"
                                control={control}
                                rules={{ required: true }}
                                disabled={false}
                                type="text"
                                label="Vendor*"
                                placeholder={'Write Service Vendor'}
                                maxLength={40}
                            />
                            <div className="flex space-between flex-gap-16">
                                <DatePickerInput
                                    name="serviceDate"
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={false}
                                    type="date"
                                    label="Service Date*"
                                    placeholder={'Add Date'}
                                />
                                <CustomInput
                                    name="totalCost"
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={false}
                                    type="number"
                                    label="Total Cost*"
                                    placeholder={'0.00'}
                                    dollar={true}
                                    step={'numeric'}
                                />
                            </div>
                            <CustomTextArea
                                name={'note'}
                                id={'note'}
                                control={control}
                                defaultValue={''}
                                label={'Notes'}
                                placeholder={'Write Instruction...'}
                                maxLength={1000}
                                height="normal"
                                showMaxCount={true}
                            />
                        </>
                    ) : (
                        <UploadDocument onChange={handleUploadFile} docs={documents} />
                    )}

                    <div className="wrapper-btn flex space-between">
                        <button type="button" className="active-btn" style={{ height: '100%' }} onClick={() => closeModal()}>
                            <span>Cancel</span>
                        </button>
                        <Box className="btn-space" />
                        <button className="add-button-style">
                            {create_loading?.length || update_loading?.length ? (
                                <MinLoader color={'#0E9594'} />
                            ) : (
                                <span className="save-load">Save</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
