import { Divider } from '@material-ui/core';
import { Images,  } from 'assets/images';
import { MinLoader } from 'components';

export const PlansAndPricingCard = ({
    cardTitle,
    cardSubtitle,
    cardPrice,
    cardSubPrice,
    cardTime,
    cardActionText,
    cardActionHandler,
    loading,
    subscriptionPlan,
    cardList = [],
}) => {
    /**
     * PlansAndPricingCard
     */

    return (
        <div className="card">
            <p className="title">{cardTitle}</p>
            <p className="sub-title">{cardSubtitle}</p>
            <p className="type">
                {cardPrice && <span className="card-price">{cardPrice}</span>}
                {cardSubPrice && <span className="card-sub-price">{cardSubPrice}</span>}
                {cardTime && <span className="time">{cardTime}</span>}
            </p>

            {subscriptionPlan ? (
                <button className="subscribed-button">Subscribed</button>
            ) : (
                <button className="get-button" onClick={cardActionHandler}>
                    {loading ? <MinLoader color={'white'} /> : cardActionText}
                </button>
            )}
            <div className='pay-list'>
                <div>
                    {cardList?.items?.map((item, index) => (
                        <div key={index} className='icon-title-wrapper'>
                            <img src={Images.CheckGreen} alt='Check-Green' />
                            <p>{item?.text} </p>
                        </div>
                    ))}
                </div>
                <Divider />
                <div style={{marginTop:'16px'}}>
                    {cardList?.notSupport?.map((item, index) => (
                        <div key={index} className='icon-title-wrapper'>
                            <img src={Images.CheckRed} alt='Check-Green' />
                            <p>{item?.text} </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
