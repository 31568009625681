import {
    createTruck,
    getTruck,
    changeTruckStatus,
    editTruckDetails,
    getTruckDetails,
    deleteTruck,
    createTruckDocument,
    getTruckDocument,
    editTruckDocumentArchive,
    deleteTruckDocument,
    truckLoads,
    createTruckCosts,
    getTruckCosts,
    removeTruckDetails,
    getTruckMaintenance,
    createTruckMaintenanceService,
    uploadTruckMaintenanceDocument,
    updateTruckMaintenanceService,
} from './truck.action';
export { truckingReducer } from './truck.reducer';
export { watchTruck } from './truck.saga';

export const truckActions = {
    createTruck,
    getTruck,
    changeTruckStatus,
    editTruckDetails,
    getTruckDetails,
    removeTruckDetails,
    deleteTruck,
    truckLoads,

    /** Truck Documents */
    createTruckDocument,
    getTruckDocument,
    editTruckDocumentArchive,
    deleteTruckDocument,
    /** End */

    createTruckCosts,
    getTruckCosts,
    getTruckMaintenance,
    createTruckMaintenanceService,
    uploadTruckMaintenanceDocument,
    updateTruckMaintenanceService,
};
