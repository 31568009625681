import React from 'react';
import { useHistory } from 'react-router-dom';
import { Svg } from 'assets/images';

export const GoBackButton = ({ title, link }) => {
    const history = useHistory();

    const handleBack = () => {
        if (link) {
            history.push(link);
        } else {
            history.goBack();
        }
    };

    return (
        <button className="back-btn" onClick={handleBack}>
            <img src={Svg.GoBackIcon} alt="Go Back Icon" />
            <span className="text-btn">Back to {title} List</span>
        </button>
    );
};
