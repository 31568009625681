import { Colors } from "utils";
import { Tooltip } from "@mui/material";
import { withStyles } from '@material-ui/core/styles';

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 396,
    // background: Colors.text,
    height: "auto",
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    fontSize: "14px",
    // color: Colors.white,
    padding: "6px 16px",
  },
}))(Tooltip);


// export const HtmlTooltip = ({ title }) => {
//   return title
// }
