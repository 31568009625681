import { GET_CARD_INFO_SUCCESS, GET_INVOICES_SUCCESS, GET_MY_PROFILE_PACKAGE_SUCCESS } from './payment.type';

const initialState = {
    activePackage: null,
    cardInfo: null,
    invoices: null,
};

export const userPaymentReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Payment */
        case GET_MY_PROFILE_PACKAGE_SUCCESS:
            return {
                ...state,
                activePackage: action.payload,
            };

        case GET_CARD_INFO_SUCCESS:
            return {
                ...state,
                cardInfo: action.payload,
            };

        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
            };

        /** End */
        default:
            return state;
    }
};
