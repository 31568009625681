import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, CustomInput, CreateCancel, CustomSelect, CustomTextArea } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { truckActions } from 'store';
import { cretaeLoadTxnDTO } from '../../constants';
import { loadActions } from 'store';

export const AddTransaction = () => {
    const {  loadIdList } = useSelector((state) => ({
        loadIdList: state?.load?.loadIdList,
    }));

    const { close, params } = useModal();
    const ACTION_TYPE = params?.info?.auth ? 'EDIT_TRANSACTION_ACTION' : 'CREATE_TRANSACTION';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);

    const { control, handleSubmit, setError } = useForm({
        defaultValues: {
            loadId: loadIdList?.id,
            amount: params?.info?.amount,
            type: params?.info?.type,
            description: params?.info?.description,
        },
    });

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };

        sendInfo.amount = Number(data?.amount);

        dispatch(loadActions.createTxn(sendInfo));
    };

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    useEffect(() => {
        dispatch(truckActions.getTruck({ status: 'AVAILABLE' }));
    }, []);

    const mobileError =
        backError?.error === 'amount must be a number conforming to the specified constraints'
            ? 'amount must be a number conforming to the specified constraints'
            : backError?.error && backError?.error?.[0] === 'amount must be a number conforming to the specified constraints'
            ? 'amount must be a number conforming to the specified constraints'
            : '';

    return (
        <div className="invite-form">
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomInput
                    name="amount"
                    control={control}
                    rules={{ required: true }}
                    type="number"
                    label="Amount"
                    placeholder={'Amount'}
                    errMessage={mobileError}
                    dollar={true}
                    step={'numeric'}
                    // errMessage={!!mobileError}
                    // errMessageToShow={
                    //     mobileError === 'amount must be a number conforming to the specified constraints'
                    //         ? 'amount must be a number conforming to the specified constraints'
                    //         : mobileError === 'amount must be a number conforming to the specified constraints'
                    //         ? 'amount must be a number conforming to the specified constraints'
                    //         : backError?.error &&
                    //           backError?.error?.[0] === 'amount must be a number conforming to the specified constraints'
                    //         ? 'amount must be a number conforming to the specified constraints'
                    //         : ''
                    // }
                />
                <CustomSelect
                    name={'type'}
                    control={control}
                    rules={{ required: true }}
                    listSelect={cretaeLoadTxnDTO}
                    displayValue={'value'}
                    displayName={'label'}
                    label="Type"
                    placeholder={'Choose Type'}
                />
                <CustomTextArea
                    name={'description'}
                    id={'description'}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                    label={'Description'}
                    listType=""
                    placeholder={'Description...'}
                    maxLength={200}
                />
                <CreateCancel title={'Save'} actionType={ACTION_TYPE} closeType={ACTION_TYPE} />
            </form>
        </div>
    );
};
