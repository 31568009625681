import { TextRow, StatusTruck } from 'components';
import { MODAL_NAMES } from 'utils';
import { Svg } from 'assets/images';

export const ACTION_TYPE = 'GET_TRAILER';

export const trailersHead = [
    { name: 'id', title: 'ID' },
    { name: 'model', title: 'Model' },
    {
        name: 'type',
        title: 'Type',
        type: 'type',
        list: [
            {
                value: 'ALL',
                label: 'All',
            },
            {
                value: 'FLATBED',
                label: 'Flatbed',
            },
            {
                value: 'REEFER',
                label: 'Reefer',
            },
            {
                value: 'DRY_VAN',
                label: 'Dryvan',
            },
            {
                value: 'OTHER',
                label: 'Other',
            },
        ],
    },
    {
        name: 'status',
        title: 'Status',
        type: 'status',
        list: [
            {
                value: 'ALL',
                label: 'All',
            },
            {
                value: 'ACTIVE',
                label: 'Active',
            },
            {
                value: 'INACTIVE',
                label: 'Inactive',
            },
        ],
    },
];

export const trailersStatus = [
    {
        svg: Svg.ActiveSvg,
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        svg: Svg.InactiveSvg,
        value: 'INACTIVE',
        label: 'Inactive',
    },
];

export const trailerMobileStatus = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        value: 'INACTIVE',
        label: 'Inactive',
    },
];

export const trailersType = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'OTHER',
        label: 'Other',
    },
    {
        value: 'FLATBED',
        label: 'Flatbed',
    },
    {
        value: 'DRY_VAN',
        label: 'Dryvan',
    },
    {
        value: 'REEFER',
        label: 'Reefer',
    },
];

export const trailersCreateType = [
    {
        value: 'OTHER',
        label: 'Other',
    },
    {
        value: 'FLATBED',
        label: 'Flatbed',
    },
    {
        value: 'DRY_VAN',
        label: 'Dryvan',
    },
    {
        value: 'REEFER',
        label: 'Reefer',
    },
];

export const trailersBody = [
    { rowText: (item) => <TextRow name={item?.displayId} /> },
    { rowText: (item) => <TextRow name={item?.model} /> },
    { rowText: (item) => <TextRow name={handleType(item?.type)} /> },
    { rowText: (item) => <StatusTruck info={item?.status} /> },
];

export const TrailersMenu = [
    {
        name: 'Edit',
        modalName: MODAL_NAMES.TRACK_MODAL,
    },
    {
        name: 'Inactivate',
        modalName: MODAL_NAMES.INACTIVATE_MODAL,
    },
    {
        name: 'Delete',
        modalName: MODAL_NAMES.DELETE_MODAL,
    },
];

export const trailerDocumentsList = [
    { name: 'Driver License', type: 'DRIVER_LICENSE' },
    { name: 'Other', type: 'OTHER' },
];

const handleType = (name) => {
    switch (name) {
        case 'FLATBED':
            return 'Flatbed';
        case 'REEFER':
            return 'Reefer';
        case 'DRY_VAN':
            return 'Dryvan';
        case 'OTHER':
            return 'Other';
        default:
            return name;
    }
};
