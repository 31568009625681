import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { ErrMessage } from '../messages';

export const AddressInput = ({
                                 disabled,
                                 errorBoolean,
                                 backError,
                                 onTrigger,
                                 enteredValue,
                                 errMessage,
                                 error,
                                 label,
                                 notRequired,
                                 flexAble,
                             }) => {
    const [address, setAddress] = useState('' );
    const [unit, setUnit] = useState(null);

    useEffect(() => {
        if (enteredValue) {
            setAddress(enteredValue?.formattedAddress ? enteredValue?.formattedAddress  : enteredValue?.address );
        }
    }, [enteredValue]);

    useEffect(() => {
        if (enteredValue && !unit) {
            setUnit(enteredValue?.unit );
        }
    }, [enteredValue]);

    const handleChangeAddress = (value) => {
        setAddress(value);
        onTrigger({
            address: value,
            unit: unit,
        });
    };

    const handleSelect = async (value) => {
        setAddress(value);
        onTrigger({
            address: value,
            unit: unit,
        });
    };

    const changeUnits = (e) => {
        setUnit(e.target.value);
        onTrigger({
            address: address,
            unit: e.target.value,
        });
    };

    const placeholder = address ? address :
        notRequired ? 'Service Location' : 'Service Location*';

    return (
        <div className='input-wrapper'>
            <div className={flexAble ? 'address-inputs-wrapper-row' : 'address-inputs-wrapper'}>
                <PlacesAutocomplete value={address} onChange={handleChangeAddress} onSelect={(ev) => handleSelect(ev)}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className='address-custom-input'>
                            <p className='title' style={disabled ? { color: '#8CA2B8' } : {}}>{label}</p>
                            <input
                                className='address-input'
                                style={
                                    disabled ? { borderColor: '#8CA2B8' } :
                                        errorBoolean ? { borderColor: '#d03325' } : enteredValue ? { borderColor: '#0E9594' } : {}}
                                {...getInputProps({
                                    placeholder: placeholder,
                                    disabled: disabled,
                                    onBlur: (e) => {
                                        handleChangeAddress(e.target.value);
                                    },
                                })}
                            />
                            <ErrMessage
                                text={
                                    errorBoolean === 'Unable to verify the address' ? 'Unable to verify the address' :
                                        errorBoolean ? 'Input is not field'
                                            : errMessage ? errMessage : error?.type === 'required'
                                                ? 'This Field is required' : backError?.error ? backError?.error : ''
                                }
                            />
                            {loading && <div className='address-loading-class'>Loading...</div>}
                            <div className='address-custom-input-wrapper'>
                                {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                    const style = suggestion.active
                                        ? { margin: '15px', cursor: 'pointer' }
                                        : { margin: '15px', cursor: 'pointer' };
                                    return (
                                        <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}>
                                            <div>
                                                <span>{suggestion.description}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}


                </PlacesAutocomplete>

                <div className={ flexAble ? 'input-wrapper unit-input' : 'input-wrapper' } style={{marginBottom:'18px'}}>
                    <label style={{ color: disabled ? '#758EA6' : '#41586D' }} htmlFor={'unit'} className='label'>
                        Unit/Suit #
                    </label>
                    <div
                        style={disabled ? { borderColor: '#8CA2B8' } : label && unit ? { border: '1px solid #0E9594' } : {}}
                        className='custom-input'>
                        <input
                            onChange={changeUnits}
                            value={unit}
                            className='input-form-control'
                            id={'unit'}
                            placeholder={'Unit Number'}
                            disabled={disabled}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};
