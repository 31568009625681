import { Svg } from '../../assets/images';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';

export const HtmlToolTip = ({ text }) => {
    return(
        <div className='HtmlToolTip'>
            <Tooltip title={text} >
                <IconButton>
                    <img src={Svg.InfoGray} alt='icon' />
                </IconButton>
            </Tooltip>
        </div>
    )
}