import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FindLoad } from 'utils';
import { loadActions } from 'store';
import { Svg } from '../../../assets/images';
import { CoastCard } from './core/coastCard';
import { HtmlToolTip, Loader } from 'components';

export const LoadCost = ({}) => {
    const { loadCoasts } = useSelector((state) => ({
        loadCoasts: state?.load?.loadCoasts,
    }));
    const dispatch = useDispatch();
    const params = useParams();
    const loader = FindLoad('GET_LOAD_COASTS');

    useEffect(() => {
        dispatch(loadActions.getLoadCosts(params?.id));
    }, []);

    if (loader?.length) return <Loader />;

    return (
        <div className='load-cost-wrapper'>
            <div className='coast-white-wrapper desktop-and-mobile'>
                <div className='flex' style={{ marginBottom: '32px' }}>
                <p  className='coast-white-wrapper-title'>Summary</p>
                <HtmlToolTip text={'information'} />
                </div>
                <p className='coasts-title'>{'Truck’s Costs'}</p>
                <div className='coasts-wrapper'>
                    <CoastCard convert={true} name={'Miles Cost'} price={loadCoasts?.truckMilesCost} icon={Svg.MileCoast} />
                    <CoastCard convert={true} name={'Fix Cost'} price={loadCoasts?.truckFixCost} icon={Svg.FixCoast}  />
                    <CoastCard convert={true} name={'Total Amount'} price={loadCoasts?.truckTotalAmount} type={'amount'}  />
                </div>
                {loadCoasts?.trailerTotalAmount &&
                    <>
                        <p className='coasts-title'>{'Trailer’s Cost'}</p>
                        <div className='coasts-wrapper'>
                            <CoastCard
                                name={'Per Miles Cost'}
                                price={loadCoasts?.trailerMilesCost}
                                icon={Svg.MileCoast}
                                convert={true}
                            />
                            <CoastCard
                                name={'Fix Cost'}
                                price={loadCoasts?.trailerFixCost}
                                icon={Svg.FixCoast}
                                convert={true}
                            />
                            <CoastCard
                                convert={true}
                                name={'Total Amount'}
                                price={loadCoasts?.trailerTotalAmount}
                                type={'amount'}
                            />
                        </div>
                    </>
                }
                <p className='coasts-title'>{'Fuel’s Costs'}</p>
                <div className='coasts-wrapper gray-width'>
                    <CoastCard
                        convert={true}
                        name={'Fuel Cost'}
                        price={loadCoasts?.fuelCost}
                        icon={Svg.FuelIcon}
                        noRight={true}
                    />
                </div>
            </div>


            <div className='coast-white-wrapper tablet'>
                <p style={{ marginBottom: '32px' }} className='coast-white-wrapper-title'>Summary</p>
                <p className='coasts-title'>{'Truck’s Costs'}</p>

                <div className='coasts-wrapper'>
                    <CoastCard
                        convert={true}
                        name={'Miles Cost'}
                        price={loadCoasts?.truckMilesCost}
                        icon={Svg.MileCoast}
                    />
                    <CoastCard
                        convert={true}
                        name={'Fix Cost'}
                        price={loadCoasts?.truckFixCost}
                        icon={Svg.FixCoast}
                        noRight={true}
                    />
                </div>
                <div className='coasts-wrapper'>
                    <CoastCard
                        convert={true}
                        name={'Total Amount'}
                        price={loadCoasts?.truckTotalAmount}
                        type={'amount'}
                    />
                </div>

                {loadCoasts?.trailerTotalAmount &&
                    <>
                        <p className='coasts-title'>{'Trailer’s Cost'}</p>
                        <div className='coasts-wrapper'>
                            <CoastCard
                                name={'Per Miles Cost'}
                                price={loadCoasts?.trailerMilesCost}
                                icon={Svg.MileCoast}
                                convert={true}
                            />
                            <CoastCard
                                name={'Fix Cost'}
                                price={loadCoasts?.trailerFixCost}
                                icon={Svg.FixCoast}
                                convert={true}
                                noRight={true}
                            />
                        </div>
                        <div className='coasts-wrapper'>
                            <CoastCard
                                convert={true}
                                name={'Total Amount'}
                                price={loadCoasts?.trailerTotalAmount}
                                type={'amount'}
                            />
                        </div>
                    </>
                }
                <p className='coasts-title'>{'Fuel’s Costs'}</p>
                <div className='coasts-wrapper'>
                    <CoastCard
                        convert={true}
                        name={'Fuel Cost'}
                        price={loadCoasts?.fuelCost}
                        icon={Svg.FuelIcon}
                        noRight={true}
                    />
                </div>
            </div>
            {loadCoasts?.driver?._id || loadCoasts?.coDriver?._id ?
                <div className='coast-white-wrapper' style={{ marginTop: '24px' }}>
                    <p className='coast-white-wrapper-title'>Assigned Drivers’ Cost</p>
                    <div className='coasts-wrapper'>
                            <CoastCard
                                name={'Driver Rate'}
                                price={`$${loadCoasts?.driver?.currentPay?.rate ? loadCoasts?.driver?.currentPay?.rate : 0}`}
                                priceText={ loadCoasts?.driver?.currentPay?.mode}
                                icon={Svg.BrakeIcon}
                            />
                            <CoastCard
                                convert={true}
                                name={'Total Amount'}
                                price={loadCoasts?.driverTotalAmount ? loadCoasts?.driverTotalAmount : 0}
                                type={'amount'}
                            />
                    </div>
                    {loadCoasts?.coDriver?._id &&
                        <div className='coasts-wrapper'>
                            <CoastCard
                                name={'CoDriver Rate'}
                                price={`$${loadCoasts?.coDriver?.currentPay?.rate ? loadCoasts?.coDriver?.currentPay?.rate : 0}`}
                                priceText={loadCoasts?.coDriver?.currentPay?.mode}
                                icon={Svg.ReportIcon}
                            />
                            <CoastCard
                                convert={true}
                                name={'Total Amount'}
                                price={loadCoasts?.coDriverTotalAmount ? loadCoasts?.coDriverTotalAmount : 0}
                                type={'amount'}
                            />
                        </div>
                    }
                </div>
                : ''
            }
        </div>
    );
};