/**  Types */

/**  Load List Types */
export const GET_LOAD = 'GET_LOAD';
export const GET_LOAD_SUCCESS = 'GET_LOAD_SUCCESS';

export const CREATE_LOAD = 'CREATE_LOAD';

/** Get Load Details Types */
export const GET_LOAD_ID = 'GET_LOAD_ID';
export const GET_LOAD_ID_SUCCESS = 'GET_LOAD_ID_SUCCESS';

export const EDIT_LOAD = 'EDIT_LOAD';

export const DELETE_LOAD = 'DELETE_LOAD';

export const CHANGE_LOAD_STATUS = 'CHANGE_LOAD_STATUS';

export const CANCEL_LOAD = 'CANCEL_LOAD';

/**  Get Load Truck Type */
export const GET_LOAD_TRUCK_ID = 'GET_LOAD_TRUCK_ID';
export const GET_LOAD_TRUCK_ID_SUCCESS = 'GET_LOAD_TRUCK_ID_SUCCESS';

/** Load Stops Type */
export const CREATE_LOAD_ID_STOP = 'CREATE_LOAD_ID_STOP';

export const EDIT_LOAD_ID_STOP_ID = 'EDIT_LOAD_ID_STOP_ID';

export const DELETE_LOAD_ID_STOP_ID = 'DELETE_LOAD_ID_STOP_ID';

/** Loads Document */
export const CREATE_LOAD_ID_DOCUMENT = 'CREATE_LOAD_ID_DOCUMENT';

export const GET_LOAD_ID_DOCUMENT = 'GET_LOAD_ID_DOCUMENT';
export const GET_LOAD_ID_DOCUMENT_SUCCESS = 'GET_LOAD_ID_DOCUMENT_SUCCESS';

export const EDIT_LOAD_DOCUMENT_ARCHIVE = 'EDIT_LOAD_DOCUMENT_ARCHIVE';

export const DELETE_LOAD_DOCUMENT = 'DELETE_LOAD_DOCUMENT';

/** Load Transaction Types  */
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';

export const EDIT_TRANSACTION_ACTION = 'EDIT_TRANSACTION_ACTION';

export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';

export const GET_LOAD_COASTS = 'GET_LOAD_COASTS';
export const GET_LOAD_COASTS_SUCCESS = 'GET_LOAD_COASTS_SUCCESS';