import { Svg } from '../../assets/images';

export const AddButton = ({ handleAdd, addText }) => {
    return (
        <>
            <button className='add-btn-wrapper' onClick={handleAdd}>
                <img src={Svg.AddStepIcon} alt='Add Step Icon' />
                <p className='rate-title'>
                    {addText}
                </p>
            </button>
        </>
    );
};