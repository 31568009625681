import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid } from '@mui/material';
import { CardBtn, CustomInput, CustomSelect, Documents, ErrorCard, MaskInput, MinLoader, useModal } from 'components';
import { FindLoad, FindSuccess, MODAL_NAMES, FindError, RolePermissions } from 'utils';
import { loadActions, httpRequestsOnSuccessActions, truckActions } from 'store';
import { Svg } from 'assets/images';
import { StopsFragment } from './stopsFragment';
import { cretaeLoadDTO, loadDocumentsList } from '../constants';
import { DateFragment } from './dateFragment';
import { renderErrorMessage } from './constants';

export const LoadDetails = ({
    tab,
    indexTab,
    trailerList,
    driversList,
    truckList,
    loadIdList,
    loadDocument,
    handelDelete,
    customerList,
}) => {
    const { activePackage, truckDetailsList } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
        truckDetailsList: state?.truck?.truckDetailsList,
    }));
    const loadStatus = loadIdList?.status;
    const checkLoadStatus = loadStatus === 'DELIVERED' || loadStatus === 'CANCELED' || loadStatus === 'COMPLETE'

    const { open } = useModal();
    const dispatch = useDispatch();
    const paramsRouter = useParams();
    const [editInput, setEditInput] = useState(true);
    const loadSave = FindLoad('EDIT_LOAD');
    const loadButton = FindLoad('CHANGE_LOAD_STATUS');
    const loadChancelButton = FindLoad('CANCEL_LOAD');
    const loadDelButton = FindLoad('DELETE_LOAD');
    const success = FindSuccess('EDIT_LOAD');
    const stopsList = loadIdList?.stops;
    const [sendStatus, setSendStatus] = useState(loadStatus);
    const [inputs, setInputs] = useState('');

    const handleAddStop = () => {
        open(MODAL_NAMES.ADD_STOPS, { modalTitle: 'Add Stop' });
    };

    const {
        control,
        reset,
        register,
        unregister,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            type: loadIdList?.type,
            weight: loadIdList?.weight,
            customerRate: loadIdList?.customerRate,
            fuelCost: loadIdList?.fuelCost,
            loadMiles: loadIdList?.loadMiles,
            deadheadMiles: loadIdList?.deadHeadMiles,
            truckId: loadIdList?.truckId ? loadIdList?.truckId : null,
            driver: loadIdList?.driverId ? loadIdList?.driverId : null,
            coDriver: loadIdList?.coDriverId,
            assignTrailer: loadIdList?.trailerId,
            customer: loadIdList?.customerId,
            driverHours: loadIdList?.driverHours,
        },
    });

    useEffect(() => {
        if (loadIdList?.type === 'POWER_ONLY') {
            setInputs({ type: 'POWER_ONLY' });
        }
    }, [loadIdList]);

    useEffect(() => {
        dispatch(truckActions.removeTruckDetails());
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_LOAD'));
            handleEdit();
        }
    }, [success]);

    useEffect(() => {
        if (loadStatus) {
            loadStatusTitle(loadStatus);
        }
    }, [loadStatus]);

    useEffect(() => {
        if (inputs?.truckId) {
            dispatch(truckActions.getTruckDetails(inputs?.truckId));
        }
    }, [inputs]);

    useEffect(() => {
        if (truckDetailsList) {
            if (inputs?.type !== 'POWER_ONLY') {
                setValue('assignTrailer', truckDetailsList?.assignedTrailerId);
            }
            setValue('driver', truckDetailsList?.assignedDriverId);
            setValue('coDriver', truckDetailsList?.assignedCoDriverId);
            setInputs({
                type: inputs?.type,
                driver: truckDetailsList?.assignedDriverId,
                coDriver: truckDetailsList?.assignedCoDriverId,
            });
        }
    }, [truckDetailsList]);

    const handleEdit = () => {
        setEditInput(!editInput);
    };

    const handleChancel = () => {
        handleEdit();
        reset({
            type: loadIdList?.type,
            weight: loadIdList?.weight,
            customerRate: loadIdList?.customerRate,
            fuelCost: loadIdList?.fuelCost,
            loadMiles: loadIdList?.loadMiles,
            deadheadMiles: loadIdList?.deadheadMiles,
            truckId: loadIdList?.truckId,
            driver: loadIdList?.driverId,
            coDriver: loadIdList?.coDriverId,
            assignTrailer: loadIdList?.trailerId?._id,
            customer: loadIdList?.customerId,
            driverHours: loadIdList?.driverHours,
        });
    };

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };
        sendInfo.loadMiles = +data.loadMiles;
        sendInfo.fuelCost = +data.fuelCost;
        sendInfo.deadheadMiles = +data.deadheadMiles;
        sendInfo.weight = +data.weight;
        sendInfo.customerRate = +data.customerRate;
        if (sendInfo.assignTrailer === 'notSet') {
            sendInfo.assignTrailer = null;
        }
        if (sendInfo.driver === 'notSet') {
            sendInfo.driver = null;
        }
        if (sendInfo.coDriver === 'notSet') {
            sendInfo.coDriver = null;
        }
        dispatch(loadActions.editLoad(sendInfo, loadIdList?.id));
    };

    const handleUploadFile = (dock) => {
        const file = {
            file: {
                ...dock?.file
            },
            status: 'CURRENT',
            type: dock?.type,
        };
        dispatch(loadActions.createDocumentLoad(paramsRouter.id, file));
    };

    const handleDeleteUpload = (dock) => {
        dispatch(loadActions.deleteDocumentDocId(paramsRouter.id, dock?.id));
    };

    const handleSetStatus = (dock) => {
        const status = dock?.status === 'CURRENT';
        dispatch(loadActions.editDocumentIdArchive(paramsRouter.id, dock?.id, status));
    };

    const loadStatusTitle = (name) => {
        switch (name) {
            case 'PENDING':
                return setSendStatus('ACTIVE');
            case 'ACTIVE':
                return setSendStatus('DELIVERED');
            case 'DELIVERED':
                return setSendStatus('COMPLETE');
            case 'COMPLETE':
                return setSendStatus('COMPLETE');
            default:
                return name;
        }
    };

    const handleLoadStatus = () => {
        const loadId = loadIdList?.id;
        dispatch(loadActions.changeStatus(loadId, sendStatus));
    };

    const handleReset = () => {
        setValue('assignTrailer', 'notSet');
        setValue('driver', 'notSet');
        setValue('coDriver', 'notSet');
    };

    const handleChange = (e) => {
        if (e.target.name === 'type' && e.target.value === 'POWER_ONLY') {
            setValue('assignTrailer', 'notSet');
        }
        if (e.target.name === 'truckId' && e.target.value) {
            dispatch(truckActions.getTruckDetails(e.target.value));
            handleReset();
        }
        if (e.target.name === 'truckId' && !e.target.value) {
            dispatch(truckActions.removeTruckDetails());
            handleReset();
        }
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    /** Submit Load Error Messages  */

    const assignPickUp =true;
    const assignDropOff = true;
    const assignTruck = loadIdList?.truckId;
    const assignDriver = loadIdList?.driverId;
    const assignDriverAndTruck = loadIdList?.driverId && loadIdList?.driverId;
    const assignCustomer = loadIdList?.customerId;
    const assignStop = true
    const assignDriverAndStop = assignDriver && assignStop;
    const assignTruckAndStop = assignDriver && assignStop;
    const checkSubmit =
        !assignTruck || !assignDriver || !assignCustomer || !assignStop || !assignDriverAndTruck || !assignPickUp || !assignDropOff;

    return (
        <Box value={tab} index={indexTab}>
            <div className="details-container">
                <div className="details-form">
                    <Box className="load-space" />
                    <Box className="load-form">
                        <form onSubmit={handleSubmit(onSubmit)} className="form-messages">
                            <Box className="basic-banner space-between">
                                <div className="load-title basic-title">
                                    <h4 className="load-basic flex">Basic Info</h4>
                                </div>
                                <CardBtn
                                    className={'basic-btn'}
                                    editInput={editInput}
                                    handleEdit={handleEdit}
                                    handleChancel={handleChancel}
                                    load={loadSave}
                                />
                            </Box>
                            <Grid container columnSpacing={3} rowSpacing={0}>
                                <Grid item xs={12} md={4}>
                                    <CustomSelect
                                        handleBlur={handleChange}
                                        name={'type'}
                                        control={control}
                                        listSelect={cretaeLoadDTO}
                                        displayValue={'value'}
                                        displayName={'label'}
                                        disabled={editInput}
                                        label="Type"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CustomInput
                                        name="customerRate"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={loadStatus !== 'PENDING' ? true : editInput}
                                        type="number"
                                        label="Customer Rate"
                                        dollar={true}

                                        step={'numeric'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CustomInput
                                        name="weight"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type="number"
                                        label="Weight"
                                        lastIcon={'kg'}
                                        min={1}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <MaskInput
                                        mask={'99:99'}
                                        name="driverHours"
                                        label="Driver hours"
                                        control={control}
                                        rules={{ required: false }}
                                        type="phone"
                                        placeholder={'Driver hours'}
                                        disabled={editInput}
                                        color={'rgb(117, 142, 166)'}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <CustomInput
                                        name="fuelCost"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type="number"
                                        label="Fuel Cost"
                                        dollar={true}
                                        step={'numeric'}
                                        lastIcon={'gl'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CustomInput
                                        name="loadMiles"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type="number"
                                        label="Load Miles"
                                        lastIcon={'mi'}
                                        step={'numeric'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CustomInput
                                        name="deadheadMiles"
                                        control={control}
                                        rules={{ required: true }}
                                        type="number"
                                        label="Dead Head Miles"
                                        disabled={editInput}
                                        lastIcon={'mi'}
                                        step={'numeric'}
                                    />
                                </Grid>

                                {/*<Grid item xs={12} md={2}>*/}
                                {/*    <MaskInput*/}
                                {/*        mask={'99:99'}*/}
                                {/*        name="driverHours"*/}
                                {/*        label="Driver hours"*/}
                                {/*        control={control}*/}
                                {/*        rules={{ required: false }}*/}
                                {/*        type="phone"*/}
                                {/*        placeholder={'Driver hours'}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                            </Grid>
                            {/* Assign Load */}
                            <Box className="load-title" style={{marginBottom:0}}>
                                <h4 className="load-basic" style={{marginBottom:0}}>Assign To Load</h4>
                            </Box>
                            <Grid container columnSpacing={3} rowSpacing={0} marginTop={3}>
                                <Grid item xs={12} sm={6}>
                                    <CustomSelect
                                        handleBlur={handleChange}
                                        displayValue={'id'}
                                        displayName={'name'}
                                        name={'truckId'}
                                        control={control}
                                        listSelect={truckList?.trucks}
                                        disabled={loadStatus !== 'PENDING' ? true : editInput}
                                        label="Assign Truck"
                                        placeholder={'Choose Truck'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomSelect
                                        displayValue={'id'}
                                        displayName={'make'}
                                        name={'assignTrailer'}
                                        control={control}
                                        listSelect={trailerList?.trailers}
                                        disabled={loadStatus !== 'PENDING' ? true : inputs?.type === 'POWER_ONLY' ? true : editInput}
                                        label="Assigned Trailer"
                                        placeholder={'Choose Trailer'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <CustomSelect
                                        handleBlur={handleChange}
                                        displayValue={'id'}
                                        displayName={'firstName'}
                                        name={'driver'}
                                        control={control}
                                        listSelect={
                                            inputs?.coDriver
                                                ? driversList?.drivers?.filter((i) => i?.id !== inputs?.coDriver)
                                                : driversList?.drivers
                                        }
                                        disabled={loadStatus !== 'PENDING' ? true : editInput}
                                        label="Assign Driver"
                                        placeholder={'Choose Driver'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <CustomSelect
                                        handleBlur={handleChange}
                                        displayValue={'id'}
                                        displayName={'firstName'}
                                        name={'coDriver'}
                                        control={control}
                                        listSelect={
                                            inputs?.driver
                                                ? driversList?.drivers?.filter((i) => i?.id !== inputs?.driver)
                                                : driversList?.drivers
                                        }
                                        disabled={loadStatus !== 'PENDING' ? true : editInput}
                                        label="Assign CoDriver"
                                        placeholder={'Choose CoDriver'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <CustomSelect
                                        displayValue={'id'}
                                        displayName={'name'}
                                        name={'customer'}
                                        control={control}
                                        listSelect={customerList?.customers}
                                        disabled={loadStatus !== 'PENDING' ? true : editInput}
                                        label="Assign Customer"
                                        placeholder={'Choose Customer'}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    <Box className="load-space" />
                    <Box className="load-form">
                        <DateFragment loadIdList={loadIdList} />
                    </Box>

                    <Box className="load-space" />
                    <Box className="load-form">
                        <Box className="basic-banner space-between">
                            <div className="load-title basic-title">
                                <h4 className="load-basic flex">Stops</h4>
                            </div>
                            <div className="basic-title">
                                {RolePermissions(activePackage) && (
                                    <button
                                        className={ checkLoadStatus  ? 'add-stop disbled-btn' : 'add-stop' }
                                        onClick={() => handleAddStop()}
                                        disabled={checkLoadStatus}
                                    >
                                        <span>
                                            {checkLoadStatus ? (
                                                <img src={Svg.DisabledAddStep} alt="Disabled_Add_Step" />
                                            ) : (
                                                <img src={Svg.AddStepIcon} alt="Add Step Icon" />
                                            )}
                                        </span>
                                        <span className="stop-title">
                                            Add <span className="stop-mobile">Stop</span>
                                        </span>
                                    </button>
                                )}
                            </div>
                        </Box>
                        <Box>
                            <StopsFragment stopsList={stopsList} paramsId={loadIdList} loadStatus={loadStatus} />
                        </Box>
                    </Box>


                    <Box className="load-space" />
                    <Box className="load-form">
                        <Box className="basic-banner space-between document-container">
                            <Documents
                                docks={loadDocument?.length ? loadDocument : []}
                                actionType={'CREATE_LOAD_ID_DOCUMENT'}
                                deleteType={'DELETE_LOAD_DOCUMENT'}
                                changeStatus={'EDIT_LOAD_DOCUMENT_ARCHIVE'}
                                activeStatus={'CURRENT'}
                                typeList={loadDocumentsList}
                                handleDelete={handleDeleteUpload}
                                handleUpload={handleUploadFile}
                                handleSetStatus={handleSetStatus}
                            />
                        </Box>
                    </Box>


                    <Box className="load-space" />
                    {!assignTruck ||
                    !assignDriver ||
                    !assignStop ||
                    !assignCustomer ||
                    !assignDriverAndTruck ||
                    !assignPickUp ||
                    !assignDropOff ||
                    !assignDriverAndStop ||
                    !assignTruckAndStop ? (
                        <ErrorCard
                            subtitle={renderErrorMessage(
                                assignTruck,
                                assignDriver,
                                assignCustomer,
                                assignStop,
                                assignPickUp,
                                assignDropOff
                            )}
                        />
                    ) : null}

                    <Box className="load-space" />
                    <Box className="load-footer">
                        <div className="submit-details">
                            <Button className="cancel-button" variant="text" color="error" onClick={() => handelDelete('delete')}>
                                {loadDelButton?.length ? <MinLoader color={'red'} /> : <span className="text-error">{'Delete Load'}</span>}
                            </Button>
                            {loadStatus === 'PENDING' && (
                                <Button className="cancel-button" variant="text" color="error" onClick={() => handelDelete('cancel')}>
                                    {loadChancelButton?.length ? (
                                        <MinLoader color={'red'} />
                                    ) : (
                                        <span className="text-error">{'Cancel Load'}</span>
                                    )}
                                </Button>
                            )}
                            {RolePermissions(activePackage) && loadStatus !== 'CANCELED' && loadStatus !== 'COMPLETE' && (
                                <button
                                    className={`add-button  ${checkSubmit ? 'disabled-button' : ''}  `}
                                    onClick={() => handleLoadStatus()}
                                    style={{ width: '193px' }}
                                    disabled={checkSubmit}>
                                    {loadButton?.length ? (
                                        <MinLoader color={'white'} />
                                    ) : (
                                        <span className="button-text">
                                            {loadStatus === 'ACTIVE'
                                                ? 'Deliver'
                                                : loadStatus === 'DELIVERED'
                                                ? 'Complete'
                                                : loadStatus === 'PENDING'
                                                ? 'Submit'
                                                : loadStatus === 'COMPLETE'
                                                ? 'Cancel'
                                                : 'Disabled'}
                                        </span>
                                    )}
                                </button>
                            )}
                        </div>
                    </Box>
                </div>
            </div>
        </Box>
    );
};
