import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { PayInfo } from './fragments/payInfo';
import { Svg } from '../../assets/images';
import CheckoutForm from './fragments/checkoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);

export const CheckoutPage = ({}) => {
    const route = useHistory();

    return (
        <div className="checkout-page">
            <div className="breadcrumb-wrapper">
                <a href="/payment">Payment</a>
                <img src={Svg.DownArrowIcon} alt="DownArrowIcon" />
                <span>Card Information</span>
            </div>
            <div className="checkout-container-wrapper">
                <Elements stripe={stripePromise}>
                    <CheckoutForm query={route?.location?.search === '?editCard=true'} />
                </Elements>
                {route?.location?.search !== '?editCard=true' && (
                    <div>
                        <PayInfo />
                    </div>
                )}
            </div>
        </div>
    );
};
