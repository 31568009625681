/** Payment Actions */
import {
    ACTIVATE_PACKAGE,
    CANCEL_SUBSCRIPTION,
    GET_CARD_INFO,
    GET_INVOICES,
    GET_MY_PROFILE_PACKAGE,
} from './payment.type';

/** Payment Actions */

export const activatePackage = (type) => {
    return {
        type: ACTIVATE_PACKAGE,
        payload: type,
    };
};

export const getMyPackage = () => {
    return {
        type: GET_MY_PROFILE_PACKAGE,
    };
};

export const getInvoices = () => {
    return {
        type: GET_INVOICES,
    };
};

export const getCardInfo = () => {
    return {
        type: GET_CARD_INFO,
    };
};

export const cancelSubscription = () => {
    return {
        type: CANCEL_SUBSCRIPTION,
    };
};

/** Edit */
