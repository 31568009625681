import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CardBtn, CustomInput, CostCard, Loader, HtmlToolTip } from 'components';
import { httpRequestsOnSuccessActions, trailerActions } from 'store';
import { FindLoad, FindSuccess } from 'utils';
import { Box, Grid } from '@mui/material';
import { fixList, perList } from './constants';

const REQUEST_TYPE = 'CREATE_TRAILER_COASTS';

export const TrailerCoast = ({ trailerCoasts }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [editInput, setEditInput] = useState(true);
    const loadSave = FindLoad(REQUEST_TYPE);
    const getLoad = FindLoad('GET_TRAILER_COASTS');
    const success = FindSuccess(REQUEST_TYPE);
    const [inputs, setInputs] = useState(trailerCoasts);

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            numberOfTire: Number(trailerCoasts?.numberOfTire),
            tireChangeMiles: Number(trailerCoasts?.tireChangeMiles),
            tireCost: Number(trailerCoasts?.tireCost),
            numberOfBrake: Number(trailerCoasts?.numberOfBrake),
            brakeChangeMiles: Number(trailerCoasts?.brakeChangeMiles),
            brakeCost: Number(trailerCoasts?.brakeCost),
            otherMileCost: Number(trailerCoasts?.otherMileCost),
            truckCost: Number(trailerCoasts?.truckCost),
            truckMilesLimit: Number(trailerCoasts?.truckMilesLimit),
            truckSalePrice: Number(trailerCoasts?.truckSalePrice),
            insuranceCost: Number(trailerCoasts?.insuranceCost),
            parkingCost: Number(trailerCoasts?.parkingCost),
            otherFixCost: Number(trailerCoasts?.otherFixCost),
            total: {
                tireCosts: Number(trailerCoasts?.total?.tireCosts),
                brakeCosts: Number(trailerCoasts?.total?.brakeCosts),
                deprecation: Number(trailerCoasts?.total?.deprecation),
                monthlyTotal: Number(trailerCoasts?.total?.monthlyTotal),
                totalPerMail: Number(trailerCoasts?.total?.totalPerMail),
            },
            trailerCost: Number(trailerCoasts?.trailerCost),
            trailerMilesLimit: Number(trailerCoasts?.trailerMilesLimit),
            trailerSalePrice: Number(trailerCoasts?.trailerSalePrice),
        },
    });


    useEffect(() => {
        if (trailerCoasts) {
            setInputs(trailerCoasts);
        }
    }, [trailerCoasts]);

    useEffect(() => {
        if (success) {
            setEditInput(!editInput);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
        }
    }, [success]);

    const handleEdit = () => {
        setEditInput(!editInput);
    };

    const handleChancel = () => {
        handleEdit();
        reset({
            numberOfTire: Number(trailerCoasts?.numberOfTire),
            tireChangeMiles: Number(trailerCoasts?.tireChangeMiles),
            tireCost: Number(trailerCoasts?.tireCost),
            numberOfBrake: Number(trailerCoasts?.numberOfBrake),
            brakeChangeMiles: Number(trailerCoasts?.brakeChangeMiles),
            brakeCost: Number(trailerCoasts?.brakeCost),
            otherMileCost: Number(trailerCoasts?.otherMileCost),
            truckCost: Number(trailerCoasts?.truckCost),
            truckMilesLimit: Number(trailerCoasts?.truckMilesLimit),
            truckSalePrice: Number(trailerCoasts?.truckSalePrice),
            insuranceCost: Number(trailerCoasts?.insuranceCost),
            parkingCost: Number(trailerCoasts?.parkingCost),
            otherFixCost: Number(trailerCoasts?.otherFixCost),
            total: {
                tireCosts: Number(trailerCoasts?.total?.tireCosts),
                brakeCosts: Number(trailerCoasts?.total?.brakeCosts),
                deprecation: Number(trailerCoasts?.total?.deprecation),
                monthlyTotal: Number(trailerCoasts?.total?.monthlyTotal),
                totalPerMail: Number(trailerCoasts?.total?.totalPerMail),
            },
            trailerCost: Number(trailerCoasts?.trailerCost),
            trailerMilesLimit: Number(trailerCoasts?.trailerMilesLimit),
            trailerSalePrice: Number(trailerCoasts?.trailerSalePrice),
        });
    };

    const onSubmit = (data) => {
        const formatData = {
            ...data,
        };
        formatData.numberOfTire = Number(data?.numberOfTire);
        formatData.tireChangeMiles = Number(data?.tireChangeMiles);
        formatData.tireCost = Number(data?.tireCost);
        formatData.numberOfBrake = Number(data?.numberOfBrake);
        formatData.brakeChangeMiles = Number(data?.brakeChangeMiles);
        formatData.brakeCost = Number(data?.brakeCost);
        formatData.otherMileCost = Number(data?.otherMileCost);
        formatData.truckCost = Number(data?.truckCost);
        formatData.truckMilesLimit = Number(data?.truckMilesLimit);
        formatData.truckSalePrice = Number(data?.truckSalePrice);
        formatData.insuranceCost = Number(data?.insuranceCost);
        formatData.parkingCost = Number(data?.parkingCost);
        formatData.otherFixCost = Number(data?.otherFixCost);
        formatData.trailerCost = Number(data?.trailerCost);
        formatData.trailerMilesLimit = Number(data?.trailerMilesLimit);
        formatData.trailerSalePrice = Number(data?.trailerSalePrice);
        dispatch(trailerActions.createTrailerCosts(params?.id, formatData));
    };

    if (getLoad?.length) {
        return <Loader />;
    }

    return (
        <div className='trailer-cost-container details-container'>
            <div className='details-form cost-summary'>
                <Box className='laod-space' />
                <Box className='load-form'>
                    <Box className='basic-banner space-between'>
                        <div className='load-title basic-title flex' style={{ marginBottom: '0' }}>
                            <h4 className='trailer-basic flex'>Summary</h4>
                            <HtmlToolTip text={'information'} />
                        </div>
                    </Box>
                    <Box className='basic-banner fix-cost'>
                        <div className='load-subtitle flex'>
                            <h4 className='subtitile-basic'>Per Miles costs</h4>
                        </div>
                    </Box>
                    <Grid container columnSpacing={3} rowSpacing={2}>
                        {perList?.map((item, index) => (
                            <Grid key={index} item xs={12} sm={6} lg={4} xl={3}>
                                <CostCard
                                    svg={item.svg}
                                    title={item.title}
                                    total={item?.total}
                                    alt={item?.alt}
                                    totlaAmount={inputs?.total}
                                    value={item?.type === 'total' ? trailerCoasts?.total?.[item?.name] : trailerCoasts[item.name]}
                                    priceSymbol={'dollar'}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Box className='basic-banner fix-cost'>
                        <div className='load-subtitle'>
                            <h4 className='subtitile-basic'>Fix Cost</h4>
                        </div>
                    </Box>
                    <Grid container columnSpacing={3} rowSpacing={2}>
                        {fixList.map((item, index) => (
                            <Grid key={index} item xs={12} sm={6} lg={6} xl={3}>
                                <CostCard
                                    svg={item.svg}
                                    title={item.title}
                                    color={item?.color}
                                    alt={item?.alt}
                                    text={item?.text}
                                    totlaAmount={inputs?.total}
                                    totalFix={inputs?.total}
                                    value={item?.type === 'total' ? inputs?.total?.[item?.name] ? inputs?.total?.[item?.name] : 0 : inputs[item.name]}
                                    priceSymbol={'dollar'}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </div>

            <div className='details-form'>
                <form onSubmit={handleSubmit(onSubmit)} className='form-messages'>
                    <Box className='laod-space' />
                    <Box className='load-form'>
                        <Box className='basic-banner space-between'>
                            <div className='load-title basic-title flex'>
                                <h4 className='trailer-basic flex'>Per Mile Costs</h4>
                                <HtmlToolTip text={'information'} />
                            </div>
                            <CardBtn
                                className={'basic-btn'}
                                editInput={editInput}
                                handleEdit={handleEdit}
                                handleChancel={handleChancel}
                                load={loadSave}
                            />
                        </Box>

                        <div className='row-flex-able'>
                            <div className='row-width'>
                                <Box className='basic-banner'>
                                    <div className='load-subtitle'>
                                        <h4 className='subtitile-basic'>Tires Cost</h4>
                                    </div>
                                </Box>
                                <div className='row-flex-response'>
                                    <div className='input-right'>
                                        <CustomInput
                                            name='numberOfTire'
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type='number'
                                            label='Number of Tires'
                                            placeholder={'0'}
                                        />
                                    </div>
                                    <div className='input-right'>
                                        <CustomInput
                                            name='tireCost'
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type='number'
                                            label='Tire Cost'
                                            placeholder={'0.00'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <CustomInput
                                        name='tireChangeMiles'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type='number'
                                        label='Tire change'
                                        placeholder={'0.00'}
                                        step={'numeric'}
                                        lastIcon={'mi'}
                                        min={0}
                                    />
                                </div>
                            </div>

                            <div className='row-width'>
                                <Box className='basic-banner'>
                                    <div className='load-subtitle'>
                                        <h4 className='subtitile-basic '>Brake Cost</h4>
                                    </div>
                                </Box>
                                <div className='row-flex-response'>
                                    <div className='input-right'>
                                        <CustomInput
                                            name='numberOfBrake'
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type='number'
                                            label='Number of Brake'
                                            placeholder={'0'}
                                        />
                                    </div>
                                    <div className='input-right'>
                                        <CustomInput
                                            name='brakeCost'
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type='number'
                                            label='Brake Cost'
                                            placeholder={'0.00'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <CustomInput
                                        name='brakeChangeMiles'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type='number'
                                        label='Brake Miles'
                                        placeholder={'0.00'}
                                        step={'numeric'}
                                        lastIcon={'mi'}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row-flex-able'>
                            <div className='row-width'>
                                <Box className='basic-banner'>
                                    <div className='load-subtitle'>
                                        <h4 className='subtitile-basic'>Other Costs</h4>
                                    </div>
                                </Box>
                                <CustomInput
                                    name='otherMileCost'
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={editInput}
                                    type='number'
                                    label='Miles'
                                    placeholder={'0.00'}
                                    step={'numeric'}
                                    lastIcon={'mi'}
                                />
                            </div>

                            <div className='row-width'>
                                <Box className='basic-banner'>
                                    <div className='load-subtitle'>
                                        <h4 className='subtitile-basic'>Depreciation</h4>
                                    </div>
                                </Box>
                                <div className='row-flex-response'>
                                    <div className='input-right'>
                                        <CustomInput
                                            name='trailerCost'
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type='number'
                                            label='Trailer Cost'
                                            placeholder={'0.00'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <div className='input-right'>
                                        <CustomInput
                                            name='trailerSalePrice'
                                            control={control}
                                            rules={{ required: true }}
                                            disabled={editInput}
                                            type='number'
                                            label='Sale Price'
                                            placeholder={'0.00'}
                                            step={'numeric'}
                                            dollar={true}
                                        />
                                    </div>
                                    <CustomInput
                                        name='trailerMilesLimit'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={editInput}
                                        type='number'
                                        label='Trailer Miles Limit'
                                        placeholder={'0.00'}
                                        step={'numeric'}
                                        lastIcon={'mi'}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row-flex-able'>
                            <div className='row-width'>
                                <Box className='basic-banner space-between'>
                                    <div className='load-title basic-title flex'>
                                        <h4 className='load-basic flex'>Fix Cost</h4>
                                        <HtmlToolTip text={'information'} />
                                    </div>
                                </Box>
                                <div className='load-subtitle'>
                                    <h4 className='subtitile-basic'>Per Month Costs</h4>
                                </div>
                                <div className='row-flex-response'>
                                    <div className='input-right'>
                                        <CustomInput
                                            name='insuranceCost'
                                            control={control}
                                            rules={{ required: false }}
                                            disabled={editInput}
                                            type='number'
                                            label='Insurance'
                                            placeholder={'0.00'}
                                            dollar={true}
                                            step={'numeric'}
                                        />
                                    </div>
                                    <div className='input-right'>
                                        <CustomInput
                                            name='parkingCost'
                                            control={control}
                                            rules={{ required: false }}
                                            disabled={editInput}
                                            type='number'
                                            label='Parking'
                                            placeholder={'0.00'}
                                            dollar={true}
                                            step={'numeric'}
                                        />
                                    </div>
                                    <CustomInput
                                        name='otherFixCost'
                                        control={control}
                                        rules={{ required: false }}
                                        disabled={editInput}
                                        type='number'
                                        label='Other month costs'
                                        placeholder={'0.00'}
                                        dollar={true}
                                        step={'numeric'}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </div>
        </div>
    );
};
