import { FIRST_PACKAGE, FREE_PACKAGE, PREMIUM_PACKAGE, SECOND_PACKAGE } from 'utils';


export const yourPlanFeatures = (userPackage) => {
    if (userPackage === FREE_PACKAGE) {
        return {
            name: FREE_PACKAGE,
            price: '0',
            items: [
                { text: 'Unlimited Users' },
                { text: 'Unlimited Trucks and Trailers' },
                { text: 'Unlimited Loads and Customers' },
                { text: 'Unlimited Documents' },
                { text: 'Unlimited Maintenance Records' },
                { text: 'Cost Calculations' },
                { text: 'Profitability Calculations' },
                { text: 'Analytics' },
            ],
        };
    } else if (userPackage === FIRST_PACKAGE) {
        return {
            name: FIRST_PACKAGE,
            price: '4.99',
            items: [
                { text: 'Unlimited Users' },
                { text: 'Unlimited Trucks and Trailers' },
                { text: 'Unlimited Loads and Customers' },
                { text: 'Unlimited Documents' },
            ],
            notSupport: [
                { text: 'Cost Calculations' },
                { text: 'Profitability Calculations' },
                { text: 'Maintenance Records' },
                { text: 'Analytics' },
                { text: 'Email Support' },
                { text: 'Phone Support' },
            ],
        };
    } else if (userPackage === SECOND_PACKAGE) {
        return {
            name: SECOND_PACKAGE,
            price: '9.99',
            items: [
                { text: 'Unlimited Users' },
                { text: 'Unlimited Trucks and Trailers' },
                { text: 'Unlimited Loads and Customers' },
                { text: 'Unlimited Documents' },
                { text: 'Unlimited Maintenance Records' },
                { text: 'Cost Calculations' },
                { text: 'Profitability Calculations' },
                { text: 'Analytics' },
                { text: 'Email Support' },
            ],
            notSupport: [
                { text: 'Phone Support' },
            ],
        };
    } else if (userPackage === PREMIUM_PACKAGE) {
        return {
            name: PREMIUM_PACKAGE,
            price: '19.99',
            items: [
                { text: 'Unlimited Users' },
                { text: 'Unlimited Trucks and Trailers' },
                { text: 'Unlimited Loads and Customers' },
                { text: 'Unlimited Documents' },
                { text: 'Unlimited Maintenance Records' },
                { text: 'Cost Calculations' },
                { text: 'Profitability Calculations' },
                { text: 'Analytics' },
                { text: 'Email Support' },
                { text: 'Phone Support' },
            ],
        };
    }
};

// INVOICE_TABLE_CONSTANTS
export const invoiceTHeadCaptions = [
    { width: '155px', caption: 'Date' },
    { width: '308px', caption: 'Package' },
    // { width: '336px', caption: 'Number of trucks' },
    { width: '162px', caption: 'Price' },
    { width: '187px', caption: 'Action' },
];

export const getInvoices = (invoices = []) => {
    return invoices.map((invoice) => ({
        date: invoice.date,
        items: invoice.items,
        cost: invoice.cost,
    }));
};
