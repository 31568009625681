import axios from 'axios';

export const authService = {
    /* Sign Up */
    signUp: (body) => axios.post('/user/signup', body),
    /* Sign In  */
    signIn: (body) => axios.post('/auth/signin', body),
    /* Sign Out  */
    signOut: () => axios.post(`/auth/logout`, {}, { auth: true }),
    /* Change Password  */
    changePassword: (body) => axios.post(`/auth/changePassword`, { ...body }, { auth: true }),
    /* forgot Password  (email) */
    forgotPassword: (email) => axios.get(`/auth/forgotPassword/${email}`),
    /* Reset Password  */

    completeAccountService: (info) => {
        const res = axios.post(
            `/auth/register`,

            {
                newPassword: info.newPassword,
                confirmation: info.confirmation,
            },
            { headers: { 'registration-token': info.token } }
        );
        return res;
    },

    resetPassword: (info) => {
        let endpoint = `/auth/resetPassword`;
        const res = axios.post(
            endpoint,
            {
                newPassword: info.newPassword,
                confirmation: info.confirmation,
            },
            { headers: { 'reset-token': info.token } }
        );
        return res;
    },

    /** My Profile */
    getMyProfile: () => axios.get('/user/profile', { auth: true }),

    editMyProfile: (body) => axios.patch(`/user`, { ...body }, { auth: true }),
    /** End */

    /** Contact Us */
    mailterContactUs: (body) => axios.post(`/mailer/contactForm`, { ...body }),
};
