import axios from 'axios';

export const notesService = {
    /**  Notes Service  */
    getNotesService: (id, type) => axios.get(`/notes/${id}/${type}`, { auth: true }),

    addNoteService: (body) => axios.post(`/notes`, { ...body }, { auth: true }),

    editNoteService: (info) => axios.patch(`/notes/${info?.noteId}`, { text: info?.text }, { auth: true }),

    deleteNoteService: (id) => axios.delete(`/notes/${id}`, { auth: true }),
    /** End  */
};
