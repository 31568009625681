import { AddButton, FullTable } from 'components';
import { maintenanceBody, maintenanceHead, ACTION_TYPE } from './constants';
import { useModalBase } from 'utils';
import { AddMaintenance } from './addMaintenance';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { truckActions } from 'store';
import { RightModal } from './rightModal';
import * as Styled from './styled';
import './maintenance.scss';

export const Maintenance = () => {
    const [openRightModal, setOpenRightModal] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const truck_id = location.pathname.split('/').at(-1);
    const { maintenance } = useSelector(({ truck }) => truck);
    const { openModal } = useModalBase();
    useEffect(() => {
        dispatch(truckActions.getTruckMaintenance({ id: truck_id, skip: 0, limit: 20 }));
    }, [dispatch]);

    return (
        <div className="right-modal-wrapper">
            <div>
                <div className="flex-end add-service-button">
                    <AddButton addText={'Add Service'} handleAdd={() => openModal(true, <AddMaintenance />)} />
                </div>
                <FullTable
                    head={maintenanceHead}
                    body={maintenanceBody}
                    loadingType={ACTION_TYPE}
                    list={maintenance.data}
                    listCount={maintenance.count}
                    noText={'Maintenance'}
                    handleClick={(id) => {
                        setOpenRightModal(id);
                    }}
                />
                <Styled.CardContainer className="mobile-view">
                    {maintenance?.data?.map((item, index) => (
                        <div key={index}>
                            <Styled.Card key={index} onClick={() => setOpenRightModal(item.id)}>
                                <div>
                                    <span>Service Name: </span>
                                    <span>{item?.serviceName}</span>
                                </div>
                                <div>
                                    <span>Date: </span>
                                    <span>{item?.serviceDate}</span>
                                </div>
                                <div>
                                    <span>Vendor: </span>
                                    <span>{item?.vendor}</span>
                                </div>
                                <div>
                                    <span>Total Cost: </span>
                                    <span>${item?.totalCost}</span>
                                </div>
                            </Styled.Card>
                        </div>
                    ))}
                </Styled.CardContainer>
            </div>
            <RightModal id={openRightModal} open={openRightModal} setOpen={setOpenRightModal} />
        </div>
    );
};
