import React, { Fragment } from 'react';
import { Svg } from 'assets/images';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const AlertSubscription = () => {
    /**
     *  Alert Subscription
     */

    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));
    const history = useHistory();
    // let start = moment().add(14, 'days');
    let start = moment();
    let end = moment(activePackage?.packageEndDate);
    let different = end.diff(start, 'days') + 1

    const goUpgrate = () => {
        history.push('/plansAndPricing');
    };

    return (
        <Fragment>
            {activePackage?.isPackageActive ? (
                <div className="alert-container">
                    <div className="alert-box">
                        <div className="notificatin-box">
                            <img src={Svg.NotificationOrange} alt="Notifiaction-Orange" />
                        </div>
                        <div className="info-box">
                            <span className="text-info">Your free trial will </span>
                            <span className="text-orange">
                                end in
                                <span className="days-text">{different > 0 ? different : 'Today' } </span>
                                {different > 0 ? 'days' : ''  }
                            </span>
                        </div>
                        <div className="upgrade-box">
                            <button type="button" className="upgrate-btn" onClick={goUpgrate}>
                                Upgrade now
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="alert-ended">
                    <div className="alert-box">
                        <div className="notificatin-box">
                            <img src={Svg.NotificationFailed} alt="Notifiaction-Failed" />
                        </div>
                        <div className="info-box">
                            <span className="text-info">Your free trial </span>
                            <span className="text-bold">ended</span>
                        </div>
                        <div className="upgrade-box">
                            <button type="button" className="upgrate-btn" onClick={goUpgrate}>
                                Upgrade now
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};
