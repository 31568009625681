import React, { Fragment } from 'react';
import { Images } from 'assets/images';
import { Grid } from '@mui/material';

export const FeaturesFragment = () => {
    /**
     *  Hooks.
     */

    return (
        <Fragment>
            <div className='section-title'>
                <h2 className='title font-semiBold text-h2'>Features</h2>
            </div>
            <div className='section-title'>
                <p className='subtitle'>Our features are continuously being updated and new ones are being added to make our carriers’ life easier</p>
            </div>
            <div className='features-card'>
                <Grid container spacing={2} rowGap={2}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <div className='card-features'>
                            <div className='icon-flex-start'>
                                <img src={Images.Pikap} alt='Pikap-Img' className='feature-img' />
                            </div>
                            <div>
                                <div className='card-title flex-start'>
                                    <h5 className='font-semiBold text-xl'>Manage Trucks, Trailers, and Drivers</h5>
                                </div>
                                <div className='card-description flex-start'>
                                    <p className='features-parapgraphy'>Add, update and delete your trucks from your account Unlimited Users</p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <div className='card-features'>
                            <div className='icon-flex-start'>
                                <img src={Images.UsersImg} alt='Users-Img' className='feature-img' />
                            </div>
                            <div>
                                <div className='card-title flex-start'>
                                    <h5 className='font-semiBold text-xl'>Unlimited Users</h5>
                                </div>
                                <div className='card-description flex-start'>
                                    <p className='features-parapgraphy'>
                                        Add as many user accounts as needed. Unlimited Loads
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <div className='card-features'>
                            <div className='icon-flex-start'>
                                <img src={Images.LoadingTruck} alt='Loaded-Truck' className='feature-img' />
                            </div>
                            <div>
                                <div className='card-title flex-start'>
                                    <h5 className='font-semiBold text-xl'>Unlimited Loads</h5>
                                </div>
                                <div className='card-description flex-start'>
                                    <p className='features-parapgraphy'>
                                        Keep all your load data in one place and link loads to trucks, trailers and drivers
                                        {/*Create unlimited loads on your system.*/}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <div className='card-features'>
                            <div className='icon-flex-start'>
                                <img src={Images.PageImg} alt='Page-Img' className='feature-img' />
                            </div>
                            <div>
                                <div className='card-title flex-start'>
                                    <h5 className='font-semiBold text-xl'>Costs and Profits</h5>
                                </div>
                                <div className='card-description flex-start'>
                                    <p className='features-parapgraphy'>
                                        See your costs and revenues in real time.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
};
