import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useModal, DropdownSelect, Loader, GoBackButton } from 'components';
import { Box, Tab, Tabs, Button } from '@mui/material';
import { dropdownStatus } from './constants';
import { FindLoad, MODAL_NAMES } from 'utils';
import { BasicInfo, Contacts } from './fragments';
import { customersAction } from 'store/customers';
import { NotesSection } from '../../fragments';

export const CustomerInfo = () => {
    const { customerIdList, customerContactsList } = useSelector((state) => ({
        customerIdList: state?.customer?.customerIdList,
        customerContactsList: state?.customer?.customerContactsList,
    }));
    const { open } = useModal();
    const dispatch = useDispatch();
    const paramsRouter = useParams();
    const loader = FindLoad('GET_CUSTOMER_ID');
    const [valueTab, setValueTab] = useState(0);
    const [status, setStatus] = useState('');

    useEffect(() => {
        dispatch(customersAction.getCustomerId(paramsRouter.id));
        dispatch(customersAction.getCustomerContacts(paramsRouter.id));
    }, []);

    useEffect(() => {
        if (customerIdList) {
            setStatus(customerIdList?.status);
        }
    }, [customerIdList]);

    const handleChange = (event, newValueTab) => {
        setValueTab(newValueTab);
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
        const info = {
            ...customerIdList,
        };
        info.status = event.target.value;
        dispatch(customersAction.changeCustomerStatus(info?.id, info.status));
    };

    const handleDelete = () => {
        open(MODAL_NAMES.DELETE_MODAL, {
            title: `Customer  ${customerIdList?.displayId}`,
            subTitle: 'Deleting a customer will permanently remove it from the system',
            actionType: 'DELETE_CUSTOMER',
            historyPush: '/customers',
            handleDelete: () => handleDeleteDriver('DELETE_CUSTOMER'),
        });
    };

    const handleDeleteDriver = () => {
        dispatch(customersAction.deleteCustomer(paramsRouter?.id));
    };

    if (loader?.length) {
        return <Loader height={'100vh'} />;
    }

    return (
        <div className='customer-info info-container'>
            <div className='customer-header info-header'>
                <div className='info-router'>
                    <GoBackButton title={'Customer'} link={'/customers'} />
                </div>
                <div className='customer-banner'>
                    <div className=' banner-box'>
                        <div className='header-title'>
                            <h4 className='title'>
                                Customer <span>{customerIdList.displayId}</span>
                            </h4>
                        </div>
                        <div className='header-btn'>
                            <div className='wrapper-btn'>
                                <div className='popper-btn'>
                                    <div className='header-select'>
                                        <DropdownSelect
                                            value={status}
                                            setStatus={setStatus}
                                            handleChange={handleChangeStatus}
                                            options={dropdownStatus}
                                            idInput={'status'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='customer-tab'>
                    <Box className='tab-btns'>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={valueTab} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab label='Basic Info' />
                                <Tab label='Notes' />
                            </Tabs>
                        </Box>
                    </Box>
                </div>
            </div>
            <div className='customer-main info-main'>

                {valueTab === 0 && (
                    <Box className='tab-btns'>
                        <BasicInfo infoList={customerIdList} />
                        <Contacts
                            customerContactsList={customerContactsList}
                            customerIdList={customerIdList}
                        />
                        <div className='box-footer'>
                            <Button variant='text' color='error' onClick={handleDelete}>
                                <span className='text-error'>Delete Customer</span>
                            </Button>
                        </div>
                    </Box>
                )}
                {valueTab === 1 && (
                    <NotesSection type={'customer'} />
                )
                }
            </div>
        </div>
    );
};
