import { call, put, takeLatest } from 'redux-saga/effects';
import { notesService } from './notes.service';
import { httpRequestsOnErrorsActions } from 'store/http_requests_on_errors';
import { httpRequestsOnLoadActions } from 'store/http_requests_on_load';
import { httpRequestsOnSuccessActions } from 'store/http_requests_on_success';
import { CREATE_NOTE, DELETE_NOTE, EDIT_NOTE, GET_NOTES, GET_NOTES_SUCCESS } from './notes.type';

/** Note Saga */

function* getNotes({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(notesService.getNotesService, payload?.id, payload?.type);
        yield put({
            type: GET_NOTES_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

function* createNote({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(notesService.addNoteService, payload?.body);
        yield put({
            type: GET_NOTES,
            payload: { id: payload?.body?.resource, type: payload?.body?.onModel, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

function* editNote({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(notesService.editNoteService, payload?.info);
        yield put({
            type: GET_NOTES,
            payload: { id: payload?.info?.resource, type: payload?.info?.onModel, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

function* deleteNote({ payload, type }) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(notesService.deleteNoteService, payload?.id);
        yield put({
            type: GET_NOTES,
            payload: { id: payload?.type?.resource, type: payload?.type?.onModel, load: 'noLoad' },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type));
    }
}

/** End */

export const watchNotes = function* watchNotesAuth() {
    /** Note Saga */
    yield takeLatest(GET_NOTES, getNotes);
    yield takeLatest(CREATE_NOTE, createNote);
    yield takeLatest(EDIT_NOTE, editNote);
    yield takeLatest(DELETE_NOTE, deleteNote);
    /** End */
};
