import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader, useModal } from 'components';
import { PlansAndPricingCard } from './fragments/plansAndPricingCard';
import { FindLoad, FIRST_PACKAGE, FREE_PACKAGE, MODAL_NAMES, PREMIUM_PACKAGE, SECOND_PACKAGE } from 'utils';
import { yourPlanFeatures } from '../../pages/payments/constants';

export const PlansAndPricing = () => {
    const { activePackage } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
    }));
    const { open } = useModal();
    const history = useHistory();
    const loader = FindLoad('GET_MY_PROFILE_PACKAGE');

    const handleSubscribe = (type) => {
        if (activePackage?.isPackageActive === false || activePackage?.package === FREE_PACKAGE) {
            history.push('/checkout', { state: { type } });
        } else if (activePackage?.package === FIRST_PACKAGE ) {
            open(MODAL_NAMES.UPGRADE_DOWNGRADE, { modalType: 'upgrade', packageType: type });
        } else if(activePackage?.package === SECOND_PACKAGE && type === FIRST_PACKAGE){
            open(MODAL_NAMES.UPGRADE_DOWNGRADE, { modalType: 'downgrade', packageType: type });
        } else if(activePackage?.package === SECOND_PACKAGE && type === PREMIUM_PACKAGE){
            open(MODAL_NAMES.UPGRADE_DOWNGRADE, { modalType: 'upgrade', packageType: type });
        } else if(activePackage?.package === PREMIUM_PACKAGE ){
            open(MODAL_NAMES.UPGRADE_DOWNGRADE, { modalType: 'downgrade', packageType: type });
        }
    };

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className='plans-and-pricing-fragment'>
            <h2 className='plans-and-pricing-title'>Plans and pricing</h2>
            <div className='cards'>
                <div className='cards-wrapper'>
                    <PlansAndPricingCard
                        cardTitle={'Basic'}
                        cardSubtitle={'For companies that need to manage their data'}
                        cardPrice={'$4.99'}
                        cardSubPrice={'/truck'}
                        cardTime={'/mo'}
                        cardActionText={
                            activePackage?.package === FREE_PACKAGE || !activePackage?.isPackageActive ? 'Get Started' : 'Downgrade'
                        }
                        cardActionHandler={() => handleSubscribe(FIRST_PACKAGE)}
                        cardList={yourPlanFeatures(FIRST_PACKAGE)}
                        subscriptionPlan={activePackage?.package === FIRST_PACKAGE}
                    />
                    <PlansAndPricingCard
                        cardTitle={'Plus'}
                        cardSubtitle={'For companies that would like to gain insights into their data'}
                        cardPrice={'$9.99'}
                        cardSubPrice={'/truck'}
                        cardTime={'/mo'}
                        cardActionText={
                            activePackage?.package === FREE_PACKAGE || !activePackage?.isPackageActive ? 'Get Started' :
                                activePackage?.package === PREMIUM_PACKAGE ? 'Downgrade' : 'Upgrade'
                        }
                        cardActionHandler={() => handleSubscribe(SECOND_PACKAGE)}
                        cardList={yourPlanFeatures(SECOND_PACKAGE)}
                        subscriptionPlan={activePackage?.package === SECOND_PACKAGE}
                    />
                    <PlansAndPricingCard
                        cardTitle={'Premium'}
                        cardSubtitle={'For companies that would like to gain insights into their data'}
                        cardPrice={'$19.99'}
                        cardSubPrice={'/truck'}
                        cardTime={'/mo'}
                        cardActionText={
                            activePackage?.package === FIRST_PACKAGE || activePackage?.package === SECOND_PACKAGE ? 'Upgrade' : 'Get Started'
                        }
                        cardActionHandler={() => handleSubscribe(PREMIUM_PACKAGE)}
                        cardList={yourPlanFeatures(PREMIUM_PACKAGE)}
                        subscriptionPlan={activePackage?.package === PREMIUM_PACKAGE}
                    />
                </div>
            </div>
        </div>
    );
};
