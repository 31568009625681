import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoadsCard, LoadsTable } from './fragments';
import { LoadMenu } from './constants';
import { useModal, Loader, PaginationItem, FilterMenu, NoDataComponent } from 'components';
import { ACTION_TYPE, loadStatus } from './constants';
import { MODAL_NAMES, SendPageSave, SaveParams, RolePermissions, FindLoad } from 'utils';
import { truckActions, loadActions } from 'store';
import { Svg } from 'assets/images';

const title = 'Totals';

export const LoadBoard = () => {
    const { loadList, truckList, loadIdTruckList, activePackage } = useSelector((state) => ({
        loadList: state?.load?.loadList,
        truckList: state?.truck?.truckList,
        loadIdTruckList: state?.load?.loadIdTruckList,
        activePackage: state.payment.activePackage,
    }));

    const { open } = useModal();
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const options = truckList?.trucks;
    const loader = FindLoad(info?.truck ? 'GET_LOAD_ID' : 'GET_LOAD');

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 10;
        delete filteredInfo.truck;
        if (info?.status && info?.status !== 'ALL') {
            filteredInfo.status = info.status;
        } else {
            delete filteredInfo.status;
        }
        delete filteredInfo.page;

        if (info?.truck) {
            dispatch(loadActions.getLoadTruckId(info?.truck));
        } else {
            dispatch(loadActions.getLoad({ ...filteredInfo }));
        }
    }, [info, dispatch]);

    useEffect(() => {
        dispatch(truckActions.getTruck({}));
    }, [dispatch]);

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number);
    };

    const handleLoad = () => {
        open(MODAL_NAMES.CREATE_LOAD, { modalTitle: 'Add Load' });
    };

    const handleClick = (name, item) => {
        if (name === 'DELETE_LOAD') {
            dispatch(loadActions.deleteLoad(item?.id));
        } else if (name === 'Activate' || name === 'Inactivate') {
            const status = name === 'Activate';
            dispatch(loadActions.changeLoadStatus(status, item?.id));
        }
    };

    const handleFilterStatus = (event) => {
        SaveParams(history, { ...info, status: event?.target?.defaultValue });
    };

    const handleSelectTruck = (event) => {
        const pushInfo = {
            ...info,
        };
        event.target.value === 'ALL' ? delete pushInfo.truck : (pushInfo.truck = event.target.value);
        SaveParams(history, { ...pushInfo });
    };

    return (
        <div className="load-container page-container">
            <div className="load-board">
                <div className="load-banner">
                    <div className="banner-box">
                        <div className="box-title">
                            <h4 className="title">Load</h4>
                        </div>
                        <div className="header-input">
                            <div className="edit-group">
                                <select
                                    id="truck"
                                    className="form-control control-minimal"
                                    value={info?.truck}
                                    onChange={handleSelectTruck}>
                                    <option selected value={'ALL'}>
                                        All Trucks
                                    </option>
                                    {truckList?.trucks?.length &&
                                        options.map((item, index) => {
                                            return (
                                                <option key={index} value={item?.id}>
                                                    {item?.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="header-btn">
                            <div className="filter-menu">
                                <FilterMenu
                                    title={' Filter the Status '}
                                    list={loadStatus}
                                    status={info?.status}
                                    handleFilterStatus={handleFilterStatus}
                                />
                            </div>
                            {/*<CreateMobileButton handleClick={handleLoad} />*/}
                            {RolePermissions(activePackage) && (
                                <div style={{ display: 'flex' }} className="wrapper-btn">
                                    <button onClick={() => handleLoad()} className="add-button-style">
                                        <img src={Svg.AddMobileIcon} alt="Add Mobile Icon" />
                                        <span className="add-text">Add Load</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="banner-mobile">
                        <div className="header-input">
                            <div className="edit-group">
                                <select
                                    name="drive"
                                    id="driver"
                                    className="form-control control-minimal"
                                    placeholder="All Trucks"
                                    disabled={truckList?.count === 0}
                                    value={info?.truck}>
                                    <option selected value={'ALL'}>
                                        {truckList?.count !== 0 ? 'All Trucks' : 'Not Yet Trucks'}
                                    </option>
                                    {truckList?.trucks &&
                                        options.map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="load-tab">
                    <div className="desktop-loads">
                        <LoadsTable
                            title={title}
                            loadList={info?.truck ? loadIdTruckList : loadList}
                            handleClickButton={handleClick}
                            info={info}
                        />
                    </div>
                    <div className="mobile-loads">
                        {loader?.length ? (
                            <Loader />
                        ) : loadList?.loads?.length ? (
                            <LoadsCard
                                poperList={LoadMenu}
                                loadList={loadList}
                                handleClickButton={handleClick}
                                actionType={ACTION_TYPE}
                                activePackage={activePackage}
                            />
                        ) : (
                            <div style={{ height: '100%' }}>
                                <NoDataComponent />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {loadList?.count !== 0 && (
                <div className="mobile-pagination">
                    <PaginationItem
                        listLength={loadList?.count}
                        page={page}
                        handleReturn={(number) => changePage(number)}
                        count={loadList?.count}
                    />
                </div>
            )}
        </div>
    );
};
