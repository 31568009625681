import React from 'react';
import { Images } from 'assets/images';
import { Grid } from '@mui/material';

export const WeCanFragment = () => {
    /**
     *  Hooks.
     */

    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={6}>
                <div className='column card-we'>
                    <div className='flex-start'>
                        <h2 className='title font-semiBold text-h2'>
                            We can help you make better
                        </h2>
                    </div>
                    <div className='flex-start'>
                        <span className='decision font-semiBold'>
                            decisions
                            <span className='start-subtile font-semiBold'>faster</span>
                        </span>
                    </div>
                    <div className='card-title'>
                        <p className='we-pargraphy'>
                            In logistics, timing matters. We have streamlined load registration, profitability
                            calculations and planning so you can make your decisions faster on which loads to take and
                            which ones to skip. Load planning is a complex task and our tools are designed to reduce the
                            complexity so you can focus on what matters most.
                        </p>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className='card-banner'>
                    <img src={Images.WeCanLarg} alt='WE CAN LARG' className='full-img' />
                </div>
            </Grid>
        </Grid>
    );
};
