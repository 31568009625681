import { Svg } from 'assets/images';
import React from 'react';
import * as Styled from './styled';
const NoUploadedFiles = ({ open }) => {
    return (
        <Styled.NoFiles open={open}>
            <img src={Svg.NoFiles} alt="no-files" width="42px" height="35px" />
            <p>No Uploaded Files Yet</p>
        </Styled.NoFiles>
    );
};

export default NoUploadedFiles;
