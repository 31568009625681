import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { driversActions, truckActions, httpRequestsOnSuccessActions } from '../../store';
import {
    FullTable,
    TableWrapper,
    useModal,
    MobileCard,
    Loader,
    PaginationItem,
    FilterMenu,
    NoDataComponent,
    CreateMobileButton,
} from 'components';
import { ACTION_TYPE, driversHead, driversBody, DriversMenu, driverStatus } from './constants';
import { FindLoad, MODAL_NAMES, SaveParams, SendPageSave } from 'utils';

const title = 'Drivers';

export const DriversPage = () => {
    /**
     * Drivers Page.
     */

    const { driversList } = useSelector((state) => ({
        driversList: state?.drivers?.driversList,
    }));
    const { open } = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);

    const handleCreateDriver = () => {
        open(MODAL_NAMES.DRIVER_MODAL, { modalTitle: 'Add Driver' });
    };

    const handleClick = (item, name) => {
        if (name === 'DELETE_DRIVER') {
            dispatch(truckActions.deleteTruck(item?.id));
        } else if (name === 'RESEND') {
            dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', item?.id));
            dispatch(driversActions.reInvite(item?.id));
        } else if (name === 'Activate' || name === 'Inactivate') {
            const status = name === 'Activate';
            dispatch(truckActions.changeTruckStatus(status, item?.id));
        }
    };

    const handleFilterStatus = (event) => {
        SaveParams(history, { ...info, status: event?.target?.defaultValue });
    };

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 10;

        if (info?.status && info?.status !== 'ALL') {
            filteredInfo.status = info.status;
        } else {
            delete filteredInfo.status;
        }
        delete filteredInfo.page;
        dispatch(driversActions.getDriver({ ...filteredInfo }));
    }, [info]);


    return (
        <div className='drivers-container page-container'>
            <div className='desktop-view'>
                <TableWrapper
                    firstButton={'Active'}
                    secondButton={'Inactive'}
                    addButton={'Add Driver'}
                    buttonsTab={true}
                    tab={false}
                    buttonsTabAddButton={true}
                    handleIniteUser={handleCreateDriver}
                    wrapperTitile={title}>
                    <FullTable
                        head={driversHead}
                        body={driversBody}
                        loadingType={ACTION_TYPE}
                        list={driversList?.drivers}
                        listCount={driversList?.count}
                        noText={title}
                        handleClickButton={handleClick}
                        handleClick={(id) => history.push({ pathname: `/driver/${id}` })}
                    />
                </TableWrapper>
            </div>
            <div className='mobile-view'>
                <div className='wrapper-header'>
                    <div className='header-title'>
                        <h4 className='title'>{'Drivers'}</h4>
                    </div>
                    <div className='header-btn'>
                        <div className='filter-menu'>
                            <FilterMenu
                                title={' Filter the Status'}
                                list={driverStatus}
                                status={info?.status}
                                handleFilterStatus={handleFilterStatus}
                            />
                        </div>
                        <CreateMobileButton handleClick={handleCreateDriver} />
                    </div>
                </div>
                {loader?.length ?
                    <Loader />
                    :
                    driversList?.drivers?.length ? (
                        <div className='mobile-card'>
                            {driversList?.drivers?.map((item, index) => (
                                <div key={index} className='card-box'>
                                    <MobileCard
                                        poperList={DriversMenu}
                                        actionType={ACTION_TYPE}
                                        item={item}
                                        handleClickButton={handleClick}
                                        handleClick={(id) => history.push({ pathname: `/driver/${item?.id}` })}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div style={{ height: '100%' }}>
                            <NoDataComponent />
                        </div>
                    )}
            </div>
            {driversList?.count !== 0 && (
                <div className='mobile-pagination'>
                    <PaginationItem
                        listLength={driversList?.count}
                        page={info?.page}
                        handleReturn={(number) => SendPageSave(number, info, history)}
                        count={driversList?.count}
                    />
                </div>
            )}
        </div>
    );
};
