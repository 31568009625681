import React from 'react';
import { Container } from '@mui/material';

export const PrivacyPage = () => {
    /**
     *  Privacy Page.
     */

    return (
        <div className="privacy-page">
            <Container maxWidth="lg">
                <div className="page-info">
                    <div className="title-box">
                        <h3 className="privacy-title">
                            Privacy
                            <span className="space">&</span>
                            Policy
                        </h3>
                    </div>
                    <div className="privacy-body">
                        <p className="privacy-subtitle">
                            1. Introduction <br />
                            We are committed to protecting your privacy. This Privacy Policy describes how we collect, use, and disclose
                            your personal information when you use our truck management software
                        </p>
                        <p className="privacy-subtitle">
                            2. Information Collection and Use <br />
                            We collect personal information that you provide to us when you use our software, such as your name, email
                            address, and billing information. We may use this information to provide you with access to our software, to
                            process payments, to provide you with access to our program, and to communicate 3. 4. 5. 6. 7. 8. with you about
                            your membership.
                        </p>
                        <p className="privacy-subtitle">
                            3. Information Sharing and Disclosure <br />
                            We may share your personal information with third-party service providers who perform services on our behalf,
                            such as payment processing, data analysis, and marketing. We may also disclose your personal information if we
                            believe that such disclosure is necessary to comply with applicable law or to protect our rights or property.
                        </p>
                        <p className="privacy-subtitle">
                            4. Membership Package Details <br />
                            When you purchase a membership package through our payment system, we collect information about your membership
                            level, membership duration, and any additional features or services that you may have purchased. We may use this
                            information to provide you with access to our program and to personalize your membership experience.
                        </p>
                        <p className="privacy-subtitle">
                            5. Data Security <br />
                            We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.
                            We use encryption technology to protect your payment information and restrict access to your personal
                            information to authorized personnel only.
                        </p>
                        <p className="privacy-subtitle">
                            6. Your Rights <br />
                            You have the right to access, correct, and delete your personal information that we hold about you. You may also
                            have the right to restrict or object to the processing of your personal information. To exercise these rights,
                            please contact us using the contact information provided below.
                        </p>
                        <p className="privacy-subtitle">
                            7. Changes to this Privacy Policy <br />
                            We reserve the right to modify this Privacy Policy at any time. We will post the updated Privacy Policy on our
                            website and notify you of any material changes.
                        </p>
                        <p className="privacy-subtitle">
                            8. Contact Us <br />
                            If you have any questions or concerns about our Privacy Policy, please contact us at [contact information].
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
};
