/**  Types */

/**  Customer Type */
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';

export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';

export const GET_CUSTOMER_ID = 'GET_CUSTOMER_ID';
export const GET_CUSTOMER_ID_SUCCESS = 'GET_CUSTOMER_ID_SUCCESS';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

export const CHANGE_CUSTOMER_STATUS = 'CHANGE_CUSTOMER_STATUS';

/**  Customer Contact Type */
export const CREATE_CUSTOMER_CONTACT = 'CREATE_CUSTOMER_CONTACT';

export const EDIT_CUSTOMER_CONTACT = 'EDIT_CUSTOMER_CONTACT';

export const GET_CUSTOMER_CONTACTS = 'GET_CUSTOMER_CONTACTS';
export const GET_CUSTOMER_CONTACTS_SUCCESS = 'GET_CUSTOMER_CONTACTS_SUCCESS';

export const DELETE_CUSTOMER_CONTACT = 'DELETE_CUSTOMER_CONTACT';
