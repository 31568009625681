import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './auth.service';
import {
    SIGN_IN,
    SIGN_UP,
    SIGN_OUT,
    CHANGE_PASSWORD,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    GET_MY_PROFILE,
    GET_MY_PROFILE_SUCCESS,
    EDIT_MY_PROFILE,
    COMPLETE_ACCOUNT,
    CONTACT_US,
} from './auth.type';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

function* signUp({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.signUp, payload.body);
        localStorage.setItem('access-token', res.data.token);
        if (res?.data?.userType) {
            localStorage.setItem('user', res.data.userType);
        }
        let link = res?.data?.userType === 'ORG' ? '/users' : '/truck';
        window.location.replace(link);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* signIn({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.signIn, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        localStorage.setItem('access-token', res.data.token);
        localStorage.setItem('user', res.data.userType);

        if (res.data.userType === 'ORG') {
            window.location.replace('/users');
        } else {
            window.location.replace('/trucks');
        }
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* signOut(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.signOut);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        localStorage.removeItem('access-token');
        localStorage.removeItem('user');
    } catch (err) {
        localStorage.removeItem('access-token');
        localStorage.removeItem('user');
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* changePassword({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.changePassword, payload?.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* resetPassword({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.resetPassword, payload?.passwords);
        localStorage.setItem('access-token', res.data.token);
        if (res?.data?.userType) {
            localStorage.setItem('user', res.data.userType);
        }
        yield put({
            type: GET_MY_PROFILE,
            payload: res.data,
        });
        let link = res?.data?.userType === 'ORG' ? '/users' : '/truck';
        window.location.replace(link);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* completeAccount({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.completeAccountService, payload);
        localStorage.setItem('access-token', res.data.token);
        if (res?.data?.userType) {
            localStorage.setItem('user', res.data.userType);
        }
        window.location.replace('/users');
        yield put({
            type: GET_MY_PROFILE,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* forgotPassword({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.forgotPassword, payload?.email);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** My Profile */
function* getMyProfile({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getMyProfile);
        yield put({
            type: GET_MY_PROFILE_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editMyProfile({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.editMyProfile, payload);
        yield put({
            type: GET_MY_PROFILE,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

/** Contact Us  */
function* contactUs({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.mailterContactUs, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchAuth = function* watchUserAuth() {
    yield takeLatest(SIGN_UP, signUp);
    yield takeLatest(SIGN_IN, signIn);
    yield takeLatest(SIGN_OUT, signOut);
    yield takeLatest(CHANGE_PASSWORD, changePassword);
    yield takeLatest(RESET_PASSWORD, resetPassword);
    yield takeLatest(COMPLETE_ACCOUNT, completeAccount);
    yield takeLatest(FORGOT_PASSWORD, forgotPassword);

    /** My Profile */
    yield takeLatest(GET_MY_PROFILE, getMyProfile);
    yield takeLatest(EDIT_MY_PROFILE, editMyProfile);
    /** Edit */

    yield takeLatest(CONTACT_US, contactUs);
};
