import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Svg } from '../../assets/images';

export const CustomInput = ({
                                type,
                                defaultValue,
                                control,
                                name,
                                idInput,
                                placeholder,
                                rules,
                                label,
                                describedby,
                                imgSrc,
                                errMessage,
                                disabled,
                                min,
                                max,
                                maxLength,
                                step,
                                dollar,
                                lastIcon
                            }) => {
    /**
     * Custom Input.
     */

    const [openEye, setOpenEye] = useState(false);

    return (
        <Controller
            control={control ? control : null}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <div className='input-wrapper'>
                    {label && (
                        <label style={{ color: disabled ? '#758EA6' : '#41586D' }} htmlFor={idInput} className='label'>
                            {label}
                        </label>
                    )}
                    <div
                        style={
                            disabled ? { borderColor: '#8CA2B8' } :
                                errMessage || error ? { border: '1px solid #d03325' } : label && value ? { border: '1px solid #0E9594' } : {}
                        }
                        className='custom-input'>
                        {imgSrc && (
                            <div className={'input-icon'}>
                                <img src={imgSrc} alt='icon' />
                            </div>
                        )}

                         <p style={{ color: disabled ? '#758EA6' : !value ? '#758EA6' : '#41586d'}} className={'dollar-style'}> { dollar ? '$' : ' '}</p>
                         <input
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            type={openEye ? 'text' : type}
                            className='input-form-control'
                            id={idInput}
                            aria-describedby={describedby}
                            placeholder={placeholder}
                            disabled={disabled}
                            // step={ 0.01 }
                            step={step === 'numeric' ? 0.01 : 0}
                            min={min ? min : 0}
                            max={max ? max : ''}
                            maxLength={maxLength ? maxLength : ''}
                        />


                        {type === 'password' && (
                            <div className='password-input' onClick={() => setOpenEye(!openEye)}>
                                {openEye ? (
                                    <img src={error?.type ? Svg.EyeRedOpen : Svg.EyeOpen} alt='Eye Open' />
                                ) : (
                                    <img src={error?.type ? Svg.EyeRedClose : Svg.EyeClose} alt='Eye Close' />
                                )}
                            </div>
                        )}

                        {lastIcon &&
                            <p style={{
                                color: disabled ? '#758EA6' : !value ? '#758EA6' : '#41586d',
                            }} className={'mill-style'}>
                                {lastIcon}
                            </p>
                        }
                    </div>


                    <p className='custom-error-messages'>

                        {/*{!errMessage && error?.type !== 'required' && maxLength &&*/}
                        {/*    <p style={{*/}
                        {/*        fontSize: '12px',*/}
                        {/*        color: '#8CA2B8',*/}
                        {/*        // marginTop: '4px',*/}
                        {/*    }}> Max {maxLength} Characters</p>*/}
                        {/*}*/}


                        {errMessage ? errMessage : error?.type === 'required' ? 'This Field is required' : error?.message}
                    </p>
                </div>
            )}
        />
    );
};
