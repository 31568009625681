import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, CustomInput, MaskInput, CreateCancel } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, driversActions } from 'store';
import { editDriverDetails } from '../../../../store/drivers/drivers.action';

export const CreateDriver = () => {
    /**
     * Create Driver
     */

    const { close, params } = useModal();
    const ACTION_TYPE = params?.info ? 'EDIT_DRIVER' : 'CREATE_DRIVERS';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);

    const { control, handleSubmit, setError } = useForm({
        defaultValues: {
            firstName: params?.info?.firstName,
            lastName: params?.info?.lastName,
            phone: params?.info?.phone,
            email: params?.info?.email,
        },
    });

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };
        if (data?.phone) {
            sendInfo.phone =  data?.phone && parseInt(data?.phone.replace(/[^0-9]/g, '')) + ''
                // `+1${parseInt(data?.phone.replace(/[^0-9]/g, ''))}`;
        }
        if (params?.info) {
            dispatch(driversActions.editDriverDetails(sendInfo, params?.info?.id));
        } else {
            dispatch(driversActions.createDrivers(sendInfo));
        }
    };

    useEffect(() => {
        if (loadSuccess) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);


    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const mobileError =
        backError?.error === 'This Organization has a driver with this data.'
            ? 'This Organization has a driver with this data.'
            : backError?.error && backError?.error?.[0] === 'phone must be a valid phone number'
            ? 'phone must be a valid phone number'
            : '';

    return (
        <div className="invite-form">
            <div className="driver-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomInput
                        name="firstName"
                        control={control}
                        rules={{ required: true }}
                        type="text"
                        label="First Name*"
                        placeholder={'Driver name'}
                        maxLength={40}
                    />
                    <CustomInput
                        name="lastName"
                        control={control}
                        rules={{ required: true }}
                        type="text"
                        label="Last Name*"
                        placeholder={'Driver last name'}
                        maxLength={40}
                    />
                    <CustomInput
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        type="email"
                        label="Email*"
                        placeholder={'User Email'}
                        errMessage={
                            backError?.error === 'User already exists.'
                                ? 'User already exists.'
                                : backError?.error && backError?.error?.[0] === 'email must be an email'
                                ? 'email must be an email'
                                :  mobileError === 'This Organization has a driver with this data.'
                            ? 'Email already exists.':''
                        }
                    />
                    <MaskInput
                        name="phone"
                        label="Phone Number*"
                        control={control}
                        rules={{ required: true }}
                        type="phone"
                        placeholder={'Driver phone number'}
                        errMessage={!!mobileError}
                        errMessageToShow={
                            mobileError === 'phone must be a valid phone number'
                                ? 'Phone must be a valid phone number'

                                : ''
                        }
                    />
                    <CreateCancel title={'Save'} actionType={ACTION_TYPE} closeType={ACTION_TYPE} />
                </form>
            </div>
        </div>
    );
};
