import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Svg } from 'assets/images';
import { MODAL_NAMES } from 'utils';
import { useModal, MinLoader, NoDataComponent } from 'components';
import { Box } from '@mui/material';
import { driversActions } from 'store';
import { TableNoInfo } from 'components/table/tableNoInfo';
import { RolePermissions } from '../../../utils/hooks';

export const DriverFuel = ({ fuelList }) => {
    /**
     * Driver Fuel.
     */

    const { driversDetailsList, activePackage } = useSelector((state) => ({
        driversDetailsList: state?.drivers?.driversDetailsList,
        activePackage: state.payment.activePackage,
    }));
    const { open } = useModal();
    const dispatch = useDispatch();
    const driverId = driversDetailsList?.id;
    const load = 0;

    const handleFuelCard = () => {
        open(MODAL_NAMES.ADD_FUEL_CARD, { modalTitle: 'Add Fuel Card' });
    };

    const editFuelCard = (item) => {
        open(MODAL_NAMES.ADD_FUEL_CARD, {
            modalTitle: 'Edit Fuel Card',
            info: {
                ...item,
            },
        });
    };

    const handleDelete = (item) => {
        open(MODAL_NAMES.DELETE_MODAL, {
            title: `Fuel Card`,
            subTitle: 'Deleting a customer will permanently remove it from the system',
            actionType: 'DRIVER_DELETE_FUEL',
            handleDelete: () => handleDeleteFuel('DRIVER_DELETE_FUEL', item?.id),
        });
    };

    const handleDeleteFuel = (_, Id) => {
        dispatch(driversActions.deleteFuel(driverId, Id));
    };

    return (
        <Box className="details-container">
            <div className="fuel-container rate-container ">
                <div className="space-between">
                    <div className="driver-title">
                        <h4 className="title-basic">Fuel Cards</h4>
                    </div>
                    <div className="basic-title">
                        {RolePermissions(activePackage) && (
                            <button className="add-rate" onClick={handleFuelCard}>
                                <span>
                                    <img src={Svg.AddStepIcon} alt="Add Step Icon" />
                                </span>
                                <span className="rate-title">
                                    Add
                                    <span className="desktop-text"> Fuel Card</span>
                                </span>
                            </button>
                        )}
                    </div>
                </div>
                <div className="fuel-body">
                    <div className="card-header">
                        <div className="title">
                            <span>Fuel Card Number</span>
                        </div>
                        <div className="title">
                            <span>Action</span>
                        </div>
                    </div>
                    {fuelList?.length ? (
                        <>
                            {fuelList.map((item, index) => (
                                <div className="card-body" key={index}>
                                    <div className="fuel-box">
                                        <div className="flex">
                                            <div className="pay-box">
                                                <img src={Svg.PayCard} alt="Pay Card" />
                                            </div>
                                            <span className="fuel-phone">
                                                {item?.cardNumber
                                                    ? `${item?.cardNumber?.slice(0, 4)}-${item?.cardNumber?.slice(
                                                          4,
                                                          8
                                                      )}-${item?.cardNumber?.slice(8, 12)}-${item?.cardNumber?.slice(12, 16)}`
                                                    : ''}
                                            </span>
                                        </div>

                                        <div className="flex">
                                            <button className="edit-btn" onClick={() => editFuelCard(item)}>
                                                {load.length ? (
                                                    <MinLoader color={'yellow'} />
                                                ) : (
                                                    <img src={Svg.EditYellowIcon} alt="Edit Yellow Icon" />
                                                )}
                                            </button>
                                            <button className="delete-btn" onClick={() => handleDelete(item)}>
                                                {load.length ? <MinLoader color={'red'} /> : <img src={Svg.DeleteItem} alt="DeleteItem" />}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <Fragment>
                            <NoDataComponent />
                        </Fragment>
                        // <div style={{ height: '100px' }}>
                        //     <TableNoInfo text={`Not Driver Fuel`} />
                        // </div>
                    )}
                </div>
            </div>
        </Box>
    );
};
