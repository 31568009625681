import React, { Fragment } from 'react';
import { RouterGlobal } from 'root/routerGlobal';

function App() {
    return (
        <Fragment>
            <RouterGlobal />
        </Fragment>
    );
}

export default App;
