import React from 'react';
import { Box } from '@mui/material';
import { Svg } from 'assets/images';

export const CostCard = ({ svg, title, total, alt, value, text, totlaAmount, priceSymbol }) => {
    /**
     *  Cost Card.
     */

    return (
        <Box className={`cost-card  ${total ? 'active-card ' : 'light-card'}`}>
            <Box className="cost-box">
                <Box className=" cost-header">
                    {svg && (
                        <div className="img-box">
                            <img src={svg} alt={alt} />
                        </div>
                    )}
                    <div className="title-box">
                        <span>{title}</span>
                    </div>
                </Box>
                <Box className="cost-body">
                    {total ? (
                        <div className="flex ">
                            <span style={{marginRight:0}} className="img-box">
                                <img src={Svg.DollarIcon} alt="Dollar-Icon" />
                            </span>
                            <span  style={{marginLeft:'0', marginBottom:'2px'}} className="text-bold">
                                {totlaAmount?.totalPerMail ? (totlaAmount?.totalPerMail).toFixed(2) : '0'}
                            </span>
                        </div>
                    ) : (
                        <div className="flex">
                            <div style={{marginRight:0}} className="img-box">
                                {priceSymbol === 'dollar' ?
                                    <img src={Svg.DollarIcon} alt="Dollar-Icon" />
                                    :
                                    <img src={Svg.CentsIcon} alt="Cents-Icon" />
                                }
                            </div>
                            <div style={{marginBottom: '2px'}}>
                            <span className="text-cents">{
                                  value ? value.toFixed(2) :
                                      totlaAmount?.monthlyTotal ?  (totlaAmount?.monthlyTotal).toFixed(2) : 0}/</span>

                            <span className="text-per"> {text ? text : 'per miles'} </span>
                            </div>
                        </div>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
