import React from 'react';
import { useHistory } from 'react-router-dom';
import { yourPlanFeatures } from '../constants';
import { Svg } from 'assets/images';

export const PayInfo = ({}) => {
    const history = useHistory();
    const info = history?.location?.state;
    const userPackage = yourPlanFeatures(info?.state?.type);

    return (
        <div className="checkout-pay-info-wrapper">
            <div className="checkout-pay-info">
                <p className="price-title">Order Summary</p>
                <div className="plane-type">
                    <p>{userPackage?.name}</p>
                    <p>
                        {`$${userPackage?.price}`} <span style={{ color: '#C8D8E6' }}>/MO</span>
                    </p>
                </div>

                {userPackage &&
                    userPackage?.items?.map((i, k) => (
                        <div key={k} className="items-wrapper" style={{marginBottom:'0'}}>
                            <div className="checkmark-circle">
                                <img src={Svg.MarkVector} alt="MarkVector" />
                            </div>
                            <p className="features-text">{i?.text}</p>
                        </div>
                    ))}

                <div className="plane-type">
                    <p>Total: </p>
                    <p>{`$${userPackage?.price}`}</p>
                </div>
            </div>

            <div className="check-info-wrapper">
                You can check your renewal date or cancel anytime via your
                <span>Billing and Payment Page</span>
            </div>
        </div>
    );
};
