import React, { Fragment, useState } from 'react';
import { Svg } from 'assets/images';
import { Button, Container } from '@mui/material';
import { GetStart, useModal } from 'components';
import { SideMenu } from 'pages/landing/fragments';
import { MODAL_NAMES } from 'utils';
import { Link } from 'react-router-dom';

export const HeaderLayout = () => {
    /**
     * Header Layout
     */

    const { open } = useModal();
    const [said, setSaid] = useState(false);

    const [state, setState] = useState({
        top: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
        setSaid(!said);
    };

    const handleGetStart = () => {
        open(MODAL_NAMES.SIGN_UP);
    };

    const handleLogIn = () => {
        open(MODAL_NAMES.SIGN_IN);
    };

    const handleTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Fragment>
            <header className='header-section'>
                <Container maxWidth='lg'>
                    <div className='header-container'>
                        <div className='header-box'>
                            <div className='box-logo'>
                                <Link to={'/'}>
                                    <img src={Svg.LogoGreen} alt='Logo' className='Logo-Green' />
                                </Link>
                            </div>
                            <div className='flex header-links'>
                                <a onClick={handleTop}>
                                    <Button variant='text' className='dark-link'>
                                        Home
                                    </Button>
                                </a>
                                <a href='#features'>
                                    <Button variant='text' className='dark-link'>
                                        Features
                                    </Button>
                                </a>
                                <a href='#pricing'>
                                    <Button variant='text' className='dark-link'>
                                        Pricing
                                    </Button>
                                </a>
                                <a href='#contactUs'>
                                    <Button variant='text' className='dark-link'>
                                        Contact us
                                    </Button>
                                </a>
                                <Button variant='text' className='dark-link' onClick={() => handleLogIn()}>
                                    Log In
                                </Button>
                                <div className='header-get'>
                                    <GetStart handelClick={() => handleGetStart()} title={'Get Started'} />
                                </div>
                            </div>
                            <div className=' burger-link'>
                                <Button onClick={toggleDrawer('top', true)}>
                                    <img src={Svg.Burger} alt='Burger-Menu' />
                                </Button>
                                <div className='side-menu'>
                                    <SideMenu anchor={'top'} saidOpen={said} toggleDrawer={toggleDrawer} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </header>
        </Fragment>
    );
};
