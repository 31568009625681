import React, { useState, useEffect } from 'react';
import { Svg } from 'assets/images';
import { IconButton } from '@mui/material';
import { useModal } from 'components';
import { MODAL_NAMES } from 'utils';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const UserInfo = ({}) => {
    /**
     * Hooks.
     */

    const { myProfile } = useSelector((state) => ({
        myProfile: state?.auth.myProfile,
    }));

    const { open } = useModal();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (myProfile?.avatar?.url) {
            setUrl(myProfile?.avatar?.url);
        }
    }, [myProfile]);

    return (
        <div className="user-info ">
            <div className="user-info-wrapper">
                {/*<div className="notification-box">*/}
                {/*  <IconButton className="notification-btn">*/}
                {/*    <img src={Svg.Notification} alt="Notification" />*/}
                {/*  </IconButton>*/}
                {/*</div>*/}

                <div className="user-box">
                    <IconButton className="menu-btn" onClick={() => open(MODAL_NAMES.MENU_USER)}>
                        {url ? <img src={url} alt={'icon'} className="avatar-img" /> : <img src={Svg.UserMenu} alt="User-Menu" />}
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
