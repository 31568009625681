import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Images, Svg } from 'assets/images';
import { CheckCoastPermission, MODAL_NAMES } from 'utils';
import { PopperComponent, PriceRow, StatusTruck, TextRow } from 'components';
import moment from 'moment';
import { HtmlTooltip } from '../../../components/messages/htmlTool';

export const LoadsCard = ({ tab, indexTab, loadList, handleClickButton, actionType, activePackage }) => {
    /**
     * Hooks.
     */

    const history = useHistory();

    const LoadMenu = (item) => {
        return [
            {
                title: 'Edit',
                params: {
                    info: item,
                    modalTitle: 'Edit Load',
                },
                name: MODAL_NAMES.CREATE_LOAD,
            },
            {
                title: item?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                name: MODAL_NAMES.INACTIVATE_MODAL,
                params: {
                    title: item?.type,
                    modalType: item?.status === 'ACTIVE' ? 'Inactivate' : 'Activate',
                    subTitle: 'Inactivating a user will not active in the system. You can activate user when do you want.',
                    actionType: 'CHANGE_LOAD_STATUS',
                    handleChange: () => handleClickButton(item?.status === 'ACTIVE' ? 'Inactivate' : 'Activate', item),
                },
            },
            {
                title: 'Delete',
                name: MODAL_NAMES.DELETE_MODAL,
                params: {
                    title: item?.type,
                    subTitle: 'Deleting a user will permanently remove it from the system',
                    actionType: 'DELETE_LOAD',
                    handleDelete: () => handleClickButton('DELETE_LOAD', item),
                },
                color: '#D03325',
            },
        ];
    };

    const handleRenderStops = (type, item) => {
        const lastStopDate = item?.stops.find((i) => i.type === 'PICKUP');
        const firstStop = item?.stops.find((i) => i.type === 'PICKUP');
        const lastStop = item?.stops.find((i) => i.type === 'DROP_OFF');
        if (item?.stops) {
            if (type === 'stopDate') {
                if (firstStop) {
                    return moment(lastStopDate?.date).format('MM/DD/YYYY');
                } else {
                    return 'Not Set';
                }
            }
            if (type === 'first') {
                if (firstStop) {
                    return firstStop?.address?.formattedAddress;
                } else {
                    return 'Not Set';
                }
            }
            if (type === 'last') {
                if (lastStop) {
                    return lastStop?.address?.formattedAddress;
                } else {
                    return 'Not Set';
                }
            }
        } else {
            return 'Not Set';
        }
    };

    const StatusMobile = (info) => {
        return (
            <div style={{ height: '24px' }} className='flex status-box'>
                {info === 'ACTIVE' && <div className='title-active '> Active</div>}
                {info === 'PENDING' && <div className='title-pending'> Pending</div>}
                {info === 'INACTIVE' && <div className='title-inactive'> Inactive</div>}
                {info === 'DELIVERED' && <div className='title-deliverd'>Delivered</div>}
                {info === 'COMPLETE' && <div className='title-complete'>Complete</div>}
                {info === 'CANCELED' && <div className='title-canceld'>Canceled</div>}
                {info === 'HOLD' && <div className='title-hold'>Hold</div>}
                {info === 'TERMINATED' && <div className='title-terminated'>Terminated</div>}
                {info === 'RECRUITMENT' && <div className='title-recruitment'>Recruitment</div>}
            </div>
        );
    };

    const renderText = (text) => {
        const screenWidth =
            window.innerWidth > 320 && window.innerWidth < 360 ? 100 :
                window.innerWidth > 360 && window.innerWidth < 400 ? 120 :
                    window.innerWidth > 400 && window.innerWidth < 440 ? 160 :
                        window.innerWidth > 440 && window.innerWidth < 500 ? 210 :
                            window.innerWidth > 550 && window.innerWidth < 600 ? 260 :
                                window.innerWidth > 600 && window.innerWidth < 650 ? 310 :
                                    window.innerWidth > 700 ? 360 : 200;
        if (text) {
            const count = Math.ceil(screenWidth / 7);
            const slicedText = '' + text?.slice(0, count);
            return (
                <div style={{ color: '#506C84' }} className='text-style'>
                    {text?.length > count ? (
                        <HtmlTooltip title={<p>{text}</p>} placement='top-start'>
                            <p style={{ textOverflow: 'ellipsis' }}>{`${slicedText}...`}</p>
                        </HtmlTooltip>
                    ) : (
                        <p>{text}</p>
                    )}
                </div>
            );
        } else {
            return '...';
        }
    };

    return (
        <Box value={tab} index={indexTab}>
            <div className='loads-container tab-container'>
                <div>
                    {actionType === 'GET_TRUCK' && (
                        <>
                            {loadList?.loads.map((item, index) => (
                                <div className='loads-table' key={index}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'>
                                            <Box className='accordion-header'>
                                                <Box className='tabls-header'>
                                                    <div className='type-header'>
                                                        <div className='load-id text-bold '>Load ID:</div>
                                                        <div className='load-text'>{item?.loadId}</div>
                                                    </div>
                                                    <div className='profit-header'>
                                                        <div
                                                            className='profit-number text-error'>${item?.profit?.amount}</div>
                                                        <div className='profit-menu'>
                                                            {actionType === 'GET_LOAD' &&
                                                                <PopperComponent list={LoadMenu(item)} />}
                                                        </div>
                                                    </div>
                                                </Box>
                                                <Divider className='divider' />
                                                <Box className='panel-mobile'>
                                                    <div className='profit-info'>
                                                        <div className='profit-text'>Profit:</div>
                                                        <div
                                                            className='profit-number text-error'>-${item?.profit?.amount}</div>
                                                    </div>
                                                    <div className='profit-info'>
                                                        <div className='profit-text'>Truck ID:</div>
                                                        <div
                                                            className='profit-number text-error'
                                                            style={{ color: '#41586D' }}>{item?.truck?.id}</div>
                                                    </div>
                                                    <div className='panel-row'>
                                                        <div className='profit-text'>{item?.pickUp}</div>
                                                        <div className='profit-number'>
                                                            <img src={Images.NumberTable} alt='Number-Table' />
                                                        </div>
                                                        <div className='panel-images'>
                                                            <img src={Svg.CircleRight} alt='Circle-Right' />
                                                        </div>
                                                    </div>
                                                    <div className='panel-row panel-img'>
                                                        <img src={Svg.CircleRight} alt='Circle-Right' />
                                                    </div>
                                                    <div className='panel-row'>
                                                        <div className='profit-text'>{item?.pickUp}</div>
                                                        <div className='profit-number'>
                                                            <img src={Images.NumberTable} alt='Number-Table' />
                                                        </div>
                                                    </div>
                                                </Box>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails className='details-box'>
                                            <Box className='table-details collapse-box'>
                                                <div className='details-column'>
                                                    <div className='title'>Customer Rate:</div>
                                                    <div className='number'>$ {item?.customer?.rate}</div>
                                                </div>
                                                <div className='details-column'>
                                                    <div className='title'>Fuel Cost:</div>
                                                    <div className='number'>$ {` Not Set `}</div>
                                                </div>
                                                <div className='details-column'>
                                                    <div className='title'>Weight:</div>
                                                    <div className='number'>$ {` Not Set `}</div>
                                                </div>
                                                <div className='details-column'>
                                                    <div className='title'>Mile:</div>
                                                    <div className='number'>{` Not Set `} mi</div>
                                                </div>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))}
                        </>
                    )}

                    {actionType === 'GET_LOAD' && (
                        <div className='load-mobile'>
                            {loadList?.loads.map((item, index) => (
                                <Box className='loads-table' key={index}
                                     onClick={(id) => history.push({ pathname: `/load/${item?.id}` })}>
                                    <Box className='tabls-header'>
                                        <div className='type-header'>
                                            <div className='load-id '>Load ID:</div>
                                            <div className='load-text'  style={{ color: '#41586D', fontWeight:400}}>{item?.displayId}</div>
                                        </div>
                                        <div className='type-status'>
                                            <StatusTruck info={item.status} />
                                        </div>
                                    </Box>
                                    <Divider className='divider' />
                                    <Box className='accordion-body'>
                                        <Box className='panel-mobile'>
                                            <div className='profit-info'>
                                                <div className='profit-bold'>Truck:</div>
                                                <div className='profit-subtitle' style={{ color: '#41586D'}}>
                                                    {item?.truck?.displayId ? item?.truck?.displayId : 'Not Set'}
                                                </div>
                                            </div>

                                            {CheckCoastPermission(activePackage) &&
                                                <>
                                                    <div className='profit-info'>
                                                        <div className='profit-bold'>Customer Rate:</div>
                                                        <div style={{ color: '#41586D', marginLeft: '4px' }}>
                                                            <PriceRow
                                                                info={item?.totalProfit?.customerRate ? item?.totalProfit?.customerRate : 0} />
                                                        </div>
                                                    </div>

                                                    <div className='profit-info'>
                                                        <div className='profit-bold'>Coast:</div>
                                                        <div style={{ color: '#41586D', marginLeft: '4px' }}>
                                                            <PriceRow
                                                                info={item?.totalProfit?.customerRate - item?.totalProfit?.profit} />
                                                        </div>
                                                    </div>

                                                    <div className='profit-info'>
                                                        <div className='profit-bold'>Profit:</div>
                                                        <div
                                                            className={` profit-number profit-subtitle  ${
                                                                item?.totalProfit?.profit < 0 && 'text-error'
                                                            } 
                                             
                                                     ${item?.totalProfit?.profit >= 0 ? 'text-success' : ''}
                                                    `}>
                                                            <span style={{
                                                                color:
                                                                    item?.totalProfit?.profit === 0 ? '#41586D' :
                                                                        item?.totalProfit?.profit >= 0 ? '#0BB53A' : '#D03325',
                                                            }}>
                                                            <PriceRow
                                                                info={item?.totalProfit?.profit ? item?.totalProfit?.profit : 0} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className='profit-info'>
                                                <div className='profit-bold'>Customer Rate:</div>
                                                <div className='profit-subtitle' style={{ color: '#41586D' }}>
                                                    <PriceRow
                                                        info={item?.totalProfit?.customerRate ? item?.totalProfit?.customerRate : 0} />
                                                </div>
                                            </div>
                                            <div className='profit-info grid-box'>
                                                <div className='profit-bold address-stop'>
                                                    <span>First Stops: </span>
                                                </div>
                                                <div className='profit-subtitle text-gunmetal text-style'>
                                                    <div className={'text-style'}>
                                                        <TextRow name={handleRenderStops('first', item)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='profit-info'>
                                                <div className='profit-bold address-stop'>Last Stop:</div>
                                                <div className='profit-subtitle text-gunmetal text-style'>
                                                    <div className={'text-style'}>
                                                        <TextRow name={handleRenderStops('last', item)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='profit-info profit-status mobile-address'>
                                                <div className='profit-bold'>Status:</div>
                                                <span
                                                    className='text-resault status-title'>{StatusMobile(item.status)}</span>
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Box>
    );
};
