/**  Service */
import axios from 'axios';

export const trailerService = {
    /* Create Trailer */
    createTrailer: (body) => axios.post(`/trailers`, body, { auth: true }),

    /* Get Trailer */
    getTrailer: (params) => axios.get(`/trailers`, { auth: true, params: { ...params } }),

    /* Edit Trailer */
    editTrailer: (body, id) => axios.patch(`/trailers/${id}`, { ...body }, { auth: true }),

    /*  Delete Trailer */
    deleteTrailer: (id) => axios.delete(`/trailers/${id}`, { auth: true }),

    /* Change Status Trailer  */
    changeStatusTrailer: (id, active) => axios.patch(`/trailers/${id}/status/${active}`, {}, { auth: true }),

    /* Get Trailer Id */
    getTrailersId: (id) => axios.get(`/trailers/${id}`, { auth: true }),



    /** Trailer Documents */
    createTrailerDocument: (id, body) => axios.post(`/trailers/${id}/documents`, { ...body }, { auth: true }),

    getTrailerDocument: (id) => axios.get(`/trailers/${id}/documents` , { auth: true }),

    editTrailerDocumentArchive: (id, docId, archive) => axios.patch(`/trailers/${id}/documents/${docId}/archive/${archive}`, {}, { auth: true }),

    deleteTrailerDocument: (id, docId) => axios.delete(`/trailers/${id}/documents/${docId}`, { auth: true }),
    /** End */


    /** Trailer Coasts */
    trailerCoastService: (id) => axios.get(`/trailers/${id}/costs`, { auth: true }),

    createTrailerCoastService: (id, body) => axios.post(`/trailers/${id}/costs`, body,{ auth: true }),
    /** End */
};
