import React from 'react';
import { Button } from '@mui/material';
import { Images } from 'assets/images';
import { useModal } from 'components';
import { useDispatch } from 'react-redux';
import { FindLoad, MODAL_NAMES } from 'utils';

export const RessetSuccessModal = () => {
    /**
     * Resset Success Modal.
     */

    const { close, open } = useModal();
    const dispatch = useDispatch();
    const load = FindLoad('RESET_PASSWORD');

    const handleDone = () => {
        close(MODAL_NAMES.CANGRATS_RESET);
        open(MODAL_NAMES.SIGN_IN);
    };

    return (
        <div className="cangrats-reset">
            <div className="invite-container">
                <div className="jusify-center">
                    <div className="flex text-center">
                        <img src={Images.ResetKey} alt="Reset-Key" />
                    </div>
                </div>
                <div className="title-box">
                    <h4 className="title">Password Reset</h4>
                    <p className="subtitle column">
                        <span>Now you can calculate your first load.</span>
                        <span>Good Luck!</span>
                    </p>
                </div>
                <div className="footer-box">
                    <div className="submit-box">
                        <Button className="add-modal-button green-btn" color="success" onClick={() => handleDone()}>
                            <span className="button-text">Done </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
