/** Types */

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const INVITE_USER = 'INVITE_USER';

export const EDIT_USER = 'EDIT_USER';

export const DELETE_USER = 'DELETE_USER';

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';

export const RESEND_INVITE = 'RESEND_INVITE';