/**  Service */
import axios from 'axios';

export const driversService = {
    /** Driver Actions */
    createDriver: (body) => axios.post(`/drivers`, body, { auth: true }),

    getDriver: (params) => axios.get(`/drivers`, { auth: true, params: { ...params } }),

    changeDriverStatus: (id, status) => axios.patch(`/drivers/${id}/status/${status}`, {}, { auth: true }),

    editDriverDetails: (body, id) => axios.patch(`/drivers/${id}/details`, { ...body }, { auth: true }),

    getDriverDetails: (params, id) => axios.get(`/drivers/${id}/details`, { auth: true, params: { ...params } }),

    deleteDriver: (id) => axios.delete(`/drivers/${id}`, { auth: true }),
    /** End */

    /** Driver Rate */
    editDriverRate: (body, id) => axios.patch(`/drivers/${id}/rate`, { ...body }, { auth: true }),

    getDriverRate: (params, id) => axios.get(`/drivers/${id}/rates`, { auth: true, params: { ...params } }),
    /** End */

    /**  Driver Fuel */
    createDriverFuel: (body, id) => axios.post(`/drivers/${id}/fuelCard`, body, { auth: true }),

    editDriverFuel: (body, driverId, id) => axios.patch(`/drivers/${driverId}/fuelCard/${id}`, { ...body }, { auth: true }),

    deleteDriverFuel: (driverId, id) => axios.delete(`/drivers/${driverId}/fuelCard/${id}`, { auth: true }),
    /** End */

    /** Driver Documents */
    createDriverDocument: (id, body) => axios.post(`/drivers/${id}/documents`, { ...body }, { auth: true }),

    getDriverDocument: (id) => axios.get(`/drivers/${id}/documents`, { auth: true }),

    editDriverDocumentArchive: (id, docId, archive) =>
        axios.patch(`/drivers/${id}/documents/${docId}/archive/${archive}`, {}, { auth: true }),

    deleteDriverDocument: (id, docId) => axios.delete(`/drivers/${id}/documents/${docId}`, { auth: true }),
    /** End */

    // ReInvite Driver.

    reInvite: (id) => axios.post(`/drivers/${id}/reInvite`, {}, { auth: true }),
};
