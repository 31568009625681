import { GET_NOTES_SUCCESS } from './notes.type';

const initialState = {
    noteList: null,
};

export const notesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTES_SUCCESS:
            return {
                ...state,
                noteList: action.payload,
            };

        default:
            return state;
    }
};
