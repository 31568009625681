import { TextRow, StatusTruck, DateRow } from 'components';
import { MODAL_NAMES } from 'utils';
import { Svg } from 'assets/images';

export const ACTION_TYPE = 'GET_CUSTOMER';

export const customersHead = [
    { name: 'id', title: 'ID' },
    { name: 'name', title: 'Name' },
    { name: 'mcNumber', title: 'MC Number' },
    {
        name: 'status',
        title: 'Status',
        type: 'status',
        list: [
            {
                value: 'ALL',
                label: 'All',
            },
            {
                value: 'ACTIVE',
                label: 'Active',
            },
            {
                value: 'DO_NOT_USE',
                label: 'Do Not Use',
            },
            {
                value: 'INACTIVE',
                label: 'Inactive',
            },
        ],
    },
];

export const customerStatus = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        value: 'DO_NOT_USE',
        label: 'Do Not Use',
    },
    {
        value: 'INACTIVE',
        label: 'Inactive',
    },
];

export const dropdownStatus = [
    {
        svg: Svg.GreenRoundedIcon,
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        svg: Svg.IndigoRoundedIcon,
        value: 'DO_NOT_USE',
        label: 'Do Not Use',
    },
    {
        svg: Svg.OrangeRoundedIcon,
        value: 'INACTIVE',
        label: 'Inactive',
    },
];

export const customerBody = [
    { rowText: (item) => <TextRow name={item?.displayId} /> },
    { rowText: (item) => <TextRow name={item?.name} /> },
    { rowText: (item) => <TextRow name={item?.mcNumber} /> },
    { rowText: (item) => <StatusTruck info={item?.status} /> },
];

export const CustomerMenu = [
    {
        name: 'Edit',
        modalName: MODAL_NAMES.TRACK_MODAL,
    },
    {
        name: 'Inactivate',
        modalName: MODAL_NAMES.INACTIVATE_MODAL,
    },
    {
        name: 'Delete',
        modalName: MODAL_NAMES.DELETE_TRUCK,
        color: '#d03325',
    },
];

export const updateDriverRate = [
    {
        value: 'MONTHLY',
        label: 'Monthly',
    },
    {
        value: 'WEEKLY',
        label: 'Weekly',
    },
    {
        value: 'BI_WEEKLY',
        label: 'Bi Weekly',
    },
    {
        value: 'HOURLY',
        label: 'Hourly',
    },
    {
        value: 'MILEAGE',
        label: 'Mileage',
    },
];

export const pastHead = [
    { name: 'start', title: 'Start Date' },
    { name: 'End', title: 'End Date' },
    { name: 'rate', title: 'Rate' },
    { name: 'type', title: 'Type' },
];

export const pastBody = [
    { rowText: (item) => <DateRow date={item?.startDate} /> },
    { rowText: (item) => <DateRow date={item?.endDate} /> },
    { rowText: (item) => <TextRow name={item?.rate} /> },
    { rowText: (item) => <TextRow name={item?.mode} /> },
];

export const driverDocumentsList = [
    { name: 'Driver License', type: 'DRIVER_LICENSE' },
    { name: 'Other', type: 'OTHER' },
];

export const ratesType = (name) => {
    switch (name) {
        case 'MONTHLY':
            return 'Monthly';
        case 'WEEKLY':
            return 'Weekly';
        case 'BI_WEEKLY':
            return 'Bi Weekly';
        case 'HOURLY':
            return 'Hourly';
        case 'MILEAGE':
            return 'Mileage';
        default:
            return name;
    }
};
