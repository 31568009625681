import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, CustomInput, MaskInput, CreateCancel, CustomTextArea } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { customersAction } from 'store/customers';

export const AddContact = () => {
    const { customerIdList } = useSelector((state) => ({
        customerIdList: state?.customer?.customerIdList,
    }));

    const { close, params } = useModal();
    const ACTION_TYPE = params?.info ? 'EDIT_CUSTOMER_CONTACT' : 'CREATE_CUSTOMER_CONTACT';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError('EDIT_CUSTOMER_CONTACT');
    const backSendError = FindError('CREATE_CUSTOMER_CONTACT');
    const [enteredAddress, setEnteredAddress] = useState('');
    const sendId = customerIdList?.id;
    const contactId = params?.info?.id;

    function handleAddressChange(selectedAddress) {
        setEnteredAddress(selectedAddress);
    }

    const { control, handleSubmit, setError } = useForm({
        defaultValues: {
            fullName: params?.info?.fullName,
            email: params?.info?.email,
            phone: params?.info?.phone,
            notes: params?.info?.notes,
        },
    });

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };
        if (data?.phone) {
            sendInfo.phone = `${parseInt(data?.phone.replace(/[^0-9]/g, ''))}`;
        } else {
            if (params?.info) {
                sendInfo.phone = null;
            } else {
                delete sendInfo.phone;
            }
            // delete sendInfo.phone;
        }
        if (!data?.email) {
            delete sendInfo.email;
        }
        if (params?.info) {
            dispatch(customersAction.editCustomerContact(sendInfo, sendId, contactId));
        } else {
            dispatch(customersAction.createCustomerContact(sendInfo, sendId));
        }
    };

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        if (backSendError?.error || backError?.error === 'phone must be a valid phone number') {
            setError('phone');
        }
    }, [backError, backSendError]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const mobileError =
        backError?.error === 'This Organization has a driver with this data.'
            ? 'This Organization has a driver with this data.'
            : backError?.error && backError?.error?.[0] === 'phone must be a valid phone number'
            ? 'phone must be a valid phone number'
            : backSendError?.error && backSendError?.error?.[0] === 'phone must be a valid phone number'
            ? 'phone must be a valid phone number'
            : '';

    return (
        <div className="invite-form">
            <div className="driver-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomInput
                        name="fullName"
                        control={control}
                        rules={{ required: true }}
                        type="text"
                        label="Full Name*"
                        placeholder={'Full Name'}
                        maxLength={40}
                    />
                    <CustomInput
                        name="email"
                        control={control}
                        rules={{ required: false }}
                        type="email"
                        label="Email Address"
                        placeholder={'Email Address'}
                    />

                    <MaskInput
                        name="phone"
                        label="Phone Number"
                        type="phone"
                        control={control}
                        rules={{ required: false }}
                        placeholder={'Phone Number'}
                        errMessage={!!mobileError}
                        errMessageToShow={
                            mobileError === 'phone must be a valid phone number'
                                ? 'Phone must be a valid phone number'
                                : mobileError === 'This Organization has a driver with this data.'
                                ? 'This Organization has a driver with this data.'
                                : ''
                        }
                    />
                    <CustomTextArea
                        name={'notes'}
                        id={'notes'}
                        control={control}
                        rules={{ required: false }}
                        defaultValue={''}
                        label={'Notes'}
                        listType=""
                        placeholder={'Instruction...'}
                        maxLength={200}
                    />
                    <CreateCancel title={'Save'} actionType={ACTION_TYPE} closeType={ACTION_TYPE} />
                </form>
            </div>
        </div>
    );
};
