import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Svg } from 'assets/images';

export const Toast = ({ info, text, type }) => {
    const screen = (
        <div className="toast-wrapper">
            <img src={type === 'error' ? Svg.Error : Svg.Success} alt="Success" />
            <p>{text}</p>
        </div>
    );

    useEffect(() => {
        if (info) {
            toast(screen);
        }
    }, [info]);
    return (
        <div>
            <ToastContainer
                style={{ zIndex: '99999' }}
                className={type === 'error' ? 'error' : 'success'}
                position={'bottom-right'}
                autoClose={2500}
            />
        </div>
    );
};
