import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { CardBtn, CustomInput, CustomSelect } from 'components';
import { httpRequestsOnSuccessActions, truckActions } from 'store';
import { FindLoad, FindSuccess } from 'utils';

const REQUEST_TYPE = 'EDIT_TRUCK_DETAILS';

export const DetailsFragment = ({ detailsList, trailerList, driversList }) => {
    const { truckDetailsList } = useSelector((state) => ({
        truckDetailsList: state?.truck?.truckDetailsList,
    }));
    const dispatch = useDispatch();
    const paramsRouter = useParams();
    const [editInput, setEditInput] = useState(true);
    const loadSave = FindLoad(REQUEST_TYPE);
    const success = FindSuccess(REQUEST_TYPE);
    const [inputs, setInputs] = useState('');

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: truckDetailsList?.name ? truckDetailsList?.name : null,
            make: truckDetailsList?.make ? truckDetailsList?.make : null,
            model: truckDetailsList?.model ? truckDetailsList?.model : null,
            color: truckDetailsList?.color ? truckDetailsList?.color : null,
            truckNumber: truckDetailsList?.truckNumber ? truckDetailsList?.truckNumber : null,
            currentMiles: truckDetailsList?.currentMiles ? truckDetailsList?.currentMiles : null,
            assignedDriver: truckDetailsList?.assignedDriverId ? truckDetailsList?.assignedDriverId : null,
            assignedCoDriver: truckDetailsList?.assignedCoDriverId ? truckDetailsList?.assignedCoDriverId : null,
            assignedTrailer: truckDetailsList?.assignedTrailerId ? truckDetailsList?.assignedTrailerId : null,
        },
    });

    useEffect(() => {
        if (success) {
            setEditInput(!editInput);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
        }
    }, [success]);

    useEffect(() => {
        dispatch(truckActions.getTruckDetails(paramsRouter.id));
    }, []);

    useEffect(() => {
        if (truckDetailsList) {
            setInputs({
                assignedDriver: truckDetailsList?.assignedDriverId,
                assignedCoDriver: truckDetailsList?.assignedCoDriverId,
            });
        }
    }, [truckDetailsList]);

    const handleEdit = () => {
        setEditInput(!editInput);
    };

    const handleChancel = () => {
        handleEdit();
        reset({
            name: truckDetailsList?.name ? truckDetailsList?.name : null,
            make: truckDetailsList?.make ? truckDetailsList?.make : null,
            model: truckDetailsList?.model ? truckDetailsList?.model : null,
            color: truckDetailsList?.color ? truckDetailsList?.color : null,
            truckNumber: truckDetailsList?.truckNumber ? truckDetailsList?.truckNumber : null,
            currentMiles: truckDetailsList?.currentMiles ? truckDetailsList?.currentMiles : null,
            assignedDriver: truckDetailsList?.assignedDriver ? truckDetailsList?.assignedDriver : null,
            assignedCoDriver: truckDetailsList?.assignedCoDriver ? truckDetailsList?.assignedCoDriver : null,
            assignedTrailer: truckDetailsList?.assignedTrailer ? truckDetailsList?.assignedTrailer : null,
        });
    };

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };

        sendInfo.currentMiles = Number(data.currentMiles);
        dispatch(truckActions.editTruckDetails(sendInfo, detailsList?.id));
    };

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    return (
        <div className="details-container">
            <div className="details-form">
                <form onSubmit={handleSubmit(onSubmit)} className="form-messages">
                    <Box className="laod-space" />
                    <Box className="load-form">
                        <Box className="basic-banner space-between">
                            <div className="load-title basic-title">
                                <h4 className="load-basic flex">Basic Info</h4>
                            </div>
                            <CardBtn
                                className={'basic-btn'}
                                editInput={editInput}
                                handleEdit={handleEdit}
                                handleChancel={handleChancel}
                                load={loadSave}
                            />
                        </Box>
                        <Grid container columnSpacing={3} rowSpacing={0}>
                            <Grid item xs={12} md={4}>
                                <CustomInput
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    disabled={editInput}
                                    type="text"
                                    label="Name*"
                                    placeholder={'Name'}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomInput
                                    name="truckNumber"
                                    control={control}
                                    rules={{ required: false }}
                                    disabled={editInput}
                                    type="number"
                                    label="Track Number"
                                    placeholder={'Track Number'}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomInput
                                    name="make"
                                    control={control}
                                    rules={{ required: false }}
                                    disabled={editInput}
                                    type="text"
                                    label="Make"
                                    placeholder={'Make'}
                                    maxLength={40}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomInput
                                    name="model"
                                    control={control}
                                    rules={{ required: false }}
                                    disabled={editInput}
                                    type="text"
                                    label="Model"
                                    placeholder={'Model'}
                                    maxLength={40}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomInput
                                    name="color"
                                    control={control}
                                    rules={{ required: false }}
                                    disabled={editInput}
                                    type="text"
                                    label="Color"
                                    placeholder={'Color'}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomInput
                                    name="currentMiles"
                                    control={control}
                                    rules={{ required: false }}
                                    disabled={editInput}
                                    type="number"
                                    label="Current Miles"
                                    placeholder={'Current Miles'}
                                    lastIcon={'mi'}
                                    step={'numeric'}
                                />
                            </Grid>
                        </Grid>
                        {/* Assign Truck */}
                        <Box className="load-title">
                            <h4 className="load-basic">Assign to truck</h4>
                        </Box>
                        <Grid container columnSpacing={3} rowSpacing={0} marginTop={1}>
                            <Grid item xs={12} sm={4}>
                                <CustomSelect
                                    handleBlur={handleChange}
                                    displayName={`firstName`}
                                    displayValue={'id'}
                                    name={'assignedDriver'}
                                    control={control}
                                    disabled={editInput}
                                    rules={{ required: false }}
                                    // defaultValue={truckDetailsList?.assignedDriver ? truckDetailsList?.assignedDriver : null}
                                    listSelect={
                                        inputs?.assignedCoDriver
                                            ? driversList?.drivers?.filter((i) => i?.id !== inputs?.assignedCoDriver)
                                            : driversList?.drivers
                                    }
                                    label="Assign Driver"
                                    placeholder={'Choose Driver'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CustomSelect
                                    handleBlur={handleChange}
                                    name={'assignedCoDriver'}
                                    displayName={'firstName'}
                                    displayValue={'id'}
                                    control={control}
                                    disabled={editInput}
                                    rules={{ required: false }}
                                    listSelect={
                                        inputs?.assignedDriver
                                            ? driversList?.drivers?.filter((i) => i?.id !== inputs?.assignedDriver)
                                            : driversList?.drivers
                                    }
                                    label="Assign CoDriver"
                                    placeholder={'Choose CoDriver'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CustomSelect
                                    name={'assignedTrailer'}
                                    displayName={'displayId'}
                                    displayValue={'id'}
                                    control={control}
                                    disabled={editInput}
                                    rules={{ required: false }}
                                    listSelect={trailerList?.trailers ? trailerList?.trailers : []}
                                    defaultValue={detailsList?.assignedTrailer}
                                    label="Assign Trailer"
                                    placeholder={'Choose Trailer'}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </div>
        </div>
    );
};
