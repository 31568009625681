import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ChangePassword } from './changePassword';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FindLoad, FindSuccess } from 'utils';
import { Svg } from 'assets/images';
import { Box, Button } from '@mui/material';
import { MinLoader, useModal } from 'components';
import { authActions } from 'store/auth';
import { httpRequestsOnSuccessActions, UploadServices } from 'store';

export const MyProfile = () => {
    /**
     * My Profile.
     */

    const { myProfile } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
    }));

    const { close } = useModal();
    const [toggleChange, SetToggleChange] = useState(false);
    const loader = FindLoad('SIGN_OUT');
    const signOutSuccess = FindLoad('SIGN_OUT');
    const editLoad = FindLoad('EDIT_MY_PROFILE');
    const success = FindSuccess('EDIT_MY_PROFILE');
    const [url, setUrl] = useState(null);
    const [disable, setDisable] = useState(true);
    const [loadUpload, setLoadUpload] = useState(false);
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            fullName: myProfile?.fullName,
            email: myProfile?.email,
        },
    });

    useEffect(() => {
        if (myProfile?.avatar?.url) {
            setUrl(myProfile?.avatar?.url);
        }
    }, [myProfile]);

    useEffect(() => {
        if (signOutSuccess?.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess('SIGN_OUT'));
        }
    }, [signOutSuccess]);

    useEffect(() => {
        if (success) {
            setDisable(true);
            dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_MY_PROFILE'));
        }
    }, [success]);

    const onSubmit = (data) => {
        const sendInfo = {
            ...data,
        };
        dispatch(authActions.editMyProfile(sendInfo));
    };

    const showFormChange = () => {
        SetToggleChange(!toggleChange);
    };

    const handleLogOut = () => {
        dispatch(authActions.signOut());
    };

    const handleChange = async (e, k) => {
        setLoadUpload(true);
        if (e.target.files[0]) {
            let formData = new FormData();
            formData.append('files', e.target.files[0]);
            try {
                const res = await UploadServices.UploadImage(formData);
                const info = {
                    file: {
                        ...res?.data,
                        name: e.target.files[0].name,
                    },
                };
                info.name = e.target.files[0].name;
                setLoadUpload(false);
                setUrl(info?.file?.url);
                const sendInfo = {
                    avatar: {
                        id: info?.file?.id,
                        url: info?.file?.url,
                        thumbUrl: info?.file?.url,
                        name: info?.name,
                        format: info?.file?.format,
                    },
                };
                dispatch(authActions.editMyProfile(sendInfo));
            } catch (e) {
                setLoadUpload(false);
            }
        }
    };

    return (
        <div className="profile-modal">
            <div className="avatar">
                <div>{url ? <img src={url} alt={'icon'} className="avatar-img" /> : <AccountCircleIcon className="circle-icon" />}</div>
                <label className="custom-file-upload ">
                    <input
                        onClick={(event) => (event.target.value = null)}
                        disabled={false}
                        onChange={handleChange}
                        type="file"
                        id="file"
                    />
                    <i className="fa fa-cloud-upload" />
                    <span className={'upload-avatar'}>
                        {loadUpload ? <MinLoader color="white" /> : <img src={Svg.AddPhoto} alt="Add-Photo" />}
                    </span>
                </label>
                <div className="avatar-name">
                    <h4 className="name-info">{myProfile?.fullName}</h4>
                </div>
            </div>
            <div className="edit-user">
                <form onSubmit={handleSubmit(onSubmit)} className="form-messages">
                    <div className="flex-end edit-box">
                        {!disable ? (
                            <div>
                                <button style={{ width: '50px' }} onClick={onSubmit} className="edit-btn">
                                    <span className="edit-text">
                                        {editLoad?.length ? (
                                            <MinLoader color={'#0e9594'} style={{ height: '15px', white: '15px' }} />
                                        ) : (
                                            'Save'
                                        )}
                                    </span>
                                </button>
                                <button onClick={() => setDisable(true)} className="cancel-profile-btn">
                                    <span className="edit-text">Cancel</span>
                                </button>
                            </div>
                        ) : (
                            <button onClick={() => setDisable(false)} className="edit-btn">
                                <span className="edit-text">Edit</span>
                            </button>
                        )}
                    </div>
                    <div className="edit-group group-input">
                        <span className="user-input">
                            <img src={Svg.UserInput} alt="User-Input" />
                        </span>
                        <input
                            disabled={disable}
                            type="text"
                            className="form-control name-control"
                            id="fullName"
                            placeholder="Full Surname"
                            {...register('fullName')}
                        />
                    </div>
                    <div className="edit-group group-input">
                        <span className="email-input">
                            <img src={Svg.EmailInput} alt="Email-Input" />
                        </span>
                        <input
                            disabled={disable}
                            type="email"
                            className="form-control email-control"
                            id="email"
                            placeholder="Write your email address"
                            {...register('email')}
                        />
                    </div>
                </form>
            </div>
            <div className="change-box">
                <ChangePassword showFormChange={showFormChange} toggleChange={toggleChange} />
            </div>
            <Box className="sing-box">
                <Button className="add-modal-button" onClick={handleLogOut} style={{ height: '48px' }}>
                    {loader ? (
                        <MinLoader color="white" />
                    ) : (
                        <>
                            <img src={Svg.OutWhite} alt="Out-White" />
                            <span className="button-text">Sign Out</span>
                        </>
                    )}
                </Button>
            </Box>
        </div>
    );
};
