import {
    createCustomer,
    getCustomer,
    editCustomer,
    getCustomerId,
    deleteCustomer,
    changeCustomerStatus,
    createCustomerContact,
    editCustomerContact,
    getCustomerContacts,
    deleteCustomerContact,
} from './customers.action';
export { customerReducer } from './customers.reducer';
export { watchCustomers } from './customers.saga';

export const customersAction = {
    /** Customer Type */
    createCustomer,
    getCustomer,
    editCustomer,
    getCustomerId,
    deleteCustomer,
    changeCustomerStatus,
    /** End */
    /** Customer Contacts */
    createCustomerContact,
    editCustomerContact,
    getCustomerContacts,
    deleteCustomerContact,
    /** End */
};
