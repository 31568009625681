import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MinLoader, useModal } from 'components';
import { httpRequestsOnSuccessActions, PaymentActions } from 'store';
import { FindLoad, FindSuccess } from 'utils';

const ACTION_TYPE = 'CANCEL_SUBSCRIPTION';

export const CancelConfirmModal = () => {
    const history = useHistory();
    const { close } = useModal();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            history.push('/plansAndPricing');
            close();
        }
    }, [success]);

    const handleChancelSubscription = () => {
        dispatch(PaymentActions.cancelSubscription());
    };

    return (
        <div className='cancel-confirm'>
            <p className='chancel-title'>
                Are you sure you want to cancel your subscription?
                {/*Do you want Cancel <br /> Subscription?*/}
            </p>

            <div className='cancel-options'>
                <p>
                    This is an irreversible step. Your information may be lost permanently if you proceed. Are you sure you want to cancel your subscription?
                    {/*If you cancel your subscription plan,we <br /> automatically will stop to charge.*/}
                </p>
            </div>

            <div className='actions'>
                <button
                    className='no-button'
                    onClick={() => close()}
                >
                    No
                </button>
                <button
                    className='yes-button'
                    onClick={() => handleChancelSubscription()}
                >
                    {loader?.length ?
                        <MinLoader color={'white'} />
                        :
                        'Yes'
                    }
                </button>
            </div>
        </div>
    );
};