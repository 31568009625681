import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FindLoad, FREE_PACKAGE } from 'utils';
import { ActivePaymentFragment } from './fragments';
import { PaymentActions } from 'store';
import { Loader } from 'components';

export const ActivePayment = () => {
    const { activePackage, cardInfo, invoices } = useSelector((state) => ({
        activePackage: state.payment.activePackage,
        cardInfo: state.payment.cardInfo,
        invoices: state.payment.invoices,
    }));
    const dispatch = useDispatch();
    const loader = FindLoad('GET_MY_PROFILE_PACKAGE');
    const loadInvoice = FindLoad('GET_INVOICES');
    const loadCard = FindLoad('GET_CARD_INFO');

    useEffect(() => {
        if (activePackage?.package !== FREE_PACKAGE) {
            dispatch(PaymentActions.getInvoices());
            dispatch(PaymentActions.getCardInfo());
        }
    }, []);

    if (loader?.length || loadInvoice?.length || loadCard?.length) {
        return <Loader />;
    }

    return (
        <ActivePaymentFragment
            userPackage={activePackage}
            cardInfo={cardInfo}
            invoices={invoices}
        />
    );
};
