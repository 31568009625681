import React, { Fragment, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerMenu, HeaderLayout, Toast } from 'fragments';
import { LandingPage, PrivacyPage, TermsPage } from 'pages';
import { ToastSuccess } from '../components/modal/toastSuccess';
import { ToastError } from '../components/modal/toastError';
import { httpRequestsOnSuccessActions } from '../store';
import { useModal } from '../components';
import { MODAL_NAMES } from '../utils';

export const RouterGlobal = () => {
    const dispatch = useDispatch();
    const { open } = useModal();
    const { httpOnError, httpOnSuccess, myProfile } = useSelector((state) => ({
        httpOnError: state.httpOnError,
        httpOnSuccess: state.httpOnSuccess,
        myProfile: state.auth.myProfile,
    }));

    const success = httpOnSuccess.length && httpOnSuccess[0].type;
    const error = httpOnError.length && httpOnError[0].type;
    const toastSuccess = ToastSuccess(success);
    const toastError = ToastError(error);
    const token = localStorage.getItem('access-token');
    const link = window.location.search;

    useEffect(() => {
        if (toastSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success));
        }
    }, [toastSuccess]);

    useEffect(() => {
        if (toastError) {
        }
    }, [toastError]);

    useEffect(() => {
        if (link.search('token=') !== -1 || link.search('resetToken=') !== -1) {
            localStorage.removeItem('access-token');
            open(MODAL_NAMES.RESET_PASSWORD);
        }
    }, []);

    return (
        <Fragment>
            {!token || link.search('resetToken=') !== -1 || link.search('token=') !== -1 ? (
                <>
                    <HeaderLayout />
                    <Switch>
                        <Route exact path={`/`} component={LandingPage} />
                        <Route path={`/privacy`} component={PrivacyPage} />
                        <Route path={`/terms`} component={TermsPage} />
                        <Route path='/resetPassword/:resetToken?' exact component={LandingPage} />
                        <Route path='/register/:token?' exact component={LandingPage} />
                        <Redirect to={'/'} />
                    </Switch>
                </>
            ) : (
                <>
                    <DrawerMenu myProfile={myProfile} />
                </>
            )}

            <Toast
                type={toastSuccess ? 'success' : toastError ? 'error' : ''}
                text={toastSuccess ? toastSuccess : toastError ? toastError : ''}
                info={toastSuccess ? !!toastSuccess : toastError ? !!toastError : ''}
            />
        </Fragment>
    );
};
