/**  Service */
import axios from 'axios';

export const loadService = {
    /* Create Load */
    createLoad: (body) => axios.post(`/loads`, body, { auth: true }),

    /* Get Load */
    getLoad: (params) => axios.get(`/loads`, { auth: true, params: { ...params } }),

    /* Edit Load */
    editLoad: (body, id) => axios.patch(`/loads/${id}`, { ...body }, { auth: true }),

    /*  Delete Load */
    deleteLoad: (id) => axios.delete(`/loads/${id}`, { auth: true }),

    /*  Change Load */
    changeStatus: (id, status) => axios.patch(`/loads/${id}/state/${status}`, {}, { auth: true }),

    /* Get Load Id */
    getLoadId: (id) => axios.get(`/loads/${id}`, { auth: true }),

    /* Get Load Truck */
    getLoadTruckId: (id) => axios.get(`/loads/truck/${id}`, { auth: true }),

    /* Create Load Stop */
    createLoadStop: (body, id) => axios.patch(`/loads/${id}/stop`, body, { auth: true }),

    /* Edit Load Stop Stop Id */
    editLoadStopId: (body, id, stopId) => axios.patch(`/loads/${id}/stop/${stopId}`, body, { auth: true }),

    /* Delete Load Id Stop StopId */
    deleteLoadsStopId: (id, stopId) => axios.delete(`/loads/${id}/stop/${stopId}`, { auth: true }),

    /** Loads Document */
    createDocumentLoad: (id, body) => axios.post(`/loads/${id}/documents`, { ...body }, { auth: true }),

    getDocumentLoad: (id) => axios.get(`/loads/${id}/documents`, { auth: true }),

    editDocumentIdArchive: (id, docId, archive) => axios.patch(`/loads/${id}/documents/${docId}/archive/${archive}`, {}, { auth: true }),

    deleteDocumentDocIdService: (id, docId) => axios.delete(`/loads/${id}/documents/${docId}`, { auth: true }),
    /** End */

    /* Load Transaction Serice  */
    createLoadTxn: (body) => axios.post(`/loads/txn`, body, { auth: true }),

    // editTxnAction: (body, id, action) => axios.patch(`/loads/txn/${id}/action/${action}`, { ...body }, { auth: true }),

    editTxnAction: (id) => axios.patch(`/loads/txn/${id}/void`, {}, { auth: true }),

    getLoadTxn: (params, id) => axios.get(`/loads/${id}/txn`, { auth: true, params: { ...params } }),

    getLoadCoastsService: (id) => axios.get(`/loads/${id}/costs`, { auth: true }),
};
