import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useModal, SubmitButton, CustomInput } from 'components';
import { EmailValidator, FindError, FindSuccess, MODAL_NAMES } from 'utils';
import { authActions } from 'store/auth';
import { Svg } from 'assets/images';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

const REQUEST_TYPE = 'FORGOT_PASSWORD';

export const ForgotModal = () => {
    /**
     *  Forgot Password Modal.
     */

    const { close, open } = useModal();
    const dispatch = useDispatch();
    const backError = FindError(REQUEST_TYPE);
    const success = FindSuccess(REQUEST_TYPE);

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
        },
    });

    const onSubmit = (data) => {
        dispatch(authActions.forgotPassword(data));
    };

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE))
            close(MODAL_NAMES.FORGOT_PASSWORD);
            setTimeout(() => {
                dispatch(httpRequestsOnSuccessActions.appendSuccess('FORGOT_PASSWORD_SUCCESS'))
            }, 500)
        }
    }, [success]);

    useEffect(() => {
        if (backError?.error === 'User with this email was not found.') {
            setError('email');
        }
    }, [backError]);

    useEffect(() => {
        return () => dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
    }, []);

    return (
        <div className="modal-width">
            <div className="enter-emile-title">
                <p>Enter your email address and we'll send you a</p>
                <p> recovery email to reset your password.</p>
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomInput
                        imgSrc={errors?.email ? Svg.SmsRed : Svg.EmailInput}
                        name="email"
                        placeholder="Email"
                        control={control}
                        rules={{
                            required: true,
                            pattern: {
                                value: EmailValidator,
                                message: 'Must be valid email',
                            },
                        }}
                        type="email"
                        errMessage={backError?.error === 'User with this email was not found.' && 'User with this email was not found.'}
                    />
                    <SubmitButton type="submit" title="Get Recovery Email" actionType={REQUEST_TYPE} />
                </form>
            </div>
        </div>
    );
};
