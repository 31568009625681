import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { CustomPassword, MinLoader } from 'components';
import { authActions } from 'store/auth';
import { FindError, FindLoad, FindSuccess } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

export const ChangePassword = ({ toggleChange, showFormChange }) => {
    const ACTION_TYPE = 'CHANGE_PASSWORD';
    const {
        handleSubmit,
        control,
        register,
        setError,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            password: '',
            newPassword: '',
            confirmation: '',
        },
    });

    const [eyeOne, setEyeOne] = useState(false);
    const [eyeTwo, setEyeTwo] = useState(false);
    const [eyeThree, setEyeThree] = useState(false);
    const dispatch = useDispatch();
    const backError = FindError(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const newPasswordError =
        backError?.error === 'newPassword must be shorter than or equal to 30 characters'
            ? 'newPassword must be shorter than or equal to 30 characters'
            : backError?.error && backError?.error?.[0] === 'newPassword must be shorter than or equal to 30 characters'
                ? 'newPassword must be shorter than or equal to 30 characters'
                : backError?.error && backError?.error?.[1] === 'newPassword must be longer than or equal to 8 characters'
                    ? 'newPassword must be longer than or equal to 8 characters'
                    : '';
    const confirmError =
        backError?.error === 'confirmation must be shorter than or equal to 30 characters'
            ? 'confirmation must be shorter than or equal to 30 characters'
            : backError?.error && backError?.error?.[2] === 'confirmation must be shorter than or equal to 30 characters'
                ? 'confirmation must be shorter than or equal to 30 characters'
                : backError?.error && backError?.error?.[3] === 'confirmation must be longer than or equal to 8 characters'
                    ? 'confirmation must be longer than or equal to 8 characters' :
                    errors?.confirmation && errors?.confirmation?.type !== 'required' ? 'Retype does not match with the New Password' :
                        '';

    useEffect(() => {
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    }, []);

    useEffect(() => {
        if (success) {
            showFormChange();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const onSubmit = (data) => {
        const sendInfo = {
            password: data?.password,
            newPassword: data?.newPassword,
            confirmation: data?.confirmation,
        };
        if (data?.newPassword === data?.confirmation) {
            dispatch(authActions.changePassword(sendInfo));
        } else {
            setError('confirmation');
        }
    };

    const openEyeOne = () => {
        setEyeOne(!eyeOne);
    };
    const openEyeTwo = () => {
        setEyeTwo(!eyeTwo);
    };

    const openEyeThree = () => {
        setEyeThree(!eyeThree);
    };

    return (
        <div className='password-form'>
            <div className='box-change'>
                <form onSubmit={handleSubmit(onSubmit)} className='form-messages'>
                    <div className='form-banner'>
                        <h5 className='title'>Change Password</h5>
                        <div>
                            {toggleChange ? (
                                <div className='flex'>
                                    <button className='save-btn' type='submit'>
                                        <span className='save-text'>
                                            {loader?.length ?
                                                <MinLoader color={'#0e9594'}
                                                           style={{ height: '15px', white: '15px' }} />
                                                :
                                                'Save'
                                            }
                                                </span>
                                    </button>
                                    <button onClick={showFormChange} className='cancel-btn'>
                                        <span className='cancel-text'>Cancel</span>
                                    </button>
                                </div>
                            ) : (
                                <button onClick={showFormChange} className='edit-btn'>
                                    <span className='edit-text'>Edit</span>
                                </button>
                            )}
                        </div>
                    </div>
                    <div className='box-paragraph'>
                        <p className='paragraph'>Use strong password to keep your account secure.</p>
                    </div>
                    {toggleChange && (
                        <div className='change-form'>
                            <CustomPassword
                                name={'password'}
                                placeholder='Current Password*'
                                control={control}
                                errors={errors}
                                openEyeState={openEyeOne}
                                eyeState={eyeOne}
                                idInput={'password'}
                                errMessageToShow={backError?.error === 'User password does not match.' ? 'User password does not match.' : ''}
                            />
                            <CustomPassword
                                name={'newPassword'}
                                placeholder='New Password*'
                                control={control}
                                errors={errors}
                                openEyeState={openEyeTwo}
                                eyeState={eyeTwo}
                                idInput={'newPassword'}
                                errMessageToShow={
                                    newPasswordError === 'newPassword must be shorter than or equal to 30 characters'
                                        ? 'Must be shorter than or equal to 30 characters'
                                        : newPasswordError === 'newPassword must be shorter than or equal to 30 characters'
                                            ? 'Must be shorter than or equal to 30 characters'
                                            : backError?.error &&
                                            backError?.error?.[0] === 'newPassword must be shorter than or equal to 30 characters'
                                                ? 'Must be shorter than or equal to 30 characters'
                                                : backError?.error &&
                                                backError?.error?.[1] === 'newPassword must be longer than or equal to 8 characters'
                                                    ? 'Must be longer than or equal to 8 characters'
                                                    : ''
                                }
                            />
                            <CustomPassword
                                name={'confirmation'}
                                placeholder='Retype Password*'
                                control={control}
                                errors={errors}
                                openEyeState={openEyeThree}
                                eyeState={eyeThree}
                                idInput={'confirmation'}
                                errMessageToShow={
                                    confirmError === 'confirmation must be shorter than or equal to 30 characters'
                                        ? 'Must be shorter than or equal to 30 characters'
                                        : confirmError === 'confirmation must be shorter than or equal to 30 characters'
                                            ? 'Must be shorter than or equal to 30 characters'
                                            : backError?.error &&
                                            backError?.error?.[2] === 'confirmation must be shorter than or equal to 30 characters'
                                                ? 'Must be shorter than or equal to 30 characters'
                                                : backError?.error &&
                                                backError?.error?.[3] === 'confirmation must be longer than or equal to 8 characters'
                                                    ? 'Must be longer than or equal to 8 characters' :
                                                    confirmError === 'Retype does not match with the New Password' ? 'Retype does not match with the New Password' :
                                                        ''
                                }
                            />
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};
