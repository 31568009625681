import React, { Fragment, useEffect } from 'react';
import { Grid, Container } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { FindSuccess } from 'utils';

export const ContactUs = () => {
    const dispatch = useDispatch();
    const success = FindSuccess('CONTACT_US');

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
            email: '',
            message: '',
        },
    });

    const onSubmit = (data) => {
        dispatch(authActions.contactUs(data));
    };

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CONTACT_US'));
            reset({
                name: '',
                email: '',
                message: '',
            });
        }
    }, [success]);

    return (
        <Fragment>
            <Container maxWidth='lg'>
                <Grid container>
                    <Grid item xs={12} lg={6} paddingX={0} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='contact-description'>
                            <div className='flex-start content-titile'>
                                <h2 className='font-semiBold text-h2'>Contact Us</h2>
                            </div>
                            <div className='flex-start title-text'>
                                <div className='column'>
                                    <p className='step-one'>
                                        Our team is always ready to answer any questions you may have about our product
                                        services.
                                    </p>
                                    <p className='step-two'>
                                        We understand the importance of clear communication and strive to provide our
                                        clients with prompt and helpful responses.
                                    </p>
                                    <br />
                                    <p className='step-three'>
                                        Don't hesitate to get in touch with us today. Our team looks forward to hearing
                                        from you and helping you with your logistics needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6} paddingX={0} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='send-messages'>
                            <h3 className='send-title'>Your Message here</h3>
                            <form onSubmit={handleSubmit(onSubmit)} className='form-messages'>
                                <div className='form-group group-input'>
                                    <label htmlFor='name' className='label'>
                                        Your Name
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='name'
                                        placeholder='Name Surname'
                                        {...register('name')}
                                    />
                                </div>
                                <div className='form-group group-input '>
                                    <label htmlFor='email' className='label'>
                                        Email
                                    </label>
                                    <input
                                        type='email'
                                        className='form-control'
                                        id='email'
                                        placeholder='Write your email address'
                                        {...register('email')}
                                    />
                                </div>
                                <div className='form-group group-input '>
                                    <label htmlFor='message' className='label'>
                                        Message
                                    </label>
                                    <textarea
                                        placeholder='Text here...'
                                        id='message'
                                        name='message'
                                        rows='6'
                                        cols='60'
                                        className='text-area'
                                        {...register('message')}></textarea>
                                </div>
                                <div className='send-button'>
                                    <button className='green-btn' type='submit'>
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
};
