import React from "react";
import { Images } from "assets/images";
import { Grid } from "@mui/material";

export const BannerFragment = () => {
  /**
   *  Hooks.
   */
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <div className="card-banner flex">
          <img src={Images.Quick} alt="Quick-Count" />
          <div className="card-title">
            <h4 className={"font-semiBold text-h4"}>Simple to Use</h4>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className="card-banner flex">
          <img src={Images.Manage} alt="Manage-Truck" />
          <div className="card-title">
            <h4 className="font-semiBold text-h4">Highly Reliable</h4>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className="card-banner flex">
          <img src={Images.TeamWork} alt="Team-Work" />
          <div className="card-title">
            <h4 className="font-semiBold text-h4">Cost Effective</h4>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
