import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { CustomInput } from 'components';
import moment from 'moment';

export const DateFragment = ({ loadIdList }) => {
    /**
     * Date Fragment
     */

    const {
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            createdDate: loadIdList?.createdDate ? moment(loadIdList?.createdDate).format('l') : 'Not Set',
            updatedDate: loadIdList?.updatedDate ? moment(loadIdList?.updatedDate).format('l') : 'Not Set',
            submittedDate: loadIdList?.submittedDate ? moment(loadIdList?.submittedDate).format('l') : 'Not Set',
            canceledDate: loadIdList?.canceledDate ? moment(loadIdList?.canceledDate).format('l') : 'Not Set',
        },
    });

    return (
        <form className="form-messages">
            <Box className="basic-banner space-between">
                <div className="load-title basic-title">
                    <h4 className="load-basic flex">Date Info</h4>
                </div>
            </Box>
            <Grid container columnSpacing={3} rowSpacing={0}>
                <Grid item xs={12} sm={6}>
                    <CustomInput
                        name="createdDate"
                        control={control}
                        disabled={true}
                        type="text"
                        defaultValue={loadIdList?.createdDate ? moment(loadIdList?.createdDate).format('L') : 'Not Set'}
                        label="Created Date"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomInput
                        name="updatedDate"
                        control={control}
                        unregister
                        disabled={true}
                        type="text"
                        defaultValue={loadIdList?.updatedDate ? moment(loadIdList?.updatedDate).format('L') : 'Not Set'}
                        label="Updated Date"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomInput
                        name="submittedDate"
                        control={control}
                        disabled={true}
                        type="text"
                        defaultValue={loadIdList?.submittedDate ? moment(loadIdList?.submittedDate).format('l') : 'Not Set'}
                        label="Submitted Date"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomInput
                        name="canceledDate"
                        control={control}
                        disabled={true}
                        type="text"
                        defaultValue={loadIdList?.canceledDate ? moment(loadIdList?.canceledDate).format('l') : 'Not Set'}
                        label="Canceled Date"
                    />
                </Grid>
            </Grid>
        </form>
    );
};
