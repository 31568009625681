import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FullTable, PaginationItem, TableWrapper, useModal, MobileCard, FilterMenu, Loader, CreateMobileButton } from 'components';
import { ACTION_TYPE, ACTION_TYPE_TRANSACTION, transactionBody, transactionHead, transactionStatus } from '../constants';
import { FindSuccess, MODAL_NAMES, SavePage, SendPageSave, SaveParams, FindLoad, RolePermissions } from 'utils';
import { httpRequestsOnSuccessActions, loadActions, userActions } from 'store';
import { TableNoInfo } from 'components/table/tableNoInfo';

const title = 'Load Transaction';

export const LoadTransaction = ({ loadIdList }) => {
    const { loadTxn, activePackage } = useSelector((state) => ({
        loadTxn: state?.load?.loadTxn,
        activePackage: state.payment.activePackage,
    }));

    const { open } = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const paramsRouter = useParams();
    const dispatch = useDispatch();
    const addTransactionButton = loadIdList?.status !== 'PENDING' && loadIdList?.status !== 'CANCELED';
    const loadingTxn = FindLoad('GET_TRANSACTION');
    const resendSuccess = FindSuccess('RESEND_INVITE');
    const [filterType, setFilterType] = useState('ALL');

    const handleTransaction = () => {
        open(MODAL_NAMES.ADD_TRANSACTION, { modalTitle: 'Add Transaction' });
    };

    const handleClick = (item, name) => {
        if (name === 'DELETE_USER') {
            dispatch(userActions.deleteUser(item?.auth?.id));
        } else if (name === 'RESEND') {
            dispatch(userActions.resendInvite(item?.auth?.id));
        } else if (name === 'Activate' || name === 'Inactivate') {
            const status = name === 'Activate';
            dispatch(userActions.changeUserStatus(status, item?.auth?.id));
        }
    };

    const handleFilterStatus = (event) => {
        SaveParams(history, { ...info, type: event?.target?.defaultValue });
        setFilterType(event?.target?.defaultValue);
    };

    useEffect(() => {
        history.replace();
    }, []);

    useEffect(() => {
        if (resendSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('RESEND_INVITE'));
            SavePage(history, info);
        }
    }, [resendSuccess]);

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 10;
        const txnId = paramsRouter.id;
        if (info?.type && info?.type !== 'ALL') {
            filteredInfo.type = info.type;
        } else {
            delete filteredInfo.type;
        }
        delete filteredInfo.page;
        dispatch(loadActions.getLoadTxn(txnId, { ...filteredInfo }));
    }, [info]);

    if (loadingTxn?.length) return <Loader />;

    return (
        <div className="transaction-container page-container">
            <div className="desktop-view">
                <TableWrapper
                    firstButton={'Active'}
                    secondButton={'Inactive'}
                    addButton={'Add Transaction'}
                    buttonsTab={loadIdList?.status === 'ACTIVE' }
                    tab={false}
                    buttonsTabAddButton={addTransactionButton}
                    handleIniteUser={handleTransaction}
                    wrapperTitile={title}>
                    <FullTable
                        head={transactionHead}
                        body={transactionBody}
                        loadingType={ACTION_TYPE}
                        list={loadTxn?.loadTxns}
                        listCount={loadTxn?.count}
                        noText={title}
                        handleClickButton={handleClick}
                    />
                </TableWrapper>
            </div>
            <div className="mobile-view">
                <div className="wrapper-header">
                    <div className="header-title">
                        <h4 className="title">{title}</h4>
                    </div>
                    <div className="header-btn">
                        <div className="filter-menu">
                            <FilterMenu
                                title={'Filter the Type'}
                                list={transactionStatus}
                                status={filterType}
                                handleFilterStatus={handleFilterStatus}
                            />
                        </div>
                        <div className="wrapper-btn">
                            {addTransactionButton && RolePermissions(activePackage) && (
                                <CreateMobileButton handleClick={handleTransaction} />
                            )}
                        </div>
                    </div>
                </div>
                {loadTxn?.loadTxns?.length ? (
                    <Fragment>
                        {loadTxn?.loadTxns?.map((item, index) => (
                            <div key={index} className="card-box">
                                <MobileCard
                                    actionType={ACTION_TYPE}
                                    action={ACTION_TYPE_TRANSACTION}
                                    handleClickButton={handleClick}
                                    handleClick={handleClick}
                                    userCount={loadTxn?.count}
                                    item={item}
                                />
                            </div>
                        ))}
                    </Fragment>
                ) : (
                    <div style={{ height: '100px' }}>
                        <TableNoInfo text={`No Transaction Yet`} />
                    </div>
                )}
            </div>
            {loadTxn?.count !== 0 && (
                <div className="mobile-pagination">
                    <PaginationItem
                        listLength={loadTxn?.loadTxns?.length}
                        page={info?.page}
                        handleReturn={(number) => SendPageSave(number, info, history)}
                        count={loadTxn?.count}
                    />
                </div>
            )}
        </div>
    );
};
