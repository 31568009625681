/* Customers Reducer */

import { GET_CUSTOMER_SUCCESS, GET_CUSTOMER_CONTACTS_SUCCESS, GET_CUSTOMER_ID_SUCCESS } from './customers.type';

const initialState = {
    customerList: [],
    customerIdList: [],
    customerContactsList: [],
};

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customerList: action.payload,
            };
        case GET_CUSTOMER_ID_SUCCESS:
            return {
                ...state,
                customerIdList: action.payload,
            };
        case GET_CUSTOMER_CONTACTS_SUCCESS:
            return {
                ...state,
                customerContactsList: action.payload,
            };
        default:
            return state;
    }
};
