import React from 'react';
import { Backdrop, Modal } from '@mui/material';
import { useModalBase } from 'utils';

export const CustomModal = ({ openDefault, content, disableScrollLock }) => {
    const { closeModal } = useModalBase();

    const body = (
        <div>
            {content}
        </div>
    );

    return (
        <div>
            <Modal
                disableScrollLock={disableScrollLock}
                open={openDefault}
                onClose={() => closeModal()}
                aria-labelledby='spring-modal-title'
                aria-describedby='spring-modal-description'
                style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    zIndex: '10000',
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={'modal-fragment'}>
                    {body}
                </div>
            </Modal>
        </div>
    );
};
