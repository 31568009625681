import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { MinLoader, useModal } from 'components';
import { PaymentActions, services } from 'store';
import { MODAL_NAMES } from 'utils';
import { Checkbox } from '@mui/material';

const cardElementOptions = {
    style: {
        base: {
            color: '#666',
            fontSize: '16px',
        },
        invalid: {
            color: '#fa755a',
            fontSize: '16px',
        },
    },
};

const CheckoutForm = ({ query }) => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const dispatch = useDispatch();
    const info = history?.location?.state;
    const { open } = useModal();
    const [checkoutError, setCheckoutError] = useState();
    const [loader, setLoader] = useState(false);
    const [cardError, setCardError] = useState('');
    const [checked, setChecked] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        setCardError('');

        try {
            const paymentMethod = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement('card'),
            });
            const sendInfo = {
                'paymentMethod': paymentMethod?.paymentMethod?.id,
                'package': info?.state?.type,
            };

            if (query) {
                delete sendInfo.package;
            }

            await axios.post('/subscriptions', { ...sendInfo }, { auth: true }).then(() => {
                const inter = setInterval(() => {
                    dispatch(PaymentActions.getMyPackage());
                    services.getSubscription().then((res) => {
                        setLoader(false);
                        if (query) {
                            history.push('/payment');
                        } else {
                            open(MODAL_NAMES.SUCCESS_CANCEL, { modalType: 'success' });
                        }
                        clearInterval(inter);
                    });
                }, 2000);
            }).catch((e) => {
                    setCardError(e?.data?.message);
                    setLoader(false);
                },
            );
        } catch (err) {
            setCheckoutError(err.message);
            setLoader(false);
        }
    };

    return (
        <div className='form-wrapper'>
            <form onSubmit={handleSubmit}>
                <div>
                    <p className='title'>Card Number</p>
                    <div className='card-element'>
                        <CardElement options={cardElementOptions} />
                    </div>
                    {checkoutError && <span style={{ color: 'red', fontSize: '12px' }}>{checkoutError}</span>}
                </div>
                {cardError && <p style={{ color: '#fa755a', marginTop: '10px' }}>{
                    cardError === 'Can not create customer Error: Your card\'s security code is incorrect.' ? 'Your card\'s security code is incorrect.' :
                        cardError === 'Can not create customer Error: Your card has expired.' ? 'Your card has expired.' :
                            cardError === 'Can not create customer Error: An error occurred while processing your card. Try again in a little bit.' ? 'An error occurred while processing your card. Try again in a little bit.' :
                                cardError === 'Can not create customer Error: Your card was declined.' ? 'Your card was declined.' :
                                    cardError === 'This customer has no attached payment source or default payment method. Please consider adding a default payment method. For more information, visit https://stripe.com/docs/billing/subscriptions/payment-methods-setting#payment-method-priority.' ?
                                        'Input is not field' :
                                        cardError
                }
                </p>
                }

                <div className='check-wrapper'>
                    <Checkbox
                        style={{ color: '#0E9594', padding: 0 }}
                        checked={checked}
                        name='available'
                        onChange={() => setChecked(!checked)}
                    />
                    <p className='check-text-style'>
                        I have read and agree to the
                        <a href={'/termsAndConditions'}>Terms of Service </a>
                        and
                        <a href={'/privacyPolicy'}> Privacy Policy.</a>
                    </p>
                </div>

                <div className='buttons-wrapper'>
                    <button
                        onClick={() => history.push('/')}
                        className='cancel-button'
                        type={'button'}
                    >
                        Cancel
                    </button>
                    <button
                        className='next-button'
                        type='submit'
                        style={checked === false ? { background: 'gray' } : {}}
                        disabled={checked === false}
                    >
                        {loader ?
                            <MinLoader color={'white'} />
                            :
                            query ? 'Save' : 'Pay'
                        }
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CheckoutForm;