import { CREATE_NOTE, DELETE_NOTE, EDIT_NOTE, GET_NOTES } from './notes.type';

/** Note Actions */

export const getNotes = (id, type, load) => {
    return {
        type: GET_NOTES,
        payload: { id, type, load },
    };
};

export const addNote = (body) => {
    return {
        type: CREATE_NOTE,
        payload: { body },
    };
};

export const editNote = (info) => {
    return {
        type: EDIT_NOTE,
        payload: { info },
    };
};

export const deleteNote = (type, id) => {

    return {
        type: DELETE_NOTE,
        payload: { type, id },
    };
};
/** End */
