import React from 'react';
import { Button } from '@mui/material';
import { Images } from 'assets/images';
import { useModal } from 'components';

export const AllSetModal = () => {
    /**
     * Hooks.
     */

    const { close } = useModal();

    return (
        <div className="cangrats-modal">
            <div className="invite-container">
                <div className=" jusify-center">
                    <div>
                        <img src={Images.CongratsSet} alt="Congrats-Set" />
                    </div>
                </div>
                <div className="title-box">
                    <h4 className="title">Congrats you're all Set</h4>
                    <p className="subtitle column">
                        <span>Now you can calculate your first load.</span>
                        <span>Good Luck!</span>
                    </p>
                </div>
                <div className="footer-box">
                    <div className="submit-box">
                        <Button className="add-modal-button green-btn" color="success">
                            <span className="button-text">Done</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
