import React, { Fragment } from 'react';
import { Images } from 'assets/images';
import { Grid } from '@mui/material';

export const HowItFragment = () => {
    /**
     *  Hooks.
     */

    return (
        <Fragment>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6} sx={{ paddingTop: 12 }}>
                    <div className='column how-title'>
                        <div className='flex-start'>
                            <h2 className='title font-semiBold text-h2'>How It Works</h2>
                        </div>
                        <div className='card-title'>
                            <p className='we-pargraphy'>
                                Getting started is simple. All you need to do is 3 steps and you can enjoy the benefits
                                FleetForest provides
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className='card-banner'>
                        <div className='banner-img'>
                            <img src={Images.StepItem} alt='Step Item' />
                        </div>
                        <div className='banner-step'>
                            {/* Step One */}
                            <div className='how-step'>
                                <div className='step-content'>
                                    <div className='account'>
                                        <Grid container display={'flex'} spacing={4}>
                                            <Grid item>
                                                <img src={Images.StepOne} alt='Step-1' />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <h4 className='title font-semiBold text-h4'>Create Account</h4>
                                                <div className='account-text'>
                                                    Fill out the short registration form to enroll in FleetForest. Once
                                                    you create your account, the system will log you in so you can
                                                    proceed to the next step
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                            {/* Step Two */}
                            <div className='how-step'>
                                <div className='step-content'>
                                    <div className='account'>
                                        <Grid container display={'flex'} spacing={4}>
                                            <Grid item>
                                                <img src={Images.StepTwo} alt='Step-2' />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <h4 className='title font-semiBold text-h4'>Add Resources</h4>
                                                <div className='account-text'>
                                                    Begin by adding your trucks, trailers, drivers, loads etc. The
                                                    system is designed to seamlessly integrate all your data in one
                                                    place, from information to documents

                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                            {/* Step Three */}
                            <div className='how-step'>
                                <div className='step-content'>
                                    <div className='account'>
                                        <Grid container display={'flex'} spacing={4}>
                                            <Grid item>
                                                <img src={Images.StepThree} alt='Step-3' />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <h4 className='title font-semiBold text-h4'>Track your Progress</h4>
                                                <div className='account-text'>
                                                    The system will store your data for easy access and help you gain
                                                    insights into your profits and company progress
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    );
};
