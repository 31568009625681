/* Truck Reducer */

import {
    GET_TRUCK_SUCCESS,
    GET_TRUCK_DETAILS_SUCCESS,
    GET_TRUCK_DOCUMENT_SUCCESS,
    GET_TRUCK_LOADS_SUCCESS,
    GET_TRUCK_COSTS_SUCCCESS,
    REMOVE_TRUCK_DETAILS,
    GET_TRUCK_MAINTENANCE_SUCCESS,
} from './truck.type';

const initialState = {
    truckList: [],
    truckDetailsList: null,
    truckDocument: null,
    truckLoads: null,
    truckCosts: [],
    maintenance: {
        data: [],
        count: 0,
    },
};

export const truckingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRUCK_SUCCESS:
            return {
                ...state,
                truckList: action.payload,
            };
        case GET_TRUCK_DETAILS_SUCCESS:
            return {
                ...state,
                truckDetailsList: action.payload,
            };

        case REMOVE_TRUCK_DETAILS:
            return {
                ...state,
                truckDetailsList: null,
            };

        /** Truck Documents */
        case GET_TRUCK_DOCUMENT_SUCCESS:
            return {
                ...state,
                truckDocument: action.payload,
            };

        case GET_TRUCK_LOADS_SUCCESS:
            return {
                ...state,
                truckLoads: action.payload,
            };
        /** End */
        case GET_TRUCK_COSTS_SUCCCESS:
            return {
                ...state,
                truckCosts: action.payload,
            };
        case GET_TRUCK_MAINTENANCE_SUCCESS:
            return { ...state, maintenance: { data: action.payload.maintenances, count: action.payload.count } };
        default:
            return state;
    }
};
