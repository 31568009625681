import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FindError, FindSuccess, SavePage } from 'utils';
import { useModal, SubmitButton, CustomInput } from 'components';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, userActions } from 'store';
import { Box } from '@mui/material';

export const InviteUser = () => {
    /**
     * Invite User.
     */

    const { close, params } = useModal();
    const ACTION_TYPE = params?.info?.auth ? 'EDIT_USER' : 'INVITE_USER';
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const loadSuccess = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);

    const { control, handleSubmit, setError } = useForm({
        defaultValues: {
            email: params?.info?.email,
            fullName: params?.info?.fullName,
        },
    });

    const onSubmit = (data) => {
        if (params?.info?.auth) {
            dispatch(userActions.editUser(data, params?.info?.auth?.id));
        } else {
            dispatch(userActions.inviteUser(data));
        }
    };

    useEffect(() => {
        if (loadSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            SavePage(history, pushInfo);
        }
    }, [loadSuccess]);

    useEffect(() => {
        if (backError?.error === 'User already exists.') {
            setError('email');
        }
        if (backError?.error?.[0] === 'email must be an email') {
            setError('email');
        }
    }, [backError]);

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    return (
        <div className="invite-form">
            <div className="invite-title-box">
                <p>To invite another user in the system, please entered the name and email.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomInput
                    name="fullName"
                    control={control}
                    rules={{ required: true }}
                    type="text"
                    label="Username*"
                    placeholder={'Jack123'}
                    maxLength={40}
                />
                <CustomInput
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    type="email"
                    label="Email*"
                    placeholder={'User Email'}
                    errMessage={
                        backError?.error === 'User already exists.'
                            ? 'User already exists.'
                            : backError?.error && backError?.error?.[0] === 'email must be an email'
                            ? 'email must be an email'
                            : ''
                    }
                />
                <Box sx={{ marginTop: '4px' }}>
                    <SubmitButton title={params?.info ? 'Save' : 'Invite'} type="submit" actionType={ACTION_TYPE} />
                </Box>
            </form>
        </div>
    );
};
