/**  Types */

/**  Get Trailer */
export const GET_TRAILER = 'GET_TRAILER';
export const GET_TRAILER_SUCCESS = 'GET_TRAILER_SUCCESS';

/**  Create Trailer */
export const CREATE_TRAILER = 'CREATE_TRAILER';

/**  Edit Trailer */
export const EDIT_TRAILER = 'EDIT_TRAILER';

/**  Delete Trailer */
export const DELETE_TRAILER = 'DELETE_TRAILER';

/** Change Status */
export const CHANGE_TRAILER_STATUS = 'CHANGE_TRAILER_STATUS';

/** Get Trailers Id */
export const GET_TRAILER_ID = 'GET_TRAILER_ID';
export const GET_TRAILER_ID_SUCCESS = 'GET_TRAILER_ID_SUCCESS';

/** Trailer Documents */

export const CREATE_TRAILER_DOCUMENT = 'CREATE_TRAILER_DOCUMENT';

export const GET_TRAILER_DOCUMENT = 'GET_TRAILER_DOCUMENT';
export const GET_TRAILER_DOCUMENT_SUCCESS = 'GET_TRAILER_DOCUMENT_SUCCESS';

export const EDIT_TRAILER_DOCUMENT_ARCHIVE = 'EDIT_TRAILER_DOCUMENT_ARCHIVE';

export const DELETE_TRAILER_DOCUMENT = 'DELETE_TRAILER_DOCUMENT';

/** Trailer Coasts */
export const GET_TRAILER_COASTS = 'GET_TRAILER_COASTS';
export const GET_TRAILER_COASTS_SUCCESS = 'GET_TRAILER_COASTS_SUCCESS';

export const CREATE_TRAILER_COASTS = 'CREATE_TRAILER_COASTS';