import React, { Fragment } from 'react';
import { GetStart } from 'components';

export const SaveFragment = ({ handleClick }) => {
    return (
        <Fragment>
            <div className='save-box'>
                <div className='save-header'>
                    <h2 style={{ textAlign: 'center' }} className='title font-bold text-h2'>
                        Join our growing base of carriers and lets make logistics better
                        <span style={{ color: '#0e9594', marginLeft: '12px' }}>
                        together
                        </span>
                    </h2>
                </div>

                <div className='header-mobile'>
                    <div className='jusify-center'>
                        <h2 className='title font-bold'> Join our growing base of carriers and lets make logistics
                            better
                            <span style={{ color: '#0e9594', marginLeft: '8px' }}>
                            together
                            </span>
                        </h2>
                    </div>
                    {/*<div className='jusify-center'>*/}
                    {/*    <h2 className='title font-bold text-h2'>and</h2>*/}
                    {/*    <div className='' />*/}
                    {/*    <h2 className='subtitle font-bold text-h2'>lets make logistics better together</h2>*/}
                    {/*</div>*/}
                </div>
                <div className='box-description'>
                    <p className='box-paragraphy'>
                        Start your free trial to see for yourself how FleetForest’s simplicity and robust workflow can
                        help you get ahead and on top

                    </p>
                </div>
                <div className='save-free'>
                    <div className='jusify-center'>
                        <GetStart handelClick={handleClick} title={'Start your free trial'} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
