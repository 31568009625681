import React from "react";
import { CircularProgress } from "@mui/material";

export const MinLoader = ({ color, margin, style = {}, position }) => {
  const styles = {
    loaderStyle: {
      position: position ? position : "relative",
      color: color,
      width: "20px",
      height: "20px",
      margin: margin ? margin : "0 auto",
    },
  };
  return <CircularProgress style={{ ...styles.loaderStyle, ...style }} />;
};
