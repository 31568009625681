// Toast Success.

export const ToastSuccess = (success) => {
    if (success) {
        return success === 'SUCCESS_MESSAGES'
            ? 'Success confirmed'
            : success === 'CREATE_TRUCK_COSTS'
            ? 'Costs was edited'
            : success === 'SUCCES_REQ'
            ? 'Request Successfuly'
            : success === 'DELETE_MODAL' ? 'Deleted Successfully'
            : success === 'FORGOT_PASSWORD_SUCCESS'
            ? 'Check Email please'
            : success === 'EDIT_MY_PROFILE'
            ? 'Account was edited'
            : success === 'CHANGE_PASSWORD'
            ? 'Password was edited'
            : success === 'EDIT_USER'
            ? 'User was edited'
            : success === 'DELETE_USER'
            ? 'User was deleted'
            : success === 'CHANGE_TRUCK_STATUS'
            ? 'Truck status was edited'
            : success === 'EDIT_TRUCK_DETAILS'
            ? 'Truck was edited'
            : success === 'DELETE_TRUCK'
            ? 'Truck was deleted'
            : success === 'CHANGE_TRAILER_STATUS'
            ? 'Triler status was edited'
            : success === 'EDIT_TRAILER'
            ? 'Triler was edited'
            : success === 'DELETE_TRAILER'
            ? 'Triler was deleted' :
                                                                    // success === 'CREATE_DRIVERS' ? 'Create new Driver' :
                                                                        success === 'CHANGE_DRIVER_STATUS'
            ? 'Driver status was changed'
            : success === 'EDIT_DRIVER_DETAILS'
            ? 'Driver was edited'
            : success === 'EDIT_DRIVERS_RATE'
            ? 'Create Driver Rate'
            : success === 'DELETE_DRIVERS'
            ? 'Driver was deleted'
            : success === 'EDIT_LOAD'
            ? 'Load was edited'
            : success === 'DELETE_LOAD'
            ? 'Load was deleted'
            : success === 'CHANGE_LOAD_STATUS'
            ? 'Load Status was edited'
            : success === 'EDIT_LOAD_ID_STOP_ID'
            ? 'Load Stop was edited'
            : success === 'DELETE_LOAD_ID_STOP_ID'
            ? 'Load Stop was deleted'
            : success === 'EDIT_CUSTOMER'
            ? 'Customer was edited'
            : success === 'DELETE_CUSTOMER'
            ? 'Customer was deleted'
            : success === 'CHANGE_CUSTOMER_STATUS'
            ? 'Customer Status was edited'
            : success === 'EDIT_CUSTOMER_CONTACT'
            ? 'Customer Contact was edited'
            : success === 'DELETE_CUSTOMER_CONTACT'
            ? 'Customer Contact was deleted'
            : success === 'DRIVER_CREATE_FUEL'
            ? 'Create Fuel was succeeded'
            : success === 'DRIVER_EDIT_FUEL'
            ? 'Driver Fuel was edited'
            : success === 'DRIVER_DELETE_FUEL'
            ? 'Driver Fuel was deleted'
            : // success === 'CANCEL_SUBSCRIPTION' ? 'Your subscription was canceled' :
            success === 'EDIT_TRANSACTION_ACTION'
            ? 'Edit Transaction was edited'
            : success === 'ACTIVATE_PACKAGE'
            ? 'Your package was edited' :
                    success === 'RESEND_INVITE' ? 'Invite was resented' :
                    success === 'CONTACT_US' ? 'Contact messages was sent successfully' :

                    success === 'DELETE_TRUCK_DOCUMENT' ? 'Document was deleted' :
                    success === 'DELETE_TRAILER_DOCUMENT' ? 'Document was deleted' :
                    success === 'DELETE_DRIVER_DOCUMENT' ? 'Document was deleted' :
                    success === 'DELETE_LOAD_DOCUMENT' ? 'Document was deleted' :
                    success === 'EDIT_TRUCK_DOCUMENT_ARCHIVE' ? 'Document status was edited' :
                    success === 'EDIT_TRAILER_DOCUMENT_ARCHIVE' ? 'Document status was edited' :
                    success === 'EDIT_DRIVER_DOCUMENT_ARCHIVE' ? 'Document status was edited' :
                    success === 'EDIT_LOAD_DOCUMENT_ARCHIVE' ? 'Document status was edited' :
                    success === 'CREATE_TRAILER_COASTS' ? 'Trailer coasts was edited' :


                        false;
    }
};
